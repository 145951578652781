<template>
  <q-dialog v-model="dialog" persistent>
    <q-card class="q-bottom-sheet--list"  style="max-width:1500px;width:1000px;height:500px">
      <q-bar>
        <q-space />
        <q-btn dense flat v-close-popup>
          <i class="fas fa-times"></i>
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <q-form ref="vForm" @submit="onSubmit">
          <div class="row">
            <div class="col-3">
              <q-select v-model="type" option-value="val" :rules="[formRules.required]" option-label="name" :options="typeOptions" label="Type" emit-value map-options class="caps"/>
              <q-input v-model="title" label="Title" :rules="[formRules.required]"  class="caps"/>
			  <q-input v-model="description" label="Description" :rules="[formRules.required]"  class="caps"/>
			  <q-input v-model="date" mask="date" :rules="['date']"  label="Date">
				  <template v-slot:append>
					  <i class="far fa-calendar-alt cursor-pointer" >
						  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
							  <q-date v-model="date" @input="() => $refs.qDateProxy.hide()" />
						  </q-popup-proxy>
					  </i>
				  </template>
			  </q-input>
              <div class="q-pa-md q-gutter-sm">
                <q-btn  class="full-width" color="teal" type="submit" >Save</q-btn>
              </div>
            </div>
            <div class="col-9">
              <div class="q-pa-md">
                <q-table
                :title="fullname"
                :data="merits"
                :columns="columns"
                row-key="id">
                <template v-slot:body="props">
                  <q-tr slot="body"  :props="props" @click="selectedRow(props)"  style="cursor: pointer;">
                    <q-td key="types" :props="props">{{ props.row.types }}</q-td>
                    <q-td key="title" :props="props">{{ props.row.title }}</q-td>
                    <q-td key="description" :props="props">{{ props.row.description }}</q-td>
                    <q-td key="date_added" :props="props">{{ props.row.date_added }}</q-td>
                    <q-td key="updateby" :props="props">{{ props.row.updateby }}</q-td>
                  </q-tr>
                </template>
                <template v-slot:no-data="">
                  <div class="full-width row flex-center text-red q-gutter-sm">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span>
                      No Result!
                    </span>
                  </div>
                </template>
              </q-table>
            </div>
          </div>
        </div>
		</q-form>
    </q-card-section>
  </q-card>
</q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      empID:0,
      dialog:false,
	  fullname:null,
	  type:null,
	  title:null,
	  description:null,
	  date:null,
      columns: [
      { name: 'types', align: 'center', label: 'Type', field: 'types', sortable: true },
      { name: 'title', align: 'center', label: 'Title', field: 'title', sortable: true },
      { name: 'description', label: 'Description', field: 'description', sortable: true },
      { name: 'date_added', label: 'Date', field: 'date_added' , sortable: true},
      { name: 'updateby', label: 'Update By', field: 'updateby' , sortable: true},
      ],
      merits: [],
	  typeOptions:[
	  {val:1,name:'TRAININGS AND SEMINARS'},
	  {val:2,name:'AWARD AND RECOGNITION'},
	  {val:3,name:'SPECIAL PROJECTS'},
	  {val:4,name:'MEMOS/DEVIATIONS'},
	  ],
    }
  },
  created(){
    this.eventHub.$on('meritsUpdate', this.meritsUpdate);
  },

  methods: {

    meritsUpdate:function(payload){
       this.fullname = payload.employee.row.firstname + ' ' + payload.employee.row.lastname + '' + ' Records'
       this.empID = payload.employee.row.id
       this.dialog = payload.stat
      this.getAwardUpdate(this.empID)
    },

    selectedRow:function(emp){
     this.cardtype = emp.row.cardtype
     this.cardNo = emp.row.new
   },


   onSubmit:function(){
    let formData = new FormData();

    formData.append("empID", this.empID);
    formData.append("type", this.type);
    formData.append("title", this.title);
	formData.append("description", this.description);
	formData.append("date", this.date);
    formData.append("updateby", this.userData.username);

    axios.create({
      baseURL : this.apiUrl,
      headers : {'Authorization' : `Bearer ${this.token}`}
    }).post('/admin/employee/award',formData)
    .then((res) => {
		this.$q.notify({
				message: res.data.data,
				color: (res.data.status) ? 'green' : 'red',
				position:'center',
				actions: [{ label: 'Dismiss', color: 'white', handler: () => {} }]
		})
      this.getAwardUpdate(this.empID)
      	this.type = ''
      	this.title = ''
	   	this.description = ''
	   	this.date = ''
      	this.$refs.vForm.reset()
    });
  },

  getAwardUpdate: function(empID) {
    axios.create({
      baseURL: this.apiUrl,
      headers: {
        'Authorization': `Bearer ${this.token}`
      }})
    .get(`/admin/employee/award/${empID}`)
    .then((res) => {
      this.merits = res.data.data;
      setTimeout(() => {
        this.spinner = false
      },500)
    })

  },

  closeModal:function(){
    this.career = false
  },
}
}
</script>
<style lang="css" scoped>
</style>
