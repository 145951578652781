<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding">
					<q-btn outline color="green" label="TK Template"  @click="getTemplate()"/>
					<q-btn outline color="red" label="Upload Hourly TK" @click="showDialog"/>
					<q-btn outline color="green" label="Premium Template"  @click="getPremiumTemplate()"/>
					<q-btn outline color="red" label="Upload Premium" @click="showPremDialog"/>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-btn color="primary" label="Filter" @click="filterByDate"/>
				</div>
			</div>
		</div>

		<div class="q-pa-md">
			<q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="HOURLY TK"
			:data="employeeMembers" :columns="columns" row-key="id" separator="cell" :pagination.sync="pagination"
			no-data-label="I didn't find anything for you!"
			:loading="loading" :filter="filter" @request="onRequest" binary-state-sort>
			<template v-slot:no-data="">
			  <div class="full-width row flex-center text-red q-gutter-sm">
				<i class="fas fa-exclamation-triangle"></i>
				<span>The filter didn't uncover any results!</span>
			  </div>
			</template>
			  <template v-slot:loading>
	              <q-inner-loading showing>
	                  <q-spinner-hourglass  size="50px" color="cyan" />
	              </q-inner-loading>
	          </template>
	          <template v-slot:top>
	            <q-input dense v-model="filter" placeholder="Search Employee">
	              <i class="fas fa-search" slot="append"></i>
	            </q-input>
	            <q-space />
	            <q-btn outline color="primary" label="Download" no-caps @click="exportTable" />
	          </template>
	        </q-table>
		</div>

		<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="uploadFile">
					<q-card-section>
						<div class="text-h6">Upload Hourly TK</div>
					</q-card-section>
					<q-card-section>
						<small>(.csv Only)</small><br/>
						<q-file color="teal" filled v-model="files" label="Select your file"  accept=".csv" :rules="[formRules.required]">
					        <template v-slot:prepend>
					          <i class="fa fa-upload"> </i>
					        </template>
					     </q-file>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		 <q-dialog v-model="importpremium" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="uploadPremium">
					<q-card-section>
						<div class="text-h6">Upload Premium</div>
					</q-card-section>
					<q-card-section>
						<small>(.csv Only)</small><br/>
						<q-file color="teal" filled v-model="premiumfiles" label="Select your file"  accept=".csv" :rules="[formRules.required]">
					        <template v-slot:prepend>
					          <i class="fa fa-upload"> </i>
					        </template>
					     </q-file>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			filter: '',
	        loading: false,
	        pagination: {
	            page: 1,
	            rowsPerPage: 15,
	            rowsNumber: 10
	        },

			yearfilter:new Date().getFullYear(),
			yearOptions: [],

			importfile:false,
			importpremium:false,
			searchKey:'',
			icon:'fas fa-lock-open',
			isLock:false,
			lockColor:'',
			//scheduletable:'',
			//members:[],
			employees:[],
			files:[],
			premiumfiles:[],
			separator:'cell',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			spinner:false,
			file:'',

			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],
			rangeHeader:[],
			employeeMembers: [],
			initData:[],
			totalDays:0,
			involvemembers:[],

			columns: [
	          { name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true},
	          { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true},
	          { name: 'date', align: 'center', label: 'Date', field: 'date', sortable: true},

			  { name: 'REG_HOUR', align: 'center', label: 'Regular Hours', field: 'REG_HOUR', sortable: true},
	          { name: 'LATE', align: 'center', label: 'LATE', field: 'LATE', sortable: true},
	          { name: 'UNDERTIME', align: 'center', label: 'UNDERTIME', field: 'UNDERTIME', sortable: true},
			  { name: 'ABSENT', align: 'center', label: 'ABSENT', field: 'ABSENT', sortable: true},
			  { name: 'OT', align: 'center', label: 'OVERTIME', field: 'OT', sortable: true},
			  { name: 'RD', align: 'center', label: 'RD', field: 'RD', sortable: true},
			  { name: 'RD_OT', align: 'center', label: 'RD OT', field: 'RD_OT', sortable: true},
			  { name: 'SH', align: 'center', label: 'SH', field: 'SH', sortable: true},
			  { name: 'SH_OT', align: 'center', label: 'SH OT', field: 'SH_OT', sortable: true},
			  { name: 'SH_RD', align: 'center', label: 'LH OT', field: 'SH_RD', sortable: true},
			  { name: 'SH_RD_OT', align: 'center', label: 'SH_RD_OT', field: 'SH_RD_OT', sortable: true},
			  { name: 'LH', align: 'center', label: 'LH', field: 'LH', sortable: true},
			  { name: 'LH_OT', align: 'center', label: 'LH_OT', field: 'LH_OT', sortable: true},
			  { name: 'LH_RD', align: 'center', label: 'LH_RD', field: 'LH_RD', sortable: true},
			  { name: 'LH_RD_OT', align: 'center', label: 'LH_RD_OT', field: 'LH_RD_OT', sortable: true},
			  { name: 'ND', align: 'center', label: 'ND', field: 'ND', sortable: true},
			  { name: 'ND_OT', align: 'center', label: 'ND_OT', field: 'ND_OT', sortable: true},
			  { name: 'ND_RD', align: 'center', label: 'ND_RD', field: 'ND_RD', sortable: true},
			  { name: 'ND_RD_OT', align: 'center', label: 'ND_RD_OT', field: 'ND_RD_OT', sortable: true},
			  { name: 'ND_SH', align: 'center', label: 'ND_SH', field: 'ND_SH', sortable: true},
			  { name: 'ND_SH_OT', align: 'center', label: 'ND_SH_OT', field: 'ND_SH_OT', sortable: true},
			  { name: 'ND_SH_RD', align: 'center', label: 'ND_SH_RD', field: 'ND_SH_RD', sortable: true},
			  { name: 'ND_LH', align: 'center', label: 'ND_LH', field: 'ND_LH', sortable: true},
			  { name: 'ND_LH_RD', align: 'center', label: 'ND_LH_RD', field: 'ND_LH_RD', sortable: true},
			  { name: 'ND_LH_RD_OT', align: 'center', label: 'ND_LH_RD_OT', field: 'ND_LH_RD_OT', sortable: true},
			  { name: 'SL', align: 'center', label: 'SL', field: 'SL', sortable: true},
			  { name: 'VL_EL', align: 'center', label: 'VL/EL', field: 'VL_EL', sortable: true},
			  { name: 'OBT', align: 'center', label: 'OBT', field: 'OBT', sortable: true},

	        ],
		}
	},

	created(){
		this.yearsList()
	},

	mounted(){
        this.onRequest({
            pagination: this.pagination,
            filter: ''
        })
    },

	methods: {
		//start if server side
		onRequest (props) {

	      const { page, rowsPerPage} = props.pagination

	      const filter = props.filter
	      this.loading = true
	      // emulate server
	      setTimeout(() => {
	        this.getRowsNumberCount(filter).then(total => {
	            this.pagination.rowsNumber = total
	        })
	        const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
	        const startRow = (page - 1) * rowsPerPage
	        this.fetchFromServer(startRow, fetchCount, filter)
	        this.pagination.page = page
	        this.pagination.rowsPerPage = rowsPerPage
	      }, 1500)
	    },

	    fetchFromServer (startRow, count, filter) {
	        axios.get(this.apiUrl + '/hourly/tk/generate', {
	            headers: { 'Authorization': `Bearer ${this.token}` },
	            params: {
	              year: this.yearfilter,
	              month: this.month,
	              cutoff: this.cutoff,
	              start: startRow,
	              countPerPage: count,
	              filter: filter,
	            }
	        }).then((response) => {
	            this.employeeMembers = response.data.members
				this.getHourlyTKSummary()
				this.loading = false
	        }).catch((error) => {
	            console.log(error);
	        })
	   },

	    async getRowsNumberCount (filter) {
	       let response = await axios.get(this.apiUrl + '/hourly/tk/generate/total', {
	           headers: { 'Authorization': `Bearer ${this.token}` },
	           params: {
	             year: this.yearfilter,
	             month: this.month,
	             cutoff: this.cutoff,
	             filter: filter,
	           }
	       })
	       return response.data
	    },

		///end of server side

		showDialog:function(){
			this.importfile = true
		},

		showPremDialog:function(){
			this.importpremium = true
		},

		closeModal:function(){
			this.importfile = false
			this.importpremium = false
		},

		// displaySchedule: function() {
		// 	this.spinner  = true
		// 	axios.create({
		// 		baseURL: this.apiUrl,
		// 		headers: {
		// 			'Authorization': `Bearer ${this.token}`
		// 		}})
		// 	.get(`/hourly/tk/generate/${this.yearfilter}/${this.month}/${this.cutoff}`)
		// 	.then((res) => {
		// 		this.getHourlyTKSummary()
		// 		//this.rangeHeader = res.data.ranges
		// 		this.employeeMembers = res.data.members
		// 		this.initData = res.data.members
		// 		this.spinner  = false
		// 	})
		// },

		getHourlyTKSummary: function(){
	      let formData = new FormData();
	      formData.append("year", this.yearfilter);
	      formData.append("month", this.month);
	      formData.append("cutoff", this.cutoff);
	      axios.create({
	          baseURL: this.apiUrl,
	          headers: { 'Authorization': `Bearer ${this.token}` }
	        }).post(`/timekeeping/generate/hourly/summary`, formData);
	    },

		filterByDate:function(){
			 this.onRequest({pagination:this.pagination,filter:''})
		},

		getTemplate: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get('/employee/template/download')
			.then((res) => {
				this.downloadTemplate(res.data.data.hourly_tk_template)
			})
		},

		getPremiumTemplate: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get('/employee/template/download')
			.then((res) => {
				this.downloadPremiumTemplate(res.data.data.hourly_premium_template)
			})
		},

		uploadFile:function(){
			this.spinner = true
			let formData  = new FormData();
			formData.append("files", this.files);
			axios({
				onUploadProgress: uploadEvent => {
					console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
				},
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+'/hourly/tk/upload',
				data: formData,
				method: 'POST'
				}).then(response => {
					//this.displaySchedule()
					setTimeout(() => {
						this.spinner = false
						this.importfile = false
						this.files = ''
						this.$q.notify({
							message: 'Hourly TK Uploaded Successfully!',
							color: 'green',
							position:'center',
							actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
						})
					},500)
				}).catch((error) => {
					console.log(error)
			})
		},

		uploadPremium:function(){
			this.spinner = true
			let formData  = new FormData();
			formData.append("files", this.premiumfiles);
			axios({
				onUploadProgress: uploadEvent => {
					console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
				},
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+'/hourly/premium/upload',
				data: formData,
				method: 'POST'
				}).then(response => {
					//this.displaySchedule()
					setTimeout(() => {
						this.spinner = false
						this.importpremium = false
						this.premiumfiles = ''
						this.$q.notify({
							message: 'Premium Uploaded Successfully!',
							color: 'green',
							position:'center',
							actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
						})
					},500)
				}).catch((error) => {
					console.log(error)
			})
		},

		downloadTemplate:function(url){
			axios({
				url: this.apiUrl+'/'+url,
				method: 'GET',
				responseType: 'arraybuffer',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'HourlyTK_Template.csv');
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},

		downloadPremiumTemplate:function(url){
			axios({
				url: this.apiUrl+'/'+url,
				method: 'GET',
				responseType: 'arraybuffer',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'HourlyPremium_Template.csv');
				document.body.appendChild(fileLink);
				fileLink.click();
			});
		},

		yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.employeeMembers.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'HourlyTK.csv',
			content,
			'text/csv'
			)
			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
