<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="full-width row justify-center q-ma-md">
               <q-form @submit="searchEmployee">
                 <div class="row items-start">
                   <div class="row inline q-pa-md">
                      <q-input v-model="employee_id" square outlined label="Employee ID"/>
                   </div>
                   <div class="row inline q-pa-md">
                     <q-btn outline style="height: 54px; color: red;" label="Search Employee" type="submit" />
                   </div>
                 </div>
               </q-form>
            </div>

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-10 q-pa-md">
                    <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                       <q-card-section class="text-center">
                         <q-img :src="require('../../assets/header_pic.png')" basic width="400px" class="items-center" />
                         <p class="text-overline">15th Floor Raffles Corporate Center, Emerald Ave. Ortigas Center, Pasig</p>
                       </q-card-section>
                       <q-separator/>
                        <div class="row">
                           <div class="col-12 col-lg-12 col-xs-12 col-sm-12 q-mb-md q-pr-xl">
                              <q-markup-table separator="none" flat>
                                 <tbody>
                                   <tr>
                                     <td class="text-right text-subtitle2">Employee Name:</td>
                                     <td class="text-left"><q-input v-model="name" dense readonly /></td>
                                     <td class="text-right text-subtitle2">Account:</td>
                                     <td class="text-left"><q-input v-model="account" dense readonly/></td>
                                   </tr>
                                   <tr>
                                     <td class="text-right text-subtitle2">Date of interview</td>
                                     <td class="text-left"><q-input v-model="date_interview" dense readonly/></td>
                                     <td class="text-right text-subtitle2">Position:</td>
                                     <td class="text-left"><q-input v-model="position" dense readonly/></td>
                                   </tr>
                                   <tr>
                                     <td class="text-right text-subtitle2">Date hired</td>
                                     <td class="text-left"><q-input v-model="date_hired" dense readonly/></td>
                                     <td class="text-right text-subtitle2">Immediate Head:</td>
                                     <td class="text-left"><q-input v-model="immediate_head" dense readonly/></td>
                                   </tr>
                                 </tbody>
                               </q-markup-table>
                           </div>
                        </div>
                        <p class="text-overline text-center text-italic">Your honest and constructive feedback will go a long way. This will help the organization identify strengths and areas for improvement.</p>
                        <q-separator/>
                        <q-card-section>
                           <p class="text-h6">Reason/s for Resignation.</p>
                           <div class="row">
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-checkbox v-model="reasons.betterCompensationPackage" size="lg" label="Better compensation package"/>
                                <q-checkbox v-model="reasons.careerChange" size="lg" label="Career change"/>
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-checkbox v-model="reasons.localEmployment" size="lg" label="Local Employment"  />
                                <q-checkbox v-model="reasons.distanceProximity" size="lg" label="Distance or Proximity"  />
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-checkbox v-model="reasons.overseasEmployment" size="lg" label="Overseas Employment"  />
                                <q-checkbox v-model="reasons.personalReason" size="lg" label="Family and/or personal reasons"  />
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-checkbox v-model="reasons.healthIssues" size="lg" label="Health Issues"/>
                             </div>
                          </div>
                          <q-input v-model="reasons.otherReason" label="Others, please specify: " style="max-width: 500px"/>
                       </q-card-section>
                       <q-separator/>
                       <q-card-section>
                          <p class="text-h6">Tasks, Roles and Responsibilities</p>
                          <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                             <q-markup-table separator="none" flat>
                                <thead>
                                <tr>
                                  <th class="text-center"></th>
                                  <th class="text-center">Strongly Disagree</th>
                                  <th class="text-center">Disagree</th>
                                  <th class="text-center">Agree</th>
                                  <th class="text-center">Strongly Agree</th>
                                </tr>
                              </thead>
                                <tbody>
                                  <tr>
                                    <td class="text-left">Job was challenging</td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.jobWasChallenging" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.jobWasChallenging" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.jobWasChallenging" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.jobWasChallenging" val="4"/></td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Sufficient opportunities for advancement </td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientOpportunity" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientOpportunity" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientOpportunity" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientOpportunity" val="4"/></td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Workload was manageable</td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.workloadManageable" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.workloadManageable" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.workloadManageable" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.workloadManageable" val="4"/></td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Sufficient resources and staff were available</td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientResources" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientResources" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientResources" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.sufficientResources" val="4"/></td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Skills were effectively used</td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.skillWereEffective" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.skillWereEffective" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.skillWereEffective" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.skillWereEffective" val="4"/></td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Access to adequate training and development programs </td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.accessToAdequate" val="1"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.accessToAdequate" val="2"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.accessToAdequate" val="3"/></td>
                                    <td class="text-center"><q-radio size="lg" v-model="tasks.accessToAdequate" val="4"/></td>
                                  </tr>
                                </tbody>
                              </q-markup-table>
                              <q-input v-model="tasks.whatCanBeImproved" label="What do you think can be improved about the job?" style="max-width: 500px"/>
                          </div>
                      </q-card-section>
                      <q-separator/>
                      <q-card-section>
                        <p class="text-h6">Wages & Benefits</p>
                        <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                           <q-markup-table separator="none" flat>
                              <thead>
                              <tr>
                                 <th class="text-center"></th>
                                 <th class="text-center">Strongly Disagree</th>
                                 <th class="text-center">Disagree</th>
                                 <th class="text-center">Agree</th>
                                 <th class="text-center">Strongly Agree</th>
                              </tr>
                             </thead>
                              <tbody>
                                 <tr>
                                   <td class="text-left">Adequate salary in relation to responsibilities</td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.adequateSalary" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.adequateSalary" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.adequateSalary" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.adequateSalary" val="4"/></td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Wages were paid on time</td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.wagesPaid" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.wagesPaid" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.wagesPaid" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.wagesPaid" val="4"/></td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Company benefits were good </td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.companyBenefits" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.companyBenefits" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.companyBenefits" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.companyBenefits" val="4"/></td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Work-life balance promoted and practiced</td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.workLikeBalance" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.workLikeBalance" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.workLikeBalance" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.workLikeBalance" val="4"/></td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Merit increase reflects performance and contributions to the company</td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.meritIncrease" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.meritIncrease" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.meritIncrease" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.meritIncrease" val="4"/></td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Highly satisfied with employee services (Pantry, Clinic & Gym)</td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.highlySatisfied" val="1"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.highlySatisfied" val="2"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.highlySatisfied" val="3"/></td>
                                   <td class="text-center"><q-radio size="lg" v-model="wages.highlySatisfied" val="4"/></td>
                                 </tr>
                              </tbody>
                             </q-markup-table>
                             <q-input v-model="wages.whatImprovement" label="What improvements, other benefits could the company offer?" style="max-width: 500px"/>
                        </div>
                    </q-card-section>
                    <q-separator/>
                    <q-card-section>
                      <p class="text-h6">The Organization</p>
                      <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                         <q-markup-table separator="none" flat>
                            <thead>
                            <tr>
                               <th class="text-center"></th>
                               <th class="text-center">Strongly Disagree</th>
                               <th class="text-center">Disagree</th>
                               <th class="text-center">Agree</th>
                               <th class="text-center">Strongly Agree</th>
                            </tr>
                           </thead>
                            <tbody>
                               <tr>
                                 <td class="text-left">Favorable and encouraging workplace</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.favorable" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.favorable" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.favorable" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.favorable" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Appropriate tools and equipment for the tasks</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.appropriateTools" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.appropriateTools" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.appropriateTools" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.appropriateTools" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Fosters positive relationships</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fosterPositive" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fosterPositive" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fosterPositive" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fosterPositive" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Sufficient staff to cover workload</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.sufficientStaff" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.sufficientStaff" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.sufficientStaff" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.sufficientStaff" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Effective internal communication</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.effectiveEternal" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.effectiveEternal" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.effectiveEternal" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.effectiveEternal" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Respectful and harassment-free</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.respectfulandHarrassment" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.respectfulandHarrassment" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.respectfulandHarrassment" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.respectfulandHarrassment" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Fair treatment without discrimination</td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fairTreatment" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fairTreatment" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fairTreatment" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="organization.fairTreatment" val="4"/></td>
                               </tr>
                            </tbody>
                           </q-markup-table>
                           <q-input v-model="organization.whatDoYouThink" label="What do you think can be improved about the Department and Company?" style="max-width: 500px"/>
                      </div>
                     </q-card-section>
                     <q-separator/>
                     <q-card-section>
                      <p class="text-h6">Management (Manager / Supervisor / TL)</p>
                      <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                         <q-markup-table separator="none" flat>
                            <thead>
                            <tr>
                               <th class="text-center"></th>
                               <th class="text-center">Strongly Disagree</th>
                               <th class="text-center">Disagree</th>
                               <th class="text-center">Agree</th>
                               <th class="text-center">Strongly Agree</th>
                            </tr>
                           </thead>
                            <tbody>
                               <tr>
                                 <td class="text-left">Sufficient knowledge of the job</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.sufficientKnowledge" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.sufficientKnowledge" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.sufficientKnowledge" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.sufficientKnowledge" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Gives fair and equal treatment</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.givesFair" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.givesFair" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.givesFair" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.givesFair" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Offers and promotes ways to develop</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.offerAndPromote" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.offerAndPromote" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.offerAndPromote" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.offerAndPromote" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Provides constructive feedback</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.provideConstructive" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.provideConstructive" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.provideConstructive" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.provideConstructive" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Receptive to open communication</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.receptive" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.receptive" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.receptive" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.receptive" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Maintains a professional relationship</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.maintain" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.maintain" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.maintain" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.maintain" val="4"/></td>
                               </tr>
                               <tr>
                                 <td class="text-left">Recognizes and acknowledges achievements</td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.recognizes" val="1"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.recognizes" val="2"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.recognizes" val="3"/></td>
                                 <td class="text-center"><q-radio size="lg" v-model="management.recognizes" val="4"/></td>
                               </tr>
                            </tbody>
                           </q-markup-table>
                           <q-input v-model="management.yourSuggestions" label="What are your suggestions for improvement to your Manager/Supervisor/TL?" style="max-width: 600px"/>
                      </div>
                     </q-card-section>
                     <q-separator/>
                     <q-card-section class="text-center">
                     <p class="text-h6">Are there other things you'd like to tell us? Please feel free to share it/them with us.</p>
                     <q-input v-model="remarks" filled type="textarea"/>
                     <br/><br/><br/>
                     <p class="text-subtitle1 text-italic">Thank you for providing this information. Your responses will be kept confidential.</p>
                     <q-btn color="primary"  @click="submitInterview" label="Submit Answer" size="lg"/>
                     </q-card-section>
                    </q-card-section>
                </q-card>
            </div>
        </div>
        <q-dialog v-model="confirm" persistent>
         <q-card>
           <q-card-section class="row items-center">
             <q-avatar icon="fa fa-check" color="primary" text-color="white" />
             <span class="q-ml-sm">PLease make sure all questions are answered!</span>
           </q-card-section>

           <q-card-actions align="right">
             <q-btn flat label="Cancel" color="primary" v-close-popup />
             <q-btn flat label="I Agree" color="primary" @click="confirmSubmission()" />
           </q-card-actions>
         </q-card>
       </q-dialog>
    </div>
</template>

<script>

import axios from 'axios'
import moment from "moment";

export default{
    data(){
        return{
            employee_id:'',
            name:'',
            account:'',
            date_interview:'',
            position:'',
            date_hired:'',
            immediate_head:'',

            reasons:{
               betterCompensationPackage: false,
               localEmployment: false,
               overseasEmployment: false,
               healthIssues: false,
               careerChange: false,
               distanceProximity: false,
               personalReason: false,
               otherReason: '',
            },

            //Tasks, Roles and Responsibilities
            tasks:{
               jobWasChallenging: '',
               sufficientOpportunity: '',
               workloadManageable: '',
               sufficientResources: '',
               skillWereEffective: '',
               accessToAdequate: '',
               whatCanBeImproved: '',
            },

            //Wages & Benefits
            wages:{
               adequateSalary: '',
               wagesPaid: '',
               companyBenefits: '',
               workLikeBalance: '',
               meritIncrease: '',
               highlySatisfied: '',
               whatImprovement: '',
            },

            //The Organization
            organization:{
               favorable: '',
               appropriateTools: '',
               fosterPositive: '',
               sufficientStaff: '',
               effectiveEternal: '',
               respectfulandHarrassment: '',
               fairTreatment: '',
               whatDoYouThink: '',
            },

            //Management (Manager / Supervisor / TL)
            management:{
               sufficientKnowledge: '',
               givesFair: '',
               offerAndPromote: '',
               provideConstructive: '',
               receptive: '',
               maintain: '',
               recognizes: '',
               yourSuggestions: '',
            },
            remarks:"",
            confirm:false

        }
    },
    methods: {
        searchEmployee:function(){
            let formData = new FormData();
            formData.append("employee_id", this.employee_id);
    		axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
            }).post(`/employee/search`, formData).then((res) => {

               if(!res.data.status){
                  this.$q.notify({
                    message: res.data.message,
                    color: 'negative',
                    position:'center',
                    actions: [
                      { label: 'Close', color: 'white', handler: () => { /* ... */ } }
                    ]
                 });
              }else{
                 this.name = res.data.data.name
                 this.account = res.data.data.account
                 this.date_interview = res.data.data.date_interview
                 this.position = res.data.data.position
                 this.date_hired = res.data.data.date_hired
                 this.immediate_head = res.data.data.immediate_head
              }

            });
        },

        submitInterview:function(){
            this.confirm = true
        },

        confirmSubmission:function(){
            let formData = new FormData();
            formData.append("employee_id", this.employee_id);
            formData.append("reasons", JSON.stringify(this.reasons));
            formData.append("tasks", JSON.stringify(this.tasks));
            formData.append("wages", JSON.stringify(this.wages));
            formData.append("organization", JSON.stringify(this.organization));
            formData.append("management", JSON.stringify(this.management));
            formData.append("remarks", this.remarks);
       		axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}`,'Content-Type': 'application/json'}
            }).post(`/employee/interview`, formData).then((res) => {
               this.confirm = false
               this.clearData();
               this.$q.notify({
                 message: res.data.message,
                 color: 'positive',
                 position:'center',
                 actions: [
                   { label: 'Close', color: 'white', handler: () => { /* ... */ } }
                 ]
              });
            });
        },

        clearData:function(){
           this.employee_id = '',
           this.name = '',
           this.account = '',
           this.date_interview = '',
           this.position = '',
           this.date_hired = '',
           this.immediate_head = '',

           this.reasons.betterCompensationPackage = false,
           this.reasons.localEmployment = false,
           this.reasons.overseasEmployment = false,
           this.reasons.healthIssues = false,
           this.reasons.careerChange = false,
           this.reasons.distanceProximity = false,
           this.reasons.personalReason = false,
           this.reasons.otherReason = '',

           //Tasks, Roles and Responsibilities
           this.tasks = {},

           //Wages & Benefits
           this.wages = {},

           //The Organization
           this.organization = {},

           //Management (Manager / Supervisor / TL)
           this.management = {},
           this.remarks = "",
           this.confirm = false
        }
    },

    mounted(){

    }
}
</script>
