<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set Module Permissions Access</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table id="result-list" title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="id" :hide-bottom="searchResultData.length > 0" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
            <q-td key="action" :props="props">
              <div>
                <q-btn color="indigo-8" @click="listModules(props.row.id,props.row.name)">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                  <q-tooltip anchor="top middle" content-class="bg-indigo-8" self="bottom middle" :offset="[10, 10]">
                    <strong>Module Permissions Access</strong>
                  </q-tooltip>
                </q-btn>
              </div>
            </q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-red-5 q-gutter-sm">
              <span>
                {{ message }}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <q-dialog v-model="accessDialog" persistent transition-show="scale" transition-hide="scale">
        <div class="bg-white" style="height: auto; width: 600px; max-width: 600px;">
          <q-card class="my-card">
            <q-card-section class="q-ma-none q-pa-none q-pl-md bg-indigo-8 text-white">
              <q-item class="q-pa-md">
                <q-item-section avatar class="q-ml-xs q-mr-md" style="width:40px; min-width:40px;">
                  <q-avatar text-color="white" icon="fa fa-1p5x fa-key" />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-h5">Modules Permission</q-item-label>
                  <q-item-label class="text-h6">{{ empName }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-card-section>
            <q-card-section class="q-ma-none q-pt-lg q-pl-lg q-pr-lg">
              <q-item-section>
                <div class="row q-col-gutter-sm" ref="accessDialogContents">
                  <div class="col-6" v-for="mods in modaxs" :key="mods.id">
                    <q-item tag="label">
                      <q-item-section side top>
                        <q-toggle v-model="mods.enabled" checked-icon="fa fa-check" color="green" unchecked-icon="fa fa-times" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="qitemLabel">{{ humanize(mods.name) }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale" :content-class="mods.enabled ? 'bg-red' : 'bg-green'" content-style="font-size: 13px">
                      <div v-if="mods.enabled === true">
                        Click to disable module access.
                      </div>
                      <div v-else>
                        Click to enable module access.
                      </div>
                    </q-tooltip>
                  </div>
                </div>
              </q-item-section>
            </q-card-section>
            <q-card-section>
              <div class="row justify-center q-gutter-md q-mb-sm">
                <q-btn :loading="modEffectLoading" @click="modulePermissionUpdate" size="md" style="width: 200px" color="indigo-8"> CONFIRM
                  <template v-slot:loading>
                    <q-spinner-gears class="on-left" />
                    UPDATING...
                  </template>
                </q-btn>
                <q-btn label="CLOSE" color="grey-8" size="md" style="width: 200px;" v-close-popup />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-dialog>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Loading Permissions
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'ModulePermissionsAccess',
  data() {
    return {
      empName: '',
      loadingModel: false,
      loading: false,
      modEffectLoading: false,
      accessDialog: false,
      accessGroup: [],
      confirmConfig: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          align: 'center',
        }
      ],
      modules: [],
      modaxsInit: [],
      modaxs: [],
      updatedMods: [],
      selectedIsAdmin: false,
      selectedEmployeeId: 0,
      borderRadius: 'border-radius'
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.loading = true;
      this.modulesArea = false;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/search', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          if (res.data.data.length > 0) {
            for (let emp of res.data.data) {
              this.searchResultData.push({
                id: emp.id,
                name: this.toTitleCase(emp.name),
                employee_id: emp.employee_id,
                position: emp.position,
                department: emp.department
              });
            }
          } else {
            this.modulesArea = false;
            this.confirmConfig = false;
          }
        })
    },

    listModules: function(id, name) {
      this.loadingModel = true;
      this.accessDialog = true;
      this.selectedEmployeeId = id;
      this.empName = name;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/module/modules-permission-access', {
          params: {
            id: id,
          }
        })
        .then((res) => {
          this.loadingModel = false;
          this.modules = res.data.data;
          let modLength = this.modules.length;
          this.modaxsInit = [];
          this.modaxs = [];
          var i;

          for (i = 1; i < modLength; i++) {
            this.modaxs.push({
              id: this.modules[i].id,
              name: this.humanize(this.modules[i].name),
              enabled: this.modules[i].enabled,
            });
            this.modaxsInit.push({
              id: this.modules[i].id,
              name: this.humanize(this.modules[i].name),
              enabled: this.modules[i].enabled
            });
          }
          this.confirmConfig = true;
        })
    },

    modulePermissionUpdate: function() {
      this.updatedMods = [];
      for (let mod of this.modaxs) {
        this.updatedMods.push({
          id: mod.id,
          name: mod.name,
          enabled: mod.enabled
        });
      }

      if (JSON.stringify(this.modaxs) === JSON.stringify(this.modaxsInit)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'No changes made in modules permission.'
        });
      } else {
        this.modEffectLoading = true;

        let formData = new FormData();

        formData.append('employee_id', this.selectedEmployeeId);
        formData.append('modules', JSON.stringify(this.updatedMods));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/employees/module/set-modules-permission-access', formData).then((res) => {
          console.log('Modules Permission Set Success');
        })

        setTimeout(() => {
          this.modEffectLoading = false;
          this.$q.notify({
            color: 'green-7',
            textColor: 'white',
            icon: 'fa fa-cogs',
            message: 'Modules permission set success.'
          });
          this.accessDialog = false;
        }, 1500);
      }
    }
  }
}
</script>
<style lang="css" scoped>
.qitemLabel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.text-h6 {
  font-size: 14px;
}

#result-list tr:hover {
  cursor: default;
}
</style>