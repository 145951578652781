import Vue from 'vue'

import './styles/quasar.styl'
import 'quasar/dist/quasar.ie.polyfills'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'

import {
  Quasar,
  ClosePopup,
  Loading,
  Notify,
  QAvatar,
  QBadge,
  QBanner,
  QBar,
  QBtn,
  QBtnDropdown,
  QCard,
  QCardActions,
  QCardSection,
  QCheckbox,
  QChip,
  QDate,
  QDialog,
  Dialog,
  QDrawer,
  QExpansionItem,
  QField,
  QFile,
  QForm,
  QHeader,
  QIcon,
  QImg,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QKnob,
  QLayout,
  QList,
  QOptionGroup,
  QPage,
  QPageContainer,
  QPageSticky,
  QPopupEdit,
  QPopupProxy,
  QRadio,
  QScrollArea,
  QSelect,
  QSeparator,
  QSlider,
  QSpace,
  QSpinner,
  QSpinnerCube,
  QSpinnerDots,
  QSpinnerGears,
  QSpinnerHourglass,
  QStep,
  QStepper,
  QStepperNavigation,
  QTable,
  QTd,
  QTh,
  QTime,
  QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  QTr,
  QUploader,
  QLinearProgress,
  QMarkupTable,
  QBtnToggle,
  QMenu,
  QChatMessage,
  Ripple,
  QSkeleton,
  QPullToRefresh,
  QInnerLoading,
} from 'quasar'

Vue.use(Quasar, {
  config: {},
  components: {
    Loading,
    QAvatar,
    QBadge,
    QBanner,
    QBar,
    QBtn,
    QBtnDropdown,
    QCard,
    QCardActions,
    QCardSection,
    QCheckbox,
    QChip,
    QDate,
    QDialog,
    QDrawer,
    QExpansionItem,
    QField,
    QFile,
    QForm,
    QHeader,
    QIcon,
    QImg,
    QInput,
    QItem,
    QItemLabel,
    QItemSection,
    QKnob,
    QLayout,
    QList,
    QOptionGroup,
    QPage,
    QPageContainer,
    QPageSticky,
    QPopupEdit,
    QPopupProxy,
    QRadio,
    QScrollArea,
    QSelect,
    QSeparator,
    QSlider,
    QSpace,
    QSpinner,
    QSpinnerCube,
    QSpinnerDots,
    QSpinnerGears,
    QSpinnerHourglass,
    QStep,
    QStepper,
    QStepperNavigation,
    QTable,
    QTd,
    QTh,
    QTime,
    QToggle,
    QToolbar,
    QToolbarTitle,
    QTooltip,
    QTr,
    QUploader,
    QLinearProgress,
    QMarkupTable,
    QBtnToggle,
    QMenu,
    QChatMessage,
    Ripple,
    QSkeleton,
    QPullToRefresh,
    QInnerLoading,
  },
  directives: {
    ClosePopup,
    Ripple
  },
  plugins: {
    Notify,
    Dialog,
    QSpinnerGears,
    QUploader,
    QStepper,
    QStep,
    QStepperNavigation,
    QExpansionItem,
    Loading,
  },
  iconSet: iconSet
})
