<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Exam List
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
        <div class="row q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <q-btn outline label="For Exam" @click="multipleForExam" color="primary"/>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter"
                        row-key="id" dense
                        selection="multiple" :selected.sync="forExamSelected"
                        virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px"
                        >

                  <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>
                      <q-btn
                        class="q-mb-lg q-mr-sm"
                        color="primary"
                        icon-right="fas fa-download"
                        label="Export to csv"
                        no-caps
                        @click="exportTable"
                      />

                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>

                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer">
                    <q-td>
                      <q-checkbox dense v-model="props.selected" />
                    </q-td>
                    <q-td key="action" :props="props">
                      <q-btn key="status" color="primary" @click="forExam(props.row.id)" rounded size="sm" text-color="white" :label="props.row.status" />
                    </q-td>
                    <!-- <q-td key="trainee_id" :props="props" >{{ props.row.trainee_id }}</q-td> -->

                    <q-td key="applicant_id" :props="props">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" >{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" >{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" >{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" >{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" >{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" >{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" >{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" >{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" >{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" >{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" >{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" >{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" >{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" >{{ props.row.account }}</q-td>
                    <q-td key="batch" :props="props" >{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" :props="props" >{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" :props="props" >{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" :props="props" >{{ props.row.result }}</q-td>

                    <q-td key="date_of_application" :props="props">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" :props="props">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" :props="props">{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="count_failed" :props="props">{{ props.row.count_failed }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forExamAction" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Exam</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Date</div>
            <div class="col-6 q-pl-sm">Exam Source</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-input type="date" v-model="form_exam.examDate" outlined color="indigo-6"></q-input>
            </div>
            <div class="col-6 q-pl-sm">
              <q-select v-model="form_exam.examSource" :options="examSource" outlined color="indigo-6"/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="For Exam" class="bg-indigo-6 text-white" @click="updateApplicationExam" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="notSelectedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="fas fa-exclamation" color="red" text-color="white" />
          <span class="q-ml-sm">There is no selected applicants!
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Okay" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'ForInitialExam',
  data() {
    return {
      examSource: ['BUPLAS', 'HRIS'],
      filter: '',
      forexam: '',
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      forExamAction: false,
      actionDialog: false,
      applications: '',
      applicationsData: [],
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1',
        },
        // {
        //   name: 'trainee_id',
        //   field: 'trainee_id',
        //   label: 'Trainee ID',
        //   sortable: true,
        //   style: 'width: 240px',
        //   align: 'center',
        //   headerStyle: 'width: 240px',
        //   headerClasses: 'bg-indigo-1'
        // },

        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'batch',
          field: 'batch',
          label: 'Batch',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_hired',
          field: 'date_hired',
          label: 'Date Hired',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'expected_pbdate',
          field: 'expected_pbdate',
          label: 'Expected PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'result',
          field: 'result',
          label: 'Result',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'count_failed',
          field: 'count_failed',
          label: 'Number of Failed',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
      ],
      applicationCounts: 0,
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      form_exam: {
        examID: [],
        examDate: null,
        examSource: null,
      },
      approveNotifType: '',
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'For Exam'
      },
      forExamSelected: [],
      multipleExam: [],
      notSelectedDialog: false,
      timer: "",
    }
  },
  methods: {
    searchData: function(){
            vueCookies.set(this.searchKey, {
                'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },
    loadApplications: function() {
      this.loadingText = 'Retrieving Applications';
      this.applications = [];
      this.applicationsData = [];

      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }
      this.filter = this.searchKeyword;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-applicant-list', {
          params: {
            status: 'For Exam',
            passfail: ''
          }
        })
        .then((res) => {
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          // console.log(this.applications)

          for (let eachApplication of this.applications) {

            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: 'For Exam',
              count_failed: eachApplication.count_failed,
            });

          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    searchDate(){
      this.loading = true;
      let formData = new FormData();
      this.searchDateToFrom.dateFrom = this.dateFrom;
      this.searchDateToFrom.dateTo = this.dateTo;

      formData.append('form_data', JSON.stringify(this.searchDateToFrom));
      axios.create({
          baseURL: this.apiUrl,
          headers: {
              'Authorization': `Bearer ${this.token}`
          }
          }).post('/recruitments/application/search-date', formData).then((res) => {
          // console.log(res)
          this.loading = false;
          this.applicationsData = [];
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;

          for (let eachApplication of this.applications) {
            // console.log(eachApplication)
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: eachApplication.edattain,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: 'For Exam',
              count_failed: eachApplication.count_failed,
            });

          }
      });
    },

    forExam: function(rowID){
      this.form_exam.examID = [];
      this.form_exam.examID.push(rowID);
      this.forExamAction = true;
    },

    updateApplicationExam: function(){
      this.loadingAction = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_exam));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-for-exam', formData).then((res) => {
        setTimeout(() => {
          this.forExamSelected = [];
          this.loadApplications();
          this.loadingAction = false;
        }, 1000);
      });
    },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        // axios.create({
        //   baseURL: this.apiUrl,
        //   headers: {
        //     'Authorization': `Bearer ${this.token}`
        //   }
        // }).post('/recruitments/application/assessment', formData).then((res) => {
        //   setTimeout(() => {
        //     this.loadingAction = false;
        //     this.actionDialog = false;
        //     this.viewDetailsDialog = false;
        //     this.applicationsData = [];
        //     this.loadApplications();
        //     this.$q.notify({
        //       color: 'green-7',
        //       textColor: 'white',
        //       icon: 'fa fa-file-signature',
        //       message: 'Assessment Done'
        //     });
        //     this.form.assessorAction = null;
        //     this.form.assessorRemarks = null;
        //     this.form.failedAssessment = null;
        //     this.form.appID = null;
        //   }, 1000);
        // });
      }
    },

    exportTable () {
      // naive encoding to csv format
      const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
        this.applicationsData.map(row => this.ResultColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'ForExam.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

    multipleForExam(){
      console.log("checkBox:", this.forExamSelected)
      if(this.forExamSelected.length > 0){
        this.form_exam.examID = [];
        for(var i = 0; i < this.forExamSelected.length;i++){
          this.form_exam.examID.push(this.forExamSelected[i].id);
        }
        // console.log(this.form_exam.examID)
        this.forExamAction = true;
      }else{
        this.notSelectedDialog = true;
      }

    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  created() {
    this.loadApplications();
    this.timer = setInterval(this.loadApplications, 300000);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
