<template>
	<div>
		<q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="vForm" @submit="addUser">
					<q-card-section>
						<div class="text-h6">Edit User</div>
					</q-card-section>
					<q-card-section>
						<div class="col-12 q-mb-md">
							<q-input v-model="models.firstname" label="First Name" class="caps"/>
							<q-input v-model="models.middlename" label="Middle Name" class="caps"/>
							<q-input v-model="models.lastname" label="Last Name" class="caps"/>
							<q-input v-model="models.email" label="Email" />
							<q-select v-model="models.role" option-value="role_id" option-label="name" :options="ranks" label="Rank" emit-value map-options class="caps"/>
							<q-input v-model="models.username" label="Username" />
							<q-input v-model="models.password" type="password" label="Password" />
						</div>
					</q-card-section>

					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>
		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			spinner: false,
			models : {
				firstname:null,
				middlename:null,
				lastname:null,
				username:null,
				password:null,
				email: null,
				role:null,
				id:null
			},
			dialog: false,
			user_id : null,
			ranks:[]
		}
	},
	created(){
		this.eventHub.$on('EditUserAccount', this.openModal);
		this.displayRanks()
	},

	methods: {
		addUser:function(){
			this.spinner=true;
			let formData = new FormData();
			formData.append("id", this.models.id);
			formData.append("firstname", this.models.firstname);
			formData.append("middlename", this.models.middlename);
			formData.append("lastname", this.models.lastname);
			formData.append("username", this.models.username);
			formData.append("email", this.models.email);
			formData.append("role", this.models.role);
			formData.append("password", this.models.password);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/update',formData)
			.then((res) => {
				
				if(res.data.status){
					this.$q.notify({
						message: 'User successfully updated.',
						color: 'positive',
						actions: [
					{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				});
					this.eventHub.$emit('refreshEmployeeList',"");
				}else{
					this.$q.notify({
						message: 'Failed to update a user.',
						color: 'negative',
						actions: [
					{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				})
				}

				this.spinner=false;
				return true;
			});

			

		},

		displayRanks: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/users`)
			.then((res) => {
				
				this.ranks = res.data.ranks
				this.ranks.unshift({'role_id':1,'name':'ALL'})			
			})

		},

		openModal:function(props){
			
			this.dialog = true;
			this.models.id	=	props.employee.row.id;
			this.models.firstname	=	props.employee.row.firstname;
			this.models.middlename	=	props.employee.row.middlename;
			this.models.lastname	=	props.employee.row.lastname;
			this.models.username	=	props.employee.row.username;
			this.models.email		=	props.employee.row.email;
			this.models.role		=	props.employee.row.role_id;
		},

		closeModal:function(){
			this.dialog = false;

		}
	}
}
</script>
<style lang="css" scoped>
</style>