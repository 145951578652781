<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="row q-mb-md full-width">
                <div class="full-width text-h4" style="height: 40px;">
                SMS Blast
                <!-- <q-chip square class="q-ml-sm text-bold" size="md" color="blue" text-color="white">
                    <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ smsStatus.pending }}</q-avatar>
                    Pending
                </q-chip>
                <q-chip square class="q-ml-sm text-bold" size="md" color="negative" text-color="white">
                    <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{  smsStatus.notSent }}</q-avatar>
                    Not Sent
                </q-chip>
                <q-chip square class="q-ml-sm text-bold" size="md" color="positive" text-color="white">
                    <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{  smsStatus.done }}</q-avatar>
                    Done
                </q-chip>
                <q-chip square class="q-ml-sm text-bold" size="md" color="dark" text-color="white">
                    <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{  smsStatus.total }}</q-avatar>
                    Total
                </q-chip> -->
                </div>
            </div>

            <div class="row q-mb-md full-width">
                <div class="col-12">
                    <q-btn label="sms blast" color="dark" class="q-mb-sm q-mr-sm" @click="smsBlast({})"/>
                    <q-btn label="Upload" color="negative" class="q-mb-sm q-mr-sm" @click="prompt = true"/>
                    <!-- <q-btn label="Truncate" color="positive" class="q-mb-sm q-mr-sm" @click="truncateTable"/> -->
                    <!-- <q-input borderless dense debounce="300"  v-model="msgContent" placeholder="Message Content...." outlined  autogrow/> -->
                </div>
            </div>

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-12 q-pa-md">
                <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div class="row">
                    <div class="col-12">
                        <q-table
                            :data="smsList"
                            :columns="ResultColumns"
                            :pagination.sync="Pagination"
                            :filter="filter"
                            row-key="id"
                            dense
                            selection="multiple"
                            :selected.sync="multiSend"
                        virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                        <template v-slot:top-right>
                            <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined>
                            <template v-slot:append>
                                <q-icon name="fas fa-search" />
                            </template>
                            </q-input>
                        </template>
                        <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                            <q-td>
                                <q-checkbox dense v-model="props.selected" />
                            </q-td>
                            <q-td key="action" style="text-align:center;">
                                <q-btn color="green" rounded size="sm" text-color="white" label="Resend" @click="smsBlast(props.row)" />  <!-- 01132023 -->
                                <q-btn color="blue" rounded size="sm" text-color="white" label="Edit" @click="editDialog(props.row)" />  <!-- 01132023 -->
                                <q-btn color="negative" rounded size="sm" text-color="white" label="Delete" @click="deleteReceiver(props.row.id)" />  <!-- 01132023 -->
                            </q-td>
                            <q-td key="id" >
                                {{ props.row.id }}
                            </q-td>
                            <!-- <q-td key="status" >
                                {{ props.row.status }}
                            </q-td> -->
                            <q-td key="name">{{ props.row.name }}</q-td>
                            <q-td key="mobile" >{{ props.row.mobile }}</q-td>
                        </q-tr>
                        <template v-slot:no-data>
                            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                            <span>
                                No Applications Yet
                            </span>
                            <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                            </div>
                        </template>
                        </q-table>
                    </div>
                    </div>
                </q-card-section>
                </q-card>
            </div>
        </div>

        <q-dialog v-model="prompt" persistent>

            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">Upload CSV File</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                <!-- <q-input dense v-model="address" autofocus @keyup.enter="prompt = false" /> -->
                <q-file outlined v-model="fileUpload" label="Select CSV File"  accept=".csv"/>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                <q-btn flat label="Cancel" v-close-popup />
                <q-btn flat label="Upload" @click="onSubmit"/>
                </q-card-actions>
            </q-card>

        </q-dialog>


        <q-dialog v-model="blastDialog" persistent>

            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">SMS Blast Message</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-select outlined v-model="sms.line" :options="msgLine" label="Line" />
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-select outlined  v-model="sms.content" :options="msgTemplate" label="Message Template" />
                </q-card-section>

                 <q-card-section class="q-pt-none" v-if="sms.content != ''">
                    <q-input outlined v-model="sms.content.value" label="Contact"  type="textarea"/>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Cancel" v-close-popup />
                    <q-btn flat label="Blast Now" @click="sendSms"/>
                </q-card-actions>
            </q-card>

        </q-dialog>


        <q-dialog v-model="editPrompt" persistent>

            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">Edit Receiver</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-input outlined v-model="receiver.name" label="Name" />
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-input outlined v-model="receiver.number" label="Mobile Number"/>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Cancel" v-close-popup />
                    <q-btn flat label="Update" @click="updateReceiver"/>
                </q-card-actions>
            </q-card>

        </q-dialog>


    </div>
</template>

<script>

const ResultColumns =  [
        {
            name: 'action',
            field: 'action',
            label: 'Action',
            style: 'width: 200px',
            align: 'center',
            headerStyle: 'width: 200px',
            headerClasses: 'bg-indigo-1'
        },
        {
            name: 'id',
            field: 'id',
            label: 'ID',
            align: 'left',
            headerStyle: 'width: 200px',
            headerClasses: 'bg-indigo-1'
        },
        {
            name: 'name',
            field: 'name',
            label: 'Name',
            sortable: true,
            style: 'width: 240px',
            align: 'left',
            headerStyle: 'width: 240px',
            headerClasses: 'bg-indigo-1'
        },
        {
            name: 'mobile',
            field: 'mobile',
            label: 'Mobile Number',
            sortable: true,
            style: 'width: 240px',
            align: 'left',
            headerStyle: 'width: 240px',
            headerClasses: 'bg-indigo-1'
        }
    ];

import axios from 'axios';
export default{
    name: "SMSBlast",
    data(){
        return{
            filter: '',
            multiSend:[],
            applications: '',
            smsList: [],
            Pagination: {
                sortBy: 'date_of_application',
                descending: true,
                // page: 1,
                rowsPerPage: 10
            },
            searchData:'',
            ResultColumns,
            smsStatus:{
                pending:0,
                notSent:0,
                done:0,
                total:0
            },
            sms:{
                line:'',
                contact:[],
                content: ''
            },
            receiver: {
                id: '',
                name: '',
                number: ''
            },
            // msgContent:'',
            prompt:false,
            blastDialog:false,
            csvFile:'',
            fileUpload: '',

            //select
            msgLine: [
                { label:'Line15',  value: 15 },
                { label:'Line16',value: 16 },
            ],
            msgTemplate:[],
            is_individual:true,
            editPrompt: false
        }
    },
    methods:{
        onSubmit(){
            let formData  = new FormData();
            formData.append("files", this.fileUpload);
            axios({
                headers : {'Authorization' : `Bearer ${this.token}`},
                url:this.apiUrl+'/recruitments/upload-sms-blast',
                data: formData,
                method: 'POST'
            })
            .then(response => {
                this.msgAlrt('Success','File Uploaded successfully','success')
                this.prompt = false
                this.fileUpload = ''
                this.loadData()
                // console.log('success sms-upload',response)
            })
        },

        loadData(){
            axios.get(this.apiUrl+'/recruitments/sms-list',{
                headers: {'Authorization': 'Bearer '+this.token }
            }).then((res) => {
                this.smsList = res.data
            })
        },

        smsBlast(data){
            if(this.multiSend.length > 0){
                this.blastDialog = true
                this.multiSend.forEach((data,index) => {
                    this.sms.contact.push(0+data.mobile)
                })
                console.log(this.sms.contact)
            }else{
                if(Object.keys(data).length > 0){
                    this.blastDialog = true
                    this.sms.contact = 0+data.mobile
                    // console.log(this.sms.contact)
                }else{
                    this.msgAlrt('No receiver','Please select receiver','error')
                }
            }

        },

        sendSms(){

            // console.log('receiver',this.sms.contact)
            // console.log('line',this.sms.line.value)
            // console.log('content',this.sms.content.value)

            this.$q.loading.show()
            var form = new FormData();
            form.append('line',this.sms.line.value)
            form.append('receiver', this.sms.contact)
            form.append('message',this.sms.content.value)
            axios.post(this.apiUrl+ '/recruitments/blast',form,{
                headers: { 'Authorization':'Bearer '+ this.token }
            }).then((res) => {
                this.blastDialog = false
                this.$q.loading.hide()
                this.multiSend = []
                this.sms.line = ''
                this.sms.contact = []
                this.sms.content = ''
                this.$q.loading.hide()
            })
        },

        msgAlrt(title,caption,type){
            let color = (type == 'success') ? 'positive' : 'negative'
            let icon = (type == 'success') ? 'far fa-check-circle' : 'far fa-times-circle'
            this.$q.notify({
                icon:icon,
                message:title,
                caption:caption,
                color:color
            })
        },

        getTemplate(){
            axios.get(this.apiUrl+'/recruitments/template-list',{
                headers: {"Authorization":'Bearer '+this.token}
            }).then((res) => {
                res.data.forEach((row,index) => {
                    this.msgTemplate.push({
                        label: row.title,
                        value:row.msg_content
                    })
                })
            })
        },

        editDialog(data){
            this.editPrompt = true
            this.receiver.id = data.id
            this.receiver.name = data.name
            this.receiver.number = data.mobile
        },

        updateReceiver(){
            var form = new FormData();
            form.append('id',this.receiver.id)
            form.append('name',this.receiver.name)
            form.append('mobile',this.receiver.number)
            axios.post(this.apiUrl+'/recruitments/update-receiver',form,{
                headers: {"Authorization":"Bearer "+this.token}
            }).then((res) => {
                this.loadData()
                var type = res.data.status == true ? 'success' : 'error'
                this.msgAlrt(type,res.data.message,type)
                this.editPrompt = false
                this.receiver.id = ''
                this.receiver.name = ''
                this.receiver.number = ''
            })
        },

        deleteReceiver(id){

            this.$q.dialog({
                title: 'Confirm',
                message: 'Would you like to continue',
                cancel: true,
                persistent: true
            }).onOk(() => {
                var form = new FormData()
                form.append('id',id)
                axios.post(this.apiUrl+ '/recruitments/delete',form,{
                    headers: { "Authorization":"Bearer "+this.token }
                }).then((res) => {
                    console.log('delete',res)
                    this.loadData()
                })
            })

        },

        truncateTable () {
            this.$q.dialog({
                title: 'Confirm',
                message: 'All data will be deleted',
                cancel: true,
                persistent: true
            }).onOk(() => {
                axios.get(this.apiUrl+ '/recruitments/truncate',{
                    headers: { "Authorization":"Bearer "+this.token }
                }).then((res) => {
                    console.log('delete',res)
                    this.loadData()
                })
            })
        }
    },
    mounted(){
        // alert('test')
        this.loadData()
        this.getTemplate()
    }
}

</script>
