<template>
  <div class="q-gutter-sm q-pa-lg">
    <q-form ref="newAppForm">
      <q-card class="my-card q-pa-lg">
        <div class="row">
          <div class="col-12">
            <div>
              <center><img class="logo q-mb-sm" src="../../assets/sgcc-black.png" alt=""></center>
              <h6 class="q-ma-none q-mb-lg text-grey-9 text-center">Employment Application</h6>
              <br />
              <div class="row q-col-gutter-sm">
                <div class="full-width q-mb-md text-right">
                  <b>Application Date</b>{{ dateToday }}
                </div>
                <div class="full-width">
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-users" size="sm" class="q-mr-sm" />
                      PERSONAL INFORMATION
                    </h6>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-4">
                        <q-input v-model="form.firstname" @input="val => {form.firstname = val.toUpperCase()}" label="First Name" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.middlename" @input="val => {form.middlename = val.toUpperCase()}" label="Middle Name" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.lastname" @input="val => {form.lastname = val.toUpperCase()}" label="Last Name" lazy-rules :rules="[formRules.required]" />
                      </div>
                    </div>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-6">
                        <q-input v-model="form.present_address" @input="val => {form.present_address = val.toUpperCase()}" label="Present Address" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.present_province" :options="provinces" @input="setProvince('Present',form.present_province)" label="Province" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.present_mun_city" :options="present_cities_municipals" label="City / Municipality" lazy-rules :rules="[formRules.required]">
                          <template v-slot:no-option>
                            <q-item>
                              <q-item-section class="text-italic text-grey">
                                Select province first
                              </q-item-section>
                            </q-item>
                          </template>
                        </q-select>
                      </div>
                    </div>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-6">
                        <q-input v-model="form.permanent_address" @input="val => {form.permanent_address = val.toUpperCase()}" label="Permament Address" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.permanent_province" :options="provinces" @input="setProvince('Permament',form.permanent_province)" label="Province" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.permanent_mun_city" :options="permanent_cities_municipals" label="City / Municipality" lazy-rules :rules="[formRules.required]">
                          <template v-slot:no-option>
                            <q-item>
                              <q-item-section class="text-italic text-grey">
                                Select province first
                              </q-item-section>
                            </q-item>
                          </template>
                        </q-select>
                      </div>
                    </div>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-4">
                        <q-input v-model="form.email" @input="val => {form.email = val.toUpperCase()}" label="Email Address" lazy-rules :rules="[formRules.email]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.mobile_number" label="Mobile Number" lazy-rules :rules="[formRules.numberRequired]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.tel_number" label="Telephone Number" />
                      </div>
                    </div>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-3">
                        <q-select v-model="form.gender" :options="['MALE','FEMALE']" label="Gender" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.religion" @input="val => {form.religion = val.toUpperCase()}" label="Religion" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.civil_status" :options="['SINGLE','MARRIED','WIDOWED']" label="Civil Status" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="birthdateInput" label="Birthday" color="indigo-6" readonly>
                          <template v-slot:append>
                            <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                              <q-popup-proxy ref="qbirthDate" transition-show="flip-down" transition-hide="flip-up">
                                <q-date v-model="form.birthdate" @input="$refs.qbirthDate.hide()" today-btn color="indigo-6" minimal />
                              </q-popup-proxy>
                            </q-icon>
                          </template>
                        </q-input>
                      </div>
                    </div>
                  </q-card>
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-search-location" size="sm" class="q-mr-sm" />
                      HOW DID YOU KNOW THIS COMPANY?
                    </h6>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-3">
                        <q-select v-model="form.through" :options="['JOBFAIR','POSTING','REFERRAL','RE-APPLY','WALK-IN','OTHERS']" @input="knowThrough(form.through)" label="Through" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3" v-if="dFalse.field_subthrough_posting">
                        <q-select v-model="form.through_suboption" :options="['FACEBOOK - NEWS FEED','FACEBOOK - STERLING PAGE','INDEED','JOBSTREET','LINKEDIN']" :label="field_subthrough_title" :disable="dTrue.sub1_disabled" />
                      </div>
                      <div class="col-3" v-if="dFalse.field_subthrough_referral">
                        <q-input v-model="form.through_suboption" @input="val => {form.through_suboption = val.toUpperCase()}" :label="field_subthrough_title" :disable="dTrue.sub2_disabled" />
                      </div>
                      <div class="col-3" v-if="dTrue.field_subthrough_others">
                        <q-input v-model="form.through_suboption" @input="val => {form.through_suboption = val.toUpperCase()}" :label="field_subthrough_title" :filled="dTrue.subthrough_input_style" :disable="dTrue.sub3_disabled" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.position_applying_for" :options="['CALL CENTER AGENT','TEAM LEADER','ASSISTANT TEAM LEADER','OTHERS']" @input="otherPosition(form.position_applying_for)" label="Position Applying For" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.other_position" @input="val => {form.other_position = val.toUpperCase()}" label="Others (Please Specify)" :filled="dTrue.other_position_input_style" :disable="dTrue.other_pos_disabled" />
                      </div>
                    </div>
                  </q-card>
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-id-badge" size="sm" class="q-mr-sm" />
                      WORK EXPERIENCES?
                    </h6>
                    <span class="row text-h6">Call Center Experience</span>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-3">
                        <q-select v-model="form.call_center_exp" :options="['YES','NO']" @input="withCCExp(form.call_center_exp)" label="With Call Center Experience?" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.call_center_exp_position" @input="val => {form.call_center_exp_position = val.toUpperCase()}" label="Position" :filled="dTrue.ccexp_style" :disable="dTrue.ccexp_disabled" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.call_center_exp_years" :options="expYears" label="Year(s) of Experience" :filled="dTrue.ccexp_style" :disable="dTrue.ccexp_disabled" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.call_center_exp_months" :options="expMonths" label="Month(s) of Experience" :filled="dTrue.ccexp_style" :disable="dTrue.ccexp_disabled" />
                      </div>
                    </div>
                    <div class="row col-12 q-col-gutter-md" v-if="dFalse.withCCExpComp">
                      <div class="col-3">
                        <q-input v-model="form.cc_exp_company_name" @input="val => {form.cc_exp_company_name = val.toUpperCase()}" label="Company Name" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.cc_exp_company_address" @input="val => {form.cc_exp_company_address = val.toUpperCase()}" label="Company Address" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.cc_exp_account_handled" @input="val => {form.cc_exp_account_handled = val.toUpperCase()}" label="Account Handled" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.cc_exp_reason_leaving" @input="val => {form.cc_exp_reason_leaving = val.toUpperCase()}" label="Reason for Leaving" />
                      </div>
                    </div>
                    <span class="row text-h6 q-mt-md">Other Work Experience</span>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-3">
                        <q-select v-model="form.other_exp" :options="['YES','NO']" @input="withOtherExp(form.other_exp)" label="With Other Work Experience?" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.other_exp_position" @input="val => {form.other_exp_position = val.toUpperCase()}" label="Position" :filled="dTrue.other_exp_style" :disable="dTrue.other_exp_disabled" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.other_exp_years" :options="expYears" label="Year(s) of Experience" :filled="dTrue.other_exp_style" :disable="dTrue.other_exp_disabled" />
                      </div>
                      <div class="col-3">
                        <q-select v-model="form.other_exp_months" :options="expMonths" label="Month(s) of Experience" :filled="dTrue.other_exp_style" :disable="dTrue.other_exp_disabled" />
                      </div>
                    </div>
                    <div class="row col-12 q-mb-md q-col-gutter-md" v-if="dFalse.withOtherExpComp">
                      <div class="col-3">
                        <q-input v-model="form.other_exp_company_name" @input="val => {form.other_exp_company_name = val.toUpperCase()}" label="Company Name" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.other_exp_company_address" @input="val => {form.other_exp_company_address = val.toUpperCase()}" label="Company Address" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.other_exp_nature_business" @input="val => {form.other_exp_nature_business = val.toUpperCase()}" label="Nature of Business" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.other_exp_reason_leaving" @input="val => {form.other_exp_reason_leaving = val.toUpperCase()}" label="Reason for Leaving" />
                      </div>
                    </div>
                  </q-card>
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-user-graduate" size="sm" class="q-mr-sm" />
                      EDUCATIONAL BACKGROUND
                    </h6>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-3">
                        <q-select v-model="form.educ_background" :options="['HIGH SCHOOL GRADUATE','VOCATIONAL COURSE','COLLEGE LEVEL','DEGREE HOLDER']" @input="educAttained(form.educ_background)" label="Highest Educational Attainment" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-1">
                        <q-input v-model="form.year_attended" mask="####" label="Year Attended" lazy-rules :rules="[formRules.numberRequired]" ref="educ_year_input" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.suc" @input="val => {form.suc = val.toUpperCase()}" label="School / University / College" lazy-rules :rules="[formRules.required]" ref="educ_suc_input" />
                      </div>
                      <div class="col-3">
                        <q-input v-model="form.course" @input="val => {form.course = val.toUpperCase()}" label="Course" placeholder="e.g. BS Information Technology" :filled="dTrue.educ_bg_course_style" :disable="dTrue.educ_bg_course_disabled" />
                      </div>
                      <div class="col-2">
                        <q-select v-model="form.year_level" :options="['1ST YEAR','2ND YEAR','3RD YEAR','4TH YEAR','5TH YEAR']" label="Year Level" :filled="dTrue.educ_bg_yearlevel_style" :disable="dTrue.educ_bg_yearlevel_disabled" />
                      </div>
                    </div>
                  </q-card>
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-hospital" size="sm" class="q-mr-sm" />
                      MEDICAL / HEALTH BACKGROUND
                    </h6>
                    <div class="row col-12 q-col-gutter-md">
                      <span>List down ilness(es) you suffered during the last 3 years, which incapacitated or grounded you at home or hospital for more than a week.</span>
                    </div>
                    <div class="row col-12 q-mt-md q-mb-sm q-col-gutter-md">
                      <div class="col-6 text-center"><b>NATURE OF ILLNESS</b></div>
                      <div class="col-6 text-center"><b>APPROXIMATE DATES (e.g. January 2019 - April 2019)</b></div>
                    </div>
                    <div class="row col-12 q-mb-sm q-col-gutter-sm">
                      <div class="row col-6">
                        <q-checkbox v-model="illness1" @input="illnessCheck(illness1,'illness1')" true-value="yes" false-value="no" color="indigo-6" />
                        <div class="col-11">
                          <q-input outlined v-model="form.nature_of_illness1" @input="val => {form.nature_of_illness1 = val.toUpperCase()}" :filled="dTrue.illness1_style" :disable="dTrue.illness1_disabled" />
                        </div>
                      </div>
                      <div class="row col-6 q-col-gutter-xs">
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_from_month1" :options="months" :filled="dTrue.illness1_style" :disable="dTrue.illness1_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_from_year1" mask="####" :filled="dTrue.illness1_style" :disable="dTrue.illness1_disabled" />
                        </div>
                        <div class="row col-2 justify-center items-center">
                          <span>To</span>
                        </div>
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_to_month1" :options="months" :filled="dTrue.illness1_style" :disable="dTrue.illness1_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_to_year1" mask="####" :filled="dTrue.illness1_style" :disable="dTrue.illness1_disabled" />
                        </div>
                      </div>
                    </div>
                    <div class="row col-12 q-mb-sm q-col-gutter-sm">
                      <div class="row col-6">
                        <q-checkbox v-model="illness2" @input="illnessCheck(illness2,'illness2')" true-value="yes" false-value="no" color="indigo-6" />
                        <div class="col-11">
                          <q-input outlined v-model="form.nature_of_illness2" @input="val => {form.nature_of_illness2 = val.toUpperCase()}" :filled="dTrue.illness2_style" :disable="dTrue.illness2_disabled" />
                        </div>
                      </div>
                      <div class="row col-6 q-col-gutter-xs">
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_from_month2" :options="months" :filled="dTrue.illness2_style" :disable="dTrue.illness2_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_from_year2" mask="####" :filled="dTrue.illness2_style" :disable="dTrue.illness2_disabled" />
                        </div>
                        <div class="row col-2 justify-center items-center">
                          <span>To</span>
                        </div>
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_to_month2" :options="months" :filled="dTrue.illness2_style" :disable="dTrue.illness2_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_to_year2" mask="####" :filled="dTrue.illness2_style" :disable="dTrue.illness2_disabled" />
                        </div>
                      </div>
                    </div>
                    <div class="row col-12 q-mb-sm q-col-gutter-sm">
                      <div class="row col-6">
                        <q-checkbox v-model="illness3" @input="illnessCheck(illness3,'illness3')" true-value="yes" false-value="no" color="indigo-6" />
                        <div class="col-11">
                          <q-input outlined v-model="form.nature_of_illness3" @input="val => {form.nature_of_illness3 = val.toUpperCase()}" :filled="dTrue.illness3_style" :disable="dTrue.illness3_disabled" />
                        </div>
                      </div>
                      <div class="row col-6 q-col-gutter-xs">
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_from_month3" :options="months" :filled="dTrue.illness3_style" :disable="dTrue.illness3_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_from_year3" mask="####" :filled="dTrue.illness3_style" :disable="dTrue.illness3_disabled" />
                        </div>
                        <div class="row col-2 justify-center items-center">
                          <span>To</span>
                        </div>
                        <div class="col-3">
                          <q-select outlined v-model="form.approx_to_month3" :options="months" :filled="dTrue.illness3_style" :disable="dTrue.illness3_disabled" />
                        </div>
                        <div class="col-2">
                          <q-input outlined v-model="form.approx_to_year3" mask="####" :filled="dTrue.illness3_style" :disable="dTrue.illness3_disabled" />
                        </div>
                      </div>
                    </div>
                  </q-card>
                  <q-card class="my-card q-mb-md q-pa-md">
                    <h6 class="q-ma-none q-mb-md text-grey-9 text-left">
                      <q-icon name="fa fa-phone-volume" size="sm" class="q-mr-sm" />
                      PERSON TO NOTIFY IN CASE OF EMERGENCY
                    </h6>
                    <div class="row col-12 q-col-gutter-md">
                      <div class="col-4">
                        <q-input v-model="form.emergency_name" @input="val => {form.emergency_name = val.toUpperCase()}" label="Contact Person" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.emergency_relation" @input="val => {form.emergency_relation = val.toUpperCase()}" label="Relationship" lazy-rules :rules="[formRules.required]" />
                      </div>
                      <div class="col-4">
                        <q-input v-model="form.emergency_contact" label="Contact Number" lazy-rules :rules="[formRules.numberRequired]" />
                      </div>
                    </div>
                  </q-card>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row q-col-gutter-sm q-mt-md justify-center">
              <div class="col-2">
                <q-btn @click="resetForm" label="Reset" size="lg" style="width: 100%" push color="red-7" />
              </div>
              <div class="col-4">
                <q-btn @click="sendApplication" :loading="appSendingEffect" label="Submit" size="lg" style="width: 100%" push color="indigo-6">
                  <template v-slot:loading>
                    <q-spinner-dots class="on-left" />
                    SUBMITTING APPLICATION
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-form>
    <q-dialog v-model="spinner" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section>
          <q-spinner-gears color="cyan" size="5.5em" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewTrackingID" persistent transition-show="scale" transition-hide="scale">
      <q-card style="width: 500px;">
        <q-card-section class="q-pt-lg q-pb-xs">
          <div class="text-h6 text-center">Your Tracking ID</div>
          <div class="tracking-id-text text-center">Please save this tracking ID as it will serve as your ID in your next appointment.</div>
        </q-card-section>
        <q-card-section>
          <div class="text-h5 text-center"><strong>
              {{trackingID}}
            </strong>
          </div>
        </q-card-section>
        <q-card-section class="q-pb-lg text-center">
          <q-btn label="OK" size="md" style="width: 100px" color="indigo-5" v-close-popup />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
import { date } from 'quasar';

let today = Date.now();
let dateToday = ':  ' + date.formatDate(today, ' dddd,  MMMM DD, YYYY');

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

const expYears = []
for (let i = 0; i <= 30; i++) {
  if (i < 2) {
    expYears.push(i + ' YEAR')
  } else {
    expYears.push(i + ' YEARS')
  }
}

const expMonths = []
for (let i = 0; i <= 11; i++) {
  if (i < 2) {
    expMonths.push(i + ' MONTH')
  } else {
    expMonths.push(i + ' MONTHS')
  }
}

const months = Array.from({ length: 12 }, (e, i) => {
  return new Date(null, i + 1, null).toLocaleDateString("en", { month: "long" }).toUpperCase();
})

export default {
  name: "ApplicationEmploymentNA",
  data: () => ({
    dateToday,
    expYears,
    expMonths,
    months,
    spinner: false,
    appSendingEffect: false,
    provinces: [],
    present_cities_municipals: [],
    permanent_cities_municipals: [],
    field_subthrough_title: 'Posting / Referrals / Others',
    dTrue: {
      field_subthrough_others: true,
      sub1_disabled: true,
      sub2_disabled: true,
      sub3_disabled: true,
      other_pos_disabled: true,
      subthrough_input_style: true,
      other_position_input_style: true,
      ccexp_style: true,
      ccexp_disabled: true,
      other_exp_style: true,
      other_exp_disabled: true,
      educ_bg_course_style: true,
      educ_bg_yearlevel_style: true,
      educ_bg_course_disabled: true,
      educ_bg_yearlevel_disabled: true,
      illness1_style: true,
      illness2_style: true,
      illness3_style: true,
      illness1_disabled: true,
      illness2_disabled: true,
      illness3_disabled: true,
    },
    dFalse: {
      field_subthrough_posting: false,
      field_subthrough_referral: false,
      withCCExpComp: false,
      withOtherExpComp: false,
    },
    field_subthrough_selected: null,
    other_pos_selected: null,
    illness1: 'no',
    illness2: 'no',
    illness3: 'no',
    form: {
      firstname: null,
      middlename: null,
      lastname: null,
      present_address: null,
      present_province: null,
      present_mun_city: null,
      permanent_address: null,
      permanent_province: null,
      permanent_mun_city: null,
      email: null,
      mobile_number: null,
      tel_number: null,
      gender: null,
      religion: null,
      civil_status: null,
      birthdate: null,
      through: null,
      through_suboption: null,
      position_applying_for: null,
      other_position: null,
      call_center_exp: null,
      call_center_exp_position: null,
      call_center_exp_years: null,
      call_center_exp_months: null,
      cc_exp_company_name: null,
      cc_exp_company_address: null,
      cc_exp_account_handled: null,
      cc_exp_reason_leaving: null,
      other_exp: null,
      other_exp_position: null,
      other_exp_years: null,
      other_exp_months: null,
      other_exp_company_name: null,
      other_exp_company_address: null,
      other_exp_nature_business: null,
      other_exp_reason_leaving: null,
      educ_background: null,
      year_attended: null,
      suc: null,
      course: null,
      year_level: null,
      nature_of_illness1: null,
      approx_from_month1: null,
      approx_from_year1: null,
      approx_to_month1: null,
      approx_to_year1: null,
      nature_of_illness2: null,
      approx_from_month2: null,
      approx_from_year2: null,
      approx_to_month2: null,
      approx_to_year2: null,
      nature_of_illness3: null,
      approx_from_month3: null,
      approx_from_year3: null,
      approx_to_month3: null,
      approx_to_year3: null,
      emergency_name: null,
      emergency_relation: null,
      emergency_contact: null
    },
    trackingID: null,
    viewTrackingID: false
  }),
  methods: {
    loadProvinces: function() {
      axios.get(this.apiUrl + '/recruitments/retrieve-provinces-list')
        .then((res) => {
          this.provinces = [];
          var provs = res.data.data;
          for (let prov of Object.values(provs)) {
            this.provinces.push(prov.province.toUpperCase());
          }
          Object.freeze(this.provinces);
        });
    },

    setProvince: function(provType, selectedProvince) {
      axios.get(this.apiUrl + '/recruitments/retrieve-cities-municipals-list', {
          params: {
            id: selectedProvince
          }
        })
        .then((res) => {
          this.present_cities_municipals = [];
          this.permanent_cities_municipals = [];
          var cities = res.data.data;
          if (provType == "Present") {
            for (let city of Object.values(cities)) {
              this.present_cities_municipals.push(city.city_municipality.toUpperCase());
            }
            Object.freeze(this.present_cities_municipals);
          } else {
            for (let city of Object.values(cities)) {
              this.permanent_cities_municipals.push(city.city_municipality.toUpperCase());
            }
            Object.freeze(this.permanent_cities_municipals);
          }
        });
    },

    knowThrough: function(knowThrough) {
      switch (knowThrough) {
        case 'JOBFAIR':
          this.field_subthrough_title = 'Posting / Referrals / Others';
          this.dFalse.field_subthrough_posting = false;
          this.dTrue.sub1_disabled = true;
          this.dFalse.field_subthrough_referral = false;
          this.dTrue.sub2_disabled = true;
          this.dTrue.field_subthrough_others = true;
          this.dTrue.sub3_disabled = true;
          this.form.through_suboption = null;
          this.field_subthrough_selected = knowThrough;
          this.dTrue.subthrough_input_style = true;
          break;
        case 'POSTING':
          this.field_subthrough_title = 'Posting';
          this.dFalse.field_subthrough_posting = true;
          this.dTrue.sub1_disabled = false;
          this.dFalse.field_subthrough_referral = false;
          this.dTrue.sub2_disabled = true;
          this.dTrue.field_subthrough_others = false;
          this.dTrue.sub3_disabled = true;
          this.form.through_suboption = null;
          this.dTrue.subthrough_input_style = false;
          break;
        case 'REFERRAL':
          this.field_subthrough_title = 'Referred By';
          this.dFalse.field_subthrough_posting = false;
          this.dTrue.sub1_disabled = true;
          this.dFalse.field_subthrough_referral = true;
          this.dTrue.sub2_disabled = false;
          this.dTrue.field_subthrough_others = false;
          this.dTrue.sub3_disabled = true;
          this.form.through_suboption = null;
          this.dTrue.subthrough_input_style = false;
          break;
        case 'RE-APPLY':
          this.field_subthrough_title = 'Posting / Referrals / Others';
          this.dFalse.field_subthrough_posting = false;
          this.dTrue.sub1_disabled = true;
          this.dFalse.field_subthrough_referral = false;
          this.dTrue.sub2_disabled = true;
          this.dTrue.field_subthrough_others = true;
          this.dTrue.sub3_disabled = true;
          this.form.through_suboption = null;
          this.field_subthrough_selected = knowThrough;
          this.dTrue.subthrough_input_style = true;
          break;
        case 'WALK-IN':
          this.field_subthrough_title = 'Posting / Referrals / Others';
          this.dFalse.field_subthrough_posting = false;
          this.dTrue.sub1_disabled = true;
          this.dFalse.field_subthrough_referral = false;
          this.dTrue.sub2_disabled = true;
          this.dTrue.field_subthrough_others = true;
          this.dTrue.sub3_disabled = true;
          this.form.through_suboption = null;
          this.field_subthrough_selected = knowThrough;
          this.dTrue.subthrough_input_style = true;
          break;
        case 'OTHERS':
          this.field_subthrough_title = 'Others (Please Specify)';
          this.dFalse.field_subthrough_posting = false;
          this.dTrue.sub1_disabled = true;
          this.dFalse.field_subthrough_referral = false;
          this.dTrue.sub2_disabled = true;
          this.dTrue.field_subthrough_others = true;
          this.dTrue.sub3_disabled = false;
          this.form.through_suboption = null;
          this.dTrue.subthrough_input_style = false;
          break;
      }
    },

    otherPosition: function(otherPosition) {
      if (otherPosition == 'OTHERS') {
        this.dTrue.other_pos_disabled = false;
        this.dTrue.other_position_input_style = false;
      } else {
        this.dTrue.other_pos_disabled = true;
        this.other_pos_selected = otherPosition;
        this.dTrue.other_position_input_style = true;
        this.form.other_position = null;
      }
    },

    withCCExp: function(withCCExp) {
      if (withCCExp == 'YES') {
        this.dTrue.ccexp_style = false;
        this.dTrue.ccexp_disabled = false;
        this.dFalse.withCCExpComp = true;
      } else {
        this.dTrue.ccexp_style = true;
        this.dTrue.ccexp_disabled = true;
        this.dFalse.withCCExpComp = false;
        this.form.call_center_exp_position = null;
        this.form.call_center_exp_years = null;
        this.form.call_center_exp_months = null;
        this.form.cc_exp_company_name = null;
        this.form.cc_exp_company_address = null;
        this.form.cc_exp_account_handled = null;
        this.form.cc_exp_reason_leaving = null;
      }
    },

    withOtherExp: function(withOtherExp) {
      if (withOtherExp == 'YES') {
        this.dTrue.other_exp_style = false;
        this.dTrue.other_exp_disabled = false;
        this.dFalse.withOtherExpComp = true;
      } else {
        this.dTrue.other_exp_style = true;
        this.dTrue.other_exp_disabled = true;
        this.dFalse.withOtherExpComp = false;
        this.form.other_exp_position = null;
        this.form.other_exp_years = null;
        this.form.other_exp_months = null;
        this.form.other_exp_company_name = null;
        this.form.other_exp_company_address = null;
        this.form.other_exp_nature_business = null;
        this.form.other_exp_reason_leaving = null;
      }
    },

    educAttained: function(educAttained) {
      if (educAttained == 'HIGH SCHOOL GRADUATE') {
        this.dTrue.educ_bg_course_style = true;
        this.dTrue.educ_bg_yearlevel_style = true;
        this.dTrue.educ_bg_course_disabled = true;
        this.dTrue.educ_bg_yearlevel_disabled = true;
        this.form.year_attended = null;
        this.form.suc = null;
        this.form.course = null;
        this.form.year_level = null;
        this.$refs.educ_year_input.resetValidation();
        this.$refs.educ_suc_input.resetValidation();
      } else if (educAttained == 'VOCATIONAL COURSE') {
        this.dTrue.educ_bg_course_style = false;
        this.dTrue.educ_bg_yearlevel_style = true;
        this.dTrue.educ_bg_course_disabled = false;
        this.dTrue.educ_bg_yearlevel_disabled = true;
        this.form.year_attended = null;
        this.form.suc = null;
        this.form.course = null;
        this.form.year_level = null;
        this.$refs.educ_year_input.resetValidation();
        this.$refs.educ_suc_input.resetValidation();
      } else if (educAttained == 'COLLEGE LEVEL') {
        this.dTrue.educ_bg_course_style = false;
        this.dTrue.educ_bg_yearlevel_style = false;
        this.dTrue.educ_bg_course_disabled = false;
        this.dTrue.educ_bg_yearlevel_disabled = false;
        this.form.year_attended = null;
        this.form.suc = null;
        this.form.course = null;
        this.form.year_level = null;
        this.$refs.educ_year_input.resetValidation();
        this.$refs.educ_suc_input.resetValidation();
      } else {
        this.dTrue.educ_bg_course_style = false;
        this.dTrue.educ_bg_yearlevel_style = true;
        this.dTrue.educ_bg_course_disabled = false;
        this.dTrue.educ_bg_yearlevel_disabled = true;
        this.form.year_attended = null;
        this.form.suc = null;
        this.form.course = null;
        this.form.year_level = null;
        this.$refs.educ_year_input.resetValidation();
        this.$refs.educ_suc_input.resetValidation();
      }
    },

    illnessCheck: function(illnessField, illnessOrder) {
      if (illnessOrder == 'illness1') {
        if (illnessField == 'yes') {
          this.dTrue.illness1_style = false;
          this.dTrue.illness1_disabled = false;
        } else {
          this.dTrue.illness1_style = true;
          this.dTrue.illness1_disabled = true;
          this.form.nature_of_illness1 = null;
          this.form.approx_from_month1 = null;
          this.form.approx_from_year1 = null;
          this.form.approx_to_month1 = null;
          this.form.approx_to_year1 = null;
        }
      } else if (illnessOrder == 'illness2') {
        if (illnessField == 'yes') {
          this.dTrue.illness2_style = false;
          this.dTrue.illness2_disabled = false;
        } else {
          this.dTrue.illness2_style = true;
          this.dTrue.illness2_disabled = true;
          this.form.nature_of_illness2 = null;
          this.form.approx_from_month2 = null;
          this.form.approx_from_year2 = null;
          this.form.approx_to_month2 = null;
          this.form.approx_to_year2 = null;
        }
      } else {
        if (illnessField == 'yes') {
          this.dTrue.illness3_style = false;
          this.dTrue.illness3_disabled = false;
        } else {
          this.dTrue.illness3_style = true;
          this.dTrue.illness3_disabled = true;
          this.form.nature_of_illness3 = null;
          this.form.approx_from_month3 = null;
          this.form.approx_from_year3 = null;
          this.form.approx_to_month3 = null;
          this.form.approx_to_year3 = null;
        }
      }
    },

    sendApplication: function() {
      this.$refs.newAppForm.validate().then(success => {
        if (success) {
          this.appSendingEffect = true;
          this.form.birthdate = date.formatDate(this.form.birthdate, 'YYYY-MM-DD');
          let formData = new FormData();
          formData.append('form_data', JSON.stringify(this.form));

          axios.create({
              baseURL: this.apiUrl,
              headers: {
                'Authorization': `Bearer ${this.token}`
              }
            })
            .post(this.apiUrl + "/recruitments/application/submit", formData)
            .then((res) => {
              this.trackingID = res.data;
              setTimeout(() => {
                this.appSendingEffect = false
                this.$q.notify({
                  color: 'indigo-9',
                  textColor: 'white',
                  icon: 'fa fa-envelope',
                  message: 'Application Submitted'
                });
                this.resetForm();
                scrollToTop();
                this.viewTrackingID = true;
              }, 2000);
            });
        }
      });
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = null;
      });
      Object.keys(this.dTrue).forEach(function(key, index) {
        self.dTrue[key] = true;
      });
      Object.keys(this.dFalse).forEach(function(key, index) {
        self.dFalse[key] = false;
      });
      this.field_subthrough_title = 'Posting / Referrals / Others';
      this.field_subthrough_selected = null;
      this.other_pos_selected = null;
      this.illness1 = 'no';
      this.illness2 = 'no';
      this.illness3 = 'no';
      this.$refs.newAppForm.resetValidation();
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    birthdateInput: function() {
      if (this.form.birthdate == null) {
        return 'Please select date of birth...';
      } else {
        let d = new Date(this.form.birthdate);
        return date.formatDate(d, 'MMMM DD, YYYY').toUpperCase();
      }
    }
  },
  mounted() {
    this.loadProvinces();
  }
};
</script>
<style scoped>
/* .login-box .title {} */

.form-box {
  border-top: 1px dashed #ddd;
  padding-top: 15px;
  margin-top: 50px;
}

.logo {
  width: 400px;
  position: relative;
  left: -5px;
}

.text-h6 {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.tracking-id-text {
  font-size: 12px;
  margin-top: 12px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}
</style>