<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="alert" persistent>
      <q-card>
         <q-card-section class="row items-center">
          <div class="text-h6 text-orange">Please confirm!</div>
          <q-space />
          <q-btn icon="fas fa-times" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section class="q-pt-none">
           <p>Your payslip password will send to this mobile number <span class="text-h6">{{ mobile }}</span></p>
           <p class="text-italic text-red"><small>*If this is not your active mobile number, please contact IT to update your number.*</small></p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Please Send" @click="sendPayslipPassword" color="primary"/>
        </q-card-actions>
      </q-card>
      <q-dialog v-model="spinner" persistent>
        <q-card class="my-card transparent no-shadow">
          <q-card-section>
            <q-spinner-gears color="cyan" size="5.5em" />
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data () {
    return {
      alert:false,
      mobile:'',
      spinner:false,
    }
  },

  mounted(){
    this.eventHub.$on('forgotPassword', this.confirmPassword);
  },


  methods:{
   sendPayslipPassword: function(){
      this.spinner = true
     axios.create({
         baseURL: this.apiUrl,
         headers: {
           'Authorization': `Bearer ${this.token}`
         }
      }).get(`/payslip/password/${this.userData.employee_id}/${this.mobile}`)
      .then((res) => {
         if(res.data.status){
            this.alert = false
            this.spinner = false
            this.$q.notify({
              color: 'green-6',
              textColor: 'white',
              icon: 'fa fa-check-circle',
              message: res.data.data
            });
         }
      })
   },

   confirmPassword(payload) {
      this.alert = payload.stat
      this.mobile = payload.mobile
   },


   }
}
</script>
