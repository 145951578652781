<template>
	<div>
		<q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="vForm" @submit="addUser">
					<q-card-section>
						<div class="text-h6">Add User</div>
					</q-card-section>
					<q-card-section>
						<div class="col-12 q-mb-md">
							<q-input v-model="models.firstname" label="First Name" class="caps"/>
							<q-input v-model="models.middlename" label="Middle Name" class="caps"/>
							<q-input v-model="models.lastname" label="Last Name" class="caps"/>
							<q-input v-model="models.email" label="Email" />
							<q-select v-model="models.role" option-value="role_id" option-label="name" :options="ranks" label="Rank" emit-value map-options class="caps"/>
							<q-input v-model="models.username" label="Username" />
							<q-input v-model="models.password" type="password" label="Password" />
						</div>
					</q-card-section>

					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>
		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			spinner: false,
			models : {
				firstname:null,
				middlename:null,
				lastname:null,
				username:null,
				email:null,
				password:null,
				role:null
			},
			dialog: false,
			ranks:[]
		}
	},
	created(){
		this.eventHub.$on('AddUserAccount', this.openModal);
		this.displayRanks()
	},

	methods: {
		addUser:function(){
			this.spinner=true;
			let formData = new FormData();
			formData.append("firstname", this.models.firstname);
			formData.append("middlename", this.models.middlename);
			formData.append("lastname", this.models.lastname);
			formData.append("username", this.models.username);
			formData.append("email", this.models.email);
			formData.append("role", this.models.role);
			formData.append("password", this.models.password);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/create',formData)
			.then((res) => {
				this.spinner=false;

				if(res.data.status){
					this.$refs.vForm.reset()
					this.resetForm()
					this.$q.notify({
						message: 'User successfully created.',
						color: 'positive',
						actions: [
					{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				})
					this.eventHub.$emit('refreshEmployeeList',"");
				}else{
					this.$q.notify({
						message: 'Failed to create a user.',
						color: 'negative',
						actions: [
					{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				})
				}
				return true;
				
			});
		},

		openModal:function(){
			this.dialog = true
		},

		closeModal:function(){
			this.dialog = false
		},

		displayRanks: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/users`)
			.then((res) => {
				
				this.ranks = res.data.ranks
				this.ranks.unshift({'role_id':1,'name':'ALL'})			
			})

		},

		resetForm:function(){
			var self = this;
			Object.keys(this.models).forEach(function(key, index) {
				self.models[key] = '';
			});
		},
	}
}
</script>
<style lang="css" scoped>
</style>