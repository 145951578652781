<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">List of Employees </h4><br />
        <div class="row">
          <div class="row justify q-banner--top-padding">
            <q-btn outline color="green" label="Export Employee" @click="downloadModal" v-show="canExport"></q-btn>
           <q-btn-dropdown outline color="red" label="Import" v-show="canImport">
               <q-list>
                 <q-item clickable v-close-popup @click="importEmployees">
                   <q-item-section>
                     <q-item-label>Import Employees</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="importAccount">
                   <q-item-section>
                     <q-item-label>Import Bank Details</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="importGovernment">
                   <q-item-section>
                     <q-item-label>Import Government Number</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="importBasicSalary">
                   <q-item-section>
                     <q-item-label>Import Basic Salary</q-item-label>
                   </q-item-section>
                 </q-item>

               </q-list>
             </q-btn-dropdown>

           <q-btn-dropdown outline color="primary" label="Template" v-show="canDownload">
               <q-list>
                 <q-item clickable v-close-popup @click="downloadTemplate('uploads/template/employeestemplate.csv','EmployeeTemplate')">
                   <q-item-section>
                     <q-item-label>Employee Template</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="downloadTemplate('uploads/template/account_template.csv','BankAccountTemplate')">
                   <q-item-section>
                     <q-item-label>Bank Details Template</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="downloadTemplate('uploads/template/goverment_template.csv','GovernmentTemplate')">
                   <q-item-section>
                     <q-item-label>Government Number Template</q-item-label>
                   </q-item-section>
                 </q-item>

                 <q-item clickable v-close-popup @click="downloadTemplate('uploads/template/basicsalary_template.csv','BasicsalaryTemplate')">
                   <q-item-section>
                     <q-item-label>Basic Salary Template </q-item-label>
                   </q-item-section>
                 </q-item>

               </q-list>
             </q-btn-dropdown>
          </div>
          <div class="row justify-end q-carousel__navigation-inner">
            <q-select v-model="location" :options="locationOptions" label="LOCATION" @input="getEmployees" style="width: 200px" tabindex="0" />
            <q-select v-model="department" :options="departments" option-value="id" option-label="name"  emit-value map-options label="DEPARTMENT" @input="getEmployees" style="width: 200px" />
          </div>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
            <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Employees"
            :data="employees"
            :columns="columns"
            row-key="id" separator="cell"
            no-data-label="I didn't find anything for you!"
            :pagination.sync="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort>
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
                <q-td key="photo" :props="props">
                  <q-avatar>
                    <img src="../../assets/boy-avatar.png">
                  </q-avatar>
                </q-td>
                <q-td key="companyname" :props="props">{{ props.row.companyname }}</q-td>
                <q-td key="location" :props="props">{{ props.row.location }}</q-td>
                <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
                <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                <q-td key="position" :props="props">{{ props.row.position }}</q-td>
                <q-td key="account" :props="props">{{ props.row.account }}</q-td>
                <q-td key="is_active" :props="props">
                  <q-btn  size="xs" :color="props.row.is_active == 'active' ? 'green': props.row.is_active == 'hold' ? 'orange' : 'red' " rounded :label="props.row.is_active"/>
                </q-td>

                <q-td key="action" :props="props" >
                  <div>
                    <q-btn round outline color="red" @click="selectedRow(props)" v-show="canEdit">
                      <i class="fas fa-user-edit"></i>
                      <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]" >
                        <strong>Edit 201 File</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn round outline color="orange" @click="careerUpdate(props)" v-show="canUpdateCareer">
                      <i class="fas fa-chart-line"></i>
                      <q-tooltip anchor="top middle" content-class="bg-orange" self="bottom middle" :offset="[10, 10]">
                        <strong>Update Career</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn round outline color="green" @click="MBFUpdate(props)" v-show="canMBF">
                      <i class="fas fa-address-card"></i>
                      <q-tooltip anchor="top middle" content-class="bg-green" self="bottom middle" :offset="[10, 10]">
                        <strong>Gov. Mandarory Number</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn round outline color="cyan" @click="meritsUpdate(props)" v-show="canMerits">
                      <i class="fas fa-award"></i>
                      <q-tooltip anchor="top middle" content-class="bg-cyan" self="bottom middle" :offset="[10, 10]">
                        <strong>Merits/Demerits</strong>
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>The filter didn't uncover any results!</span>
              </div>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing>
                    <q-spinner-hourglass  size="50px" color="cyan" />
                </q-inner-loading>
            </template>
            <template v-slot:top-right="props">
              <q-input dense v-model="filter" placeholder="Search Employee">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
          <q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
          </q-dialog>
        </div>
        <!-- ruben::29/08/2023 start -->
      <q-dialog v-model="importAccountDialog" persistent transition-show="scale" transition-hide="scale">
         <q-card class="q-bottom-sheet--list">
           <q-form ref="form" autocomplete="off" @submit="onSubmit">
             <q-card-section>
               <div class="text-h6">Select Bank Template File</div>
             </q-card-section>
             <q-card-section>
               <input type="file" id="file" ref="file" :rules="[ val => val && val.length > 0 || 'This field is required!']"  v-on:change="handleFileUpload()" required />

             </q-card-section>
             <q-card-actions align="right">
               <div>
                 <q-btn flat label="Submit" type="submit" color="primary"/>
                 <q-btn flat label="Close" color="primary" @click="closeModal"/>
               </div>
             </q-card-actions>
           </q-form>
         </q-card>
      </q-dialog>
      <!-- ruben::29/08/2023 end -->
        <EditDialog></EditDialog>
        <ImportDialog></ImportDialog>
        <ChangeStatus></ChangeStatus>
        <CareerUpdate></CareerUpdate>
        <FilterExport></FilterExport>
        <MBFDialog></MBFDialog>
        <MeritsDialog></MeritsDialog>
        <BasicSalaryDialog></BasicSalaryDialog>
        <ImportGonvernmentMandatory></ImportGonvernmentMandatory>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import EditDialog from './EditModal';
import ImportDialog from './ImportModal';
import ChangeStatus from './ChangeStatusModal';
import CareerUpdate from './CareerUpdate';
import FilterExport from './FilterExportDialog';
import MBFDialog from './MBFDialog';
import MeritsDialog from './MeritsDemerits';
import BasicSalaryDialog from './BasicSalaryDialog';
import ImportGonvernmentMandatory from './ImportGonvernmentMandatory';
export default {
  name: 'ListEmployee',
  components: {
    "EditDialog": EditDialog,
    "ImportDialog": ImportDialog,
    "ChangeStatus": ChangeStatus,
    "CareerUpdate": CareerUpdate,
    "FilterExport": FilterExport,
    "MBFDialog": MBFDialog,
    "MeritsDialog": MeritsDialog,
    "BasicSalaryDialog": BasicSalaryDialog,
    "ImportGonvernmentMandatory": ImportGonvernmentMandatory,
  },
  data() {
    return {
        filter: '',
        loading: false,
        pagination: {
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },

      canDownload:false,
      canImport:false,
      canExport:false,
      canEdit:false,
      canUpdateCareer:false,
      canMBF:false,
      canMerits:false,

      canDownloadValue:1,
      canImportValue:2,
      canEmportValue:3,
      canEditValue:4,
      canUpdateCareerValue:5,
      canMBFValue:6,
      canMeritsValue:7,

      exportModaL:false,
      importfile: false,
      template: null,
      status: 'ALL',
      department: 0,
      location:'ALL',
      spinner: false,

      columns: [
      { name: 'photo', align: 'left', label: 'Photo', field: '', sortable: true },
      { name: 'companyname', align: 'center', label: 'Company', field: 'companyname', sortable: true },
      { name: 'location', align: 'center', label: 'Location', field: 'location', sortable: true },
      { name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
      { name: 'firstname', align: 'center', label: 'First Name', field: 'firstname', sortable: true },
      { name: 'middlename', align: 'center', label: 'Middle Name', field: 'middlename', sortable: true },
      { name: 'lastname', align: 'center', label: 'Last Name', field: 'lastname', sortable: true },
      { name: 'position', align: 'center', label: 'Position', field: 'position', sortable: true },
      { name: 'account', align: 'center', label: 'Account', field: 'account', sortable: true },
      { name: 'is_active', align: 'center', label: 'Status', field: 'is_active', sortable: true },
      { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
      ],
      locationOptions:[],
      employees: [],
      statuses: [],
      departments: [],
      //ruben::29/08/2023
      importAccountDialog:false,
    }

  },

  created() {
    this.getDropdownProperties()
    this.getEmployees()
    this.autoHoldEmployee()
    this.autoCareerUpdate()
    this.eventHub.$on('refreshEmployeeList', this.getEmployees);
    this.getMyPermission()

  },


  methods: {
     // ================================================
     // ruben::29/08/2023 start
     // ================================================

     importAccount(){
       this.importAccountDialog = true
     },

     onSubmit:function(){
       this.spinner = true
       let formData  = new FormData();
       formData.append("files", this.file);
       axios({
          // onUploadProgress: uploadEvent => {
          //   console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
          // },
          headers : {'Authorization' : `Bearer ${this.token}`},
          url:this.apiUrl+'/employee/import-account',
          data: formData,
          method: 'POST'
       }).then(response => {
          console.log(this.token)
          console.log('tes',response)

          setTimeout(() => {
            this.spinner = false
            this.importfile = false
          },500)
          // this.eventHub.$emit('refreshEmployeeList');
          this.$q.notify({
              message: 'Successful',
              color: 'green',
              position:'center',
              actions: [
                { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
              ]
          })
       }).catch((error) => {
          var message = error.response.data.exception[1].message
          var index = message.indexOf("1062");
          var final = message.substring(index + 4);
          this.$q.notify({
            message: final,
            color: 'negative',
            position:'center',
            actions: [
              { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
            ]
          })
          this.spinner = false
       })
     },

     handleFileUpload(){
       this.file = this.$refs.file.files[0];
     },

     closeModal:function(){
       this.importAccountDialog = false
     },
     // ==============================================================
     // ruben::29/08/2023 end
      // ==============================================================

      //start if server side
      onRequest (props) {

        const { page, rowsPerPage } = props.pagination


        const filter = props.filter
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/employees', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                status:'ACTIVE',
                location: this.location,
                department: this.department,
                roleID: this.userData.role_id,
                start: startRow,
                countPerPage: count,
                filter: filter,
              }
          }).then((response) => {
              this.employees = response.data.data
              this.loading = false
          }).catch((error) => {
              console.log(error);
          })
     },

      async getRowsNumberCount (filter) {
          console.log(this.department)
         let response = await axios.get(this.apiUrl + '/employees/total', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
                status:'ACTIVE',
                location: this.location,
                department: this.department,
                roleID: this.userData.role_id,
                filter: filter,
             }
         })
         return response.data
      },

      ///end of server side

    getEmployees: function() {
         this.onRequest({pagination:this.pagination,filter:''})
    },

    selectedRow(emp) {
      this.eventHub.$emit('EditEmployee', { stat: true, employee: emp });
    },

    careerUpdate(emp) {
      this.eventHub.$emit('CareerUpdates', { stat: true, employee: emp });
    },

    MBFUpdate(emp) {
      this.eventHub.$emit('MBFUpdate', { stat: true, employee: emp });
    },

    meritsUpdate(emp) {
      this.eventHub.$emit('meritsUpdate', { stat: true, employee: emp });
    },


  downloadTemplate: function(url,filename) {
    axios({
      url: this.apiUrl + '/' + url,
      headers: {
        'Authorization': `Bearer ${this.token}`
     },
      method: 'GET',
      responseType: 'arraybuffer',
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${filename}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  },

  importEmployees: function() {
    this.eventHub.$emit('importFileDialog', { status: true });
  },

  importGovernment: function() {
    this.eventHub.$emit('importGovDialog', { status: true });
  },

  importBasicSalary: function() {
    console.log('test dialog')
    this.eventHub.$emit('bsDialog', { status: true });
  },

  changeStatus: function(status, obj) {
    this.eventHub.$emit('changeEmpStatus', { status: status, emp: obj });
  },

  getDropdownProperties: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/dropdown/properties')
    .then((res) => {
      this.paymentmode = res.data.data.paymentMode
      this.payingbank = res.data.data.bank
      this.payclass = res.data.data.payClass
      this.gender = res.data.data.gender
      this.locationOptions = res.data.data.location
      this.locationOptions.unshift('ALL')
      this.statuses = res.data.data.status
      this.statuses.unshift('ALL')
      this.departments = res.data.data.department
      this.departments.unshift({'id':0,'name':'ALL'})
    });
  },

  autoCareerUpdate:function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/check/careerupdate')
    .then((res) => {
      console.log(res.data.msg)
    });
  },

  autoHoldEmployee: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/hold/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  autoActivateEmployee: function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/activate/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  downloadModal:function(){
    this.eventHub.$emit('FilterOptions', {status: true});
  },

  getMyPermission:function(){
    //console.log(this.userData.id)
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get(`/admin/role/access/${this.userData.id}`)
    .then((res) => {
      var access= res.data.userAccess.moduleAction_id.split (",").map(Number)

      var dlResult = access.includes(this.canDownloadValue);
      if(dlResult){
        this.canDownload = true
      }else{
        this.canDownload = false
      }

      var imResult = access.includes(this.canImportValue);
      if(imResult){
        this.canImport = true
      }else{
        this.canImport = false
      }

      var exResult = access.includes(this.canEmportValue);
      if(exResult){
        this.canExport = true
      }else{
        this.canExport = false
      }

      var edResult = access.includes(this.canEditValue);
      if(edResult){
        this.canEdit = true
      }else{
        this.canEdit = false
      }

      var cuResult = access.includes(this.canUpdateCareerValue);
      if(cuResult){
        this.canUpdateCareer = true
      }else{
        this.canUpdateCareer = false
      }

      var mbfResult = access.includes(this.canMBFValue);
      if(mbfResult){
        this.canMBF = true
      }else{
        this.canMBF = false
      }

      var meritsResult = access.includes(this.canMBFValue);
      if(meritsResult){
        this.canMerits = true
      }else{
        this.canMerits = false
      }

    });
  }
}
}
</script>
<style lang="css" scoped>
</style>
