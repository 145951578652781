<template>
  <q-dialog v-model="dialog" persistent>
    <q-card class="q-bottom-sheet--list"  style="max-width:1500px;width:1000px;height:500px">
      <q-bar>
        <q-space />
        <q-btn dense flat v-close-popup>
          <i class="fas fa-times"></i>
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <q-form ref="vForm" @submit="onSubmit">
          <div class="row">
            <div class="col-3">
              <q-select v-model="cardtype" option-value="val" :rules="[formRules.required]" option-label="name" :options="typeOptions" label="Type" emit-value map-options class="caps"/>
              <q-input v-model="cardNo" label="Card No." :rules="[formRules.required]" type="number" class="caps"/>
              <div class="q-pa-md q-gutter-sm">
                <q-btn  class="full-width" color="teal" type="submit" >Save</q-btn>
              </div>
            </div>
            <div class="col-9">
              <div class="q-pa-md">
                <q-table
                :title="fullname"
                :data="MBF"
                :columns="columns"
                row-key="id">
                <template v-slot:body="props">
                  <q-tr slot="body"  :props="props" @click="selectedRow(props)"  style="cursor: pointer;">
                    <q-td key="cardtype" :props="props">{{ props.row.cardtype }}</q-td>
                    <q-td key="old" :props="props">{{ props.row.old }}</q-td>
                    <q-td key="new" :props="props">{{ props.row.new }}</q-td>
                    <q-td key="date_added" :props="props">{{ props.row.date_added }}</q-td>
                    <q-td key="updateby" :props="props">{{ props.row.updateby }}</q-td>
                  </q-tr>
                </template>
                <template v-slot:no-data="">
                  <div class="full-width row flex-center text-red q-gutter-sm">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span>
                      No Result!
                    </span>
                  </div>
                </template>
              </q-table>
            </div>
          </div>
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      empID:0,
      dialog:false,
      cardtype:null,
      cardNo:null,
      date_added:null,
      updateby:null,
      fullname:null,
      columns: [
      { name: 'cardtype', align: 'center', label: 'Type', field: 'cardtype', sortable: true },
      { name: 'old', align: 'center', label: 'Old', field: 'old', sortable: true },
      { name: 'new', label: 'New', field: 'new', sortable: true },
      { name: 'date_added', label: 'Last Update', field: 'date_added' , sortable: true},
      { name: 'updateby', label: 'Update By', field: 'updateby' , sortable: true},
      ],
      MBF: [],
      typeOptions:[
      {val:'SSS',name:'SSS #'},
      {val:'TIN',name:'TIN #'},
      {val:'HDMF',name:'HDMF #'},
      {val:'PHIC',name:'PHIC #'},
      ],
    }
  },
  created(){
    this.eventHub.$on('MBFUpdate', this.MBFUpdate);
  },

  methods: {

    MBFUpdate:function(payload){
      this.fullname = payload.employee.row.firstname + ' ' + payload.employee.row.lastname + '' + ' MBF List'
      this.empID = payload.employee.row.id
      this.dialog = payload.stat
      this.getMBFUpdate(this.empID)
    },

    selectedRow:function(emp){
     this.cardtype = emp.row.cardtype
     this.cardNo = emp.row.new
   },


   onSubmit:function(){
    let formData = new FormData();

    formData.append("empID", this.empID);
    formData.append("cardtype", this.cardtype);
    formData.append("cardNo", this.cardNo);
    formData.append("updateby", this.userData.username);

    axios.create({
      baseURL : this.apiUrl,
      headers : {'Authorization' : `Bearer ${this.token}`}
    }).post('/admin/employee/mbf',formData)
    .then((res) => {
      console.log(res.data.data)
      this.getMBFUpdate(this.empID)
      this.cardtype = ''
      this.cardNo = ''
      this.$refs.vForm.reset()
    });
  },

  getMBFUpdate: function(empID) {
    axios.create({
      baseURL: this.apiUrl,
      headers: {
        'Authorization': `Bearer ${this.token}`
      }})
    .get(`/admin/employee/mbf/${empID}`)
    .then((res) => {
      this.MBF = res.data.data;
      setTimeout(() => {
        this.spinner = false
      },500)
    })

  },

  closeModal:function(){
    this.career = false
  },
}
}
</script>
<style lang="css" scoped>
</style>
