<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
        <div class="row q-mb-md full-width">
          <div class="full-width" style="height: 40px;">
            <h4 class="q-ma-none row inline">Work From Home</h4>
          </div>
        </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Dates</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">From</div>
                <div class="col-6 q-pl-sm">To</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input v-model="dateFromInput" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateFromProxy" transition-show="flip-down" transition-hide="flip-up">
                          <!--<q-date v-model="form.selectedDateFrom" @input="$refs.qDateFromProxy.hide()" :options="startDate" today-btn color="red-7" minimal />-->
                          <q-date v-model="form.selectedDateFrom" @input="$refs.qDateFromProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input v-model="dateToInput" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateToProxy" transition-show="flip-down" transition-hide="flip-up">
                          <!--<q-date v-model="form.selectedDateTo" @input="$refs.qDateToProxy.hide()" :options="endDate" today-btn color="red-7" minimal />-->
                          <q-date v-model="form.selectedDateTo" @input="$refs.qDateToProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <div class="row col-4 q-pl-sm">
            <q-card bordered class="my-card col-12 q-pa-md">
              <q-card-section class="q-pa-none q-ma-none">
                <div class="text-h6">Date of Return</div>
              </q-card-section>
              <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div class="row ">
                  <div class="col-12">
                    <q-input v-model="dateReturnInput" outlined color="red-7" readonly>
                      <template v-slot:append>
                        <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                          <q-popup-proxy ref="qDateRetProxy" transition-show="flip-down" transition-hide="flip-up">
                            <q-date v-model="form.dateReturn" @input="$refs.qDateRetProxy.hide()"  today-btn color="red-7" minimal />
                            <!--<q-date v-model="form.dateReturn" @input="$refs.qDateRetProxy.hide()" :options="returnDate" today-btn color="red-7" minimal />-->
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.obtReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="lg" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="lg" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
let dateToday = date.formatDate(today, 'YYYY/MM/DD');
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'OfficialBusinessTrip',
  props: ['value'],
  data() {
    return {
      loading: false,
      date: '',
      dateToday,
      form: {
        selectedDateFrom: '',
        selectedDateTo: '',
        dateReturn: '',
        obtReason: ''
      }
    }
  },
  methods: {
    startDate: function(date) {
      return date >= this.dateToday;
    },

    endDate: function(date) {
      if (this.form.selectedDateFrom == '') {
        return date >= this.dateToday;
      } else {
        return date >= this.form.selectedDateFrom;
      }
    },

    returnDate: function(date) {
      if (this.form.selectedDateTo == '') {
        return date >= this.dateToday;
      } else {
        return date >= this.form.selectedDateTo;
      }
    },

    submitApplication: function() {
      if (this.form.selectedDateFrom == undefined || this.form.selectedDateTo == undefined ||
        this.form.dateReturn == undefined || this.form.tripCovered == '' ||
        this.form.obtReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (this.form.selectedDateFrom > this.form.selectedDateTo) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date From" is greater than "Date To".'
        });
      } else if (this.form.selectedDateTo > this.form.dateReturn) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date To" is greater than "Date of Return".'
        });
      } else {
        this.loading = true;
        this.form.selectedDateFrom = date.formatDate(this.form.selectedDateFrom, 'YYYY-MM-DD');
        this.form.selectedDateTo = date.formatDate(this.form.selectedDateTo, 'YYYY-MM-DD');
        this.form.dateReturn = date.formatDate(this.form.dateReturn, 'YYYY-MM-DD');

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/wfh', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: 'WFH Application Submitted!'
            });
            this.resetForm();
          }, 1000);
        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    dateFromInput: function() {
      if (this.form.selectedDateFrom == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateFrom);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },

    dateToInput: function() {
      if (this.form.selectedDateTo == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateTo);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },

    dateReturnInput: function() {
      if (this.form.dateReturn == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.dateReturn);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  }
}
</script>
<style scoped>
</style>
