<template>
   <div class="q-pa-md q-gutter-sm">
      <q-dialog v-model="dialog" persistent>

      <q-card style="width: 700px; max-width: 80vw;">
         <q-card-section>
         <div class="text-subtitle2 text-right"><q-btn flat outline color="teal" label="DOWNLOAD" @click="generatePayslip"/></div>
         <div class="text-h6">Date: {{ pay_date }}</div>
         <div class="text-subtitle2">{{ payroll_period_from }} - {{ payroll_period_to }}</div>
         </q-card-section>

         <q-card-section class="q-pt-none">
            <q-markup-table dense flat bordered separator="cell" >
               <tbody>
                 <tr>
                   <td class="text-right">Employee ID</td>
                   <td class="text-left">{{ employee_id }}</td>
                   <td class="text-right">Monthly Rate</td>
                   <td class="text-left">{{ monthly_rate }}</td>
                 </tr>
                 <tr>
                   <td class="text-right">Employee Name</td>
                   <td class="text-left">{{ employee_name }}</td>
                   <td class="text-right">Daily Rate</td>
                   <td class="text-left">{{ daily_rate }}</td>
                 </tr>
                 <tr>
                   <td class="text-right">Department</td>
                   <td class="text-left">{{ department }}</td>
                   <td class="text-right">Hourly Rate</td>
                   <td class="text-left">{{ hourly_rate }}</td>
                 </tr>
                 <tr>
                   <td class="text-right">Tax Status</td>
                   <td class="text-left">{{ tax_status }}</td>
                   <td class="text-right">Days/Hrs Worked</td>
                   <td class="text-left">{{ day_work}}</td>
                 </tr>
               </tbody>
             </q-markup-table>
             <q-markup-table dense flat bordered separator="cell" >
               <thead>
                 <tr>
                   <th class="text-left">LABEL</th>
                   <th class="text-center">HOURS/DAY</th>
                   <th class="text-center">AMOUNT</th>
                 </tr>
               </thead>
               <tbody>
                 <tr v-for="payslip in paySlipBreakdown" :key="payslip.key">
                   <td class="text-left text-red-10"
                   :class="
                   (payslip.add_back == 'Y') ? 'text-blue-10' :
                   (payslip.key == 'total_reg_wage') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'earnings') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'gross_pay') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'total_deduction') ? 'text-h6 text-red-10' :
                   (payslip.key == 'net_pay') ? 'text-h6 text-green-10' : ''
                   " >{{ payslip.title }}</td>
                   <td class="text-center">{{ payslip.hour }}</td>
                   <td class="text-center"
                   :class="
                   (payslip.add_back == 'Y') ? 'text-blue-10' :
                   (payslip.key == 'total_reg_wage') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'earnings') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'gross_pay') ? 'text-h6 text-blue-10' :
                   (payslip.key == 'total_deduction') ? 'text-h6 text-red-10' :
                   (payslip.key == 'net_pay') ? 'text-h6 text-green-10' : 'text-red-10'
                   "
                   >{{ payslip.value }}</td>
                 </tr>
               </tbody>
             </q-markup-table>
         </q-card-section>

         <q-card-actions align="right" class="bg-white text-teal">
           <q-btn flat label="Close" @click="closeDialog"/>
         </q-card-actions>
      </q-card>
      <q-dialog v-model="spinner" persistent>
        <q-card class="my-card transparent no-shadow">
          <q-card-section>
            <q-spinner-gears color="cyan" size="5.5em" />
          </q-card-section>
        </q-card>
      </q-dialog>
      </q-dialog>
   </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data () {
    return {
      isCorrect:false,
      spinner:false,
      dialog:false,
      id:'',
      employee_id: '',
      employee_name: '',
      pay_date: '',
      payroll_period_from: '',
      payroll_period_to: '',
      tax_status: '',
      department: '',
      monthly_rate: '',
      daily_rate: '',
      hourly_rate: '',
      day_work: '',
      paySlipBreakdown:[],
      paySlipInfo:{},
      path:'',
    }
  },

  created(){
   this.eventHub.$on('showPayslip', this.showPayslip);
  },


  methods:{

   getDetails: function(id,employee_id) {
     this.loadingText = 'Retrieving Data';
     this.spinner = true;
     axios.create({
         baseURL: this.apiUrl,
         headers: {
           'Authorization': `Bearer ${this.token}`
         }
      })
      .get(`/mobile/payslip/details/${id}/${employee_id}`)
      .then((res) => {
         this.pay_date = res.data.paySlipInfo.pay_date
         this.employee_id = res.data.paySlipInfo.employee_id
         this.employee_name = res.data.paySlipInfo.employee_name
         this.pay_date = res.data.paySlipInfo.pay_date
         this.payroll_period_from = res.data.paySlipInfo.payroll_period_from
         this.payroll_period_to = res.data.paySlipInfo.payroll_period_to
         this.tax_status = res.data.paySlipInfo.tax_status
         this.department = res.data.paySlipInfo.department
         this.monthly_rate = res.data.paySlipInfo.monthly_rate
         this.daily_rate = res.data.paySlipInfo.daily_rate
         this.hourly_rate = res.data.paySlipInfo.hourly_rate
         this.day_work = res.data.paySlipInfo.day_work
         this.paySlipBreakdown = res.data.paySlipBreakdown
         this.paySlipInfo = res.data.paySlipInfo
         this.spinner = false;

      })
      .finally(() => {
         this.spinner = false;
         this.loadingText = '';
      })
   },

   showPayslip : function(payload){
      this.dialog = payload.stat
      this.getDetails(payload.payslipID,payload.employeeID);
   },

   generatePayslip:function(){
      let formData = new FormData();
      formData.append('paySlipInfo', JSON.stringify(this.paySlipInfo));
      formData.append('paySlipBreakdown', JSON.stringify(this.paySlipBreakdown));
      formData.append('date',this.pay_date);
      formData.append('empid',this.userData.employee_id);
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
     }).post('/payslip/download', formData).then((res) => {
        this.path = res.data.path
        this.downloadPayslip(res.data.path,res.data.filename)
      });
   },

   downloadPayslip:function(url,filename){
      axios({
         url: this.apiUrl+'/'+url,
         method: 'GET',
         responseType: 'arraybuffer',
      }).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', filename);
         document.body.appendChild(fileLink);
         fileLink.click();
      });
   },

   closeDialog:function(){
      let formData = new FormData();
      formData.append('path', this.path);
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
     }).post('/payslip/remove', formData).then((res) => {
        if(res.data.status){
           this.dialog = false
        }
      });
   }


   }
}
</script>
