<template>
   <q-dialog v-model="dialog">
       <q-card style="width: 500px">
         <q-card-section>
           <div class="text-h6">{{ title }}</div>
         </q-card-section>
         <q-card-section class="q-pt-none">
            <q-list bordered separator>
             <q-item clickable v-ripple v-for="reason in otherReason" :key="reason">
               <q-item-section >{{ reason }}</q-item-section>
             </q-item>
           </q-list>
         </q-card-section>
         <q-card-actions align="right">
           <q-btn flat label="OK" color="primary" v-close-popup />
         </q-card-actions>
       </q-card>
     </q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

   data() {
     return {
        dialog:false,
        title:"",
        otherReason:{}
     }
   },

  created(){
    this.eventHub.$on('showOtherAnswer', this.showOtherAnswer);
  },

  methods: {
   showOtherAnswer:function(payload){
      this.title = payload.title
      this.otherReason = payload.reasons
      this.dialog = true;
   },

}
}
</script>
<style lang="css" scoped>
</style>
