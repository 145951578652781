<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">My Approvals</h4>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Emergency Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="elAppResultData" :columns="ResultColumns" :pagination.sync="elPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('elApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Vacation Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="vlAppResultData" :columns="ResultColumns" :pagination.sync="vlPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('vlApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Sick Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="slAppResultData" :columns="ResultColumns" :pagination.sync="slPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('slApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Maternity Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="mlAppResultData" :columns="ResultColumns" :pagination.sync="mlPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('mlApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Paternity Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="plAppResultData" :columns="ResultColumns" :pagination.sync="plPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('plApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Magna Carta Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="mcAppResultData" :columns="ResultColumns" :pagination.sync="mcPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('mcApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Solo Parent Leave</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="spAppResultData" :columns="ResultColumns" :pagination.sync="spPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('spApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div> -->
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing {{ actionType }} of Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewLeaveDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-amber-8 text-center text-white">
          <div class="text-h5">{{ leaveType }}</div>
          <div>( {{ viewLeaveDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date(s)</div>
              <div class="row q-col-gutter-md">
                <div class="col-3">
                  <q-field label="From" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.date_start}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="To" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.date_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Covered Leave" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.leave_covered}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Total Number of Days" stack-label color="amber-7">
                    <template v-slot:control>
                      <div>{{viewLeaveDetails.total_days}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="amber-7">
                  <template v-slot:control>
                    <div>{{viewLeaveDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve',leaveType,viewLeaveDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline',leaveType,viewLeaveDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-amber-8 text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-amber-8 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">Confirm {{ actionType }} of Application</span>
        </q-card-section>
        <q-card-section class="q-pa-md q-mt-md">
          Are you sure you want to {{ actionText }} the application?
        </q-card-section>
        <q-card-section class="q-pa-md q-mt-sm">
          <span class="small-text">Remarks:</span>
          <q-input v-model="form.approverRemarks" dense color="amber-8">
            <template v-slot:append>
              <q-icon name="fa fa-times" @click="form.approverRemarks = ''" class="cursor-pointer" />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions class="q-pa-md" align="right">
          <q-btn flat label="Cancel" @click="form.approverRemarks = ''" color="amber-8" v-close-popup />
          <q-btn flat label="Confirm" @click="submitConfirmation" class="bg-amber-8 text-white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

export default {
  name: 'MyApprovals_Leave',
  data() {
    return {
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewLeaveDetailsDialog: false,
      actionDialog: false,
      actionType: '',
      actionText: '',
      applications: '',
      elAppResultData: [],
      vlAppResultData: [],
      slAppResultData: [],
      mlAppResultData: [],
      plAppResultData: [],
      mcAppResultData: [],
      spAppResultData: [],
      elPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      vlPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      slPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      mlPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      plPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      mcPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      spPagination: {
        sortBy: 'date_applied',
        descending: true,
        page: 1,
        rowsPerPage: 5
      },
      ResultColumns: [{
          name: 'status',
          field: 'status',
          label: 'Status',
          sortable: true,
          style: 'width: 140px',
          align: 'center',
          headerStyle: 'width: 140px',
          headerClasses: 'bg-amber-1'
        },
        {
          name: 'date_applied',
          field: 'date_applied',
          label: 'Date Applied',
          sortable: true,
          style: 'width: 180px',
          align: 'center',
          headerStyle: 'width: 180px',
          headerClasses: 'bg-amber-1'
        },
        {
          name: 'inclusive_dates',
          field: 'inclusive_dates',
          label: 'Inclusive Date(s)',
          style: 'width: 270px',
          align: 'center',
          headerStyle: 'width: 270px',
          headerClasses: 'bg-amber-1'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          align: 'center',
          headerClasses: 'bg-amber-1'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px',
          headerClasses: 'bg-amber-1'
        }
      ],
      leaveType: '',
      viewLeaveDetails: {},
      form: {
        approverAction: '',
        approverRemarks: '',
        appType: '',
        appID: ''
      },
      approveNotifType: ''
    }
  },
  methods: {
    loadApplications: function() {
      this.loadingText = 'Retrieving Applications';
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/applications/retrieve-approver-list')
        .then((res) => {
          this.applications = res.data.data;
          Object.keys(this.applications).forEach((key, index) => {
            for (let eachApp of this.applications[key]) {
              let tempDate = date.extractDate(eachApp.date_applied, 'YYYY-MM-DD HH:mm:ss');
              let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
              var finalIncDate = '';

              let tempIncDate = eachApp.inclusive_dates.split('/');
              if (tempIncDate[0] == tempIncDate[1]) {
                finalIncDate = date.formatDate(tempIncDate[0], 'MMMM DD, YYYY');
              } else {
                let tempInc1 = date.formatDate(tempIncDate[0], 'MMMM DD');
                let tempInc2 = date.formatDate(tempIncDate[1], 'DD, YYYY');
                finalIncDate = tempInc1 + ' - ' + tempInc2;
              }

              let appKey = key + "ResultData";
              this[appKey].push({
                id: eachApp.id,
                status: eachApp.status,
                date_applied: date_applied,
                inclusive_dates: finalIncDate,
                name: eachApp.name,
                department: eachApp.department
              });
            }
          });
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    viewDetails: function(leaveApp, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/applications/details', {
          params: {
            id: rowID,
            type: leaveApp
          }
        })
        .then((res) => {
          this.loadingModel = false;
          this.viewLeaveDetails = res.data.data;
          var temp_date_applied, final_date_applied, final_inclusive_date_start, final_inclusive_date_end,
            totalIncDays, final_date_of_return, temp_date_processed, final_date_processed;

          temp_date_applied = date.extractDate(this.viewLeaveDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
          final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
          this.viewLeaveDetails.date_applied = final_date_applied;

          final_inclusive_date_start = date.formatDate(this.viewLeaveDetails.date_start, 'MMMM DD, YYYY');
          this.viewLeaveDetails.date_start = final_inclusive_date_start;

          final_inclusive_date_end = date.formatDate(this.viewLeaveDetails.date_end, 'MMMM DD, YYYY');
          this.viewLeaveDetails.date_end = final_inclusive_date_end;

          final_date_of_return = date.formatDate(this.viewLeaveDetails.date_return, 'MMMM DD, YYYY');
          this.viewLeaveDetails.date_return = final_date_of_return;

          if (this.viewLeaveDetails.status != 'Pending') {
            temp_date_processed = date.extractDate(this.viewLeaveDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
            final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
            this.viewLeaveDetails.date_processed = final_date_processed;
          }

          switch (leaveApp) {
            case 'elApp':
              this.leaveType = 'Emergency Leave';
              break;
            case 'vlApp':
              this.leaveType = 'Vacation Leave';
              break;
            case 'slApp':
              this.leaveType = 'Sick Leave';
              break;
            case 'mlApp':
              this.leaveType = 'Maternity Leave';
              break;
            case 'ptApp':
              this.leaveType = 'Paternity Leave';
              break;
            case 'mcApp':
              this.leaveType = 'Magna Carta Leave';
              break;
            case 'spApp':
              this.leaveType = 'Solo Parent Leave';
              break;
          }

          this.viewLeaveDetailsDialog = true;
        })
    },

    actionForm: function(action, leaveApp, appID) {
      this.form.approverAction = action;
      this.form.appType = leaveApp;
      this.form.appID = appID;
      if (action === 'Approve') {
        this.approveNotifType = 'Approved';
        this.actionType = 'Approval';
        this.actionText = action.toLowerCase();

      } else {
        this.approveNotifType = 'Declined';
        this.actionType = 'Denial';
        this.actionText = action.toLowerCase();
      }
      this.actionDialog = true;
    },

    submitConfirmation: function() {
      this.loadingAction = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form));

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/leave/applications/approver-process', formData).then((res) => {
        setTimeout(() => {
          this.loadingAction = false;
          this.actionDialog = false;
          this.viewLeaveDetailsDialog = false;
          this.elAppResultData = [];
          this.vlAppResultData = [];
          this.slAppResultData = [];
          this.mlAppResultData = [];
          this.plAppResultData = [];
          this.mcAppResultData = [];
          this.spAppResultData = [];
          this.loadApplications();
          this.$q.notify({
            color: 'green-7',
            textColor: 'white',
            icon: 'fa fa-signature',
            message: this.form.appType + ' Application ' + this.approveNotifType
          });
          this.form.approverAction = '';
          this.form.approverRemarks = '';
          this.form.appType = '';
          this.form.appID = '';
        }, 1000);
      });
    }
  },

  mounted() {
    this.loadApplications();
  }
}
</script>
<style scoped>
.btn-height {
  height: 40px;
}

.small-text {
  font-size: 12px;
  font-weight: 500;
}
</style>
