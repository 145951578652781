<template>
	<div class="q-pa-md">
		<q-dialog v-model="pendingDialog">
		 	<q-card  style="width: 700px; max-width: 80vw;">
		   		<q-card-section>
			 		<div class="text-h6">Request Details</div>
		   		</q-card-section>

		   		<q-card-section class="q-pt-none">
					<q-markup-table>
				      	<thead>
				        	<tr>
				          		<th>LABEL</th>
				          		<th  class="text-center">OLD RECORD</th>
				          		<th  class="text-center">NEW RECORD</th>
				        	</tr>
				      	</thead>
				      	<tbody>
				        	<tr>
				          		<td>Regular Hour/Days</td>
				          		<td class="text-center">{{ tk_summary_old.REG_HOUR }}</td>
				          		<td class="text-center" :class="tk_summary_old.REG_HOUR != tk_summary_new.REG_HOUR ? 'text-red' : '' ">{{ tk_summary_new.REG_HOUR }}</td>
				        	</tr>
							<tr>
				          		<td>UNDERTIME</td>
				          		<td class="text-center">{{ tk_summary_old.UNDER_TIME }}</td>
				          		<td class="text-center" :class="tk_summary_old.UNDER_TIME != tk_summary_new.UNDER_TIME ? 'text-red' : '' ">{{ tk_summary_new.UNDER_TIME }}</td>
				        	</tr>
							<tr>
				          		<td>Absent</td>
				          		<td class="text-center">{{ tk_summary_old.ABSENT }}</td>
				          		<td class="text-center" :class="tk_summary_old.ABSENT != tk_summary_new.ABSENT ? 'text-red' : '' ">{{ tk_summary_new.ABSENT }}</td>
				        	</tr>
							<tr>
				          		<td>Overtime</td>
								<td class="text-center">{{ tk_summary_old.OT }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT != tk_summary_new.OT ? 'text-red' : '' ">{{ tk_summary_new.OT }}</td>
				        	</tr>
							<tr>
				          		<td>Restday</td>
								<td class="text-center">{{ tk_summary_old.RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.RD != tk_summary_new.RD ? 'text-red' : '' ">{{ tk_summary_new.RD }}</td>
				        	</tr>
							<tr>
				          		<td>Restday-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_RD != tk_summary_new.OT_RD ? 'text-red' : '' ">{{ tk_summary_new.OT_RD }}</td>
				        	</tr>

							<tr>
				          		<td>Special Holiday</td>
								<td class="text-center">{{ tk_summary_old.SH }}</td>
				          		<td class="text-center" :class="tk_summary_old.SH != tk_summary_new.SH ? 'text-red' : '' ">{{ tk_summary_new.SH }}</td>
				        	</tr>
							<tr>
				          		<td>Special Holiday-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_SH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_SH != tk_summary_new.OT_SH ? 'text-red' : '' ">{{ tk_summary_new.OT_SH }}</td>
				        	</tr>
							<tr>
				          		<td>Special Holiday-RD</td>
								<td class="text-center">{{ tk_summary_old.SH_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.SH_RD != tk_summary_new.SH_RD ? 'text-red' : '' ">{{ tk_summary_new.SH_RD }}</td>
				        	</tr>
							<tr>
				          		<td>Special Holiday-OT-RD</td>
								<td class="text-center">{{ tk_summary_old.OT_RD_SH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_RD_SH != tk_summary_new.OT_RD_SH ? 'text-red' : '' ">{{ tk_summary_new.OT_RD_SH }}</td>
				        	</tr>

							<tr>
				          		<td>Legal Holiday</td>
								<td class="text-center">{{ tk_summary_old.LH }}</td>
				          		<td class="text-center" :class="tk_summary_old.LH != tk_summary_new.LH ? 'text-red' : '' ">{{ tk_summary_new.LH }}</td>
				        	</tr>
							<tr>
				          		<td>Legal Holiday-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_LH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_LH != tk_summary_new.OT_LH ? 'text-red' : '' ">{{ tk_summary_new.OT_LH }}</td>
				        	</tr>
							<tr>
				          		<td>Legal Holiday-RD</td>
								<td class="text-center">{{ tk_summary_old.LH_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.LH_RD != tk_summary_new.LH_RD ? 'text-red' : '' ">{{ tk_summary_new.LH_RD }}</td>
				        	</tr>
							<tr>
				          		<td>Legal Holiday-OT-RD</td>
								<td class="text-center">{{ tk_summary_old.OT_RD_LH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_RD_LH != tk_summary_new.OT_RD_LH ? 'text-red' : '' ">{{ tk_summary_new.OT_RD_LH }}</td>
				        	</tr>

							<tr>
				          		<td>Night Diff</td>
								<td class="text-center">{{ tk_summary_old.ND }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND != tk_summary_new.ND ? 'text-red' : '' ">{{ tk_summary_new.ND }}</td>
				        	</tr>
							<tr>
				          		<td>Night Diff-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_ND }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_ND != tk_summary_new.OT_ND ? 'text-red' : '' ">{{ tk_summary_new.OT_ND }}</td>
				        	</tr>
							<tr>
				          		<td>Night Diff-RD</td>
								<td class="text-center">{{ tk_summary_old.ND_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND_RD != tk_summary_new.ND_RD ? 'text-red' : '' ">{{ tk_summary_new.ND_RD }}</td>
				        	</tr>
							<tr>
				          		<td>Night Diff-RD-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_ND_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_ND_RD != tk_summary_new.OT_ND_RD ? 'text-red' : '' ">{{ tk_summary_new.OT_ND_RD }}</td>
				        	</tr>

							<tr>
				          		<td>Night Diff-Special Holiday</td>
								<td class="text-center">{{ tk_summary_old.ND_SH }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND_SH != tk_summary_new.ND_SH ? 'text-red' : '' ">{{ tk_summary_new.ND_SH }}</td>
				        	</tr>
							<tr>
				          		<td>Night Diff-Special Holiday-OT</td>
								<td class="text-center">{{ tk_summary_old.OT_ND_SH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_ND_SH != tk_summary_new.OT_ND_SH ? 'text-red' : '' ">{{ tk_summary_new.OT_ND_SH }}</td>
				        	</tr>
							<tr>
				          		<td>Night Diff-Special Holiday-RD</td>
								<td class="text-center">{{ tk_summary_old.ND_SH_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND_SH_RD != tk_summary_new.ND_SH_RD ? 'text-red' : '' ">{{ tk_summary_new.ND_SH_RD }}</td>
				        	</tr>

							<tr>
				          		<td>Night Shift-Legal Holiday</td>
								<td class="text-center">{{ tk_summary_old.ND_LH }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND_LH != tk_summary_new.ND_LH ? 'text-red' : '' ">{{ tk_summary_new.ND_LH }}</td>
				        	</tr>
							<tr>
				          		<td>Night Shift-Legal Holiday-RD</td>
								<td class="text-center">{{ tk_summary_old.ND_LH_RD }}</td>
				          		<td class="text-center" :class="tk_summary_old.ND_LH_RD != tk_summary_new.ND_LH_RD ? 'text-red' : '' ">{{ tk_summary_new.ND_LH_RD }}</td>
				        	</tr>
							<tr>
				          		<td>Night Shift-Legal Holiday-Restday-Overtime</td>
								<td class="text-center">{{ tk_summary_old.OT_ND_RD_LH }}</td>
				          		<td class="text-center" :class="tk_summary_old.OT_ND_RD_LH != tk_summary_new.OT_ND_RD_LH ? 'text-red' : '' ">{{ tk_summary_new.OT_ND_RD_LH }}</td>
				        	</tr>
				      	</tbody>
				    </q-markup-table><br/>
					<q-form ref="vForm"  class="q-gutter" @submit="onSubmit">
						<q-input outlined autogrow v-model="alterreason"  type="textarea" label="Alteration Reason" lazy-rules  :rules="[ val => val && val.length > 0 || 'Please write your reason']"/>
						<q-input outlined autogrow v-model="declinereason"  type="textarea" label="Decline Reason" lazy-rules />
						<q-card-actions align="right" class="bg-white text-teal">
					 		<q-btn flat label="DECLINED" color="primary" @click="onDeclined"/>
							<q-btn flat label="APPROVED" type="submit" color="primary"/>
				   		</q-card-actions>
					</q-form>
				</q-card-section>
		 	</q-card>
	   	</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'

export default {
	data () {
		return {
			selectedID:0,
			pendingDialog:false,
			tk_summary_old:{},
			tk_summary_new:{},
			alterreason:'',
			declinereason:''
		}
	},

	created(){
		this.eventHub.$on('selectPending', this.selectPending);

	},


	methods: {

		selectPending:function(payload){
			this.declinereason='';
			this.pendingDialog = payload.stat
			this.selectedID = payload.id
			let formData = new FormData();
		 	formData.append("id", payload.id);
		 	axios.create({
			 	baseURL: this.apiUrl,
			 	headers: { 'Authorization': `Bearer ${this.token}` }
		   	}).post(`/timekeeping/selected/request`, formData)
		   	.then((res) => {
			   this.tk_summary_old = res.data.data.tk_summary_old
			   this.tk_summary_new = res.data.data.tk_summary_new
			   this.alterreason = res.data.data.alter_remarks
		   	});
	    },

		onSubmit:function(){
			let formData = new FormData();
			formData.append("id", this.selectedID);
			axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).post(`/timekeeping/request/approved`, formData)
			.then((res) => {
				console.log(res.data.data)
				this.eventHub.$emit('refreshPending');
				this.pendingDialog = false
			});
		},

		onDeclined:function(){
			if(this.declinereason == ''){
				this.$q.notify({
		          color: 'red-6',
		          textColor: 'white',
		          icon: 'fa fa-exclamation-circle',
		          message: 'Please provide your decline reason!'
		        });
			}else{
				let formData = new FormData();
			 	formData.append("id", this.selectedID);
				formData.append("declinereason", this.declinereason);
			 	axios.create({
				 	baseURL: this.apiUrl,
				 	headers: { 'Authorization': `Bearer ${this.token}` }
			   	}).post(`/timekeeping/request/declined`, formData)
			   	.then((res) => {
					this.eventHub.$emit('refreshPending');
					this.pendingDialog = false
			   	});
			}
		}


	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
