<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-input v-model="fromDay" mask="date" :rules="['date']"  label="From">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="fromDay" @input="() => $refs.qDateProxy.hide()" />
									</q-popup-proxy>
								</i>
							</template>
						</q-input>
						<q-input v-model="toDay" mask="date" :rules="['date']"  label="To">
							<template v-slot:append>
								<i class="far fa-calendar-alt cursor-pointer" >
									<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
										<q-date v-model="toDay" @input="() => $refs.qDateProxy.hide()" />
										</q-popup-proxy>
									</i>
								</template>
							</q-input>
							<q-btn color="primary" label="Filter" @click="filterByDate"/>
						</div>
					</div>
				</div>
				<div class="q-pa-md">
					<q-table
					title="My Schedules"
					dense
					:data="schdules"
					:columns="columns"
					row-key="name"
					:pagination.sync="pagination"
					/>
				</div>

				<q-dialog v-model="spinner" persistent >
					<q-card class="my-card transparent no-shadow">
						<q-card-section >
							<q-spinner-gears color="cyan"  size="5.5em"/>
						</q-card-section>
					</q-card>
				</q-dialog>
			</div>
		</template>

		<script>
		import axios from 'axios';
		import mixins from '../../mixins';
		export default {
			data () {
				return {
					fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
					toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
					spinner:false,
					pagination: {
						rowsPerPage: 10
					},
					columns: [
					{ name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
					{ name: 'in', align: 'center', label: 'Time-in', field: 'in', sortable: true },
					{ name: 'out', align: 'center', label: 'Time-out', field: 'out', sortable: true },
					{ name: 'date', align: 'center', label: 'Date', field: 'date', sortable: true },
					],
					schdules: []
				}
			},

			created(){
				this.displayMySchedule()
			},

			methods: {

				displayMySchedule: function() {
					let employee_id =  this.userData.employee_id
					let from = this.fromDay.replace("/", "-").replace("/", "-")
					let to = this.toDay.replace("/", "-").replace("/", "-")

					this.spinner = true
					let formData  = new FormData();
					formData.append("from", this.fromDay);
					formData.append("to", this.toDay);
					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.get(`/schedule/individual/${from}/${to}/${employee_id}`)
					.then((res) => {
						console.log(res.data)
						this.schdules = res.data.data;

						setTimeout(() => {
							this.spinner = false
						},500)
					})

				},

				filterByDate:function(){
					this.displayMySchedule()
				}
			}
		}
		</script>
