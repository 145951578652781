<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="row full-width text-h4 q-mb-md">
        <div class="col-8">
          My Leave Credits
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <q-card bordered class="my-card">
          <q-card-section class="q-ma-none q-pt-sm q-pb-xs q-pl-sm q-pr-xs">
            <div class="row col-12 q-gutter-xs q-mb-xs">
              <q-chip square class="col text-bold" color="green-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ max_vl }}</q-avatar>
                Max VL Credits
              </q-chip>
              <q-chip square class="col text-bold" color="green-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ max_sl }}</q-avatar>
                Max SL Credits
              </q-chip>
              <q-chip square class="col text-bold" color="blue-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_earned }}</q-avatar>
                Total Earned VL Credits
              </q-chip>
              <q-chip square class="col text-bold" color="blue-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_earned }}</q-avatar>
                Total Earned SL Credits
              </q-chip>
            </div>
            <div class="row col-12 q-gutter-xs">
              <q-chip square class="col text-bold" color="red-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_availed }}</q-avatar>
                Availed Vacation Leave
              </q-chip>
              <q-chip square class="col text-bold" color="red-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_availed }}</q-avatar>
                Availed Sick Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-orange-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_remaining }}</q-avatar>
                Remaining VL Credits
              </q-chip>
              <q-chip square class="col text-bold" color="deep-orange-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_remaining }}</q-avatar>
                Remaining SL Credits
              </q-chip>
            </div>
            <div class="row col-12 q-gutter-xs">
              <!-- <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ ml_availed }}</q-avatar>
                Availed Maternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ pl_availed }}</q-avatar>
                Availed Paternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ mc_availed }}</q-avatar>
                Availed Magna Carta Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sp_availed }}</q-avatar>
                Availed Solo Parent Leave
              </q-chip> -->
              <q-chip square class="col text-bold" color="yellow-5">
                <q-avatar :icon="ml_availment" font-size="13px" color="grey-5" :text-color="ml_avail_color" style="width:2em" />
                Availed Maternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="yellow-5">
                <q-avatar :icon="pl_availment" font-size="13px" color="grey-5" :text-color="pl_avail_color" style="width:2em" />
                Availed Paternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="yellow-5">
                <q-avatar :icon="mc_availment" font-size="13px" color="grey-5" :text-color="mc_avail_color" style="width:2em" />
                Availed Magna Carta Leave
              </q-chip>
              <q-chip square class="col text-bold" color="yellow-5">
                <q-avatar :icon="sp_availment" font-size="13px" color="grey-5" :text-color="sp_avail_color" style="width:2em" />
                Availed Solo Parent Leave
              </q-chip>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-12">
        <q-card bordered class="my-card">
          <q-card-section class="q-ma-none q-pa-none">
            <q-table class="center-cell" :data="leaveSpreadData" :columns="leaveSpreadColumns" row-key="name" hide-bottom separator="cell">
              <q-tr slot="body" slot-scope="props" :props="props">
                <q-td key="year" :props="props">{{ props.row.year }}</q-td>
                <q-td v-for="(earned,index) in props.row.earned" :key="index">{{ props.row.earned[index][index] }}</q-td>
                <q-td key="total" :props="props">{{ props.row.total }}</q-td>
              </q-tr>
            </q-table>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Fetching Records
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
let today = Date.now();
let dateToday = date.formatDate(today, 'MM-DD-YYYY-hhmmA');
export default {
  name: 'MyLeaveCredits',
  data() {
    return {
      dateToday,
      loadingModel: false,
      max_vl: null,
      max_sl: null,
      vl_earned: null,
      sl_earned: null,
      vl_availed: null,
      sl_availed: null,
      vl_remaining: null,
      sl_remaining: null,
      ml_availed: null,
      ml_availment: 'fa fa-times',
      ml_avail_color: 'red-10',
      pl_availed: null,
      pl_availment: 'fa fa-times',
      pl_avail_color: 'red-10',
      mc_availed: null,
      mc_availment: 'fa fa-times',
      mc_avail_color: 'red-10',
      sp_availed: null,
      sp_availment: 'fa fa-times',
      sp_avail_color: 'red-10',
      leaveSpreadData: [],
      leaveSpreadColumns: [{
          name: 'year',
          field: 'year',
          label: 'Year',
          align: 'center',
          style: 'font-weight: bold; width: 100px',
          classes: 'bg-grey-2',
          headerClasses: 'bg-blue-8 text-white text-center',
          headerStyle: 'width: 100px'
        },
        {
          name: 'january',
          field: 'january',
          label: 'January',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'february',
          field: 'february',
          label: 'February',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'march',
          field: 'march',
          label: 'March',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'april',
          field: 'april',
          label: 'April',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'may',
          field: 'may',
          label: 'May',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'june',
          field: 'june',
          label: 'June',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'july',
          field: 'july',
          label: 'July',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'august',
          field: 'august',
          label: 'August',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'september',
          field: 'september',
          label: 'September',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'october',
          field: 'october',
          label: 'October',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'november',
          field: 'november',
          label: 'November',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'december',
          field: 'december',
          label: 'December',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'total',
          field: 'total',
          label: 'Total',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        }
      ]
    }
  },
  methods: {
    loadCredits: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/credits/retrieve', {
          params: {
            category: 'single',
            keyword: 'user'
          }
        })
        .then((res) => {
          let leaveCredits = res.data.data;
          this.max_vl = leaveCredits.max_vl;
          this.max_sl = leaveCredits.max_sl;
          this.vl_earned = leaveCredits.vl_earned;
          this.sl_earned = leaveCredits.sl_earned;
          this.vl_availed = leaveCredits.vl_availed;
          this.sl_availed = leaveCredits.sl_availed;
          this.vl_remaining = leaveCredits.vl_remaining;
          this.sl_remaining = leaveCredits.sl_remaining;
          this.ml_availed = leaveCredits.ml_availed;
          this.pl_availed = leaveCredits.pl_availed;
          this.mc_availed = leaveCredits.mc_availed;
          this.sp_availed = leaveCredits.sp_availed;
          this.leaveSpreadData = leaveCredits.plotted;
          if (this.ml_availed > 0) {
            this.ml_availment = 'fa fa-check';
            this.ml_avail_color = 'green-10';
          }
          if (this.pl_availed > 0) {
            this.pl_availment = 'fa fa-check';
            this.pl_avail_color = 'green-10';
          }
          if (this.mc_availed > 0) {
            this.mc_availment = 'fa fa-check';
            this.mc_avail_color = 'green-10';
          }
          if (this.sp_availed > 6) {
            this.sp_availment = 'fa fa-check';
            this.sp_avail_color = 'green-10';
          }
        });
    }
  },
  mounted() {
    this.loadCredits();
  }
}
</script>
<style lang="css" scoped>
.center-cell td {
  text-align: center;
}
</style>
