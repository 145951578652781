<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding">
					<q-btn outline color="green" label="Template"  @click="getTemplate()"/>
					<q-btn outline color="red" label="Upload Timelogs" @click="importEmployees"/>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
							<q-btn color="primary" label="Filter" @click="filterByDate"/>
						</div>
					</div>
				</div>
				<div class="q-pa-md">
					<q-table :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="TIMELOGS" :data="timelogs" :columns="columns"
					:pagination.sync="pagination" row-key="id" separator="cell"
					no-data-label="I didn't find anything for you!" :loading="loading" :filter="filter"
					@request="onRequest" binary-state-sort>

					<template v-slot:body="props">
						<q-tr slot="body" :props="props">
							<q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
							<q-td key="name" :props="props" class="caps">{{ props.row.name }}</q-td>
							<q-td key="type" :props="props" class="caps">{{ props.row.type }}</q-td>
							<q-td key="time_logs" :props="props">{{ props.row.time_logs }}</q-td>
						</q-tr>
					</template>
					<template v-slot:no-data="">
			          <div class="full-width row flex-center text-red q-gutter-sm">
			            <i class="fas fa-exclamation-triangle"></i>
			            <span>The filter didn't uncover any results!</span>
			          </div>
			        </template>
					<template v-slot:loading>
			            <q-inner-loading showing>
			                <q-spinner-hourglass  size="50px" color="cyan" />
			            </q-inner-loading>
			        </template>
					<template v-slot:top>
						<q-input dense v-model="filter" placeholder="Search Employee">
							<i class="fas fa-search" slot="append"></i>
						</q-input>
						<q-space />
						<q-btn outline color="primary" label="Download" no-caps @click="exportTable"/>
					</template>
				</q-table>
			</div>

			<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
				<q-card class="q-bottom-sheet--list">
					<q-form ref="form" autocomplete="off" @submit="onSubmit">
						<q-card-section>
							<div class="text-h6">Select File</div>
						</q-card-section>
						<q-card-section>
							<small>(.csv Only)</small><br/>
							<input type="file" id="file" ref="file" accept=".csv" :rules="[ val => val && val.length > 0 || 'This field is required!']"  v-on:change="handleFileUpload()"/>

						</q-card-section>
						<q-card-actions align="right">
							<div>
								<q-btn flat label="Submit" type="submit" color="primary"/>
								<q-btn flat label="Close" color="primary" @click="closeModal"/>
							</div>
						</q-card-actions>
					</q-form>
				</q-card>
			</q-dialog>

			<q-dialog v-model="spinner" persistent >
				<q-card class="my-card transparent no-shadow">
					<q-card-section >
						<q-spinner-gears color="cyan"  size="5.5em"/>
					</q-card-section>
				</q-card>
			</q-dialog>
		</div>
	</template>

	<script>
	import axios from 'axios';
	import mixins from '../../mixins';
	import { exportFile } from 'quasar';
	function wrapCsvValue (val, formatFn) {
		let formatted = formatFn !== void 0 ? formatFn(val) : val

		formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

		formatted = formatted.split('"').join('""')
		return `"${formatted}"`
	}
	export default {
		data () {
			return {
				filter: '',
		        loading: false,
		        pagination: {
		            page: 1,
		            rowsPerPage: 15,
		            rowsNumber: 10
		        },

				yearfilter: new Date().getFullYear(),
				yearOptions: [],
				cutoff: 1,
				month: new Date().getMonth() + 1,

				importfile:false,
				spinner:false,
				file:'',

				columns: [
				{ name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
				{ name: 'name', align: 'center', label: 'Fullname', field: 'name', sortable: true },
				{ name: 'type', align: 'center', label: 'Types', field: 'type', sortable: true },
				{ name: 'time_logs', align: 'center', label: 'Date', field: 'time_logs', sortable: true },
				],
				timelogs: [],
				cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
				monthOptions:[
				{id:1,name:'January'},
				{id:2,name:'February'},
				{id:3,name:'March'},
				{id:4,name:'April'},
				{id:5,name:'May'},
				{id:6,name:'June'},
				{id:7,name:'July'},
				{id:8,name:'August'},
				{id:9,name:'September'},
				{id:10,name:'October'},
				{id:11,name:'November'},
				{id:12,name:'December'},],
				}
		},

		created(){
			this.yearsList()
		},

		mounted(){
	        this.onRequest({
	            pagination: this.pagination,
	            filter: ''
	        })
	    },

		methods: {

			//start if server side
			onRequest (props) {
		      const { page, rowsPerPage} = props.pagination
		      const filter = props.filter
		      this.loading = true
		      // emulate server
		      setTimeout(() => {
		        this.getRowsNumberCount(filter).then(total => {
		            this.pagination.rowsNumber = total
		        })
		        const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
		        const startRow = (page - 1) * rowsPerPage
		        this.fetchFromServer(startRow, fetchCount, filter)
		        this.pagination.page = page
		        this.pagination.rowsPerPage = rowsPerPage
		      }, 1500)
		    },

		    fetchFromServer (startRow, count, filter) {
		        axios.get(this.apiUrl + '/timelogs/display', {
		            headers: { 'Authorization': `Bearer ${this.token}` },
		            params: {
		              year: this.yearfilter,
		              month: this.month,
		              cutoff: this.cutoff,
		              start: startRow,
		              countPerPage: count,
		              filter: filter,
		            }
		        }).then((response) => {
		            this.timelogs = response.data.data
					this.loading = false
		        }).catch((error) => {
		            console.log(error);
		        })
		   },

		    async getRowsNumberCount (filter) {
		       let response = await axios.get(this.apiUrl + '/timelogs/display/total', {
		           headers: { 'Authorization': `Bearer ${this.token}` },
		           params: {
		             year: this.yearfilter,
		             month: this.month,
		             cutoff: this.cutoff,
		             filter: filter,
		           }
		       })
		       return response.data
		    },

			///end of server side

			importEmployees:function(){
				this.importfile = true
			},

			closeModal:function(){
				this.importfile = false
			},

			onSubmit:function(){
				this.spinner = true
				let formData  = new FormData();
				formData.append("files", this.file);
				axios({
					onUploadProgress: uploadEvent => {
						console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
					},
					headers : {'Authorization' : `Bearer ${this.token}`},
					url:this.apiUrl+'/timelogs/upload',
					data: formData,
					method: 'POST'
				})
				.then(response => {
					console.log(response.status)
					this.onRequest({pagination:this.pagination,filter:''})
					this.spinner = false
					this.importfile = false
				}).catch((error) => {
					console.log(error.response)
				})
			},

			handleFileUpload(){
				this.file = this.$refs.file.files[0];
			},

			// displayTimelogs: function() {
			// 		this.spinner = true
			// 		axios.create({
			// 			baseURL: this.apiUrl,
			// 			headers: {
			// 				'Authorization': `Bearer ${this.token}`
			// 			}})
			// 		.get(`/timelogs/display/${this.yearfilter}/${this.month}/${this.cutoff}`)
			// 		.then((res) => {
			// 			//console.log(res.data.data)
			// 			this.timelogs = res.data.data;
			// 			this.initData = res.data.data;
			// 			setTimeout(() => {
			// 				this.spinner = false
			// 			},500)
			// 		})
			//
			// 	},

				filterByDate:function(){
					this.onRequest({pagination:this.pagination,filter:''})
				},

				getTemplate: function() {
					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.get('/employee/template/download')
					.then((res) => {
						this.downloadTemplate(res.data.data.biometric_template)
					})
				},

				downloadTemplate:function(url){
					axios({
						url: this.apiUrl+'/'+url,
						method: 'GET',
						responseType: 'arraybuffer',
					}).then((response) => {
						var fileURL = window.URL.createObjectURL(new Blob([response.data]));
						var fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', 'Attendance_template.csv');
						document.body.appendChild(fileLink);

						fileLink.click();
					});
				},


				yearsList : function(){
					axios.create({
						baseURL : this.apiUrl,
						headers : {'Authorization' : `Bearer ${this.token}`}
					}).post(`/timekeeping/years/list`)
					.then((res) => {
						this.yearOptions = res.data.data
					});
				},

				exportTable:function(){

					const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
					this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
					typeof col.field === 'function'
					? col.field(row)
					: row[col.field === void 0 ? col.name : col.field],
					col.format
					)).join(','))
					).join('\r\n')

					const status = exportFile(
					'Timelogs.csv',
					content,
					'text/csv'
					)

					if (status !== true) {
						this.$q.notify({
							message: 'Browser denied file download...',
							color: 'negative',
							icon: 'warning'
						})
					}
				},
			}
		}
		</script>
