<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set As Approver</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table id="result-list" title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="id" :hide-bottom="searchResultData.length > 0" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
            <q-td key="action" :props="props">
              <div>
                <q-toggle :props="props" v-model="props.row.is_approver" @input="setState(props.row.is_approver,props.row.id)" checked-icon="fa fa-check" color="purple-10" unchecked-icon="fa fa-times">
                  <q-tooltip anchor="top middle" content-class="bg-purple-10" self="bottom middle" :offset="[10, 10]">
                    <strong>Set As Approver</strong>
                  </q-tooltip>
                </q-toggle>
              </div>
            </q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-red-5 q-gutter-sm">
              <span>
                {{ message }}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'SetAsApprover',
  data() {
    return {
      loading: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          align: 'center',
        }
      ],
      form: {
        employee_id: '',
        status: ''
      },
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.loading = true;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/search', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          if (res.data.data.length > 0) {
            for (let emp of res.data.data) {
              if (emp.is_approver == 'yes') {
                emp.is_approver = true;
              } else {
                emp.is_approver = false;
              }
              this.searchResultData.push({
                id: emp.id,
                name: this.toTitleCase(emp.name),
                employee_id: emp.employee_id,
                position: emp.position,
                department: emp.department,
                is_approver: emp.is_approver
              });
            }
          }
        })
    },

    setState: function(status, empID) {
      this.form.employee_id = empID;
      this.form.status = status;

      let formData = new FormData();

      formData.append('form_data', JSON.stringify(this.form));

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/employees/status/set-as-approver', formData).then((res) => {
        console.log('Successfully Set Approver Status');
        this.form.employee_id = '';
        this.form.status = '';
      })

      this.$q.notify({
        color: 'green-7',
        textColor: 'white',
        icon: 'fa fa-file-signature',
        message: 'Successfully Set Approver Status'
      });
    }
  }
}
</script>
<style lang="css" scoped>
#result-list tr:hover {
  cursor: default;
}
</style>