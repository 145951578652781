
</<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="row q-mb-md full-width">
                <div class="full-width text-h4" style="height: 40px;">
                Dump List
                <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
                    <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
                    Applications
                </q-chip>
                </div>
            </div>
            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-12 q-pa-md">
                <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div class="row">
                    <div class="col-12">
                        <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                        virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                        <template v-slot:top-left>
                            <q-btn
                            color="primary"
                            icon-right="fas fa-download"
                            label="Export to csv"
                            no-caps
                            @click="exportTable"
                            />
                        </template>
                        <template v-slot:top-right>
                            <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                            <template v-slot:append>
                                <q-icon name="fas fa-search" />
                            </template>
                            </q-input>
                        </template>
                        <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer">
                            <q-td key="action" :props="props">
                              <q-btn color="primary" @click="forReturn(props.row.id, props.row.applicant_id )" rounded size="sm" text-color="white" label="Return to Pool" />
                              <q-btn color="red" @click="forDelete(props.row.id, props.row.applicant_id )" rounded size="sm" text-color="white" label="Delete" />
                            </q-td>
                            <!-- <q-td key="applicant_id" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.applicant_id }}</q-td>
                            <q-td key="name" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.name }}</q-td>
                            <q-td key="date_of_application" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.date_of_application }}</q-td>
                            <q-td key="position_applying_for" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.position_applying_for }}</q-td>
                            <q-td key="with_call_center_experience" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.with_call_center_experience }}</q-td>
                            <q-td key="exam_date" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.exam_date }}</q-td>
                            <q-td key="exam_source" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)">{{ props.row.exam_source }}</q-td>
                            <q-td key="pass_fail" :props="props" @click="viewDetails('For Exam','Dump',props.row.id)"> {{ props.row.passfail }} </q-td>
                            <q-td key="reason" :props="props"> {{ props.row.reason }} </q-td>
                            <q-td key="totaldaysdump" :props="props"> {{ props.row.totaldaysdump }} </q-td> -->
                            <q-td key="applicant_id" :props="props">{{ props.row.applicant_id }}</q-td>
                            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                            <q-td key="date_of_application" :props="props">{{ props.row.date_of_application }}</q-td>
                            <q-td key="position_applying_for" :props="props">{{ props.row.position_applying_for }}</q-td>
                            <q-td key="with_call_center_experience" :props="props">{{ props.row.with_call_center_experience }}</q-td>
                            <q-td key="exam_date" :props="props">{{ props.row.exam_date }}</q-td>
                            <q-td key="exam_source" :props="props">{{ props.row.exam_source }}</q-td>
                            <q-td key="pass_fail" :props="props"> {{ props.row.passfail }} </q-td>
                            <q-td key="reason" :props="props"> {{ props.row.reason }} </q-td>
                            <q-td key="totaldaysdump" :props="props"> {{ props.row.totaldaysdump }} </q-td>
                        </q-tr>
                        <template v-slot:no-data>
                            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                            <span>
                                No Applications Yet
                            </span>
                            <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                            </div>
                        </template>
                        </q-table>
                    </div>
                    </div>
                </q-card-section>
                </q-card>
            </div>
        </div>
        <q-dialog v-model="forReturnDialog" persistent>
          <q-card>
            <q-card-section class="row items-center">
              <span class="q-ml-sm">Are you sure you want to return this applicant
                  <span class="text-bold text-indigo-7" >
                      {{ this.returnData.applicantID }}
                  </span>?
              </span>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancel" color="red" v-close-popup />
              <q-btn flat label="Yes" color="indigo-7" @click="returnToPool" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
        <q-dialog v-model="forDeleteDialog" persistent>
          <q-card>
            <q-card-section class="row items-center">
              <span class="q-ml-sm">Are you sure you want to delete this applicant
                  <span class="text-bold text-red" >
                      {{ this.deleteData.applicantID }}
                  </span>?
              </span>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancel" color="red" v-close-popup />
              <q-btn flat label="Yes" color="indigo-7" @click="toDeleteData" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
        <q-dialog v-model="viewDetailsDialog" transition-show="scale" transition-hide="scale">
            <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
                <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
                <div class="text-h5">{{ vAD.name }}</div>
                <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div>
                </div>
                <div class="col-12 q-ma-sm">
                <q-card class="my-card transparent no-shadow">
                    <q-card-section class="q-mt-sm q-pa-md">
                    <div class="text-bold text-head">Personal Information</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-6">
                        <q-field label="Present Address" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.presentAddress}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-6">
                        <q-field label="Permanent Address" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.permanentAddress}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Email Address" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.email}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Mobile Number" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.mobileNumber}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Telephone Number" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.telNumber}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Gender" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.gender}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Birthday" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.birthday}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Civil Status" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.civilStatus}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="Religion" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.religion}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md">
                    <div class="text-bold text-head">Application Information</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-6">
                        <q-field label="Know Company Through" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.through}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-6">
                        <q-field label="Position Applying For" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.positionApplyingFor}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>

                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-3">
                        <q-field label="Onsite?" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.onsite}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="WFH?" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.wfh}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Mode of Transportation" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.trasportation}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Availability to join?" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.availabilityToJoin}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-3">
                        <q-field label="Pending Application" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.pendingApplication}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Account" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.account}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.position}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Recruiter" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.recruiter}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-4">
                        <q-field label="Exam Date" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.examDate}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Exam Result" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.examResult}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-3">
                        <q-field label="ISP" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.isp}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Speed" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.speed}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Previous Salary" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.prevSalary}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Expected Salary" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.expSalary}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-12">
                        <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.remarks}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md">
                    <div class="text-bold text-head">Work Eperiences</div>
                    <div class="row q-col-gutter-md">
                        <div class="col">
                        <q-field label="With Call Center Experience" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.callCenterExp}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                        <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.ccExpPosition}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                        <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.ccExpDuration}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                        <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.ccExpCompany}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                        <q-field label="Account Handled" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.ccExpAccount}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                        <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.ccExpReasonLeave}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-md">
                        <div class="col q-mt-sm q-pt-none">
                        <q-field label="With Other Work Experience" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.otherWorkExperience}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                        <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.owExpPosition}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                        <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.owExpDuration}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                        <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.owExpCompany}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                        <q-field label="Nature of Work" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.owExpBusNature}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                        <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.owExpReasonLeave}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md">
                    <div class="text-bold text-head">Educational Background</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-2">
                        <q-field label="Highest Educational Attainment" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.educAttainment}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-1">
                        <q-field label="Year Attended" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.yearAttended}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-5">
                        <q-field label="School / University / College" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.suc}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3" v-if="vAD.educAttainment != 'High School Graduate'">
                        <q-field label="Course" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.course}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-1" v-if="vAD.educAttainment == 'College Level'">
                        <q-field label="Year Level" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.yearLevel}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md" v-if="vAD.natureOfIllness1 != 'N/A'">
                    <div class="text-bold text-head">Medical / Health Background</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-8">
                        <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.natureOfIllness1}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.illnessDuration1}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness2 != 'N/A'">
                        <div class="col-8">
                        <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.natureOfIllness2}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.illnessDuration2}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness3 != 'N/A'">
                        <div class="col-8">
                        <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.natureOfIllness3}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.illnessDuration3}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md">
                    <div class="text-bold text-head">Emergency Contact</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-4">
                        <q-field label="Contact Person" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.contactPerson}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Contact Relationship" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.contactRelation}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-4">
                        <q-field label="Contact Number" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.contactNumber}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-section class="q-mt-xs q-pa-md">
                    <div class="text-bold text-head">Assessment</div>
                    <div class="row q-col-gutter-md">
                        <div class="col-4">
                        <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.assessment}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Assessed By" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.assessedBy}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-3">
                        <q-field label="Date of Assessment" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.assessmentDate}}</div>
                            </template>
                        </q-field>
                        </div>
                        <div class="col-2">
                        <q-field label="Last Process" label-color="indigo-7" stack-label color="indigo-7">
                            <template v-slot:control>
                            <div>{{vAD.lastProcess}}</div>
                            </template>
                        </q-field>
                        </div>
                    </div>
                    </q-card-section>
                    <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
                    <div class="row col-9 q-pl-sm q-col-gutter-sm"></div>
                    <div class="col-3 q-pl-sm justify-end">
                        <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
                    </div>
                    </q-card-actions>
                </q-card>
                </div>
            </div>
            </q-dialog>
    </div>
</template>

<script>

import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
    name: 'ForDumpList',
    data() {
        return {
            forReturnDialog: false,
            forDeleteDialog: false,
            filter: '',
            Pagination: {
                sortBy: 'date_of_application',
                descending: true,
                // page: 1,
                rowsPerPage: 0
            },
            ResultColumns: [
                {
                  name: 'action',
                  field: 'action',
                  label: 'Action',
                  style: 'width: 200px',
                  align: 'center',
                  headerStyle: 'width: 200px',
                  headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'applicant_id',
                    field: 'applicant_id',
                    label: 'Applicant ID',
                    style: 'width: 200px',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'name',
                    field: 'name',
                    label: 'Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'date_of_application',
                    field: 'date_of_application',
                    label: 'Date Applied',
                    sortable: true,
                    align: 'center',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'position_applying_for',
                    field: 'position_applying_for',
                    label: 'Position Applying For',
                    align: 'center',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'with_call_center_experience',
                    field: 'with_call_center_experience',
                    label: 'With Call Center Expereience?',
                    style: 'width: 200px',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'exam_date',
                    field: 'exam_date',
                    label: 'Exam Date',
                    style: 'width: 150px',
                    align: 'center',
                    headerStyle: 'width: 150px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'exam_source',
                    field: 'exam_source',
                    label: 'Exam Source',
                    style: 'width: 150px',
                    align: 'center',
                    headerStyle: 'width: 150px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'pass_fail',
                    field: 'pass_fail',
                    label: 'Pass/Fail/NA',
                    style: 'width: 150px',
                    align: 'center',
                    headerStyle: 'width: 150px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'reason',
                    field: 'reason',
                    label: 'Reason',
                    style: 'width: 200px',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'totaldaysdump',
                    field: 'totaldaysdump',
                    label: 'Total Days Dump',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
            ],
            viewDetailsDialog: false,
            vAD: {
                id: null,
                trackingID: null,
                dateApplied: null,
                name: null,
                presentAddress: null,
                permanentAddress: null,
                email: null,
                mobileNumber: null,
                telNumber: null,
                gender: null,
                birthday: null,
                civilStatus: null,
                religion: null,
                knowCompanyThru: null,
                positionApplyingFor: null,
                callCenterExp: null,
                ccExpDuration: null,
                ccExpCompany: null,
                ccExpPosition: null,
                ccExpAccount: null,
                ccExpReasonLeave: null,
                otherWorkExperience: null,
                owExpDuration: null,
                owExpCompany: null,
                owExpPosition: null,
                owExpBusNature: null,
                owExpReasonLeave: null,
                educAttainment: null,
                yearAttended: null,
                suc: null,
                course: null,
                yearLevel: null,
                natureOfIllness1: null,
                illnessDuration1: null,
                natureOfIllness2: null,
                illnessDuration2: null,
                natureOfIllness3: null,
                illnessDuration3: null,
                contactPerson: null,
                contactRelation: null,
                contactNumber: null,
                assessment: null,
                assessedBy: null,
                assessmentDate: null,
                lastProcess: null,
                onsite: null,
                wfh: null,
                trasportation: null,
                availabilityToJoin: null,
                pendingApplication: null,
                isp: null,
                speed: null,
                prevSalary: null,
                expSalary: null,
                account: null,
                position: null,
                recruiter: null,
                examDate: null,
                examResult: null,
                remarks: null,
            },

            applicationCounts: 0,
            applicationsData: [],
            timer: "",
            returnID: null,
            returnApplicantID: null,
            returnData: {
                id: null,
                applicantID: null,
            },
            deleteData: {
                id: null,
                applicantID: null,
            },
        }
    },
    mounted() {
        this.loadApplications();
        this.timer = setInterval(this.loadApplications, 300000);
    },
    methods: {
        searchData: function(){
            vueCookies.set(this.searchKey, {
                'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },
        forReturn(id, applicantID){
            this.returnData.id = id;
            this.returnData.applicantID = applicantID;
            this.forReturnDialog = true;
        },
        returnToPool() {
            this.loadingAction = true;
            let formData = new FormData();

            // this.returnData.id = id;
            // this.returnData.applicantID = applicantID;
            formData.append('form_data', JSON.stringify(this.returnData));
                axios.create({
                    baseURL: this.apiUrl,
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }).post('/recruitments/dump/return-pool', formData).then((res) => {
                    setTimeout(() => {
                  console.log(res);
                  this.loadApplications();
                  this.loadingAction = false;
                  this.forReturnDialog = false;
                }, 1000);
            });

        },
        forDelete(id, applicantID){
            this.deleteData.id = id;
            this.deleteData.applicantID = applicantID;
            this.forDeleteDialog = true;
        },
        toDeleteData() {
            this.loadingAction = true;
            let formData = new FormData();
            formData.append('form_data', JSON.stringify(this.deleteData));
                axios.create({
                    baseURL: this.apiUrl,
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }).post('/recruitments/dump/delete-data', formData).then((res) => {
                    setTimeout(() => {
                  console.log(res);
                  this.loadApplications();
                  this.loadingAction = false;
                  this.forDeleteDialog = false;
                }, 1000);
            });
        },
        loadApplications: function() {
            this.loadingText = 'Retrieving Lists';
            this.applications = [];
            this.applicationsData = [];

            // if(document.cookie != ''){
            //     let decodedCookie = decodeURIComponent(document.cookie);
            //     let ca = decodedCookie.split(';');
            //     let searchFilter = ca[0].substring(8, ca[0].length);
            //     this.filter = searchFilter;
            // }

            this.filter = this.searchKeyword;

            axios.create({
                baseURL: this.apiUrl,
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
                })
                .get('/recruitments/application/get-dump-list')
                .then((res) => {
                this.applications = res.data.data;
                this.applicationCounts = Object.keys(this.applications).length;
                for (let eachApplication of this.applications) {
                    let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
                    let tempDate = eachApplication.date_apply;
                    let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
                    let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');
                    let today = new Date().toISOString().slice(0, 10);
                    let date_dump = eachApplication.date_dump;

                    let dumpdate = this.getDateDiff(today,date_dump) + ' Days';
                    this.applicationsData.push({
                        id: eachApplication.id,
                        name: name,
                        date_of_application: date_applied,
                        position_applying_for: eachApplication.position_sought,
                        with_call_center_experience: eachApplication.ccexperience,
                        applicant_id: eachApplication.tracking_number,
                        exam_date: date_exam,
                        passfail: eachApplication.passfail_int,
                        exam_source: eachApplication.exam_source,
                        reason: eachApplication.dump_reason,
                        totaldaysdump: dumpdate
                        // status: statuspf
                    });

                }
                })
                .finally(() => {
                this.loadingModel = false;
                this.loadingText = '';
                })
        },

        viewDetails: function(applicationStatus, status, rowID) {
            this.loadingModel = true;
            this.loadingText = 'Retrieving Details';
            axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
            })
            .get('/recruitments/application/get-examresult-list', {
            params: {
                id: rowID,
                status: applicationStatus,
                dump: status
            }
            })
            .then((res) => {

            this.loadingModel = false;
            var viewApplicantDetails = res.data.data[0];

            console.log(viewApplicantDetails);

            var temp_date_applied, temp_birthdate;

            this.vAD.id = viewApplicantDetails.a_id;

            this.vAD.trackingID = viewApplicantDetails.tracking_number;
            this.vAD.dateApplied = date.formatDate(viewApplicantDetails.date_apply, 'MMMM DD, YYYY');

            this.vAD.name = this.toTitleCase(viewApplicantDetails.firstname) + ' ' + this.toTitleCase(viewApplicantDetails.middlename) + ' ' +
                this.toTitleCase(viewApplicantDetails.lastname);

            this.vAD.presentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

            this.vAD.permanentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

            this.vAD.email = viewApplicantDetails.email.toLowerCase();

            this.vAD.mobileNumber = viewApplicantDetails.mobile;

            this.vAD.telNumber = viewApplicantDetails.telephone;

            this.vAD.gender = this.toTitleCase(viewApplicantDetails.gender);

            this.vAD.birthday = date.formatDate(viewApplicantDetails.date_of_birth, 'MMMM DD, YYYY');

            this.vAD.civilStatus = this.toTitleCase(viewApplicantDetails.civil_status);

            this.vAD.religion = this.toTitleCase(viewApplicantDetails.religion);

            if (viewApplicantDetails.through == "job posting" || viewApplicantDetails.through == "Referral" || viewApplicantDetails.through == 'OTHERS') {
                this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.thru);
            } else {
                this.vAD.through = this.toTitleCase(viewApplicantDetails.through);
            }

            this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_sought);

            if (viewApplicantDetails.ccexperience == 'with call center experience') {
                this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
                this.vAD.ccExpDuration = this.toTitleCase(viewApplicantDetails.ccyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.ccmonthexp);
                this.vAD.ccExpCompany = this.toTitleCase(viewApplicantDetails.cc_compname);
                this.vAD.ccExpPosition = this.toTitleCase(viewApplicantDetails.position);
                this.vAD.ccExpReasonLeave = this.toTitleCase(viewApplicantDetails.reason_for_leaving);
            } else {
                this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
            }

            if (viewApplicantDetails.othexp == 'YES') {
                this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
                this.vAD.owExpDuration = this.toTitleCase(viewApplicantDetails.othyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.othmonthexp);
            } else {
                this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
            }

            var edattn = '';
            if(viewApplicantDetails.edattain == "ed0"){
                edattn = "High School Graduate";
            } else if(viewApplicantDetails.edattain == "ed1"){
                edattn = "College Level";
            } else if(viewApplicantDetails.edattain == "ed2"){
                edattn = "Degree Holder";
            } else if(viewApplicantDetails.edattain == "ed3"){
                edattn = "Vocational Graduate";
            }

            this.vAD.educAttainment = this.toTitleCase(edattn);

            this.vAD.yearAttended = viewApplicantDetails.school_year;

            this.vAD.natureOfIllness1 = viewApplicantDetails.illness;

            this.viewDetailsDialog = true;
            })
        },
        exportTable () {
            // naive encoding to csv format
            const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
                this.applicationsData.map(row => this.ResultColumns.map(col => wrapCsvValue(
                typeof col.field === 'function'
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                col.format
                )).join(','))
            ).join('\r\n')

            const status = exportFile(
                'ForExamResult.csv',
                content,
                'text/csv'
            )

            if (status !== true) {
                this.$q.notify({
                message: 'Browser denied file download...',
                color: 'negative',
                icon: 'warning'
                })
            }
        },
        getDateDiff: function(time1, time2) {
            var str1= time1.split('-');
            var str2= time2.split('-');
            //                yyyy   , mm       , dd
            var t1 = new Date(str1[0], str1[1], str1[2]);
            var t2 = new Date(str2[0], str2[1], str2[2]);

            var diffMS = t1 - t2;
            var diffS = diffMS / 1000;
            var diffM = diffS / 60;
            var diffH = diffM / 60;
            var diffD = diffH / 24;

            return diffD;
        },

        cancelAutoUpdate() {
          clearInterval(this.timer);
        },
    },
    beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>

<style>

</style>
