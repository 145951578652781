<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="row full-width text-h4 justify-between q-mb-md">
        <div class="col-8">
          Employee Leave Credits
        </div>
        <div class="col-4">
          <q-btn @click="extractAllLeave" class="bg-green-8 text-white float-right" label="Extract All" size="md" push color="green-8" no-caps>
            <q-icon class="q-ml-sm" size="1em" name="fa fa-download" />
          </q-btn>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input :rules="[formRules.required]" outlined v-model="searchModel" dense placeholder="Search employee here..." color="amber-8">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="credit_id" :hide-bottom="searchResultData.length < 5" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="searchRowClick(props.row.name,props.row.credit_id,props.row.employee_id,props.row.max_vl_credits,props.row.date_created)">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
            <q-td key="vl_credits" :props="props">{{ props.row.vl_credits }} / {{ props.row.max_vl_credits }}</q-td>
            <q-td key="sl_credits" :props="props">{{ props.row.sl_credits }} / {{ props.row.max_sl_credits }}</q-td>
             <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
              <span>
                {{message}}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <div class="col-12" v-if="empForModify">
        <q-card bordered class="my-card q-pa-sm">
          <div class="col-12 q-mb-md">
            <q-banner bordered dense rounded class="bg-light-blue-9 text-white text-center">
              Leave Modification / Details For: <b>{{empName}}</b>
            </q-banner>
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col-6">
              <q-card bordered class="my-card q-pa-sm">
                <q-card-section class="text-center q-ma-none q-pa-none">
                  <div class="text-h6">Add Max VL/SL Credits</div>
                </q-card-section>
                <q-card-section class="text-center">
                  <q-slider v-model="added_vlsl_maxcredits" :min="0" :max="limited_vlsl_credits" :step="0.5" snap markers label label-always color="amber-8">
                  </q-slider>
                </q-card-section>
              </q-card>
            </div>
            <div class="col-3">
              <q-input class="q-ma-none mod-input" outlined v-model="form.modifyReason" label="Reason For Modification" clearable type="textarea" color="amber-8" />
            </div>
            <div class="col-3">
              <div class="column justify-between" style="height: 100%">
                <q-btn @click="extractIndividualLeave" label="Extract Leave Details" size="lg" style="width: 100%" push color="green-8" no-caps />
                <q-btn :loading="lvEffectLoading" @click="confirmModifyLeave" label="Confirm Modification" size="lg" style="width: 100%" push color="amber-8" no-caps>
                  <template v-slot:loading>
                    <q-spinner-gears class="on-left" />
                    Updating Leave Credits
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
          <div class="col-12 q-mt-md">
            <q-card bordered class="my-card">
              <q-card-section class="q-ma-none q-pa-none">
                <q-table class="center-cell" :data="leaveSpreadData" :columns="leaveSpreadColumns" row-key="name" hide-bottom separator="cell">
                  <q-tr slot="body" slot-scope="props" :props="props">
                    <q-td key="year" :props="props">{{ props.row.year }}</q-td>
                    <q-td v-for="(earned,index) in props.row.earned" :key="index">{{ props.row.earned[index][index] }}</q-td>
                    <q-td key="total" :props="props">{{ props.row.total }}</q-td>
                  </q-tr>
                </q-table>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-12 q-mt-md q-mb-md">
            <q-card bordered class="my-card">
              <q-card-section class="q-ma-none q-pt-sm q-pb-xs q-pl-sm q-pr-xs">
                <div class="row col-12 q-gutter-xs q-mb-xs">
                  <q-chip square class="col text-bold" color="green-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ max_vl }}</q-avatar>
                    Max VL Credits
                  </q-chip>
                  <q-chip square class="col text-bold" color="green-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ max_sl }}</q-avatar>
                    Max SL Credits
                  </q-chip>
                  <q-chip square class="col text-bold" color="blue-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_earned }}</q-avatar>
                    Total Earned VL Credits
                  </q-chip>
                  <q-chip square class="col text-bold" color="blue-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_earned }}</q-avatar>
                    Total Earned SL Credits
                  </q-chip>
                </div>
                <div class="row col-12 q-gutter-xs">
                  <q-chip square class="col text-bold" color="red-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_availed }}</q-avatar>
                    Availed Vacation Leave
                  </q-chip>
                  <q-chip square class="col text-bold" color="red-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_availed }}</q-avatar>
                    Availed Sick Leave
                  </q-chip>
                  <q-chip square class="col text-bold" color="deep-orange-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ vl_remaining }}</q-avatar>
                    Remaining VL Credits
                  </q-chip>
                  <q-chip square class="col text-bold" color="deep-orange-3">
                    <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sl_remaining }}</q-avatar>
                    Remaining SL Credits
                  </q-chip>
                </div>
                <div class="row col-12 q-gutter-xs">
                  <!-- <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ ml_availed }}</q-avatar>
                Availed Maternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ pl_availed }}</q-avatar>
                Availed Paternity Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ mc_availed }}</q-avatar>
                Availed Magna Carta Leave
              </q-chip>
              <q-chip square class="col text-bold" color="deep-purple-3">
                <q-avatar font-size="13px" color="blue-grey-6" text-color="white" style="width:2em">{{ sp_availed }}</q-avatar>
                Availed Solo Parent Leave
              </q-chip> -->
                  <q-chip square class="col text-bold" color="yellow-5">
                    <q-avatar :icon="ml_availment" font-size="13px" color="grey-5" :text-color="ml_avail_color" style="width:2em" />
                    Availed Maternity Leave
                  </q-chip>
                  <q-chip square class="col text-bold" color="yellow-5">
                    <q-avatar :icon="pl_availment" font-size="13px" color="grey-5" :text-color="pl_avail_color" style="width:2em" />
                    Availed Paternity Leave
                  </q-chip>
                  <q-chip square class="col text-bold" color="yellow-5">
                    <q-avatar :icon="mc_availment" font-size="13px" color="grey-5" :text-color="mc_avail_color" style="width:2em" />
                    Availed Magna Carta Leave
                  </q-chip>
                  <q-chip square class="col text-bold" color="yellow-5">
                    <q-avatar :icon="sp_availment" font-size="13px" color="grey-5" :text-color="sp_avail_color" style="width:2em" />
                    Availed Solo Parent Leave
                  </q-chip>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </q-card>
      </div>
    </div>
    <!-- <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Fetching Records
          </div>
        </q-card-section>
      </q-card>
    </q-dialog> -->
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
let today = Date.now();
let dateToday = date.formatDate(today, 'MM-DD-YYYY-hhmmA');
export default {
  name: 'EmployeeLeaveCredits',
  data() {
    return {
      dateToday,
      empName: '',
      empForModify: false,
      // loadingModel: false,
      loading: false,
      lvEffectLoading: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 160px',
          align: 'center',
          headerStyle: 'width: 160px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          align: 'center',
        },
        {
          name: 'vl_credits',
          field: 'vl_credits',
          label: 'Vacation Leave Credits',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'sl_credits',
          field: 'sl_credits',
          label: 'Sick Leave Credits',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
       },
       {
          name: 'date_created',
          field: 'date_created',
          label: 'Date Credit',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        }
      ],
      current_max_vlsl_credits: 0,
      limited_vlsl_credits: 0,
      added_vlsl_maxcredits: 0,
      selectedEmployeeID: null,
      max_vl: null,
      max_sl: null,
      vl_earned: null,
      sl_earned: null,
      vl_availed: null,
      sl_availed: null,
      vl_remaining: null,
      sl_remaining: null,
      ml_availed: null,
      ml_availment: 'fa fa-times',
      ml_avail_color: 'red-10',
      pl_availed: null,
      pl_availment: 'fa fa-times',
      pl_avail_color: 'red-10',
      mc_availed: null,
      mc_availment: 'fa fa-times',
      mc_avail_color: 'red-10',
      sp_availed: null,
      sp_availment: 'fa fa-times',
      sp_avail_color: 'red-10',
      leaveSpreadData: [],
      leaveSpreadColumns: [{
          name: 'year',
          field: 'year',
          label: 'Year',
          align: 'center',
          style: 'font-weight: bold; width: 100px',
          classes: 'bg-grey-2',
          headerClasses: 'bg-blue-8 text-white text-center',
          headerStyle: 'width: 100px'
        },
        {
          name: 'january',
          field: 'january',
          label: 'January',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'february',
          field: 'february',
          label: 'February',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'march',
          field: 'march',
          label: 'March',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'april',
          field: 'april',
          label: 'April',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'may',
          field: 'may',
          label: 'May',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'june',
          field: 'june',
          label: 'June',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'july',
          field: 'july',
          label: 'July',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'august',
          field: 'august',
          label: 'August',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'september',
          field: 'september',
          label: 'September',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'october',
          field: 'october',
          label: 'October',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'november',
          field: 'november',
          label: 'November',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'december',
          field: 'december',
          label: 'December',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        },
        {
          name: 'total',
          field: 'total',
          label: 'Total',
          align: 'center',
          style: 'width: 80px',
          headerClasses: 'bg-blue-8 text-white',
          headerStyle: 'width: 80px'
        }
      ],
      form: {
        modifiedMaxVLSLCredits: 0,
        addedMaxVLSLCredits: 0,
        modifyReason: null,
        selectedCreditID: null,
        currentMaxVLSLCredits: 0
      },
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.empName = '';
      this.empForModify = false;
      this.loading = true;
      this.$refs.vForm.validate();
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/credits/search-employee-credits', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          for (let emp of res.data.data) {
            this.searchResultData.push({
              credit_id: emp.credit_id,
              employee_id: emp.employee_id,
              name: this.toTitleCase(emp.name),
              position: emp.position,
              department: emp.department,
              vl_credits: emp.vl_credits,
              sl_credits: emp.sl_credits,
              max_vl_credits: emp.max_vl_credits,
              max_sl_credits: emp.max_sl_credits,
              date_created: emp.leave
            });
          }
        })
    },
    searchRowClick: function(rowName, rowID, empID, maxVL,dateCreated) {
      this.added_vlsl_maxcredits = 0;
      this.ml_availment = 'fa fa-times';
      this.ml_avail_color = 'red-10';
      this.pl_availment = 'fa fa-times';
      this.pl_avail_color = 'red-10';
      this.mc_availment = 'fa fa-times';
      this.mc_avail_color = 'red-10';
      this.sp_availment = 'fa fa-times';
      this.sp_avail_color = 'red-10';
      this.empName = rowName;
      this.form.selectedCreditID = rowID;
      this.selectedEmployeeID = empID;
      this.empForModify = true;
      this.limited_vlsl_credits = 15 - maxVL;
      this.current_max_vlsl_credits = maxVL;
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/credits/retrieve', {
          params: {
            category: 'all',
            keyword: this.form.selectedCreditID,
            date_created: dateCreated
          }
        })
        .then((res) => {
          let leaveCredits = res.data.data;
          this.max_vl = leaveCredits.max_vl;
          this.max_sl = leaveCredits.max_sl;
          this.vl_earned = leaveCredits.vl_earned;
          this.sl_earned = leaveCredits.sl_earned;
          this.vl_availed = leaveCredits.vl_availed;
          this.sl_availed = leaveCredits.sl_availed;
          this.vl_remaining = leaveCredits.vl_remaining;
          this.sl_remaining = leaveCredits.sl_remaining;
          this.ml_availed = leaveCredits.ml_availed;
          this.pl_availed = leaveCredits.pl_availed;
          this.mc_availed = leaveCredits.mc_availed;
          this.sp_availed = leaveCredits.sp_availed;
          this.leaveSpreadData = leaveCredits.plotted;
          if (this.ml_availed > 0) {
            this.ml_availment = 'fa fa-check';
            this.ml_avail_color = 'green-10';
          }
          if (this.pl_availed > 0) {
            this.pl_availment = 'fa fa-check';
            this.pl_avail_color = 'green-10';
          }
          if (this.mc_availed > 0) {
            this.mc_availment = 'fa fa-check';
            this.mc_avail_color = 'green-10';
          }
          if (this.sp_availed > 6) {
            this.sp_availment = 'fa fa-check';
            this.sp_avail_color = 'green-10';
          }
        });
    },
    confirmModifyLeave: function() {
      if (this.added_vlsl_maxcredits == 0) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'No modification done.'
        });
      } else if (this.form.modifyReason == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please indicate reason for modification.'
        });
      } else {
        this.lvEffectLoading = true;
        this.form.addedMaxVLSLCredits = Number(this.added_vlsl_maxcredits);
        this.form.currentMaxVLSLCredits = Number(this.current_max_vlsl_credits);
        this.form.modifiedMaxVLSLCredits = Number(this.added_vlsl_maxcredits) + Number(this.current_max_vlsl_credits);
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));
        axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .post('/leave/credits/modify-credits', formData)
          .then((res) => {
            setTimeout(() => {
              this.lvEffectLoading = false;
              this.$q.notify({
                color: 'green-7',
                textColor: 'white',
                icon: 'fa fa-edit',
                message: 'Leave Credits Modified Successfully'
              });
              this.onSubmitSearch();
              this.form.modifiedMaxVLSLCredits = 0;
              this.form.addedMaxVLSLCredits = 0;
              this.form.modifyReason = null;
              this.form.selectedCreditID = null;
              this.added_vlsl_maxcredits = 0;
            }, 1000);
          });
      }
    },
    extractIndividualLeave: function() {
      axios.create({
          baseURL: this.apiUrl,
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${this.token}` }
        })
        .get('/leave/credits/exportIndividual', {
          params: {
            keyword: this.form.selectedCreditID
          }
        })
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.selectedEmployeeID + '-' + this.removeFullnameSpace(this.empName) + '-Leave-Credits_AO_' + this.dateToday + '.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    extractAllLeave: function() {
      axios.create({
          baseURL: this.apiUrl,
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).get(`/leave/credits/exportAll`)
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'All-Leave-Credits_AO_' + this.dateToday + '.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }
  }
}
</script>
<style lang="css" scoped>
.text-height {
  font-size: 30px;
  text-align: center;
}
.mod-input {
  height: 122px;
  min-height: 122px;
  resize: none;
  overflow: hidden;
}
.center-cell td {
  text-align: center;
}
</style>
