<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
    v-model="dialog"
    persistent
    :maximized="maximizedToggle"
    transition-show="slide-up"
    transition-hide="slide-down"
    >
    <q-card>
      <q-bar>
        <!-- <h5 class="q-ma-none">Update Employee</h5> -->
        <q-space />
        <q-btn dense flat  @click="maximizedToggle = false" :disable="!maximizedToggle">
          <i class="fas fa-window-minimize"></i>
          <q-tooltip v-if="maximizedToggle" content-class="bg-white text-primary">Minimize</q-tooltip>
        </q-btn>

        <q-btn dense flat  @click="maximizedToggle = true" :disable="maximizedToggle">
          <i class="far fa-window-maximize"></i>
          <q-tooltip v-if="!maximizedToggle" content-class="bg-white text-primary">Maximize</q-tooltip>
        </q-btn>

        <q-btn dense flat v-close-popup>
          <i class="fas fa-times"></i>
          <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section class="q-ma-md">
        <q-form ref="vFormEdit">
          <div class="row">
            <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-dialog__inner--fullwidth" style="max-width: 280px" >
             <q-avatar size="200px" rounded  >
               <img src="../../assets/boy-avatar.png">
             </q-avatar>
           </div>
           <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl" >

           </div>
           <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">

           </div>
           <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md">
            <q-btn  color="blue" v-close-popup style="width: 100px" type="button" >Close</q-btn>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
            <div class="text-h6">Personal Information</div>
            <q-separator/>
            <q-input v-model="personalInfo.employee_no" label="EMPLOYEE NO." readonly />
            <q-input v-model="personalInfo.lastname" label="LASTNAME" :label-color="validateInput(personalInfo.lastname)" class="caps" readonly/>
            <q-input v-model="personalInfo.firstname" label="FIRSTNAME"  :label-color="validateInput(personalInfo.firstname)" class="caps" readonly/>
            <q-input v-model="personalInfo.middlename" label="MIDDLENAME"  :label-color="validateInput(personalInfo.middlename)" class="caps" readonly/>
            <q-input v-model="personalInfo.birthdate" mask="date"  label="DATE OF BIRTH"  :label-color="validateInput(personalInfo.birthdate)" readonly >
              <template v-slot:append>
                <i class="far fa-calendar-alt cursor-pointer" >
                 <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="personalInfo.birthdate" @input="() => $refs.qDateProxy.hide()" />
                  </q-popup-proxy>
                </i>
              </template>
            </q-input>
            <q-select v-model="personalInfo.gender" :options="gender" label="GENDER"  :label-color="validateInput(personalInfo.gender)" readonly/>
            <q-select v-model="personalInfo.civilStat" :options="civilStatus" label="CIVIL STATUS" :label-color="validateInput(personalInfo.civilStat)" readonly/>
            <q-input v-model="personalInfo.contactNo" label="CONTACT NO."  :label-color="validateInput(personalInfo.contactNo)" class="caps" readonly/>
            <q-input v-model="personalInfo.landline" label="LANDLINE"  :label-color="validateInput(personalInfo.landline)" class="caps" readonly/>
            <q-input v-model="personalInfo.permanentAdd" label="PERMANENT ADDRESS" :label-color="validateInput(personalInfo.permanentAdd)" class="caps" readonly/>
            <q-input v-model="personalInfo.presentAdd" label="PRESENT ADDRESS" :label-color="validateInput(personalInfo.presentAdd)" class="caps" readonly/>
            <br/>
            <div class="text-h6">Person to Notify in case of Emergency</div>
            <q-separator/>
            <q-input v-model="notifyPerson.personToNotif" label="PERSON TO NOTIFY" :label-color="validateInput(notifyPerson.personToNotif)" class="caps"/>
            <q-input v-model="notifyPerson.personToNotifContact" label="PERSON TO NOTIFY CONTACT" :label-color="validateInput(notifyPerson.personToNotifContact)" class="caps" readonly/>
            <q-select v-model="notifyPerson.personToNotifRelationship" :options="relationship" label="RELATIONSHIP(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifRelationship)" readonly/>
            <q-input v-model="notifyPerson.personToNotifEmailAdd" label="EMAIL ADDRESS(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifEmailAdd)" readonly/>

          </div>
          <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
            <div class="text-h6">Company Information</div>
            <q-separator/>
            <q-select v-model="companyInfo.company" option-value="id" option-label="name" :options="companyOptions" label="COMPANY" emit-value map-options :label-color="validateInput(companyInfo.company)" readonly/>
            <q-select v-model="companyInfo.is_active"  :options="statuses" label="STATUS" :label-color="companyInfo.is_active == 'ACTIVE' ? 'green' : companyInfo.is_active == 'HOLD' ? 'orange' : 'red'" readonly/>
            <q-input v-model="companyInfo.holdEffectivity" mask="date"  label="HOLD EFFECTIVITY" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdEffectivity)">
              <template v-slot:append>
                <i class="far fa-calendar-alt cursor-pointer" >
                 <q-popup-proxy ref="heDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="companyInfo.holdEffectivity" />
                </q-popup-proxy>
              </i>
            </template>
          </q-input>
          <q-input v-model="companyInfo.hold_period"  label="HOLD PERIOD" v-show="companyInfo.isHold" readonly :label-color="validateInput(companyInfo.hold_period)"/>
          <q-select v-model="companyInfo.holdReason"  :options="reasons" label="HOLD/SEPARATION REASON" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdReason)" readonly/>
          <q-input v-model="companyInfo.holdDuration" label="HOLD DURATION" class="caps" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdDuration)" readonly/>

          <q-input v-model="companyInfo.holdDate" mask="date"  label="HOLD DATE" v-show="companyInfo.isHold" readonly :label-color="validateInput(companyInfo.holdDate)"/>

          <q-select v-model="companyInfo.position"  :options="positions" label="POSITION" :label-color="validateInput(companyInfo.position)" readonly/>
          <q-select v-model="companyInfo.department_id" option-value="id" option-label="name" :options="departmentslist" label="DEPARTMENT" emit-value map-options :label-color="validateInput(companyInfo.department_id)" readonly/>
          <q-input v-model="personalInfo.email" label="EMAIL ADDRESS" :label-color="validateInput(personalInfo.email)" readonly/>

          <q-input  v-model="companyInfo.dateHired" mask="date" label="DATE HIRED" :label-color="validateInput(companyInfo.dateHired)" readonly>
            <template v-slot:append>
              <i class="far fa-calendar-alt cursor-pointer" >
               <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="companyInfo.dateHired" @input="() => $refs.qDateProxy.hide()" />
                </q-popup-proxy>
              </i>
            </template>
          </q-input>

          <q-select v-model="companyInfo.account"  :options="accounts" label="ACCOUNT" :label-color="validateInput(companyInfo.account)" readonly/>
          <q-select v-model="companyInfo.costcenter"  :options="costCenter" label="COST CENTER" :label-color="validateInput(companyInfo.costcenter)" readonly/>
          <q-select v-model="companyInfo.location"  :options="location" label="LOCATION" :label-color="validateInput(companyInfo.location)" readonly/>
          <q-select v-model="companyInfo.rank"  :options="rank" label="RANK" :label-color="validateInput(companyInfo.rank)" readonly />
          <q-select v-model="companyInfo.employmentStatus"  :options="empStatus" label="EMPLOYMENT STATUS" :label-color="validateInput(companyInfo.employmentStatus)" readonly/>

          <q-select v-model="companyInfo.immediateHead"  :options="immediateHeadOptions" label="IMMEDIATE HEAD" :label-color="validateInput(companyInfo.immediateHead)" readonly/>
          <q-select v-model="companyInfo.divisionHead"  :options="divisionHeadOptions" label="DIVISION HEAD" :label-color="validateInput(companyInfo.divisionHead)" readonly/>

        </div>
        <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
          <div class="text-h6">Salary Information</div>
          <q-separator/>

          <q-input v-model="salaryInfo.basicSalary" type="number" label="BASIC" :label-color="validateInput(salaryInfo.basicSalary)" :readonly="disabled" v-show="ifConsultant" />
          <q-input v-model="salaryInfo.allowance"   type="number" label="ALLOWANCE"  :label-color="validateInput(salaryInfo.allowance)" :readonly="disabled" v-show="ifConsultant" />
          <q-input v-model="salaryInfo.deminimis"   type="number" label="DEMINIMIS"  :label-color="validateInput(salaryInfo.deminimis)" :readonly="disabled" v-show="ifConsultant" />
          <q-input v-model="salaryInfo.totalSalary" type="number" label="TOTAL SALARY"  :label-color="validateInput(salaryInfo.totalSalary)" readonly v-show="ifConsultant" />

          <q-select v-model="salaryInfo.paymentMode"  :options="paymentmode" label="PAYMENT MODE" :label-color="validateInput(salaryInfo.paymentMode)" v-show="ifConsultant" readonly/>
          <q-select v-model="salaryInfo.paymentBank"  :options="payingbank" label="PAYING BANK" :label-color="validateInput(salaryInfo.paymentBank)" v-show="ifConsultant" readonly/>
          <q-input v-model="salaryInfo.accountNumber" label="ACCOUNT/PAYCARD NO." :label-color="validateInput(salaryInfo.accountNumber)" class="caps" v-show="ifConsultant" readonly/>
          <q-input v-model="salaryInfo.fee" label="Fee" v-show="!ifConsultant" />
          <q-select v-model="governmentInfo.payClass" :options="payclass" label="PAY CLASS" :label-color="validateInput(governmentInfo.payClass)"  v-show="ifConsultant" readonly/>
          <q-select v-model="governmentInfo.region" option-value="id" option-label="region_no" :options="regionOptions" label="REGION" emit-value map-options v-show="isMWE" readonly/>
          <q-select v-model="governmentInfo.sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options v-show="isMWE" readonly/>

          <br/>
          <div class="text-h6">Educational Background</div>
          <q-separator/>
          <q-select v-model="educationBackground.educationAttainment"  :options="educationalOptions" label="EDUCATIONAL ATTAINMENT" :label-color="validateInput(educationBackground.educationAttainment)" readonly/>
          <q-select v-model="educationBackground.course" :options="coursesOptions" label="COURSE" v-show="isCGrad" :label-color="validateInput(educationBackground.course)" readonly/>
          <q-input v-model="educationBackground.school" label="SCHOOL GRADUATED"  v-show="isCGrad" :label-color="validateInput(educationBackground.school)" class="caps" readonly/>
          <q-input v-model="educationBackground.highschool" label="HIGH SCHOOL" v-show="isHSGrad" :label-color="validateInput(educationBackground.highschool)" class="caps" readonly/>
        </div>
        <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl" v-show="isTrainee">
          <div class="text-h6">Government Information</div>
          <q-separator/>
          <q-input v-model="governmentInfo.sssNo" label="SSS #"  :label-color="validateInput(governmentInfo.sssNo)" readonly/>
          <q-input v-model="governmentInfo.hdmfNo" label="HDMF #" :label-color="validateInput(governmentInfo.hdmfNo)" readonly/>
          <q-input v-model="governmentInfo.phicNo" label="PHIC #" :label-color="validateInput(governmentInfo.phicNo)" readonly/>
          <q-input v-model="governmentInfo.tinNo" label="TIN #" :label-color="validateInput(governmentInfo.tinNo)" readonly/>
          <q-select v-model="governmentInfo.taxStatus"  :options="taxStatus" label="TAX  STATUS" :label-color="validateInput(governmentInfo.taxStatus)" readonly/>
          <q-select v-model="governmentInfo.accountability" label="ACCOUNTABILITY" use-input use-chips multiple :options="accountabilities" counter hint="Total" readonly/>
        </div>
      </div>
    </q-form>
  </q-card-section>
</q-card>
</q-dialog>
<!-- spinner -->
<q-dialog v-model="spinner" persistent >
  <q-card class="my-card transparent no-shadow">
    <q-card-section >
      <q-spinner-gears color="cyan"  size="5.5em"/>
    </q-card-section>
  </q-card>
</q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data () {
    return {
      canDelete:false,
      disabled:false,
      isMWE:false,
      ifConsultant:true,
      isTrainee:true,
      spinner:false,
      dialog: false, //for modal 
      maximizedToggle: true, //for modal
      holdPeriodList: [],
      statuses: [],
      reasons: [],
      coursesOptions:[],
      paymentmode: [],
      departmentslist: [],
      payingbank: [],
      payclass: [],
      gender: [],
      civilStatus:[],
      relationship:[],
      accounts:[],
      costCenter:[],
      taxStatus:[],
      location:[],
      rank: [],
      empStatus:[],
      regionOptions:[],
      sectorOptions:[],
      accountabilities:[],
      positions:[],
      immediateHeadOptions:[],
      divisionHeadOptions:[],
      educationalOptions:[],
      companyOptions:[],
      isHSGrad:false,
      isCGrad:false,
      personalInfo:{
        id:null,
        employee_no:null,
        firstname:null,
        middlename:null,
        lastname:null,
        birthdate:null,
        gender:null,
        civilStat:null,
        contactNo:null,
        landline:null,
        permanentAdd:null,
        presentAdd:null,
        email:null,
      },
      notifyPerson:{
        personToNotif:null,
        personToNotifContact:null,
        personToNotifRelationship:null,
        personToNotifEmailAdd:null
      },
      educationBackground:{
        educationAttainment:null,
        course:null,
        school:null,
        highschool:null,
      },
      companyInfo:{
        is_active:null,
        isHold:false,
        company:null,
        department:null,
        department_id:null,
        dateHired:null,
        position:null,
        account:null,
        costcenter:null,
        location:null,
        rank:null,
        employmentStatus:null,
        hold_period:null,
        holdReason:null,
        holdDate:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        holdEffectivity:null,
        holdDuration:null,
        immediateHead:null,
        divisionHead:null
      },
      previousCompany:{
        companyName:null,
        workDuration:null
      },
      salaryInfo:{
        basicSalary:null,
        allowance:null,
        deminimis:null,
        totalSalary:null,
        paymentMode:null,
        paymentBank:null,
        accountNumber:null,
        fee:null
      },
      healthInfo:{
        healthCardName:null,
        healthCardNo:null,
        insuranceName:null,
        insuranceNo:null
      },
      governmentInfo:{
        sssNo:null,
        hdmfNo:null,
        phicNo:null,
        tinNo:null,
        taxStatus:null,
        payClass:null,
        region:null,
        sector:null,
        accountability:[],
      }
    }
  },

  mounted(){
    this.eventHub.$on('EditEmployee', this.editEmployee);
  },


  methods:{

   validateInput:function(value){
    if (value == null || value == '' || value == 'NULL' || value == 'null' || value == "0000-00-00" || value == 0){
      return 'red';
    } else {
      return 'green';
    }
  },

  editEmployee : function(payload){


    this.governmentInfo.accountability = [];  
    this.personalInfo.id = payload.employee.row.id;
    this.personalInfo.employee_no = payload.employee.row.employee_id;
    this.personalInfo.firstname = payload.employee.row.firstname;
    this.personalInfo.middlename = payload.employee.row.middlename;
    this.personalInfo.lastname = payload.employee.row.lastname;
    this.personalInfo.email = payload.employee.row.email;

    this.personalInfo.birthdate = payload.employee.row.date_of_birth;
    this.personalInfo.gender = payload.employee.row.gender;
    this.personalInfo.civilStat = payload.employee.row.civil_status;
    this.personalInfo.contactNo = payload.employee.row.mobile_number;
    this.personalInfo.permanentAdd = payload.employee.row.address;
    this.personalInfo.presentAdd = payload.employee.row.city_address;

    this.notifyPerson.personToNotif = payload.employee.row.person_to_notif;
    this.notifyPerson.personToNotifContact = payload.employee.row.person_to_notif_contact;
    this.notifyPerson.personToNotifRelationship = payload.employee.row.relation_to_person_to_notif;
    this.notifyPerson.personToNotifEmailAdd = payload.employee.row.email_of_person_to_notif;

    this.educationBackground.educationAttainment = payload.employee.row.educational_attainment;
    this.educationBackground.course = payload.employee.row.college_course;
    this.educationBackground.school = payload.employee.row.college_school;
    this.educationBackground.highschool = payload.employee.row.high_school;

    if(this.educationBackground.educationAttainment == 'HIGH SCHOOL'){
      this.isHSGrad = true;
      this.isCGrad = false;
    }else if(this.educationBackground.educationAttainment == 'COLLEGE'){
      this.isHSGrad = false;
      this.isCGrad = true;
    }else{
      this.isHSGrad = false;
      this.isCGrad = false;
    }

    this.companyInfo.is_active = payload.employee.row.is_active.toUpperCase();
    if(this.companyInfo.is_active == 'HOLD'){
      this.companyInfo.isHold = true
      this.canDelete = true
    }else{
      this.companyInfo.isHold = false
      this.canDelete = false
    }

    this.companyInfo.company = payload.employee.row.company;
    this.companyInfo.department = payload.employee.row.department;
    this.companyInfo.department_id = payload.employee.row.department_id;
    this.companyInfo.dateHired = payload.employee.row.date_hired;
    this.companyInfo.position = payload.employee.row.position;
    this.companyInfo.account = payload.employee.row.account;
    this.companyInfo.costcenter = payload.employee.row.cost_center;
    this.companyInfo.location = payload.employee.row.location;
    this.companyInfo.rank = payload.employee.row.rank;
    this.companyInfo.employmentStatus = payload.employee.row.employment_status;

    if(this.companyInfo.rank === 'TRAINEE'){
      this.isTrainee = false;

    }else{
      this.isTrainee = true;
    }


    this.companyInfo.hold_period = payload.employee.row.hold_period;
    this.companyInfo.holdReason = payload.employee.row.hold_reason;
    this.companyInfo.holdDate = payload.employee.row.hold_date;
    this.companyInfo.holdEffectivity = payload.employee.row.hold_effectivity;
    this.companyInfo.holdDuration = payload.employee.row.hold_duration;
    this.companyInfo.immediateHead = payload.employee.row.immediate_head;
    this.companyInfo.divisionHead = payload.employee.row.division_head;

    this.previousCompany.companyName = payload.employee.row.previous_company;
    this.previousCompany.workDuration = payload.employee.row.previous_company_length_of_stay;

    this.salaryInfo.basicSalary = payload.employee.row.basic_salary;
    this.salaryInfo.allowance = payload.employee.row.allowance;
    this.salaryInfo.deminimis = payload.employee.row.deminimis;
    this.salaryInfo.totalSalary = payload.employee.row.total_salary;
    this.salaryInfo.paymentMode = payload.employee.row.payment_mode;
    this.salaryInfo.paymentBank = payload.employee.row.paying_bank;
    this.salaryInfo.accountNumber = payload.employee.row.account_number;

    this.healthInfo.healthCardName = payload.employee.row.healthcard_name;
    this.healthInfo.healthCardNo = payload.employee.row.healthcard_number;
    this.healthInfo.insuranceName = payload.employee.row.insurance_name;
    this.healthInfo.insuranceNo = payload.employee.row.insurance_number;

    this.governmentInfo.sssNo = payload.employee.row.sss_number;
    this.governmentInfo.hdmfNo = payload.employee.row.hdmf_number;
    this.governmentInfo.phicNo = payload.employee.row.philhealth_number;
    this.governmentInfo.tinNo = payload.employee.row.tin_number;
    this.governmentInfo.bir_number = payload.employee.row.bir_number;
    this.governmentInfo.taxStatus = payload.employee.row.tax_status;
    this.governmentInfo.payClass = payload.employee.row.pay_class;
     // this.governmentInfo.accountability = payload.employee.row.accountability;
     var type = this.governmentInfo.payClass

     if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
      this.disabled = true
    }else{
      this.disabled = false
    }
    
    var myStr = payload.employee.row.accountability;
    var strArray = myStr.split(",");

    for(var i = 0; i < strArray.length; i++){
      this.governmentInfo.accountability.push(strArray[i]);
    }
    this.dialog = payload.stat;
  },

}
}
</script>


