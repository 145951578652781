<template>
  <div class="q-pa-md">
    <div class="q-pa-md">
      <div class="row">
        <div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
          <q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" />
          <q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px" />
<!--          <q-btn color="primary" label="Process Payroll" @click="processPayroll" :disable="hasPendingInTK"/>-->
          <q-btn color="primary" label="Process Payroll" @click="savePayrollHistory" :disable="hasPendingInTK"/>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
<!--          <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Payroll Summary"-->
<!--          :data="generatedData"-->
<!--          :columns="columns"-->
<!--          row-key="id" separator="cell"-->
<!--          no-data-label="I didn't find anything for you!"-->
<!--          :pagination.sync="pagination"-->
<!--          :loading="loading"-->
<!--          :filter="filter"-->
<!--          @request="onRequest"-->
<!--          binary-state-sort>-->

      <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Payroll Summary"
               :data="generatedData"
               :columns="columns"
               row-key="id" separator="cell"
               no-data-label="I didn't find anything for you!"
               :pagination.sync="pagination"
               :loading="loading"
               :filter="filter"
               binary-state-sort>

          <template v-slot:no-data="">
            <div class="full-width row flex-center text-red q-gutter-sm">
              <i class="fas fa-exclamation-triangle"></i>
              <span>The filter didn't uncover any results!</span>
            </div>
          </template>
          <template v-slot:loading>
              <q-inner-loading showing>
                  <q-spinner-hourglass  size="50px" color="cyan" />
              </q-inner-loading>
          </template>

        <template v-slot:top="props">
            <q-input dense v-model="filter" placeholder="Search Employee" class="caps">
                <i class="fas fa-search" slot="append"></i>
            </q-input>
            <q-space />
            <q-btn outline color="primary" label="Download" no-caps @click="exportTable"/>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="spinner" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section>
          <q-spinner-gears color="cyan" size="5.5em" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}
export default {
  data() {
    return {
        filter: '',
        loading: false,
        hasPendingInTK:false,
        pagination: {
            // page: 1,
            rowsPerPage: 15,
            // rowsNumber: 10
        },

        yearfilter:new Date().getFullYear(),
        yearOptions: [],

      separator: 'cell',
      spinner: false,
      month: new Date().getMonth() + 1,
      cutoff: 1,
      headers: [],
      schdules: [],
      searchKey:'',
      cutoffOptions: [{ id: 1, name: 'First Cut-off' }, { id: 2, name: 'Second Cut-off' }],
      monthOptions: [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ],

      columns: [
        { name: 'empID', align: 'center', label: 'EMPLOYEE ID', field: 'empID', sortable: true},
        { name: 'name', align: 'center', label: 'NAME', field: 'name', sortable: true},

        { name: 'REG_HOUR', align: 'center', label: 'REGULAR DAYS', field: 'REG_HOUR', sortable: true},
        { name: 'BASIC_PAY', align: 'center', label: 'BASIC PAY', style:'color:blue',headerStyle:'color:blue', field: 'BASIC_PAY', sortable: true},
        { name: 'DAILY_RATE', align: 'center', label: 'DAILY RATE', style:'color:blue',headerStyle:'color:blue', field: 'DAILY_RATE', sortable: true},
        { name: 'HOURLY_RATE', align: 'center', label: 'HOURLY RATE', style:'color:blue',headerStyle:'color:blue', field: 'HOURLY_RATE', sortable: true},

        { name: 'DEMINIMIS', align: 'center', label: 'DEMINIMIS', style:'color:blue',headerStyle:'color:blue', field: 'DEMINIMIS', sortable: true},
        { name: 'ALLOWANCE', align: 'center', label: 'ALLOWANCE', style:'color:blue',headerStyle:'color:blue', field: 'ALLOWANCE', sortable: true},
        // { name: 'GROSS_PAY', align: 'center', label: 'GROSS PAY', style:'color:blue',headerStyle:'color:blue', field: 'GROSS_PAY', sortable: true},

        { name: 'ADJUSTMENT', align: 'center', label: 'ADJUSTMENT', style:'color:green',headerStyle:'color:green', field: 'ADJUSTMENT', sortable: true},
        { name: 'REG_HOLIDAY', align: 'center', label: 'REG HOLIDAY', style:'color:green',headerStyle:'color:green', field: 'REG_HOLIDAY', sortable: true},
        { name: 'SPH_HOLIDAY', align: 'center', label: 'SPH HOLIDAY', style:'color:green',headerStyle:'color:green', field: 'SPH_HOLIDAY', sortable: true},
        { name: 'NIGHT_DIFF', align: 'center', label: 'NIGHT DIFF', style:'color:green',headerStyle:'color:green', field: 'NIGHT_DIFF', sortable: true},
        { name: 'OVERTIME', align: 'center', label: 'OVERTIME', style:'color:green',headerStyle:'color:green', field: 'OVERTIME', sortable: true},


        { name: 'SSS', align: 'center', label: 'SSS', style:'color:red',headerStyle:'color:red', field: 'SSS', sortable: true, desc: 'SSS' },
        { name: 'PHILHEALTH', align: 'center', label: 'PHILHEALTH', style:'color:red',headerStyle:'color:red', field: 'PHILHEALTH', sortable: true},
        { name: 'PAG_IBIG', align: 'center', label: 'PAG IBIG', style:'color:red',headerStyle:'color:red', field: 'PAG_IBIG', sortable: true},
        { name: 'TAX', align: 'center', label: 'TAX', style:'color:red',headerStyle:'color:red', field: 'TAX', sortable: true},

        { name: 'UNDERTIME', align: 'center', label: 'UNDERTIME', style:'color:red',headerStyle:'color:red', field: 'UNDERTIME', sortable: true},
        { name: 'ABSENT', align: 'center', label: 'ABSENT', style:'color:red',headerStyle:'color:red', field: 'ABSENT', sortable: true},
        { name: 'DEDUCTION', align: 'center', label: 'DEDUCTION', style:'color:red',headerStyle:'color:red', field: 'DEDUCTION', sortable: true},
        { name: 'GROSS_PAY', align: 'center', label: 'GROSS PAY', style:'color:blue',headerStyle:'color:blue', field: 'GROSS_PAY', sortable: true},
        { name: 'TOTAL_DEDUCTION', align: 'center', label: 'TOTAL DEDUCTION', style:'color:red',headerStyle:'color:red', field: 'TOTAL_DEDUCTION', sortable: true},
        { name: 'NET_PAY', align: 'center', label: 'NET PAY', style:'color:green',headerStyle:'color:green', field: 'NET_PAY', sortable: true},
      ],
      generatedData: [],
    }
  },

  created() {
    this.yearsList()
    this.checkIfHasPending()
  },

  mounted(){
      // this.onRequest({
      //     pagination: this.pagination,
      //     filter: ''
      // })
  },

  methods: {

      //start if server side
      onRequest (props) {

        const { page, rowsPerPage } = props.pagination


        const filter = props.filter
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/payroll/generate', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                year: this.yearfilter,
                month: this.month,
                cutoff: this.cutoff,
                start: startRow,
                countPerPage: count,
                filter: filter,
              }
          }).then((response) => {
              this.generatedData = response.data.data
              this.loading = false
              // this.savePayrollHistory();
              this.getFetchData();
          })
     },

      async getRowsNumberCount (filter) {
         let response = await axios.get(this.apiUrl + '/payroll/generate/total', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
               year: this.yearfilter,
               month: this.month,
               cutoff: this.cutoff,
               filter: filter,
             }
         })
         return response.data
      },

      ///end of server side


    processPayroll: function() {
      this.onRequest({pagination:this.pagination,filter:''})
      // this.spinner = true;
      // let formData = new FormData();
      // formData.append("year", this.yearfilter);
      // formData.append("month", this.month);
      // formData.append("cutoff", this.cutoff);
      // axios.create({
      //     baseURL: this.apiUrl,
      //     headers: { 'Authorization': `Bearer ${this.token}` }
      //   }).post(`/payroll/generate`, formData)
      //   .then((res) => {
      //       //console.log(res.data.data)
      //     this.generatedData = res.data.data
      //     this.spinner = false
      //     //this.dataFlag = true
      //   });
    },

    yearsList : function(){
        axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
        }).post(`/timekeeping/years/list`)
        .then((res) => {
            this.yearOptions = res.data.data
        });
    },

    checkIfHasPending : function(){
        axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
        }).get(`/payroll/check/pending`)
        .then((res) => {
            if(res.data > 0){
                this.hasPendingInTK = true
            }else{
                this.hasPendingInTK = false
            }
        });
    },

    exportTable: function() {

      const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
        this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function' ?
          col.field(row) :
          row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'Payroll.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

    //===================================================================================
    //ruben: for payroll history 15/02/2024
    //===================================================================================

    savePayrollHistory(){

      let formData = new FormData();
      formData.append("year", this.yearfilter);
      formData.append("month", this.month);
      formData.append("cutoff", this.cutoff);
      formData.append("data", JSON.stringify(this.generatedData));
      formData.append("user", this.usersFullname);

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      }).post('/payroll-check',formData).then((result) => {
          if(result.data > 0){
            this.$q.dialog({
              title:'Info',
              message: 'Payroll exist do you want to replace the existing data?.',
              cancel: true,
              persistent: true
            }).onOk(() =>{
              this.deleteExist(formData)
            }).onCancel(() => {
              this.getFetchData();
            })
          }else{
            this.onRequest({pagination:this.pagination,filter:''})
          }

      });
    },

    deleteExist(formData){
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      }).post('/payroll-delete',formData).then((result) => {
        if(result.data.status == 200){
          this.onRequest({pagination:this.pagination,filter:''})
        }else{
          console.log('error')
        }
      });
    },

    getFetchData(){
      let formData = new FormData();
      formData.append("year", this.yearfilter);
      formData.append("month", this.month);
      formData.append("cutoff", this.cutoff);
      // formData.append("data", JSON.stringify(this.generatedData));
      // formData.append("user", this.usersFullname);

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      }).post('/payroll-history',formData).then((result) => {
        var rows = result.data.data;
        this.generatedData = [];

        rows.forEach((data,ind) => {
          this.generatedData.push(JSON.parse(data.data))
        })
      });
    }

  }
}
</script>
<style lang="css" scoped>
.center {
  text-align: center
}
</style>
