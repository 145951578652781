<template>
    <div class="q-pa-md">
        <div class="q-pa-md">
            <q-form @submit="showDetails">
    			<div class="row">
    				<div class="row justify q-banner--top-padding q-pa-md">
    					<q-select filled v-model="searchKey" use-input input-debounce="0" label="Search Employee"
    			        :options="employeeList" @filter="filterFn"
    			        style="width: 550px" behavior="dialog" class="caps"
                        :rules="[ val => val && val.length > 0 || 'Please select Employee']"
    			      	>
    			        <template v-slot:no-option>
    			          <q-item>
    			            <q-item-section class="text-grey">
    			              No results
    			            </q-item-section>
    			          </q-item>
    			        </template>
    			      </q-select>
    				</div>
                    <div class="row justify q-banner--top-padding q-pa-md">
                        <q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
    				</div>
                    <div class="row justify q-banner--top-padding q-pa-md">
                        <q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" />
    				</div>
                    <div class="row justify q-banner--top-padding q-pa-md">
                        <q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
    				</div>
                    <div class="row justify q-banner--top-padding q-pa-md">
                         <q-btn outline style="color: red;" label="Show Details" type="submit"/>
    				</div>
    			</div>
            </q-form>
		</div>
        <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
            <q-markup-table separator="cell">
                <thead>
                    <tr>
                        <th class="text-center">VERTICAL DETAILED</th>
                        <th class="text-center" colspan="4" v-for="head in headerDate" :key="head.dateHeader">{{ head.dateHeader }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">SCHEDULE</td>
                        <td class="text-center" v-for="(sched,i) in schedules" :key="i">{{ sched.sched}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">LOGS</td>
                        <td class="text-center" v-for="(log,a) in logs" :key="a">{{ log.logs }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">TARDY (MINS)</td>
                        <td class="text-center" v-for="(tard,b) in tardy" :key="b">{{ tard.tardy }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">UNDERTIME (MINS)</td>
                        <td class="text-center" colspan="4" v-for="(under,c) in undertime" :key="c">{{ under.undertime }}</td>
                    </tr>
                    <!-- <tr>
                        <td class="text-left">OVERTIME</td>
                        <td class="text-center" colspan="2" v-for="(ot,d) in overtime" :key="d">{{ ot.OT }}</td>
                    </tr> -->
                    <tr>
                        <td class="text-left">ABSENT</td>
                        <td class="text-center" colspan="4" v-for="(absent,e) in absents" :key="e">{{ absent.absent }}</td>
                    </tr>

                    <!-- HOLIDAY -->
                    <tr>
                        <td class="text-left" >RD</td>
                        <td class="text-center" colspan="4" v-for="(r_d,j) in rd" :key="j">{{ r_d.RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">SH</td>
                        <td class="text-center" colspan="4" v-for="(sph,i) in specialholiday" :key="i">{{ sph.SPH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">SH-RD</td>
                        <td class="text-center" colspan="4" v-for="(shrd,k) in sh_rd" :key="k">{{ shrd.SH_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">LH</td>
                        <td class="text-center" colspan="4" v-for="(lh,l) in legalholiday" :key="l">{{ lh.LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">LH-RD</td>
                        <td class="text-center" colspan="4" v-for="(lhrd,m) in lh_rd" :key="m">{{ lhrd.LH_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(dbllh,n) in dbl_lh" :key="n">{{ dbllh.DBL_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">DBL-RD</td>
                        <td class="text-center" colspan="4" v-for="(dblrd,o) in dbl_rd" :key="o">{{ dblrd.DBL_RD }}</td>
                    </tr>
                    <!-- NIGHT DIFFERENTIAL -->
                    <tr>
                        <td class="text-left">ND</td>
                        <td class="text-center" colspan="4" v-for="(n_d,p) in nd" :key="p">{{ n_d.ND }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-RD</td>
                        <td class="text-center" colspan="4" v-for="(ndrd,q) in nd_rd" :key="q">{{ ndrd.ND_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-SH</td>
                        <td class="text-center" colspan="4" v-for="(ndsh,r) in nd_sh" :key="r">{{ ndsh.ND_SH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-SH-RD</td>
                        <td class="text-center" colspan="4" v-for="(ndsh_rd,s) in nd_sh_rd" :key="s">{{ ndsh_rd.ND_SH_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-LH</td>
                        <td class="text-center" colspan="4" v-for="(ndlh,t) in nd_lh" :key="t">{{ ndlh.ND_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-LH-RD</td>
                        <td class="text-center" colspan="4" v-for="(ndlh_rd,u) in nd_lh_rd" :key="u">{{ ndlh_rd.ND_LH_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(nddbl_lh,v) in nd_dbl_lh" :key="v">{{ nddbl_lh.ND_DBL_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ND-RD-DBL-RH</td>
                        <td class="text-center" colspan="4" v-for="(ndrd_dbl_rh,w) in nd_rd_dbl_rh" :key="w">{{ ndrd_dbl_rh.ND_RD_DBL_RH }}</td>
                    </tr>
                    <!-- OVERTIME -->
                    <tr>
                        <td class="text-left">OT</td>
                        <td class="text-center" colspan="2" v-for="(ot,d) in overtime" :key="d">{{ ot.OT }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-PAY</td>
                        <td class="text-center" colspan="4" v-for="(otpay,x) in ot_pay" :key="x">{{ otpay.OT_PAY }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-RD</td>
                        <td class="text-center" colspan="4" v-for="(otrd,y) in ot_rd" :key="y">{{ otrd.OT_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-SH</td>
                        <td class="text-center" colspan="4" v-for="(otsh,z) in ot_sh" :key="z">{{ otsh.OT_SH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-RD-SH</td>
                        <td class="text-center" colspan="4" v-for="(otrd_sh,aa) in ot_rd_sh" :key="aa">{{ otrd_sh.OT_RD_SH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-LH</td>
                        <td class="text-center" colspan="4" v-for="(otlh,ab) in ot_lh" :key="ab">{{ otlh.OT_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-RD-LH</td>
                        <td class="text-center" colspan="4" v-for="(otrd_lh,ac) in ot_rd_lh" :key="ac">{{ otrd_lh.OT_RD_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(otdbl_lh,ad) in ot_dbl_lh" :key="ad">{{ otdbl_lh.OT_DBL_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-RD-DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(otrd_dbl_lh,ae) in ot_rd_dbl_lh" :key="ae">{{ otrd_dbl_lh.OT_RD_DBL_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND</td>
                        <td class="text-center" colspan="4" v-for="(otnd,af) in ot_nd" :key="af">{{ otnd.OT_ND }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-RD</td>
                        <td class="text-center" colspan="4" v-for="(otnd_rd,ag) in ot_nd_rd" :key="ag">{{ otnd_rd.OT_ND_RD }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-SH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_sh,ah) in ot_nd_sh" :key="ah">{{ otnd_sh.OT_ND_SH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-RD-SH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_rd_sh,ai) in ot_nd_rd_sh" :key="ai">{{ otnd_rd_sh.OT_ND_RD_SH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-LH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_lh,aj) in ot_nd_lh" :key="aj">{{ otnd_lh.OT_ND_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-RD-LH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_rd_lh,ak) in ot_nd_rd_lh" :key="ak">{{ otnd_rd_lh.OT_ND_RD_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_dbl_lh,al) in ot_nd_dbl_lh" :key="al">{{ otnd_dbl_lh.OT_ND_DBL_LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OT-ND-RD-DBL-LH</td>
                        <td class="text-center" colspan="4" v-for="(otnd_rd_dbl_lh,am) in ot_nd_rd_dbl_lh" :key="am">{{ otnd_rd_dbl_lh.OT_ND_RD_DBL_LH }}</td>
                    </tr>
                    <!-- LEAVES -->
                    <tr>
                        <td class="text-left">VL</td>
                        <td class="text-center" colspan="4" v-for="(vl,g) in vacationleave" :key="g">{{ vl.VL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">SL</td>
                        <td class="text-center" colspan="4" v-for="(sl,f) in sickleave" :key="f">{{ sl.SL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">EL</td>
                        <td class="text-center" colspan="4" v-for="(e_l,an) in el" :key="an">{{ e_l.EL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OBT</td>
                        <td class="text-center" colspan="4" v-for="(obt,h) in businesstrip" :key="h">{{ obt.OBT }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">ML</td>
                        <td class="text-center" colspan="4" v-for="(m_l,ao) in ml" :key="ao">{{ m_l.ML }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">PL</td>
                        <td class="text-center" colspan="4" v-for="(p_l,ap) in pl" :key="ap">{{ p_l.PL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">MCL</td>
                        <td class="text-center" colspan="4" v-for="(m_cl,aq) in mcl" :key="aq">{{ m_cl.MCL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">SPL</td>
                        <td class="text-center" colspan="4" v-for="(s_pl,ar) in spl" :key="ar">{{ s_pl.SPL }}</td>
                    </tr>

                    <!-- <tr>
                        <td class="text-left">SL</td>
                        <td class="text-center" colspan="4" v-for="(sl,f) in sickleave" :key="f">{{ sl.SL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">VL</td>
                        <td class="text-center" colspan="4" v-for="(vl,g) in vacationleave" :key="g">{{ vl.VL }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">OBT</td>
                        <td class="text-center" colspan="4" v-for="(obt,h) in businesstrip" :key="h">{{ obt.OBT }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">LH</td>
                        <td class="text-center" colspan="4" v-for="(lh,i) in legalholiday" :key="i">{{ lh.LH }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">SP</td>
                        <td class="text-center" colspan="4" v-for="(sph,i) in specialholiday" :key="i">{{ sph.SPH }}</td>
                    </tr> -->
                 </tbody>
            </q-markup-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {

	data () {
		return {
            yearfilter:new Date().getFullYear(),
            yearOptions: [],

			searchKey:'',
            employeeOptions:[],
            employeeList: this.employeeOptions,
            month:'',
            monthOptions:[
                { id: 1, name: 'January' },
                { id: 2, name: 'February' },
                { id: 3, name: 'March' },
                { id: 4, name: 'April' },
                { id: 5, name: 'May' },
                { id: 6, name: 'June' },
                { id: 7, name: 'July' },
                { id: 8, name: 'August' },
                { id: 9, name: 'September' },
                { id: 10, name: 'October' },
                { id: 11, name: 'November' },
                { id: 12, name: 'December' },
            ],
            cutoff:'',
            cutoffOptions:[{ id: 1, name: 'First Cut-off' }, { id: 2, name: 'Second Cut-off' }],
            headerDate:[],
            schedules:[],
            logs:[],
            tardy:[],
            undertime:[],
            overtime:[],
            absents:[],
            vacationleave:[],
            sickleave:[],
            businesstrip:[],
            legalholiday:[],
            specialholiday:[],

            rd:[],
            sh_rd:[],
            lh_rd:[],
            dbl_lh:[],
            dbl_rd :[],

            nd:[],
            nd_rd :[],
            nd_sh :[],
            nd_sh_rd :[],
            nd_lh :[],
            nd_lh_rd :[],
            nd_dbl_lh :[],
            nd_rd_dbl_rh :[],

            ot_pay :[],
            ot_rd :[],
            ot_sh :[],
            ot_rd_sh :[],
            ot_lh :[],
            ot_rd_lh :[],
            ot_dbl_lh :[],
            ot_rd_dbl_lh:[],
            ot_nd:[],
            ot_nd_rd:[],
            ot_nd_sh:[],
            ot_nd_rd_sh:[],
            ot_nd_lh:[],
            ot_nd_rd_lh:[],
            ot_nd_dbl_lh:[],
            ot_nd_rd_dbl_lh:[],
            el :[],
            ml :[],
            pl :[],
            mcl :[],
            spl :[],

		}
	},

	created(){
		this.getListOfEmployees()
        this.yearsList()
	},

	methods: {

		filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  	},

		getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})

		},

        showDetails:function(){
            // this.$q.loading.show({
            //     spinnerColor: 'yellow',
            //     spinnerSize: 140,
            //     backgroundColor: 'purple',
            //     message: 'Generating in process...',
            //     messageColor: 'black'
            // })
            var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
            let formData = new FormData();
            formData.append("empID", employeeID);
            formData.append("year", this.yearfilter);
            formData.append("month", this.month);
            formData.append("cutoff", this.cutoff);
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
              }).post(`/backend/vertical/detailed`, formData)
              .then((res) => {
                    this.headerDate = res.data.data.header
                    this.schedules = res.data.data.schedule
                    this.logs = res.data.data.logs
                    this.tardy = res.data.data.tardy
                    this.undertime = res.data.data.undertime
                    this.overtime = res.data.data.overtime

                    this.absents = res.data.data.absent
                    this.vacationleave = res.data.data.vacationleave
                    this.sickleave = res.data.data.sickleave
                    this.businesstrip = res.data.data.businesstrip
                    this.legalholiday = res.data.data.legalholiday
                    this.specialholiday = res.data.data.specialholiday
                    //console.log(this.schedules)

                    this.rd  = res.data.data.rd;
                    this.sh_rd = res.data.data.sh_rd;
                    this.lh_rd = res.data.data.lh_rd;
                    this.dbl_lh = res.data.data.dbl_lh;
                    this.dbl_rd  = res.data.data.dbl_rd;

                    this.nd = res.data.data.nd;
                    this.nd_rd  = res.data.data.nd_rd;
                    this.nd_sh  = res.data.data.nd_sh;
                    this.nd_sh_rd  = res.data.data.nd_sh_rd;
                    this.nd_lh  = res.data.data.nd_lh;
                    this.nd_lh_rd  = res.data.data.nd_lh_rd;
                    this.nd_dbl_lh  = res.data.data.nd_dbl_lh;
                    this.nd_rd_dbl_rh  = res.data.data.nd_rd_dbl_rh;

                    this.ot_pay = res.data.data.ot_pay;
                    this.ot_rd  = res.data.data.ot_rd;
                    this.ot_sh  = res.data.data.ot_sh;
                    this.ot_rd_sh  = res.data.data.ot_rd_sh;
                    this.ot_lh  = res.data.data.ot_lh;
                    this.ot_rd_lh  = res.data.data.ot_rd_lh;
                    this.ot_dbl_lh  = res.data.data.ot_dbl_lh;
                    this.ot_rd_dbl_lh = res.data.data.ot_rd_dbl_lh;
                    this.ot_nd = res.data.data.ot_nd;
                    this.ot_nd_rd = res.data.data.ot_nd_rd;
                    this.ot_nd_sh = res.data.data.ot_nd_sh;
                    this.ot_nd_rd_sh = res.data.data.ot_nd_rd_sh;
                    this.ot_nd_lh = res.data.data.ot_nd_lh;
                    this.ot_nd_rd_lh = res.data.data.ot_nd_rd_lh;
                    this.ot_nd_dbl_lh = res.data.data.ot_nd_dbl_lh;
                    this.ot_nd_rd_dbl_lh = res.data.data.ot_nd_rd_dbl_lh;
                    this.el  = res.data.data.el;
                    this.ml  = res.data.data.ml;
                    this.pl  = res.data.data.pl;
                    this.mcl  = res.data.data.mcl;
                    this.spl  = res.data.data.spl;

                    // this.timer = setTimeout(() => {
                    //     this.$q.loading.hide()
                    //     this.timer = void 0
                    // }, 3000)
              });
        },

        yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

        beforeDestroy () {
            if (this.timer !== void 0) {
              clearTimeout(this.timer)
              this.$q.loading.hide()
            }
        }
	}
}
</script>
