<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Employee Logs
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
            <!-- <div class="row upper-row">
                <div class="col-4" style="display:inline-flex">
                    <div class="h6"></div>
                </div>
                <div class="col-8"></div>
            </div> -->
            <div class="row upper-row">
                <div class="col-5" style="display:inline-flex">
                    <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                    <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                    <q-btn class="q-mb-lg" label="Search" color="primary" @click="searchDate"/>
                </div>
                <div class="col-5"></div>
                <div class="col-2">
                    <q-input dense debounce="400" color="primary" v-model="searchKey" placeholder="Search..." outlined>
                        <template v-slot:append>
                            <q-icon name="fa fa-search" />
                        </template>
                    </q-input>
                </div>
            </div>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div class="row">
                <div class="col-12">
                    <q-table :data="searchVerifier" :columns="employeecolumn" :pagination="pagination" row-key="name" dense>
                    </q-table>
                </div>
                </div>
            </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'NewEmployeeLogs',
    data() {
        return {
            filter: '',
            employeecolumn: [
                { name: 'employee_id', field: 'employee_id', label: 'Employee ID' },
                { name: 'firstname', field: 'firstname', label: 'First Name' },
                { name: 'middlename', field: 'middlename', label: 'Middle Name' },
                { name: 'lastname', field: 'lastname', label: 'Last Name' },
                { name: 'action_taken', field: 'action_taken', label: 'Action Taken' },
                { name: 'effectivity', field: 'effectivity', label: 'Effectivity' },
                { name: 'date_created', field: 'date_created', label: 'Date Created' },
            ],
            searchKey: null,
            hidePagination: true,
            pagination: {
                sortBy: 'date_created',
                descending: true,
                page: 1,
                rowsPerPage: 15
            },
            rows: [],
            dateTo: null,
            dateFrom: null,
            searchDateToFrom: {
                dateTo: null,
                dateFrom: null,
            }
        }
    },
    created() {
        this.getEmployeeLogs()
    },
    computed: {
        searchVerifier() {
            if (this.searchKey !== null) {
                return this.rows.filter(row => {
                    return row.firstname.toLowerCase().match(this.searchKey) || row.firstname.toUpperCase().match(this.searchKey) ||
                    row.lastname.toLowerCase().match(this.searchKey) || row.lastname.toUpperCase().match(this.searchKey)
                });
            } else {
                return this.rows;
            }
        }
    },
    methods: {
        getEmployeeLogs () {
            axios.create({
                baseURL: this.apiUrl,
                headers: {
                'Authorization': `Bearer ${this.token}`
                }
            })
            .get(`/admin/get-employeelogs`)
            .then((res) => {
                this.rows = res.data.data;
            })
        },
        searchDate(){
            this.loading = true;
            let formData = new FormData();
            this.searchDateToFrom.dateFrom = this.dateFrom;
            this.searchDateToFrom.dateTo = this.dateTo;

            formData.append('form_data', JSON.stringify(this.searchDateToFrom));
            axios.create({
                baseURL: this.apiUrl,
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
                }).post('/admin/get-employeelogs-datecreated', formData).then((res) => {

                this.rows = res.data.data;
            });
        }
    }
}
</script>

<style>

</style>
