<template>
   <q-dialog v-model="confirm" persistent>
      <q-card>
         <q-form @submit="confirmEditingTrainee">
           <q-card-section class="row items-center">
             <span class="q-ml-sm text-subtitle1">Do you want to continue this action?</span>
           </q-card-section>

           <q-card-section class="q-pt-none">
             <q-input v-model="traineeid" label="Trainee ID" :rules="[formRules.required]"/>
             <q-input v-model="fname" label="Firstname" :rules="[formRules.required]"/>
             <q-input v-model="lname" label="Lastname" :rules="[formRules.required]"/>
             <q-input v-model="department" label="Department" :rules="[formRules.required]"/>
             <q-input v-model="max_credit" label="Max Credit" :rules="[formRules.required]"/>
           </q-card-section>

           <q-card-actions align="right">
             <q-btn flat label="Cancel" color="primary" v-close-popup />
             <q-btn flat label="Confirm" color="red" type="submit"/>
           </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      confirm:false,
      id:'',
      traineeid:'',
      fname:'',
      lname:'',
      department:'',
      max_credit:''
    }
  },

  created(){
    this.eventHub.$on('editEmployee', this.editEmployee);
  },

  methods: {
   editEmployee:function(obj){
      this.id = obj.obj.id
      this.traineeid = obj.obj.tid
      this.fname = obj.obj.fname
      this.lname = obj.obj.lname
      this.department = obj.obj.department
      this.max_credit = obj.obj.max_credit
      this.confirm = obj.status
   },

   confirmEditingTrainee:function(){
      var formData = new FormData()
      formData.append('id',this.id);
      formData.append('traineeid',this.traineeid);
      formData.append('fname',this.fname);
      formData.append('lname',this.lname);
      formData.append('department',this.department);
      formData.append('max_credit',this.max_credit);

      axios.create({
       baseURL : this.apiUrl,
       headers : {'Authorization' : `Bearer ${this.token}`}
     }).post(`/mobile/pantry/trainee/update`,formData)
     .then((response) => {
       this.confirm = false
       this.eventHub.$emit('refreshList');
     });
   }
}
}
</script>
<style lang="css" scoped>
</style>
