<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="alert" persistent>
      <q-card>
         <q-card-section class="row items-center">
          <div class="text-h6 text-orange">Please confirm your password!</div>
          <q-space />
          <q-btn icon="fas fa-times" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section class="q-pt-none">
           <q-form ref="vForm" @submit="verifyPassword" class="col-gutter-y-sm">
             <q-input filled v-model="password" type="password" label="Enter your payslip password" lazy-rules :rules="[formRules.required]" >
               <template v-slot:prepend>
                 <q-icon name="fa fa-key" />
               </template>
             </q-input>
             <q-btn  type="submit" color="red" text-color="white" style="height: 50px; width: 100%; margin-top: 20px;" label="Verify" />
           </q-form>
        </q-card-section>
      </q-card>
      <q-dialog v-model="spinner" persistent>
        <q-card class="my-card transparent no-shadow">
          <q-card-section>
            <q-spinner-gears color="cyan" size="5.5em" />
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data () {
    return {
      alert:false,
      password:'',
      spinner:false,
      payslipID:'',
      employeeID:'',
      type:'',
      thirteenthMonth:{},
    }
  },

  mounted(){
    this.eventHub.$on('validatePassword', this.confirmPassword);
  },

  methods:{
   verifyPassword: function(){
      this.spinner = true
     axios.create({
         baseURL: this.apiUrl,
         headers: {
           'Authorization': `Bearer ${this.token}`
         }
      }).get(`/payslip/password/validate/${this.userData.employee_id}/${this.password}`)
      .then((res) => {
         if(res.data.status){
            this.alert = false
            this.spinner = false
            if(this.type == 'payslip'){
               this.eventHub.$emit('showPayslip', { stat: true, payslipID: this.payslipID, employeeID: this.employeeID });
            }else{
              this.eventHub.$emit('showthirteenthMonth', { stat: true, thirteenthMonth: this.thirteenthMonth});
           }
         }else{
            this.spinner = false
            this.$q.notify({
              color: 'red-6',
              textColor: 'white',
              icon: 'fa fa-time-circle',
              message: res.data.message
            });
         }
      })


   },

   confirmPassword(payload) {
      this.alert = payload.stat
      this.type = payload.type
      this.employeeID = this.userData.employee_id
      if(payload.type == 'payslip'){
         this.payslipID = payload.payslip.id
      }else{
         this.thirteenthMonth = payload.thirteenthMonth
      }
   },


   }
}
</script>
