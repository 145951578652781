<template>
   <q-dialog v-model="confirm" persistent>
      <q-card>
         <q-form @submit="confirmHoldingTrainee">
           <q-card-section class="row items-center">
             <span class="q-ml-sm text-subtitle1">Do you want to continue this action?</span>
           </q-card-section>

           <q-card-section class="q-pt-none">
             <q-input v-model="reason" label="Write your reason" :rules="[formRules.required]"/>
           </q-card-section>

           <q-card-actions align="right">
             <q-btn flat label="Cancel" color="primary" v-close-popup />
             <q-btn flat label="Confirm" color="red" type="submit"/>
           </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      confirm:false,
      id:'',
      isHold:'',
      reason:'',
    }
  },

  created(){
    this.eventHub.$on('holdEmployee', this.holdEmployee);
  },

  methods: {
   holdEmployee:function(obj){
      this.id = obj.id
      this.isHold = obj.isHold
      this.confirm = obj.status
   },

   confirmHoldingTrainee:function(){
      var formData = new FormData()
      formData.append('id',this.id);
      formData.append('isHold',this.isHold);
      formData.append('reason',this.reason);

      axios.create({
       baseURL : this.apiUrl,
       headers : {'Authorization' : `Bearer ${this.token}`}
     }).post(`/mobile/pantry/trainee/hold`,formData)
     .then((response) => {
       this.confirm = false
       this.eventHub.$emit('refreshList');
     });
   }
}
}
</script>
<style lang="css" scoped>
</style>
