<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Endorsed For Training
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
        <div class="row q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <!-- <q-btn outline label="For Exam" @click="multipleForExam" color="primary"/> -->
          <q-btn outline color="accent" @click="multipleReprofile" label="Reprofile" />
          <q-btn outline color="red" @click="multipleDump"  label="Dump" />
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
              <!-- :visible-columns="visibleColumns" 01182023 -->
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter"
                row-key="id" dense :visible-columns="visibleColumns"
                selection="multiple" :selected.sync="forTrainingSelected"
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
              <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                  <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-select dense v-model="site" :options="sites" outlined class="q-mr-sm"  />
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>
                      <q-btn
                        class="q-mb-lg"
                        color="primary"
                        icon-right="fas fa-download"
                        label="Export to csv"
                        no-caps
                        @click="exportModal = true"
                      />
                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                    <q-td>
                      <q-checkbox dense v-model="props.selected" />
                    </q-td>
                    <q-td key="action" :props="props" v-if="props.row.inactive == 'N'">
                      <q-btn color="accent" @click="forReprofile(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Reprofile" />
                      <q-btn color="green" @click="hired(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Passed" />
                      <q-btn color="red" @click="forDumping(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Dump" />
                    </q-td>
                    <q-td key="action" v-else style="text-align: center;">
                      <q-btn key="status" v-if="props.row.inactive == 'Y'" color="red" disable rounded size="sm" text-color="white" label="Inactive" />
                    </q-td>
                  <!-- 01182023 for sorting purposes-->
                    <q-td key="a_id" :props="props">{{ props.row.id }}</q-td>
                  <!-- END:: 01182023 -->
                    <q-td key="site" @click="viewDetails('For Training','',props.row.id)" :props="props">{{ props.row.site }}</q-td>
                    <q-td key="applicant_id" @click="viewDetails('For Training','',props.row.id)" :props="props">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.account }}</q-td>
                    <q-td key="date_of_application" @click="viewDetails('For Training','',props.row.id)" :props="props">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" @click="viewDetails('For Training','',props.row.id)" :props="props">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" @click="viewDetails('For Training','',props.row.id)" :props="props">{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="reprofile_count" :props="props" >{{ props.row.reprofile_count }}</q-td> <!-- 01182023 -->
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="ForHiredDialog" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Passing</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Trainee ID</div>
            <div class="col-6 q-pl-sm">Batch</div>
          </div>
          <div class="row q-mb-sm">
            <div class="col-6 q-pr-sm">
              <q-input v-model="passing.trainee_id" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input v-model="passing.batch" outlined color="indigo-6"/>
            </div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">Account</div>
            <div class="col-6 q-pl-sm">Training Status</div>
          </div>
          <div class="row q-mb-sm">
            <div class="col-6 q-pr-sm">
              <q-input v-model="passing.account" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input v-model="passing.es_trainee" outlined color="indigo-6"/>
            </div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">Training Start Date</div>
            <div class="col-6 q-pl-sm">Expected PB Date</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-input type="date" v-model="passing.date_hired" v-on:change="update_expectedPBDate()" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input type="date" v-model="passing.expected_pbdate" readonly outlined color="indigo-6"/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <q-btn flat label="Passed" color="indigo-7" @click="hiredApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="ForInactiveDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Are you sure you want to <span class="text-bold text-red" >INACTIVE</span> this applicant
            <span class="text-bold text-indigo-7" > {{ this.form_inactive.track_no }} </span>?
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <q-btn flat label="Inactive" color="indigo-7" @click="inactiveApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewDetailsDialog" transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
        <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
          <div class="text-h5">{{ vAD.name }}</div>
          <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md">
              <div class="text-bold text-head">Personal Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Present Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.presentAddress}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Permanent Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.permanentAddress}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Email Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.email}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Mobile Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.mobileNumber}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Telephone Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.telNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Gender" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.gender}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Birthday" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.birthday}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Civil Status" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.civilStatus}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Religion" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.religion}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Application Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Know Company Through" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.knowCompanyThru}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Position Applying For" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.positionApplyingFor}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Work Eperiences</div>
              <div class="row q-col-gutter-md">
                <div class="col">
                  <q-field label="With Call Center Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.callCenterExp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Account Handled" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpAccount}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-md">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="With Other Work Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.otherWorkExperience}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Nature of Work" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpBusNature}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Educational Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-2">
                  <q-field label="Highest Educational Attainment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.educAttainment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1">
                  <q-field label="Year Attended" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearAttended}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-5">
                  <q-field label="School / University / College" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.suc}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3" v-if="vAD.educAttainment != 'High School Graduate'">
                  <q-field label="Course" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.course}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1" v-if="vAD.educAttainment == 'College Level'">
                  <q-field label="Year Level" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearLevel}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md" v-if="vAD.natureOfIllness1 != 'N/A'">
              <div class="text-bold text-head">Medical / Health Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness1}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration1}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness2 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness2}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration2}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness3 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness3}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration3}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Emergency Contact</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Contact Person" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactPerson}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Relationship" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactRelation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Assessment</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Assessed By" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessedBy}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Date of Assessment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessmentDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-2">
                  <q-field label="Last Process" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.lastProcess}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Passed" @click="actionForm('Passed')" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Failed" @click="actionForm('Failed')" class="full-width bg-red-6 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <div class="bg-white" style="height: auto; width: 500px; max-width: 500px;">
        <q-card>
          <q-card-section class="row q-pa-sm bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-exclamation-circle" />
            <span class="q-pl-sm">Application Assessment : [ {{ form.assessorAction }} ]</span>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm" v-if="form.assessorAction == 'Failed'">
            <div class="q-gutter-sm">
              <q-radio v-model="form.failedAssessment" val="Failed" label="Failed" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Reconsideration" label="For Reconsideration" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Pooling" label="For Pooling" color="amber-8" />
            </div>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm">
            <span class="small-text">Assessment:</span>
            <q-input v-model="form.assessorRemarks" dense color="amber-8">
              <template v-slot:append>
                <q-icon name="fa fa-times" @click="form.assessorRemarks = null" class="cursor-pointer" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn flat label="Cancel" @click="form.assessorRemarks = null,form.failedAssessment = null" color="amber-8" v-close-popup />
            <q-btn flat label="Confirm" @click="processAssessment" class="bg-amber-8 text-white" />
          </q-card-actions>
        </q-card>
      </div>
    </q-dialog>
    <q-dialog v-model="dumpModal" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Dump

          </span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-12 q-px-sm">Reason of Dumping
              <span v-for="(applicantid, i) in this.dump.dumpApplicantID" v-bind:key="i" class="text-bold text-indigo-7" >
                {{ applicantid }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 q-px-sm q-pb-sm">
              <q-select v-model="dump.dumpReason" :options="['Failed', 'Fall Out', 'No Show']" outlined color="indigo-6"/>
            </div>
              <div class="col-12 q-px-sm" v-if="isMultipleDump == false">Dump Remarks</div>
            <div class="col-12 q-px-sm">
              <q-input v-model="dump.dumpRemarks" v-if="isMultipleDump == false" outlined color="indigo-6"/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Dump" class="bg-indigo-6 text-white" @click="dumpApplication" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="reprofileModal" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-question-circle" />
          <span class="q-pl-sm">For Reprofile</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Are you sure you want to reprofile this applicant/s
            <span v-for="(applicantid, i) in this.reprofile.reprofileApplicantID" :key="i" class="text-bold text-indigo-7" >
              {{ applicantid }}
            </span> ?
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="No" color="negative" v-close-popup />
          <q-btn flat label="Yes" color="primary"  @click="reprofileApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="exportModal" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Export</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Batch</div>
            <div class="col-6 q-pl-sm">Account</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-select :options="exporting_batch" v-model="exporting.batch" outlined color="indigo-6" required/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-select :options="exporting_account" v-model="exporting.account" outlined color="indigo-6" required/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Export" class="bg-indigo-6 text-white" @click="exportTable" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="noData">
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">NO DATA</span>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-pa-md">
            <div class="text-h6">There is no data!</div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="resetNoData" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="notSelectedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="fas fa-exclamation" color="red" text-color="white" />
          <span class="q-ml-sm">There is no selected applicants!
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Okay" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}
export default {
  name: 'ForOrientationNA',
  data() {
    return {
      sites: ['ALL','ORTIGAS', 'MARILAO'],
      site:'ALL',
      filter: '',
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewDetailsDialog: false,
      actionDialog: false,
      applications: '',
      applicationsData: [],
      Pagination: { // 01182023
        sortBy: 'a_id',
        descending: 'ascending',
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'a_id',
          field: 'a_id',
          label: 'ID',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'site',
          field: 'site',
          label: 'Site',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'reprofile_count',
          field: 'reprofile_count',
          label: 'Reprofile Count',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
      ],
      applicationCounts: 0,
      vAD: {
        id: null,
        trackingID: null,
        dateApplied: null,
        name: null,
        presentAddress: null,
        permanentAddress: null,
        email: null,
        mobileNumber: null,
        telNumber: null,
        gender: null,
        birthday: null,
        civilStatus: null,
        religion: null,
        knowCompanyThru: null,
        positionApplyingFor: null,
        callCenterExp: null,
        ccExpDuration: null,
        ccExpCompany: null,
        ccExpPosition: null,
        ccExpAccount: null,
        ccExpReasonLeave: null,
        otherWorkExperience: null,
        owExpDuration: null,
        owExpCompany: null,
        owExpPosition: null,
        owExpBusNature: null,
        owExpReasonLeave: null,
        educAttainment: null,
        yearAttended: null,
        suc: null,
        course: null,
        yearLevel: null,
        natureOfIllness1: null,
        illnessDuration1: null,
        natureOfIllness2: null,
        illnessDuration2: null,
        natureOfIllness3: null,
        illnessDuration3: null,
        contactPerson: null,
        contactRelation: null,
        contactNumber: null,
        assessment: null,
        assessedBy: null,
        assessmentDate: null,
        lastProcess: null,
        inactive: null
      },
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      approveNotifType: '',
      ForHiredDialog: false,
      form_hired: {
        id: '',
        track_no: ''
      },
      ForInactiveDialog: false,
      form_inactive: {
        id: '',
        track_no: ''
      },
      dumpModal: false,
      dump: {
        dumpReason: '',
        dumpRemarks: '',
        dumpApplicantID: [],
        dumpApplicationID: []
      },
      isMultipleDump: false,
      reprofileModal: false,
      reprofile: {
        reprofileApplicantID: [],
        reprofileApplicationID: []
      },
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'For Training',
          site:'ALL'
      },
      // 06132022
      passing: {
        id: '',
        tracking_no: '',
        trainee_id: '',
        es_trainee: '',
        batch: '',
        account: '',
        date_hired: '',
        expected_pbdate: ''
      },
      exportModal: false,
      exporting: {
        account: '',
        batch: ''
      },
      exporting_account: [],
      exporting_batch: [],
      noData: false,
      forTrainingSelected: [],
      notSelectedDialog: false,
      timer: "",
      visibleColumns: ['action','site', 'applicant_id', 'firstname', 'middlename' , 'lastname', 'mobile','email',
      'presaddress', 'prescity','civil_status','gender','date_of_birth','edattain','course','estrainee','account',
      'date_of_application','position_applying_for','with_call_center_experience','reprofile_count'],
    }
  },
  methods: {
    searchData: function(){
            vueCookies.set(this.searchKey, {
                'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },
    loadApplications: function() {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Applications';
      this.applications = [];
      this.applicationsData = [];

      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }
      this.filter = this.searchKeyword;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-applicant-listv2', {
          params: {
            status: 'For Training',
            passfail: 'Pass',
            code:'for_training'
          }
        })
        .then((res) => {
          console.log(res)


          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;

          for (let eachApplication of this.applications) {
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site,
            });
          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    viewDetails: function(applicationStatus, status, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-details-list', {
          params: {
            id: rowID,
            status: applicationStatus,
            dump: status
          }
        })
        .then((res) => {
          this.loadingModel = false;
          var viewApplicantDetails = res.data.data[0];

          // console.log(viewApplicantDetails);

          var temp_date_applied, temp_birthdate;

          this.vAD.id = viewApplicantDetails.id;

          this.vAD.trackingID = viewApplicantDetails.tracking_number;
          this.vAD.dateApplied = date.formatDate(viewApplicantDetails.date_apply, 'MMMM DD, YYYY');

          this.vAD.name = this.toTitleCase(viewApplicantDetails.firstname) + ' ' + this.toTitleCase(viewApplicantDetails.middlename) + ' ' +
            this.toTitleCase(viewApplicantDetails.lastname);

          this.vAD.presentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.permanentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.email = viewApplicantDetails.email.toLowerCase();

          this.vAD.mobileNumber = viewApplicantDetails.mobile;

          this.vAD.telNumber = viewApplicantDetails.telephone;

          this.vAD.gender = this.toTitleCase(viewApplicantDetails.gender);

          this.vAD.birthday = date.formatDate(viewApplicantDetails.date_of_birth, 'MMMM DD, YYYY');

          this.vAD.civilStatus = this.toTitleCase(viewApplicantDetails.civil_status);

          this.vAD.religion = this.toTitleCase(viewApplicantDetails.religion);

          if (viewApplicantDetails.through == "job posting" || viewApplicantDetails.through == "Referral" || viewApplicantDetails.through == 'OTHERS') {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.thru);
          } else {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through);
          }

          this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_sought);
          if (viewApplicantDetails.ccexperience == 'with call center experience') {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
            this.vAD.ccExpDuration = this.toTitleCase(viewApplicantDetails.ccyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.ccmonthexp);
            this.vAD.ccExpCompany = this.toTitleCase(viewApplicantDetails.cc_compname);
            this.vAD.ccExpPosition = this.toTitleCase(viewApplicantDetails.position);
            //this.vAD.ccExpAccount = this.toTitleCase(viewApplicantDetails.cc_exp_account_handled);
            this.vAD.ccExpReasonLeave = this.toTitleCase(viewApplicantDetails.reason_for_leaving);
          } else {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
          }

          if (viewApplicantDetails.othexp == 'YES') {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
            this.vAD.owExpDuration = this.toTitleCase(viewApplicantDetails.othyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.othmonthexp);
          } else {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
          }

          var edattn = '';
          if(viewApplicantDetails.edattain == "ed0"){
            edattn = "High School Graduate";
          } else if(viewApplicantDetails.edattain == "ed1"){
            edattn = "College Level";
          } else if(viewApplicantDetails.edattain == "ed2"){
            edattn = "Degree Holder";
          } else if(viewApplicantDetails.edattain == "ed3"){
            edattn = "Vocational Graduate";
          }

          this.vAD.educAttainment = this.toTitleCase(edattn);

          this.vAD.yearAttended = viewApplicantDetails.school_year;
          this.vAD.natureOfIllness1 = viewApplicantDetails.illness;
          this.viewDetailsDialog = true;
        })
    },

    searchDate(){
      this.loading = true;
      let formData = new FormData();
      this.searchDateToFrom.dateFrom = this.dateFrom;
      this.searchDateToFrom.dateTo = this.dateTo;
      this.searchDateToFrom.site = this.site;
      formData.append('form_data', JSON.stringify(this.searchDateToFrom));
      axios.create({
          baseURL: this.apiUrl,
          headers: {
              'Authorization': `Bearer ${this.token}`
          }
          }).post('/recruitments/application/search-date', formData).then((res) => {
          // console.log(res)
          this.applicationsData = [];
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          for (let eachApplication of this.applications) {
            // console.log(eachApplication)
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: eachApplication.edattain,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site,
            });

          }
      });
    },

    multipleReprofile(){
      console.log("checkBox reprofile:", this.forTrainingSelected)
      if(this.forTrainingSelected.length > 0){
        this.reprofile.reprofileApplicantID = []; //HRD
        this.reprofile.reprofileApplicationID = [];  // NUMBER
        for(var i = 0; i < this.forTrainingSelected.length;i++){
          this.reprofile.reprofileApplicationID.push(this.forTrainingSelected[i].id);
          this.reprofile.reprofileApplicantID.push(this.forTrainingSelected[i].tracking_number);
        }
        this.reprofileModal = true;
        console.log("training form: ", this.reprofile)
      }else{
        this.notSelectedDialog = true;
      }
    },

    multipleDump(){
      this.isMultipleDump = true;
      console.log("checkBox dump:", this.forTrainingSelected)
      if(this.forTrainingSelected.length > 0){
        this.dump.dumpApplicantID = []; //HRD
        this.dump.dumpApplicationID = [];  // NUMBER
        for(var i = 0; i < this.forTrainingSelected.length;i++){
          this.dump.dumpApplicationID.push(this.forTrainingSelected[i].id);
          this.dump.dumpApplicantID.push(this.forTrainingSelected[i].tracking_number);
        }
        this.dumpModal = true;
        console.log("training form: ", this.dump)
      }else{
        this.notSelectedDialog = true;
      }
    },

    dumpApplication: function(){
      // console.log("DUMP APPLICATION");
      this.loading = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.dump));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/dump-application', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.dump.dumpRemarks = "";
          this.dump.dumpReason = "";
          this.dump.dumpApplicantID = []; //HRD
          this.dump.dumpApplicationID = [];  // NUMBER
          this.forTrainingSelected = []; // this 06302022
          this.loadApplications();
          this.loading = false;
        }, 1000);
      });
    },

    reprofileApplication: function(){
      console.log(this.reprofile)
      this.loading = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.reprofile));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/reprofile-application', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.reprofile.reprofileApplicantID = [];
          this.reprofile.reprofileApplicationID = [];
          this.forTrainingSelected = [];
          this.reprofileModal = false;
          this.loadApplications();
          this.loading = false;
        }, 1000);
      });
    },

    forReprofile: function(rowID, applicationID){
      // console.log("rowID: ", rowID);
      // console.log("applicationID: ", applicationID);

      this.reprofile.reprofileApplicantID = [];
      this.reprofile.reprofileApplicationID = [];

      this.reprofile.reprofileApplicantID.push(applicationID);
      this.reprofile.reprofileApplicationID.push(rowID);
      this.reprofileModal = true;
    },

    forDumping: function(rowID, applicationID){
      // console.log("rowID: ", rowID);
      // console.log("applicationID: ", applicationID);
      this.isMultipleDump = false;

      this.dump.dumpApplicantID = []; //HRD
      this.dump.dumpApplicationID = [];  // NUMBER

      this.dump.dumpApplicantID.push(applicationID);
      this.dump.dumpApplicationID.push(rowID);

      this.dumpModal = true;
    },

    hired: function(rowID, applicantID){
      this.passing.id = rowID;
      this.passing.tracking_no = applicantID;
      this.ForHiredDialog = true;
    },

    hiredApplication: function(){
      this.loading = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.passing));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-for-hired', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.passing.id = "";
          this.passing.tracking_no = "";
          this.passing.trainee_id = "";
          this.passing.es_trainee = "";
          this.passing.batch = "";
          this.passing.account = "";
          this.passing.date_hired = "";
          this.passing.expected_pbdate = "";

          this.forTrainingSelected = [];
          this.loadApplications();
          this.loading = false;
          // this.uploadNewHiredApplication();
        }, 1000);
      });
    },

    inactive: function(rowID, applicantID){
      this.form_inactive.id = rowID;
      this.form_inactive.track_no = applicantID;
      this.ForInactiveDialog = true;
    },

    inactiveApplication: function(){
      this.loading = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_inactive));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-for-inactive', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.forTrainingSelected = [];
          this.loadApplications();
          this.loading = false;
          // this.uploadNewHiredApplication();
        }, 1000);
      });
    },

    update_expectedPBDate: function(){
      var dateHired = new Date(this.passing.date_hired);
      dateHired.setMonth(dateHired.getMonth() + 1);
      var updateDate = this.updateDateFormat(dateHired);
      this.passing.expected_pbdate = updateDate;
    },

    updateDateFormat(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
  },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/assessment', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.viewDetailsDialog = false;
            this.applicationsData = [];
            this.loadApplications();
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Assessment Done'
            });
            this.form.assessorAction = null;
            this.form.assessorRemarks = null;
            this.form.failedAssessment = null;
            this.form.appID = null;
          }, 1000);
        });
      }
    },

    getAccount(){
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }})
      .get('/employee/get-account')
      .then((res) => {
        // console.log(res)
        this.exporting_account = res.data.data;
      })
    },

    getBatch(){
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }})
      .get('/employee/get-batch')
      .then((res) => {
        // console.log(res)
        this.exporting_batch = res.data.data;
      })
    },

    exportTable () {
      // naive encoding to csv format
      // // console.log(this.ResultColumns);
      // // console.log(this.applicationsData);
      let newApplicationData = [];
      let account = this.exporting.account;
      let batch = this.exporting.batch;

      for(let i = 0; i < this.applicationsData.length; i++){
        if(account == 'ALL' && batch == 'ALL'){
          newApplicationData = this.applicationsData;
          break;
        }else if(account != '' && batch != ''){
          if(this.applicationsData[i].account == account && this.applicationsData[i].batch == batch){
            newApplicationData.push(this.applicationsData[i]);
            continue;
          }
        }
      }

      if(newApplicationData.length != 0){
        //// console.log("EXPORT!")
        this.exportingTable(newApplicationData);
      }else{
        this.noData = true;
      }
      // // console.log(account);
      // // console.log(batch);
      //// console.log(newApplicationData);
    },

    exportingTable(data){
      const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
        data.map(row => this.ResultColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'ForTraining.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }

      this.exporting.account = '';
      this.exporting.batch = '';
    },

    resetNoData(){
      this.exporting.account = '';
      this.exporting.batch = '';
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  mounted() {
    this.loadApplications();
    this.getAccount();
    this.getBatch();
    this.timer = setInterval(this.loadApplications, 300000);
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
