<template>
	<q-dialog v-model="dialog" full-width persistent transition-hide="fade-out" >
		<q-card >
			<q-form ref="vForm" @submit="updateSchedule">
				<q-card-section>
					<div class="text-h5"><strong>Update Schedule</strong></div>
					<div class="text-h5">
						<strong style="color:red">Note: 24 hour format is required (Ex. 08:00-18:00)</strong>
					</div>
				</q-card-section>

				<q-card-section class="q-pt-none">
					<table class="editSchdule">
						<thead>
							<tr>
								<th v-for="(head) in rangeHeader" :key="head.name" class="text-center" style="height:50px;font-size:30px;">{{ head.label }}</th>
							</tr>
						</thead>
						<tbody>
							<tr class="text-center caps">
								<td style="height:50px;font-size:30px;">{{ empID }}</td>
								<td class="text-center" style="height:50px;font-size:30px;">{{ name }} </td>
								<td v-for="(sch,i) in schedules" :key="i">
									 <input type="text" v-model="schedules[i].editsched" size="10" placeholder="00:00-00:00" required class="text-center caps" style="height:50px;font-size:30px;">
								</td>
							</tr>
						</tbody>
					</table>
				</q-card-section>
				<q-card-actions align="right">
					<div>
						<q-btn flat label="Save" type="submit" color="primary" />
						<q-btn flat label="Close" color="primary" @click="closeModal"/>
					</div>
				</q-card-actions>
			</q-form>
		</q-card>
	</q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			hide:false,
			dialog: false,
			empID:'',
			name:'',
			rangeHeader:[],
			schedules:[],
			schedHolder:[],
			rangeHeaderHolder:[]
		}
	},

	created(){
		this.eventHub.$on('EditSchdule', this.editSchdule);
	},

	methods: {

		editSchdule:function(payload){

		//	alert(payload.status)
			this.dialog = payload.status
			this.rangeHeader = payload.header
			this.empID = payload.emp.employee_id
			this.name = payload.emp.name
			this.schedules = payload.emp.schedule
		},


		updateSchedule:function(){
			this.schedHolder = []
			for(let i in this.schedules){
				this.schedHolder.push(this.schedules[i].editsched)
			}
			this.rangeHeaderHolder = []
			for(let i in this.rangeHeader){
				this.rangeHeaderHolder.push(this.rangeHeader[i].name)
			}

			let formData  = new FormData()
			formData.append("empID",this.empID);
			formData.append("dateRange",this.rangeHeaderHolder);
			formData.append("schdules", this.schedHolder);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/manual',formData)
			.then((res) => {
				this.eventHub.$emit('UpdateSchdule');
				this.dialog = false;
				this.$q.notify({
					message: 'Schedule Updated!',
					color: 'green',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
			}).catch((error) => {
				console.log(error.response)
			})
		},

		submitGroup:function(){

		},

		closeModal:function(){
			this.dialog = false
		}
	}
}
</script>
<style lang="css" >
.editSchdule {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

td, th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}


.sortable tr:hover {
	cursor: pointer;
	background-color:#f8f5f8;
}

</style>
