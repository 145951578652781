<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="row q-mb-md full-width">
                <div class="full-width text-h4" style="height: 40px;">
                    Re-Hire List
                    <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
                        <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
                        Applications
                    </q-chip>
                </div>
            </div>

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-10 q-pa-md">
                    <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                        <div class="row">
                        <div class="col-12">
                        <!-- :visible-columns="visibleColumns" 01182023 -->
                            <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                            virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                        <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                            <!-- <template v-slot:top-left>
                                <div style="display:inline-flex;">
                                <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                                <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                                <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>
                                <q-btn
                                    class="q-mb-lg"
                                    color="primary"
                                    icon-right="fas fa-download"
                                    label="Export to csv"
                                    no-caps
                                    @click="exportModal = true"
                                />
                                </div>
                            </template> -->
                            <template v-slot:top-right>
                                <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                                <template v-slot:append>
                                    <q-icon name="fas fa-search" />
                                </template>
                                </q-input>
                            </template>
                            <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                                <!-- <q-td>
                                <q-checkbox dense v-model="props.selected" />
                                </q-td> -->
                                <q-td key="action" style="text-align:center;">
                                    <q-btn color="green" rounded size="sm" text-color="white" @click="forEndorsing(props.row)" label="Endorse" />
                                </q-td>
                                <q-td key="id" :props="props">{{ props.row.id }}</q-td>
                                <q-td key="employee_id" :props="props" >{{ props.row.employee_id }}</q-td>
                                <q-td key="firstname" :props="props" >{{ props.row.firstname }}</q-td>
                                <q-td key="middlename" :props="props" >{{ props.row.middlename }}</q-td>
                                <q-td key="lastname" :props="props" >{{ props.row.lastname }}</q-td>
                                <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
                                <!-- <q-td key="id" :props="props">{{ props.row.status }}</q-td> -->

                            </q-tr>
                            <template v-slot:no-data>
                                <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                                <span>
                                    No Applications Yet
                                </span>
                                <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                                </div>
                            </template>
                            </q-table>
                        </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>


            <!-- ======================================================================================= -->
            <q-dialog v-model="ForEndorseDialog" persistent>
                <q-card>

                    <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
                    <q-icon size="1.5em" name="fa fa-exclamation-circle" />
                    <span class="q-pl-sm">For Endorsing</span>
                    </q-card-section>
                    <q-card-section>
                    <div class="row">
                        <div class="col-6 q-pr-sm">Account</div>
                        <div class="col-6 q-pl-sm">Position</div>
                    </div>
                    <div class="row q-mb-sm">
                        <div class="col-6 q-pr-sm">
                        <q-select dense  v-model="endorse.account" :options="accountEndorse" outlined color="indigo-6"/>
                        </div>
                        <div class="col-6 q-pl-sm">
                        <q-select dense v-model="endorse.position" :options="positionEndorse" outlined color="indigo-6"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pr-sm">PB Date</div>
                        <div class="col-6 q-pl-sm">Basic Salary</div>
                    </div>
                    <div class="row q-mb-sm">
                        <div class="col-6 q-pr-sm">
                        <q-input dense type="date" v-model="endorse.pb_date" outlined color="indigo-6"/>
                        </div>
                        <div class="col-6 q-pl-sm">
                        <q-input dense v-model="endorse.basic_salary" outlined color="indigo-6"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pr-sm">Location</div>
                        <div class="col-6 q-pl-sm">Deminimis</div>
                    </div>
                    <div class="row q-mb-sm">
                        <div class="col-6 q-pr-sm">
                        <q-select dense  v-model="endorse.location" :options="sites" outlined color="indigo-6"/>
                        </div>
                        <div class="col-6 q-pl-sm">
                        <q-input dense v-model="endorse.deminimis" outlined color="indigo-6"/>
                        </div>
                    </div>
                    <!-- end -->
                    <div class="row">
                        <div class="col-12 q-pr-sm">Status</div>
                    </div>
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <q-select dense v-model="endorse.status" :options="['Project Base', 'Probationary', 'Seasonal']" outlined color="indigo-6"/>
                        </div>
                    </div>

                    <!-- add by ruben -->
                    <!-- 09-03-2023 -->
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">Person to notify</label>
                        <q-input dense v-model="endorse.contact_name" outlined color="indigo-6"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">Person to notify contact</label>
                        <q-input dense v-model="endorse.contact_number" outlined color="indigo-6" mask="#### - ### - ####" unmasked-value hint="0912-345-6789"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">Relationship</label>
                        <q-input dense v-model="endorse.contact_relation" outlined color="indigo-6" />
                        </div>
                    </div>

                    <!--========================================================== ruben::13/07/2023 =========================================================-->
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">SSS</label>
                        <q-input dense v-model="endorse.sss" outlined color="indigo-6" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">TIN</label>
                        <q-input dense v-model="endorse.tin" outlined color="indigo-6" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">HDMF</label>
                        <q-input dense v-model="endorse.hdmf" outlined color="indigo-6" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 q-px-none">
                        <label for="">PHIC</label>
                        <q-input dense v-model="endorse.phic" outlined color="indigo-6" />
                        </div>
                    </div>
                    <!--========================================================== end =========================================================-->
                    
                    </q-card-section>
                    <q-card-actions align="right">
                    <q-btn flat label="Cancel" color="red" v-close-popup />
                    <q-btn flat label="Endorse" color="indigo-7" @click="endorseApplication" v-close-popup />
                    </q-card-actions>
                </q-card>
                </q-dialog>
            <!-- ======================================================================================= -->

        </div>
    </div>
</template>

<script>

import axios from 'axios'
export default{
    name: "ReHireList",
    data(){
        return{
            filter: '',
            loadingModel: true,
            loadingText: '',
            loadingAction: false,
            viewDetailsDialog: false,
            actionDialog: false,
            ForNotHiredDialog: false,
            applications: '',
            applicationsData: [],
            Pagination: {
                sortBy: 'date_of_application',
                descending: true,
                // page: 1,
                rowsPerPage: 0
            },
            searchData:'',
            ResultColumns: [
                {
                    name: 'action',
                    field: 'action',
                    label: 'Action',
                    style: 'width: 200px',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'id',
                    field: 'id',
                    label: 'ID',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'employee_id',
                    field: 'employee_id',
                    label: 'Employee ID',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'firstname',
                    field: 'firstname',
                    label: 'First Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'middlename',
                    field: 'middlename',
                    label: 'Middle Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'lastname',
                    field: 'lastname',
                    label: 'Last Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'date_created',
                    field: 'date_created',
                    label: 'Date Created',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                }
            ],
            applicationCounts: 0,
            ForEndorseDialog: false,
            endorse: {
                rh_id:"",
                account: "",
                position: "",
                pb_date: "",
                basic_salary: "",
                status: "",
                contact_name: "",
                contact_relation: "",
                contact_number: "",
                location: "",
                deminimis:"",
                // ruben::13/07/2023
                 sss: "",
                 tin: "",
                 hdmf: "",
                 phic: ""
            },
            positionEndorse: [],
            accountEndorse: [],
            sites:["ORTIGAS","MARILAO","DAPITAN"],
            emp_details:{}
        }
    },
    methods: {
        forEndorsing(data){
            console.log('show endorse dialog:',data)
            this.ForEndorseDialog = true
            this.endorse.rh_id = data.id
            this.emp_details = JSON.parse(data.emp_data)
            // Object.assign(emp_details,{ruben:"ruben",pedragosa:'pedragosa'})
            // console.log(emp_details)
            // this.rehire = a
        },
        endorseApplication(){
            this.ForEndorseDialog = false
            // var formData = new FormData()
            // formData.append('form_data',Object.assign(this.emp_details,this.endorse))
            let newHire = Object.assign(this.emp_details,this.endorse)
            axios.get(this.apiUrl+'/employee/rehired-endorse/endorse',{
                headers: {'Authorization': `Bearer ${this.token}`},
                params:newHire
            }).then((res) => {
                console.log('endorse success:',res)
                this.fetchData()
            })
        },
        fetchData(){
            axios.get(this.apiUrl+'/employee/rehired-list',{
                headers: { 'Authorization': `Bearer ${this.token}`},
                params:{status:'processing'}
            }).then((res) => {
                this.applicationsData = res.data
                this.applicationCounts = res.data.length
            })
        },

        getDropdownProperties:function(){
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).get('/employee/dropdown/properties')
            .then((res) => {
                this.positionEndorse = res.data.data.positions
                this.accountEndorse = res.data.data.account
            });
        },
    },
    mounted(){
        this.fetchData()
        this.getDropdownProperties();
    }
}
</script>
