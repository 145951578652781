<template>
   <q-dialog v-model="confirm" persistent>
      <q-card>
         <q-form @submit="confirmDeletingTrainee">
           <q-card-section class="row items-center">
             <span class="q-ml-sm text-subtitle1">Do you want to continue this action?</span>
           </q-card-section>
           <q-card-actions align="right">
             <q-btn flat label="Cancel" color="primary" v-close-popup />
             <q-btn flat label="Confirm" color="red" type="submit"/>
           </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      confirm:false,
      id:'',
    }
  },

  created(){
    this.eventHub.$on('deleteEmployee', this.deleteEmployee);
  },

  methods: {
   deleteEmployee:function(obj){
      this.id = obj.id
      this.confirm = obj.status
   },

   confirmDeletingTrainee:function(){
      var formData = new FormData()
      formData.append('id',this.id);
      axios.create({
       baseURL : this.apiUrl,
       headers : {'Authorization' : `Bearer ${this.token}`}
    }).post(`/mobile/pantry/trainee/delete`,formData)
     .then((response) => {
       this.confirm = false
       this.eventHub.$emit('refreshList');
     });
   }
}
}
</script>
<style lang="css" scoped>
</style>
