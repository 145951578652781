<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="row full-width text-h4 justify-between q-mb-md">
        <div class="col-8">
          Employee Special Leave Applications
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input :rules="[formRules.required]" outlined v-model="searchModel" dense placeholder="Search employee here..." color="amber-8">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="credit_id" :hide-bottom="searchResultData.length < 5" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="openLeaveAppDialog(props.row.name,props.row.credit_id,props.row.employee_id,props.row.gender,props.row.ml_note,props.row.pl_note,props.row.mc_note,props.row.sp_note,props.row.sp_availed)">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
            <q-td key="maternity_leave" :props="props">
              <q-avatar :icon="props.row.ml_availed" font-size="20px" :text-color="props.row.ml_bg_color" />
              <q-tooltip anchor="top middle" self="center middle" content-class="bg-amber-8 text-black" :offset="[10, 10]">
                <strong>{{ props.row.ml_note }}</strong>
              </q-tooltip>
            </q-td>
            <q-td key="paternity_leave" :props="props">
              <q-avatar :icon="props.row.pl_availed" font-size="20px" :text-color="props.row.pl_bg_color" />
              <q-tooltip anchor="top middle" self="center middle" content-class="bg-amber-8 text-black" :offset="[10, 10]">
                <strong>{{ props.row.pl_note }}</strong>
              </q-tooltip>
            </q-td>
            <q-td key="magna_carta_leave" :props="props">
              <q-avatar :icon="props.row.mc_availed" font-size="20px" :text-color="props.row.mc_bg_color" />
              <q-tooltip anchor="top middle" self="center middle" content-class="bg-amber-8 text-black" :offset="[10, 10]">
                <strong>{{ props.row.mc_note }}</strong>
              </q-tooltip>
            </q-td>
            <q-td key="solo_parent_leave" :props="props">
              <q-avatar :icon="props.row.is_sp_availed" font-size="20px" :text-color="props.row.sp_bg_color" />
              <q-tooltip anchor="top middle" self="center middle" content-class="bg-amber-8 text-black" :offset="[10, 10]">
                <strong>{{ props.row.sp_note }}</strong>
              </q-tooltip>
            </q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
              <span>
                {{message}}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <q-dialog v-model="leaveAppDialog" persistent transition-show="scale" transition-hide="scale">
        <div class="bg-white" style="height: auto; width: 600px; max-width: 600px;">
          <q-card class="my-card">
            <q-card-section class="q-ma-none q-pa-none bg-amber-8 text-white">
              <q-item class="q-pa-md">
                <q-item-section avatar class="q-mr-md" style="width:40px; min-width:40px;">
                  <q-avatar text-color="white" icon="fa fa-1p5x fa-file-contract" />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-h5">Special Leave Application</q-item-label>
                  <q-item-label class="text-h6-special">{{ employeeNameID }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-card-section>
            <q-form class="full-width q-ma-none q-pa-none q-pa-md">
              <div class="full-width row justify-center q-mb-md">
                <q-card bordered class="my-card col-12 q-pa-md">
                  <q-card-section class="q-pa-none q-ma-none">
                    <div class="text-h6">Leave Type</div>
                  </q-card-section>
                  <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div>
                      <q-select v-model="form.leaveType" :options="leaveOptions" outlined color="amber-7" />
                    </div>
                  </q-card-section>
                </q-card>
              </div>
              <div class="full-width row justify-center q-mb-md">
                <q-card bordered class="my-card col-12 q-pa-md">
                  <q-card-section class="q-pa-none q-ma-none">
                    <div class="text-h6">Inclusive Dates</div>
                  </q-card-section>
                  <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div class="row">
                      <div class="col-6 q-pr-sm">From</div>
                      <div class="col-6 q-pl-sm">To</div>
                    </div>
                  </q-card-section>
                  <q-card-section class="q-pa-none q-ma-none">
                    <div class="row ">
                      <div class="col-6 q-pr-sm">
                        <q-input v-model="dateFromInput" outlined color="amber-7" readonly>
                          <template v-slot:append>
                            <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                              <q-popup-proxy ref="qDateFromProxy" transition-show="flip-down" transition-hide="flip-up">
                                <q-date v-model="form.selectedDateFrom" @input="$refs.qDateFromProxy.hide()" :options="startDate" today-btn color="amber-7" minimal />
                              </q-popup-proxy>
                            </q-icon>
                          </template>
                        </q-input>
                      </div>
                      <div class="col-6 q-pl-sm">
                        <q-input v-model="dateToInput" outlined color="amber-7" readonly>
                          <template v-slot:append>
                            <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                              <q-popup-proxy ref="qDateToProxy" transition-show="flip-down" transition-hide="flip-up">
                                <q-date v-model="form.selectedDateTo" @input="$refs.qDateToProxy.hide()" :options="endDate" today-btn color="amber-7" minimal />
                              </q-popup-proxy>
                            </q-icon>
                          </template>
                        </q-input>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>
              <div class="full-width row justify-center">
                <q-card bordered class="my-card col-12 q-pa-md">
                  <q-card-section class="q-pa-none q-ma-none">
                    <div class="text-h6">Reason</div>
                  </q-card-section>
                  <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div style="width: 100%; min-height: 50px;">
                      <q-input v-model="form.leaveReason" outlined color="amber-7" maxlength="200" autogrow clearable bottom-slots counter hint="Max 200 characters" />
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </q-form>
            <q-card-section>
              <div class="row justify-center q-gutter-md q-mb-sm">
                <q-btn @click="submitLeaveApplication" :loading="loading" label="Submit" size="md" style="width: 200px" color="amber-8">
                  <template v-slot:loading>
                    <q-spinner-hourglass class="on-left" />
                    Sending...
                  </template>
                </q-btn>
                <q-btn label="CLOSE" color="grey-8" size="md" style="width: 200px;" v-close-popup />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-dialog>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
let today = Date.now();
let dateToday = date.formatDate(today, 'MM-DD-YYYY-hhmmA');
export default {
  name: 'SpecialLeaveApplications',
  data() {
    return {
      dateToday,
      employeeNameID: '',
      leaveAppDialog: false,
      loading: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 120px',
          align: 'center',
          headerStyle: 'width: 120px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'maternity_leave',
          field: 'maternity_leave',
          label: 'Maternity Leave',
          style: 'width: 100px',
          align: 'center',
          headerStyle: 'width: 100px; padding: none'
        },
        {
          name: 'paternity_leave',
          field: 'paternity_leave',
          label: 'Paternity Leave',
          style: 'width: 100px',
          align: 'center',
          headerStyle: 'width: 100px'
        },
        {
          name: 'magna_carta_leave',
          field: 'magna_carta_leave',
          label: 'Magna Carta Leave',
          style: 'width: 100px',
          align: 'center',
          headerStyle: 'width: 100px'
        },
        {
          name: 'solo_parent_leave',
          field: 'solo_parent_leave',
          label: 'Solo Parent Leave',
          style: 'width: 100px',
          align: 'center',
          headerStyle: 'width: 100px'
        }
      ],
      gender: null,
      ml_availed: null,
      ml_availment: 'fa fa-check',
      ml_avail_color: 'green-10',
      pl_availed: null,
      pl_availment: 'fa fa-check',
      pl_avail_color: 'green-10',
      mc_availed: null,
      mc_availment: 'fa fa-check',
      mc_avail_color: 'green-10',
      is_sp_availed: null,
      sp_availed: null,
      sp_availment: 'fa fa-check',
      sp_avail_color: 'green-10',
      leaveOptions: [],
      sp_validation: null,
      form: {
        employee_id: null,
        leaveType: 'Please select type of Leave',
        selectedDateFrom: null,
        selectedDateTo: null,
        leaveReason: null
      },
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.employeeNameID = '';
      this.loading = true;
      this.$refs.vForm.validate();
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/credits/search-employee-credits', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          for (let emp of res.data.data) {
            this.searchResultData.push({
              credit_id: emp.credit_id,
              employee_id: emp.employee_id,
              name: this.toTitleCase(emp.name),
              gender: emp.gender,
              position: emp.position,
              department: emp.department,
              ml_availed: emp.ml_availed,
              ml_bg_color: emp.ml_bg_color,
              ml_note: emp.note_ml,
              pl_availed: emp.pl_availed,
              pl_bg_color: emp.pl_bg_color,
              pl_note: emp.note_pl,
              mc_availed: emp.mc_availed,
              mc_bg_color: emp.mc_bg_color,
              mc_note: emp.note_mc,
              sp_availed: emp.sp_availed,
              is_sp_availed: emp.is_sp_availed,
              sp_bg_color: emp.sp_bg_color,
              sp_note: emp.note_sp
            });
          }
          console.log(this.searchResultData);
        })
    },
    openLeaveAppDialog: function(rowName, rowID, empID, gender, noteML, notePL, noteMC, noteSP, spAvailed) {
      this.form.employee_id = rowID;
      this.leaveOptions = [];
      this.form.leaveType = 'Please select type of Leave';
      this.form.selectedDateFrom = null;
      this.form.selectedDateTo = null;
      this.form.leaveReason = null;
      this.employeeNameID = rowName + ' - ' + empID;
      this.sp_validation = spAvailed;
      if (gender == 'F') {
        if (noteML == 'Application Available') {
          this.leaveOptions.push('Maternity Leave')
        }
        if (noteMC == 'Application Available') {
          this.leaveOptions.push('Magna Carta Leave')
        }
        if (noteSP == 'Application Available') {
          this.leaveOptions.push('Solo Parent Leave')
        }
      } else {
        if (notePL == 'Application Available') {
          this.leaveOptions.push('Paternity Leave')
        }
        if (noteSP == 'Application Available') {
          this.leaveOptions.push('Solo Parent Leave')
        }
      }
      this.leaveAppDialog = true;
    },
    startDate: function(date) {
      return date >= this.dateToday;
    },
    endDate: function(date) {
      if (this.form.selectedDateFrom == null) {
        return date >= this.dateToday;
      } else {
        return date >= this.form.selectedDateFrom;
      }
    },
    submitLeaveApplication: function() {
      const dateTots = date.getDateDiff(this.form.selectedDateTo, this.form.selectedDateFrom, 'days') + 1;
      let remSP = 7 - this.sp_validation;
      console.log(remSP);
      console.log(this.sp_validation);
      null
      if (this.form.selectedDateFrom == null || this.form.selectedDateTo == null ||
        this.form.leaveReason == null || this.form.leaveReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (this.form.selectedDateFrom > this.form.selectedDateTo) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date From" is greater than "Date To".'
        });
      } else if (this.form.leaveType == 'Solo Parent Leave' && dateTots > remSP) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Leave Credits. Available SP Leave Credit: ' + remSP
        });
      } else {
        this.loading = true;
        this.form.selectedDateFrom = date.formatDate(this.form.selectedDateFrom, 'YYYY-MM-DD');
        this.form.selectedDateTo = date.formatDate(this.form.selectedDateTo, 'YYYY-MM-DD');
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));
        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/leave/special-application', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: this.form.leaveType + ' Application Submitted'
            });
            var self = this;
            this.form.leaveType = 'Please select type of Leave from the list';
            this.form.selectedDateFrom = null;
            this.form.selectedDateTo = null;
            this.form.leaveReason = null;
            this.onSubmitSearch();
            this.leaveAppDialog = false;
          }, 1000);
        });
      }
    },
  },
  computed: {
    dateFromInput: function() {
      if (this.form.selectedDateFrom == null) {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateFrom);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },
    dateToInput: function() {
      if (this.form.selectedDateTo == null) {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateTo);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  }
}
</script>
<style lang="css" scoped>
.text-h6-special {
  font-size: 14px;
  color: #ffecb3;
}
</style>
