<template>
	<div>
		<q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="vForm" @submit="onSubmit">
					<q-card-section>
						<div class="text-h6">{{ title }} MWE-Monthly</div>
					</q-card-section>
					<q-card-section>
						<div class="col-12 q-mb-md">
							<q-select v-model="regionID" option-value="id" option-label="region_no"  :rules="[formRules.required]" :options="regionOptions" label="REGIONS" emit-value map-options @input="getCoverage" />
							<q-select v-model="coverageID" option-value="id" option-label="coverage" :rules="[formRules.required]" :options="coverageOptions" label="COVERAGE" emit-value map-options @input="getSector"/>
							<q-select v-model="sectorID" option-value="id" option-label="sector" :rules="[formRules.required]" :options="sectorOptions" label="SECTOR" emit-value map-options />
							<q-input v-model="basic" label="Basic" type="number" class="caps" :rules="[formRules.required]" @input="calculateTotal" />
							<q-input v-model="allowance" label="Allowance" type="number" class="caps" @input="calculateTotal"/>
							<q-input v-model="deminimis" label="Deminimis" type="number" class="caps"  @input="calculateTotal"/>
							<q-input v-model="total" label="Total" type="number" class="caps" readonly/>
							<q-input v-model="wonumber" label="W.O Number" class="caps" :rules="[formRules.required]"/>
						</div>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>
		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			title:'',
			tempID:0,
			spinner: false,
			dialog: false,
			regionID:null,
			coverageID:null,
			sectorID:null,
			basic:0,
			allowance:0,
			deminimis:0,
			total:0,
			wonumber:null,
			regionOptions:[],
			coverageOptions:[],
			sectorOptions:[],
			type:'monthly',
		}
	},
	created(){
		this.eventHub.$on('AddMWEMonthly', this.openModal);
		this.displayRegions()
	},

	methods: {
		openModal:function(obj){

			if(obj.type == "add"){
				this.title = 'Add'
			}

			if(obj.type == "edit"){
				this.title = 'Edit'
				this.tempID = obj.emp.row.id
				this.regionID = obj.emp.row.regionID
				this.coverageID = obj.emp.row.coverageID
				this.sectorID = obj.emp.row.sectorID
				this.basic = obj.emp.row.basic
				this.allowance = obj.emp.row.allowance
				this.deminimis = obj.emp.row.deminimis
				this.total = obj.emp.row.total
				this.wonumber = obj.emp.row.wonumber
				this.getCoverage()	
				this.getSector()
			}

			this.dialog = true
			
		},
		closeModal:function(){
			this.dialog = false
		},

		onSubmit : function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("regionID", this.regionID);
			formData.append("coverageID", this.coverageID);
			formData.append("sectorID", this.sectorID);
			formData.append("basic", this.basic);
			formData.append("allowance", this.allowance);
			formData.append("deminimis", this.deminimis);
			formData.append("total", this.total);
			formData.append("wonumber", this.wonumber);
			formData.append("created_by", this.userData.username);
			formData.append("tempID", this.tempID);
			formData.append("type", this.type);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/mwe/monthly',formData)
			.then((res) => {
				console.log(res.data)
				this.regionID = ''
				this.coverageID = ''
				this.sectorID = ''
				this.basic = ''
				this.allowance = ''
				this.deminimis = ''
				this.total = ''
				this.wonumber = ''
				this.tempID = 0
				this.spinner = false;
				this.$refs.vForm.reset()
				this.eventHub.$emit('updateList',{status: false});
			});
		},

		calculateTotal:function(){
			var total =  parseInt(this.basic) + parseInt(this.allowance) + parseInt(this.deminimis);
			if(isNaN(total)){
				total = 0;
			}
			this.total = parseInt(total);
		},

		displayRegions: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/regions`)
			.then((res) => {
				this.regionOptions = res.data.data;
			})
		},

		getCoverage:function(){
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/region/sector/${this.regionID}`)
			.then((res) => {
				if(this.title == 'Add'){
					this.coverageID = ''
					this.sectorID = ''
				}
				
				this.coverageOptions = res.data.data;
			})
		},

		getSector:function(){
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/sectors/${this.coverageID}`)
			.then((res) => {

				if(this.title == 'Add'){
					this.sectorID = ''
				}
				
				this.sectorOptions = res.data.data;
			})
		},

	}
}
</script>
<style lang="css" scoped>
</style>