<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-btn color="primary" label="Filter" @click="generateDTR"/>

				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table
			:dense="$q.screen.lt.md"
			:grid="$q.screen.xs"
			title="TK Summary"
			:data="generatedData"
			:columns="columns"
			row-key="id"
			:pagination.sync="pagination">
			<template v-slot:header="props">
				<q-tr :props="props">
					<q-th v-for="col in props.cols" :key="col.name" :props="props">
						{{ col.label }}
						<q-tooltip anchor="top middle" content-class="bg-red" :offset="[10, 10]">
							{{ col.desc }}
						</q-tooltip>
					</q-th>
				</q-tr>
			</template>
			<template v-slot:no-data="">
				<div class="full-width row flex-center text-red q-gutter-sm">
					<i class="fas fa-exclamation-triangle"></i>
					<span>
						No Result Found!
					</span>
				</div>
			</template>
			<template v-slot:top-right>
				<q-btn
				outline
				color="primary"
				label="Download"
				no-caps
				@click="exportTable"
				/>
			</template>
		</q-table>

	</div>


	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			separator:'cell',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			importfile:false,
			spinner:false,
			file:'',
			pagination: {
				rowsPerPage: 30
			},
			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
			headers:[],
			schdules: [],
			generatedData:[],
			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],

			data: [],


			columns: [
			{ name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true,desc:'Employee ID' },
			{ name: 'name', align: 'center', label: 'Name', field: 'name' , sortable: true,desc:'Name'},

			{ name: 'REG_HOUR', align: 'center', label: 'Regular Days', field: 'REG_HOUR', sortable: true,desc:'Regular Days' },
			{ name: 'UNDER_TIME', align: 'center', label: 'Under Time', field: 'UNDER_TIME', sortable: true ,desc:'Undertime'},
			{ name: 'ABSENT', align: 'center', label: 'Absent', field: 'ABSENT', sortable: true ,desc:'Absent'},
			{ name: 'TARDY', align: 'center', label: 'Tardy', field: 'TARDY', sortable: true ,desc:'Tardy'},

			{ name: 'RD', align: 'center', label: 'RD', field: 'RD', sortable: true ,desc:'Working on Restday'},
			{ name: 'SH', align: 'center', label: 'SH', field: 'SH', sortable: true ,desc:'Special Holiday'},
			{ name: 'SH_RD', align: 'center', label: 'SH-RD', field: 'SH_RD', sortable: true ,desc:'Special Holiday, Restday'},
			{ name: 'LH', align: 'center', label: 'LH', field: 'LH', sortable: true ,desc:'Legal Holiday'},
			{ name: 'LH_RD', align: 'center', label: 'LH-RD', field: 'LH_RD', sortable: true ,desc:'Legal Holiday, Restday'},
			{ name: 'DBL_LH', align: 'center', label: 'DBL-LH', field: 'DBL_LH', sortable: true ,desc:'Double Legal Holiday'},
			{ name: 'DBL_RD', align: 'center', label: 'DBL-RD', field: 'DBL_RD', sortable: true ,desc:'Double Legal Holiday, Restday'},

			{ name: 'ND', align: 'center', label: 'ND', field: 'ND', sortable: true ,desc:'Night Shift'},
			{ name: 'ND_RD', align: 'center', label: 'ND-RD', field: 'ND_RD', sortable: true ,desc:'Night Shift, Restday'},
			{ name: 'ND_SH', align: 'center', label: 'ND-SH', field: 'ND_SH', sortable: true ,desc:'Night Shift, Special Holiday'},
			{ name: 'ND_SH_RD', align: 'center', label: 'ND-SH-RD', field: 'ND_SH_RD', sortable: true ,desc:'Night Shift,Special Holiday,Restday'},
			{ name: 'ND_LH', align: 'center', label: 'ND-LH', field: 'ND_LH', sortable: true ,desc:'Night Shift, Legal Holiday'},
			{ name: 'ND_LH_RD', align: 'center', label: 'ND-LH-RD', field: 'ND_LH_RD', sortable: true ,desc:'Night Shift, Legal Holiday, Restday'},
			{ name: 'ND_DBL_LH', align: 'center', label: 'ND-DBL-LH', field: 'ND_DBL_LH', sortable: true ,desc:'Night Shift, Double Legal Holiday'},
			{ name: 'ND_RD_DBL_RH', align: 'center', label: 'ND-RD-DBL-RH', field: 'ND_RD_DBL_RH', sortable: true ,desc:'Night Shift, Restday, Double Legal Holiday'},

			{ name: 'OT', align: 'center', label: 'OT', field: 'OT', sortable: true ,desc:'Overtime'},
			{ name: 'OT_PAY', align: 'center', label: 'OT-PAY', field: 'OT_PAY', sortable: true ,desc:'Overtime Pay'},
			{ name: 'OT_RD', align: 'center', label: 'OT-RD', field: 'OT_RD', sortable: true ,desc:'Overtime, Restday'},
			{ name: 'OT_SH', align: 'center', label: 'OT-SH', field: 'OT_SH', sortable: true ,desc:'Overtime, Special Holiday'},
			{ name: 'OT_RD_SH', align: 'center', label: 'OT-RD-SH', field: 'OT_RD_SH', sortable: true ,desc:'Overtime, Restday, Special Holiday'},
			{ name: 'OT_LH', align: 'center', label: 'OT-LH', field: 'OT_LH', sortable: true ,desc:'Overtime,Legal Holiday'},
			{ name: 'OT_RD_LH', align: 'center', label: 'OT-RD-LH', field: 'OT_RD_LH', sortable: true ,desc:'Overtime,Restday, Legal Holiday'},
			{ name: 'OT_DBL_LH', align: 'center', label: 'OT-DBL-LH', field: 'OT_DBL_LH', sortable: true ,desc:'Overtime,Double Legal Holiday'},
			{ name: 'OT_RD_DBL_LH', align: 'center', label: 'OT-RD-DBL-LH', field: 'OT_RD_DBL_LH', sortable: true ,desc:'Overtime,Restday, Double Legal Holiday'},
			{ name: 'OT_ND', align: 'center', label: 'OT-ND', field: 'OT_ND', sortable: true ,desc:'Overtime, Night Shift'},
			{ name: 'OT_ND_RD', align: 'center', label: 'OT-ND-RD', field: 'OT_ND_RD', sortable: true ,desc:'Overtime, Night Shift, Restday'},
			{ name: 'OT_ND_SH', align: 'center', label: 'OT-ND-SH', field: 'OT_ND_SH', sortable: true ,desc:'Overtime, Night Shift, Special Holiday'},
			{ name: 'OT_ND_RD_SH', align: 'center', label: 'OT-ND-RD-SH', field: 'OT_ND_RD_SH', sortable: true ,desc:'Overtime, Night Shift, Restday, Special Holiday'},
			{ name: 'OT_ND_LH', align: 'center', label: 'OT-ND-LH', field: 'OT_ND_LH', sortable: true ,desc:'Overtime,Night Shift, Legal Holiday'},
			{ name: 'OT_ND_RD_LH', align: 'center', label: 'OT-ND-RD-LH', field: 'OT_ND_RD_LH', sortable: true ,desc:'Overtime, Night Shift, Restday, Legal Holiday'},
			{ name: 'OT_ND_DBL_LH', align: 'center', label: 'OT-ND-DBL-LH', field: 'OT_ND_DBL_LH', sortable: true ,desc:'Overtime, Night Shift, Double Legal Holiday'},
			{ name: 'OT_ND_RD_DBL_LH', align: 'center', label: 'OT-ND-RD-DBL-LH', field: 'OT_ND_RD_DBL_LH', sortable: true ,desc:'Overtime, Night Shift, Restday, Double Legal Holiday'},

			//leaves
			{ name: 'VL', align: 'center', label: 'VL', field: 'VL', sortable: true ,desc:'Vacation Leave'},
			{ name: 'SL', align: 'center', label: 'SL', field: 'SL', sortable: true ,desc:'Sick Leave'},
			{ name: 'EL', align: 'center', label: 'EL', field: 'EL', sortable: true ,desc:'Emergency Leave'},
			{ name: 'OBT', align: 'center', label: 'OBT', field: 'OBT', sortable: true ,desc:'On Business Trip'},
			{ name: 'ML', align: 'center', label: 'ML', field: 'ML', sortable: true ,desc:'Maternity Leave'},
			{ name: 'PL', align: 'center', label: 'PL', field: 'PL', sortable: true ,desc:'Paternity Leave'},
			{ name: 'MCL', align: 'center', label: 'MCL', field: 'MCL', sortable: true ,desc:'Magna Carta Leave'},
			{ name: 'SPL', align: 'center', label: 'SPL', field: 'SPL', sortable: true ,desc:'Solo Parent Leave'},
			{ name: 'DEDU', align: 'center', label: 'DEDUCT', field: 'DEDU', sortable: true ,desc:'Deduction'},
			{ name: 'ADJU', align: 'center', label: 'ADJUST', field: 'ADJU', sortable: true ,desc:'Adjustment'},
			{ name: 'TOTAL', align: 'center', label: 'TOTAL', field: 'TOTAL', sortable: true ,desc:'TOTAL'},
			],
		}
	},

	created(){

	},

	methods: {

		generateDTR : function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/binary/timekeeping/generate/summary`,formData)
			.then((res) => {
				//console.log(res.data)
				this.generatedData = res.data.summary
				this.spinner = false
				this.dataFlag = true
			});
		},

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'TKSummary.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	.my-sticky-column-table
		thead tr:nth-child(2) th:nth-child(2)
			background-color: #fff
		td:nth-child(2)
			background-color: #c1f4cd
		th:nth-child(2)
		td:nth-child(2)
			position: sticky
			left: 0
			z-index: 1
</style>
