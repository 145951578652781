<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set Application Approvers</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input :rules="[formRules.required]" outlined v-model="searchModel" dense placeholder="Search employee here..." color="amber-8">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="id"  :loading="loading" :pagination="initialPagination">
          <q-tr slot="body" slot-scope="props" :props="props" class="default">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
            <q-td key="approver" :props="props">{{ props.row.approverName }}</q-td>
            <q-td key="action" :props="props">
              <div v-if="props.row.approverName === 'None'">
                <q-btn label="Set Approver" @click="listApprover('setApprover','',props.row.id)" color="teal-10" size="md" class="approver-btn" icon="fa fa-file-signature" />
              </div>
              <div v-else>
                <q-btn label="Edit Approver" @click="listApprover('editApprover',props.row.approverID, props.row.id)" color="deep-orange-10" size="md" class="approver-btn" icon="fa fa-file-signature" />
              </div>
            </q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
              <span>
                {{message}}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <q-dialog v-model="setApproverDialog" persistent>
        <q-card class="approver-dialog">
          <q-card-section class="row q-pa-sm q-pl-md bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-file-signature" />
            <span class="q-pl-sm">Set Approver For This Employee</span>
          </q-card-section>
          <q-card-section class="q-ma-sm q-pa-none">
            <div>
              <q-list v-for="approver in approvers" :key="approver.id">
                <q-item tag="label">
                  <q-item-section avatar>
                    <q-radio v-model="form.selectedApproverID" :val="approver.id" color="amber-8" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ approver.name }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="amber-8" class="btn-fixed-width" v-close-popup />
            <q-btn flat label="Confirm" @click="setApprover" class="bg-amber-8 text-white btn-fixed-width" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow loading-width">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="text-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{ loadingMessage }}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'SetApprovers_Leave',
  data() {
    return {
      initialPagination: {
        rowsPerPage: 100
      },
      loadingModel: false,
      loading: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 160px',
          align: 'center',
          headerStyle: 'width: 160px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 230px',
          align: 'center',
          headerStyle: 'width: 230px'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'approver',
          field: 'approver',
          label: 'Approver',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px'
        },
      ],
      loadingMessage: '',
      approvers: [],
      selectedApproverID: '',
      setApproverDialog: false,
      form: {
        approveType: '',
        selectedEmployeeID: '',
        selectedApproverID: ''
      }
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.loading = true;
      this.$refs.vForm.validate();

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/applications/search-approvers', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          for (let emp of res.data.data) {
            this.searchResultData.push({
              id: emp.leave_id,
              name: this.toTitleCase(emp.name),
              employee_id: emp.employee_id,
              position: emp.position,
              department: emp.department,
              approverName: emp.approver,
              approverID: emp.approverID
            });
          }
        })
    },

    listApprover: function(approveType, approverID, applicantID) {
      this.form.approveType = approveType;
      this.form.selectedEmployeeID = applicantID;
      this.loadingMessage = 'Getting List of Approvers';
      this.loadingModel = true;
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/applications/approvers-list', {
          params: {
            applicantID: applicantID
          }
        })
        .then((res) => {
          this.approvers = [];
          for (let aprv of res.data.data) {
            this.approvers.push({
              name: this.toTitleCase(aprv.name),
              id: aprv.id
            });
          }
          this.loadingModel = false;
          this.form.selectedApproverID = approverID;
          this.setApproverDialog = true;
        })
    },

    setApprover: function() {
      this.loadingMessage = 'Setting Approver';
      this.loadingModel = true;

      if (!this.form.selectedApproverID) {
        this.loadingModel = false;
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select approver from the list'
        });
      } else {
        this.setApproverDialog = false;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/leave/applications/set-approver', formData).then((res) => {
          this.onSubmitSearch();
          setTimeout(() => {
            this.loadingModel = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Approver Has Been Set'
            });
          }, 1000);
        });
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-fixed-width {
  width: 100px;
}

.loading-width {
  width: 270px;
}

.default {
  cursor: default;
}

.approver-btn {
  width: 175px;
}

.approver-dialog {
  height: auto;
  width: 350px;
  max-width: 500px;
}
</style>
