<template>
  <div class="container q-pa-sm">
    <div class="row q-gutter-md justify-center q-mt-xs">
      <div class="col-12 col-md-12">
        <div class="row q-gutter-md justify-center">
          <div class="col-12 col-md-5" style="text-align:center; overflow:hidden;">
            <img width="500" class="logo q-mb-xs" src="../assets/sgcc-black.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="row q-gutter-md justify-center ">
          <div class="col-12 col-md-3 col-sm-6" v-for="(module, index) in uniqueMenu" v-bind:key="index">
            <router-link :to="'/'+module.code">
              <q-card @click="clickSelectModule(module.name)" class="hoverable" :class="module.bgClass" dark>
                <q-card-section class="text-white text-center">
                  <i class="fa fa-5x" :class="module.iconClass"></i>
                  <div class="q-my-sm"></div>
                  <h6 class="q-ma-none moudle-name">{{module.name}}</h6>
                </q-card-section>
              </q-card>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- START:: ANNOUNCEMENT MODAL -->
    <q-dialog v-model="announcement">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">{{latestAnnouncement.subject}}</div>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-pt-none" style="padding: 15px;">
          <p v-if="latestAnnouncement.full_message == ''">{{latestAnnouncement.message}}</p>
          <p v-else>{{latestAnnouncement.full_message}}</p>
          <q-img v-if="latestAnnouncement.file_type == 'image/jpeg'" :src="latestAnnouncement.attachmentLink" style="width: 100%" />
        </q-card-section>
        <q-separator />
        <q-card-actions class="row">
          <div class="text-blue col-sm-6" style="margin-top: 3px;float: right;" v-if="latestAnnouncement.attachment != null">
            <a class="text-primary" href="#" @click="dlAttacment(latestAnnouncement.attachmentLink,latestAnnouncement.attachment)">
              <i class="fa fa-paperclip"></i>
              Download Attachment
            </a>
          </div>
          <div class="col-sm-6" style="width: 50%;" align="right">
            <q-btn flat label="Close" color="primary" v-close-popup />
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- END:: ANNOUNCEMENT MODAL -->
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "Home",
  data() {
    return {
      announcement: false,
      latestAnnouncement: [],
      attachementLink: '',
      allowedMenu: [],
      uniqueMenu: [] //change to modules if you want to show all
    }
  },

  mounted: function() {
    this.setMenu();
  },

  created: function() {
    this.displayData();
  },

  methods: {
    //START:: ANNOUNCEMENT
    displayData: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`getAnnouncementList`)
        .then((res) => {
          this.latestAnnouncement = res.data.data.pub[0];
          //this.attachementLink = this.latestAnnouncement.attachmentLink;
         // this.readAnnouncement();

        })
    },
    //update read_announcement
    readAnnouncement: function() {
      let formData = new FormData();
      formData.append("id", this.latestAnnouncement.id);

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .post(`announcement/read`, formData)
        .then((res) => {
          //console.log(res.data.data)
          if (res.data.data == 'read') {
            this.announcement = false;
          } else {
            this.announcement = true;
          }
        })
    },
    //download attachment
    dlAttacment: function(url, filename) {
      //alert(filename)
      axios({
        url: url,
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    //END:: ANNOUNCEMENT

    setMenu: function() {
      const menuList = this.modules; //get menu list
      const allowedMenu = this.userData; //get allowed menu in token
      let isAdmin = (this.usersModules[1] !== undefined) ? true : false;
      let newApplicant = (this.userData.username == 'NewApplicant') ? true : false; //For New Applicants

      if (isAdmin) {
        this.allowedMenu = menuList
      } else if (newApplicant) {
        for (let i in menuList) {
          for (let key in allowedMenu.modules) {
            if (menuList[i]['allowedmenu'] === allowedMenu.modules[key]['name']) {
              this.allowedMenu.push(menuList[i]);
            }
          }
        }
      } else {
        for (let i in menuList) {
          if (menuList[i]['allowedmenu'] == 'forms' || menuList[i]['allowedmenu'] == 'leaves' || menuList[i]['allowedmenu'] == 'announcement' || menuList[i]['allowedmenu'] == 'payslip') { //make form and leaves default
            this.allowedMenu.push(menuList[i]);
          }
          for (let key in allowedMenu.modules) {
            if (menuList[i]['allowedmenu'] === allowedMenu.modules[key]['name']) {
              this.allowedMenu.push(menuList[i]);
            }
          }
        }
      }

      var unique = this.allowedMenu.filter(function(elem, index, self) { //remove duplicate menu
        return index === self.indexOf(elem);
      })

      this.uniqueMenu = unique;
    },

    clickSelectModule: function(module) {
      this.$router.push(module.url);
    }

  }
};
</script>
<style scoped>
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #eee;
  background: url('../assets/office-background-3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
}

.hoverable {
  cursor: pointer;
  transition: 0.4s all ease;
}

.hoverable:hover i.fa {
  transition: 0.4s all ease;
}

.hoverable:hover {
  transform: scale(1.009);
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
}

.hoverable:hover i.fa {
  transform: rotate(-12deg);
}

h6 {
  line-height: 20px;
}
</style>
