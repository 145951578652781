<template>
	<div class="q-pa-md q-gutter-sm">
		<q-dialog
		v-model="dialog"
		persistent
		:maximized="maximizedToggle"
		transition-show="slide-up"
		transition-hide="slide-down"
		>
		<q-card>
			<q-bar>
				<q-space />
				<q-btn dense flat  @click="maximizedToggle = false" :disable="!maximizedToggle">
					<i class="fas fa-window-minimize"></i>
					<q-tooltip v-if="maximizedToggle" content-class="bg-white text-primary">Minimize</q-tooltip>
				</q-btn>

				<q-btn dense flat  @click="maximizedToggle = true" :disable="maximizedToggle">
					<i class="far fa-window-maximize"></i>
					<q-tooltip v-if="!maximizedToggle" content-class="bg-white text-primary">Maximize</q-tooltip>
				</q-btn>

				<q-btn dense flat v-close-popup>
					<i class="fas fa-times"></i>
					<q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
				</q-btn>
			</q-bar>

			<q-card-section class="q-ma-md">
				<q-form ref="vFormEdit" @submit="onSubmit">
					<div class="row">
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-dialog__inner--fullwidth" style="max-width: 280px" >
							<q-avatar size="200px" rounded  >
								<img src="../../assets/boy-avatar.png">
							</q-avatar>
						</div>
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl" >

						</div>
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
						</div>
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md">
							<q-btn  color="red"  style="width: 100px" type="button" @click="deleteEmployee" v-show="companyInfo.is_active =='HOLD' ? true : false">INACTIVE</q-btn>
							<q-btn  color="orange" style="width: 100px" type="submit" >Update</q-btn>
							<q-btn  color="blue" v-close-popup style="width: 100px" type="button" >Close</q-btn><br/>
							<q-toggle size="xl" v-show="companyInfo.is_active =='ACTIVE' ? true : false" :label="(isActive) ? 'ACTIVE' : 'INACTIVE'" v-model="isActive" color="green" @input="deactToogle"/><br/>
							<q-chip outline v-show="isActiveMsg != ''" color="red" text-color="white">{{ isActiveMsg }} </q-chip>
						</div>
					</div>
					<br/>
					<div class="row">
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
							<div class="text-h6">Personal Information</div>
							<q-separator/>
							<q-input outlined v-model="personalInfo.employee_no" label="EMPLOYEE NO." readonly />
							<q-input outlined v-model="personalInfo.lastname" label="LASTNAME" :label-color="validateInput(personalInfo.lastname)" class="caps"/>
							<q-input outlined v-model="personalInfo.firstname" label="FIRSTNAME"  :label-color="validateInput(personalInfo.firstname)" class="caps"/>
							<q-input outlined v-model="personalInfo.middlename" label="MIDDLENAME"  :label-color="validateInput(personalInfo.middlename)" class="caps"/>
							<q-input outlined v-model="personalInfo.birthdate" mask="date"  label="DATE OF BIRTH"  :label-color="validateInput(personalInfo.birthdate)" >
								<template v-slot:append>
									<i class="far fa-calendar-alt cursor-pointer" >
										<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
											<q-date v-model="personalInfo.birthdate" @input="() => $refs.qDateProxy.hide()" />
											</q-popup-proxy>
										</i>
									</template>
								</q-input>
								<q-select outlined v-model="personalInfo.gender" :options="gender" label="GENDER"  :label-color="validateInput(personalInfo.gender)" />
								<q-select outlined v-model="personalInfo.civilStat" :options="civilStatus" label="CIVIL STATUS" :label-color="validateInput(personalInfo.civilStat)" />
								<q-input outlined v-model="personalInfo.contactNo" label="CONTACT NO."  :label-color="validateInput(personalInfo.contactNo)" class="caps"/>
								<q-input outlined v-model="personalInfo.landline" label="LANDLINE"  :label-color="validateInput(personalInfo.landline)" class="caps"/>
								<q-input outlined v-model="personalInfo.permanentAdd" label="PERMANENT ADDRESS" :label-color="validateInput(personalInfo.permanentAdd)" class="caps"/>
								<q-input outlined v-model="personalInfo.presentAdd" label="PRESENT ADDRESS" :label-color="validateInput(personalInfo.presentAdd)" class="caps"/>
								<br/>
								<div class="text-h6">Person to Notify in case of Emergency</div>
								<q-separator/>
								<q-input outlined v-model="notifyPerson.personToNotif" label="PERSON TO NOTIFY" :label-color="validateInput(notifyPerson.personToNotif)" class="caps"/>
								<q-input outlined v-model="notifyPerson.personToNotifContact" label="PERSON TO NOTIFY CONTACT NUMBER" :label-color="validateInput(notifyPerson.personToNotifContact)" class="caps"/>
								<q-select outlined v-model="notifyPerson.personToNotifRelationship" :options="relationship" label="RELATIONSHIP(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifRelationship)"/>
								<q-input outlined v-model="notifyPerson.personToNotifEmailAdd" label="EMAIL ADDRESS(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifEmailAdd)"/>

							</div>
							<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
								<div class="text-h6">Company Information</div>
								<q-separator/>
								<q-select outlined v-model="companyInfo.company" option-value="id" option-label="name" :options="companyOptions" label="COMPANY" emit-value map-options :label-color="validateInput(companyInfo.company)"/>
								<q-select outlined v-model="companyInfo.is_active"  :options="statuses" label="STATUS" :label-color="companyInfo.is_active == 'ACTIVE' ? 'green' : companyInfo.is_active == 'HOLD' ? 'orange' : 'red'" @input="changeStatus"/>
								<q-input outlined v-model="companyInfo.holdEffectivity" mask="date"  label="HOLD EFFECTIVITY" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdEffectivity)">
									<template v-slot:append>
										<i class="far fa-calendar-alt cursor-pointer" >
											<q-popup-proxy ref="heDateProxy" transition-show="scale" transition-hide="scale">
												<q-date v-model="companyInfo.holdEffectivity"  @input="calendarChange"/>
											</q-popup-proxy>
										</i>
									</template>
								</q-input>
								<q-input outlined v-model="companyInfo.hold_period"  label="HOLD PERIOD" v-show="companyInfo.isHold" readonly :label-color="validateInput(companyInfo.hold_period)"/>
								<q-select outlined v-model="companyInfo.holdReason"  :options="reasons" label="HOLD/SEPARATION REASON" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdReason)"/>
								<q-input outlined v-model="companyInfo.holdDuration" label="HOLD DURATION" class="caps" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdDuration)"/>

								<q-input outlined v-model="companyInfo.holdDate" mask="date"  label="HOLD DATE" v-show="companyInfo.isHold" readonly :label-color="validateInput(companyInfo.holdDate)"/>

								<q-select outlined v-model="companyInfo.position"  :options="positions" label="POSITION" :label-color="validateInput(companyInfo.position)"/>
								<q-select outlined v-model="companyInfo.department_id" option-value="id" option-label="name" :options="departmentslist" label="DEPARTMENT" emit-value map-options :label-color="validateInput(companyInfo.department_id)"/>
								<q-input outlined v-model="personalInfo.email" label="EMAIL ADDRESS" :label-color="validateInput(personalInfo.email)"/>

								<q-input  outlined v-model="companyInfo.dateHired" mask="date" label="DATE HIRED" :label-color="validateInput(companyInfo.dateHired)">
									<template v-slot:append>
										<i class="far fa-calendar-alt cursor-pointer" >
											<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
												<q-date v-model="companyInfo.dateHired" @input="() => $refs.qDateProxy.hide()" />
												</q-popup-proxy>
											</i>
										</template>
									</q-input>

									<q-select outlined v-model="companyInfo.account"  :options="accounts" label="ACCOUNT" :label-color="validateInput(companyInfo.account)"/>
									<q-input outlined v-model="companyInfo.current_section"  label="CURRENT SECTION"  :label-color="validateInput(companyInfo.current_section)"/>
									<q-select outlined v-model="companyInfo.costcenter"  :options="costCenter" label="COST CENTER" :label-color="validateInput(companyInfo.costcenter)"/>
									<q-select outlined v-model="companyInfo.location"  :options="location" label="LOCATION" :label-color="validateInput(companyInfo.location)"/>
									<q-select outlined v-model="companyInfo.rank"  :options="rank" readonly label="RANK" :label-color="validateInput(companyInfo.rank)" @input="employmentStatusOption()"/>
									<q-select outlined v-model="companyInfo.employmentStatus"  :options="empStatus" readonly label="EMPLOYMENT STATUS" :label-color="validateInput(companyInfo.employmentStatus)"/>

									<q-select outlined v-model="companyInfo.immediateHead"  :options="immediateHeadOptions" label="IMMEDIATE HEAD" :label-color="validateInput(companyInfo.immediateHead)"/>
									<q-select outlined v-model="companyInfo.divisionHead"  :options="divisionHeadOptions" label="DIVISION HEAD" :label-color="validateInput(companyInfo.divisionHead)"/>

      </div>
      <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
      	<div class="text-h6">Salary Information</div>
      	<q-separator/>

		<q-select outlined v-model="governmentInfo.payClass" :options="payclass" label="PAY CLASS" :label-color="validateInput(governmentInfo.payClass)" @input="checkPayclass" v-show="ifConsultant"/>
		<q-select outlined v-model="governmentInfo.region" option-value="id" option-label="region_no" :options="regionOptions" label="REGION" emit-value map-options v-show="isMWE" @input="getSector"/>
		<q-select outlined v-model="governmentInfo.sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options v-show="isMWE" @input="getMWEInfo"/>

      	<q-input outlined v-model="salaryInfo.basicSalary"  label="BASIC" :label-color="validateInput(salaryInfo.basicSalary)" readonly  v-show="ifConsultant" @input="calculateTotalSalary()"/>
      	<q-input outlined v-model="salaryInfo.allowance"    label="ALLOWANCE"  :label-color="validateInput(salaryInfo.allowance)" readonly  v-show="ifConsultant" @input="calculateTotalSalary()"/>
      	<q-input outlined v-model="salaryInfo.deminimis"    label="DEMINIMIS"  :label-color="validateInput(salaryInfo.deminimis)" readonly v-show="ifConsultant" @input="calculateTotalSalary()"/>
      	<q-input outlined v-model="salaryInfo.totalSalary"  label="TOTAL SALARY"  :label-color="validateInput(salaryInfo.totalSalary)" readonly v-show="ifConsultant"/>

      	<q-select outlined v-model="salaryInfo.paymentMode"  :options="paymentmode" label="MODE OF PAYMENT" :label-color="validateInput(salaryInfo.paymentMode)" v-show="ifConsultant"/>
      	<q-select outlined v-model="salaryInfo.paymentBank"  :options="payingbank" label="PAYING BANK" :label-color="validateInput(salaryInfo.paymentBank)" v-show="ifConsultant"/>
      	<q-input outlined v-model="salaryInfo.accountNumber" label="ACCOUNT/PAYCARD NO." :label-color="validateInput(salaryInfo.accountNumber)" class="caps" v-show="ifConsultant"/>
		<q-input outlined v-model="salaryInfo.training_allowance" label="TRAINING ALLOWANCE" :label-color="validateInput(salaryInfo.training_allowance)" class="caps" v-show="ifConsultant"/>
		<q-input outlined v-model="salaryInfo.fee" label="Fee" v-show="!ifConsultant" />
          <br/>
          <div class="text-h6">Educational Background</div>
          <q-separator/>
          <q-select outlined v-model="educationBackground.educationAttainment"  :options="educationalOptions" label="EDUCATIONAL ATTAINMENT" @input="checkEduc" :label-color="validateInput(educationBackground.educationAttainment)"/>
          <q-select outlined v-model="educationBackground.course" :options="coursesOptions" label="COURSE" v-show="isCGrad" :label-color="validateInput(educationBackground.course)"/>
          <q-input outlined v-model="educationBackground.school" label="SCHOOL GRADUATED"  v-show="isCGrad" :label-color="validateInput(educationBackground.school)" class="caps"/>
          <q-input outlined v-model="educationBackground.highschool" label="HIGH SCHOOL" v-show="isHSGrad" :label-color="validateInput(educationBackground.highschool)" class="caps"/>
      </div>
      <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md" v-show="isTrainee">
      	<div class="text-h6">Government Information</div>
      	<q-separator/>
      	<q-input outlined v-model="governmentInfo.sssNo" label="SSS #"  :label-color="validateInput(governmentInfo.sssNo)" readonly/>
      	<q-input outlined v-model="governmentInfo.hdmfNo" label="HDMF #" :label-color="validateInput(governmentInfo.hdmfNo)" readonly/>
      	<q-input outlined v-model="governmentInfo.phicNo" label="PHIC #" :label-color="validateInput(governmentInfo.phicNo)" readonly/>
      	<q-input outlined v-model="governmentInfo.tinNo" label="TIN #" :label-color="validateInput(governmentInfo.tinNo)" readonly/>
      	<q-select outlined v-model="governmentInfo.taxStatus"  :options="taxStatus" label="TAX  STATUS" :label-color="validateInput(governmentInfo.taxStatus)" />
      	<q-select outlined v-model="governmentInfo.accountability" label="ACCOUNTABILITY" use-input use-chips multiple :options="accountabilities" counter hint="Total"/>
		<q-input outlined v-model="companyInfo.shift_hours" label="SHIFT HOUR"  :label-color="validateInput(companyInfo.shift_hours)"/>
		<q-select outlined v-model="companyInfo.period_rate"  :options="periodRates" label="PERIOD RATE" :label-color="validateInput(companyInfo.period_rate)"/>
	  </div>
  </div>
</q-form>
</q-card-section>
</q-card>
</q-dialog>
<!-- spinner -->
<q-dialog v-model="spinner" persistent >
	<q-card class="my-card transparent no-shadow">
		<q-card-section >
			<q-spinner-gears color="cyan"  size="5.5em"/>
		</q-card-section>
	</q-card>
</q-dialog>

<!--  ruben 06/02/2024 -->
    <q-dialog v-model="inact_promt" persistent>
      <q-card style="min-width: 350px">
			<q-form ref="vInactiveForm" @submit="proceedInactive">
	        <q-card-section>
	          <div class="text-h6">Inactive Form</div>
	        </q-card-section>
	        <q-card-section class="q-pt-none">

	          <q-input v-model="inact_effectivity_date" class="q-mb-sm" filled type="date" hint="Native date" :rules="[formRules.required]"/>
	          <q-select class="q-mb-sm" label="Payroll Period" transition-show="flip-up" transition-hide="flip-down" filled v-model="inact_payroll_period" :options="['1-15', '16 - 31']" :rules="[formRules.required]"/>
	          <q-select class="q-mb-sm" label="Reason" transition-show="flip-up" transition-hide="flip-down" filled v-model="inact_reason" :options="statuses" :rules="[formRules.required]"/>

	        </q-card-section>

	        <q-card-actions align="right" class="text-primary q-mr-md q-mb-sm">
	          <q-btn rounded color="negative" glossy label="Cancel" v-close-popup @click="cancelInactive" />
	          <q-btn rounded color="primary" glossy label="Submit" type="submit"/>
	        </q-card-actions>
		  </q-form>
      </q-card>
    </q-dialog>
<!--end-->

</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
	data () {
		return {
			isActive:false,
			isActiveMsg: '',

			canDelete:false,
			disabled:false,
			isMWE:false,
			ifConsultant:true,
			isTrainee:true,
			spinner:false,
      dialog: false, //for modal
      maximizedToggle: true, //for modal
      holdPeriodList: [],
      statuses: [],
      reasons: [],
      coursesOptions:[],
      paymentmode: [],
      departmentslist: [],
      payingbank: [],
      payclass: [],
      gender: [],
      civilStatus:[],
      relationship:[],
      accounts:[],
      costCenter:[],
      taxStatus:[],
      location:[],
      rank: [],
	  periodRates:[],
      empStatus:[],
      regionOptions:[],
      sectorOptions:[],
      accountabilities:[],
      positions:[],
      immediateHeadOptions:[],
      divisionHeadOptions:[],
      educationalOptions:[],
      companyOptions:[],
      isHSGrad:false,
      isCGrad:false,
      personalInfo:{
      	id:null,
      	employee_no:null,
      	firstname:null,
      	middlename:null,
      	lastname:null,
      	birthdate:null,
      	gender:null,
      	civilStat:null,
      	contactNo:null,
      	landline:null,
      	permanentAdd:null,
      	presentAdd:null,
      	email:null,
      },
      notifyPerson:{
      	personToNotif:null,
      	personToNotifContact:null,
      	personToNotifRelationship:null,
      	personToNotifEmailAdd:null
      },
      educationBackground:{
      	educationAttainment:null,
      	course:null,
      	school:null,
      	highschool:null,
      },
      companyInfo:{
      	is_active:null,
      	isHold:false,
      	company:null,
      	department:null,
      	department_id:null,
      	dateHired:null,
      	position:null,
      	account:null,
      	costcenter:null,
			current_section:null,
      	location:null,
      	rank:null,
      	employmentStatus:null,
      	hold_period:null,
      	holdReason:null,
      	holdDate:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      	holdEffectivity:null,
      	holdDuration:null,
      	immediateHead:null,
      	divisionHead:null,
		shift_hours:null,
		period_rate:null,
      },
      previousCompany:{
      	companyName:null,
      	workDuration:null
      },
      salaryInfo:{
      	basicSalary:null,
		training_allowance:null,
      	allowance:null,
      	deminimis:null,
      	totalSalary:null,
      	paymentMode:null,
      	paymentBank:null,
      	accountNumber:null,
      	fee:null
      },
      healthInfo:{
      	healthCardName:null,
      	healthCardNo:null,
      	insuranceName:null,
      	insuranceNo:null
      },
      governmentInfo:{
      	sssNo:null,
      	hdmfNo:null,
      	phicNo:null,
      	tinNo:null,
      	taxStatus:null,
      	payClass:null,
      	region:null,
      	sector:null,
      	accountability:[],
      },
      // ruben 06/02/2024
      inact_promt: false,
      inact_effectivity_date: '',
      inact_payroll_period: '',
      inact_reason: '',
  }
},
mounted(){
	this.eventHub.$on('EditEmployee', this.editEmployee);
	this.getDropdownProperties()
},


methods:{

	validateInput:function(value){
		if (value == null || value == '' || value == 'NULL' || value == 'null' || value == "0000-00-00" || value == 0){
			return 'red';
		} else {
			return 'green';
		}
	},

	editEmployee : function(payload){

		if(payload.employee.row.is_active.toUpperCase() == 'ACTIVE'){
			this.isActive = true;
		}else{
			this.isActive = false;
		}

		this.governmentInfo.accountability = [];
		this.personalInfo.id = payload.employee.row.id;
		this.personalInfo.employee_no = payload.employee.row.employee_id;
		this.personalInfo.firstname = payload.employee.row.firstname;
		this.personalInfo.middlename = payload.employee.row.middlename;
		this.personalInfo.lastname = payload.employee.row.lastname;
		this.personalInfo.email = payload.employee.row.email;

		this.personalInfo.birthdate = payload.employee.row.date_of_birth;
		this.personalInfo.gender = payload.employee.row.gender;
		this.personalInfo.civilStat = payload.employee.row.civil_status;
		this.personalInfo.contactNo = payload.employee.row.mobile_number;
		this.personalInfo.permanentAdd = payload.employee.row.address;
		this.personalInfo.presentAdd = payload.employee.row.city_address;

		this.notifyPerson.personToNotif = payload.employee.row.person_to_notif;
		this.notifyPerson.personToNotifContact = payload.employee.row.person_to_notif_contact;
		this.notifyPerson.personToNotifRelationship = payload.employee.row.relation_to_person_to_notif;
		this.notifyPerson.personToNotifEmailAdd = payload.employee.row.email_of_person_to_notif;

		this.educationBackground.educationAttainment = payload.employee.row.educational_attainment;
		this.educationBackground.course = payload.employee.row.college_course;
		this.educationBackground.school = payload.employee.row.college_school;
		this.educationBackground.highschool = payload.employee.row.high_school;

		if(this.educationBackground.educationAttainment == 'HIGH SCHOOL'){
			this.isHSGrad = true;
			this.isCGrad = false;
		}else if(this.educationBackground.educationAttainment == 'COLLEGE'){
			this.isHSGrad = false;
			this.isCGrad = true;
		}else{
			this.isHSGrad = false;
			this.isCGrad = false;
		}

		this.companyInfo.is_active = payload.employee.row.is_active.toUpperCase();
		if(this.companyInfo.is_active == 'HOLD'){
			this.companyInfo.isHold = true
			this.canDelete = true
		}else{
			this.companyInfo.isHold = false
			this.canDelete = false
		}

    this.companyInfo.company = payload.employee.row.company;
    this.companyInfo.department = payload.employee.row.department;
    this.companyInfo.department_id = payload.employee.row.department_id;
    this.companyInfo.dateHired = payload.employee.row.date_hired;
    this.companyInfo.position = payload.employee.row.position;
    this.companyInfo.account = payload.employee.row.account;
	 this.companyInfo.current_section = payload.employee.row.c_section;
    this.companyInfo.costcenter = payload.employee.row.cost_center;
    this.companyInfo.location = payload.employee.row.location;
    this.companyInfo.rank = payload.employee.row.rank;
    this.companyInfo.employmentStatus = payload.employee.row.employment_status;

    if(this.companyInfo.rank === 'TRAINEE'){
    	this.isTrainee = false;

    }else{
    	this.isTrainee = true;
    }


    this.companyInfo.hold_period = payload.employee.row.hold_period;
    this.companyInfo.holdReason = payload.employee.row.hold_reason;
    this.companyInfo.holdDate = payload.employee.row.hold_date;
    this.companyInfo.holdEffectivity = payload.employee.row.hold_effectivity;
    this.companyInfo.holdDuration = payload.employee.row.hold_duration;
    this.companyInfo.immediateHead = payload.employee.row.immediate_head;
    this.companyInfo.divisionHead = payload.employee.row.division_head;
	this.companyInfo.shift_hours = payload.employee.row.shift_hours;
	this.companyInfo.period_rate = payload.employee.row.period_rate;

    this.previousCompany.companyName = payload.employee.row.previous_company;
    this.previousCompany.workDuration = payload.employee.row.previous_company_length_of_stay;

    this.salaryInfo.basicSalary = payload.employee.row.basic_salary;
    this.salaryInfo.allowance = payload.employee.row.allowance;
	this.salaryInfo.training_allowance = payload.employee.row.training_allowance;
    this.salaryInfo.deminimis = payload.employee.row.deminimis;
    this.salaryInfo.totalSalary = payload.employee.row.total_salary;
    this.salaryInfo.paymentMode = payload.employee.row.payment_mode;
    this.salaryInfo.paymentBank = payload.employee.row.paying_bank;
    this.salaryInfo.accountNumber = payload.employee.row.account_number;

    this.healthInfo.healthCardName = payload.employee.row.healthcard_name;
    this.healthInfo.healthCardNo = payload.employee.row.healthcard_number;
    this.healthInfo.insuranceName = payload.employee.row.insurance_name;
    this.healthInfo.insuranceNo = payload.employee.row.insurance_number;

    this.governmentInfo.sssNo = payload.employee.row.sss_number;
    this.governmentInfo.hdmfNo = payload.employee.row.hdmf_number;
    this.governmentInfo.phicNo = payload.employee.row.philhealth_number;
    this.governmentInfo.tinNo = payload.employee.row.tin_number;
    this.governmentInfo.bir_number = payload.employee.row.bir_number;
    this.governmentInfo.taxStatus = payload.employee.row.tax_status;
    this.governmentInfo.payClass = payload.employee.row.pay_class;
     // this.governmentInfo.accountability = payload.employee.row.accountability;
     var type = this.governmentInfo.payClass

     if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
     	this.disabled = true
     }else{
     	this.disabled = false
     }

     var myStr = payload.employee.row.accountability;
     var strArray = myStr.split(",");

     for(var i = 0; i < strArray.length; i++){
     	this.governmentInfo.accountability.push(strArray[i]);
     }
     this.dialog = payload.stat;
 },


 onSubmit : function(){

 	let formData = new FormData();

 	formData.append("id", this.personalInfo.id);
 	formData.append("employee_id", this.personalInfo.employee_no);
 	formData.append("firstname", this.personalInfo.firstname);
 	formData.append("middlename", this.personalInfo.middlename);
 	formData.append("lastname", this.personalInfo.lastname);
 	formData.append("email", this.personalInfo.email);
 	formData.append("date_of_birth", this.personalInfo.birthdate);

 	formData.append("gender", this.personalInfo.gender);
 	formData.append("civil_status", this.personalInfo.civilStat);
 	formData.append("mobile_number", this.personalInfo.contactNo);
 	formData.append("landline_number", this.personalInfo.email);
 	formData.append("address", this.personalInfo.permanentAdd);
 	formData.append("city_address", this.personalInfo.presentAdd);
	formData.append("mobile_number", this.personalInfo.contactNo);

 	formData.append("person_to_notif", this.notifyPerson.personToNotif);
 	formData.append("person_to_notif_contact", this.notifyPerson.personToNotifContact);
 	formData.append("relation_to_person_to_notif", this.notifyPerson.personToNotifRelationship);
 	formData.append("email_of_person_to_notif", this.notifyPerson.personToNotifEmailAdd);

 	formData.append("educational_attainment", this.educationBackground.educationAttainment);
 	formData.append("college_course", this.educationBackground.course);
 	formData.append("college_school", this.educationBackground.school);
 	formData.append("high_school", this.educationBackground.highschool);

 	formData.append("date_hired", this.companyInfo.dateHired);
 	formData.append("company", this.companyInfo.company);
 	formData.append("department", this.companyInfo.department_id);
 	formData.append("position", this.companyInfo.position);
 	formData.append("account", this.companyInfo.account);
	formData.append("current_section", this.companyInfo.current_section);
 	formData.append("cost_center", this.companyInfo.costcenter);
 	formData.append("location", this.companyInfo.location);
 	formData.append("rank", this.companyInfo.rank);
 	formData.append("employment_status", this.companyInfo.employmentStatus);

 	formData.append("is_active", this.companyInfo.is_active);
 	formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
 	formData.append("hold_period", this.companyInfo.hold_period);
 	formData.append("hold_reason", this.companyInfo.holdReason);
 	formData.append("hold_duration", this.companyInfo.holdDuration);
 	formData.append("hold_date", this.companyInfo.holdDate);


 	formData.append("immediate_head", this.companyInfo.immediateHead);
 	formData.append("division_head", this.companyInfo.divisionHead);

 	formData.append("previous_company", this.previousCompany.companyName);
 	formData.append("previous_company_length_of_stay", this.previousCompany.workDuration);

	formData.append("training_allowance", this.salaryInfo.training_allowance);
 	formData.append("payment_mode", this.salaryInfo.paymentMode);
 	formData.append("paying_bank", this.salaryInfo.paymentBank);
 	formData.append("account_number", this.salaryInfo.accountNumber);

 	formData.append("healthcard_name", this.healthInfo.healthCardName);
 	formData.append("healthcard_number", this.healthInfo.healthCardNo);
 	formData.append("insurance_name", this.healthInfo.insuranceName);
 	formData.append("insurance_number", this.healthInfo.insuranceNo);

 	formData.append("bir_number", this.governmentInfo.tinNo);
 	formData.append("tax_status", this.governmentInfo.taxStatus);
 	formData.append("pay_class", this.governmentInfo.payClass);
 	formData.append("regionID", this.governmentInfo.region);
 	formData.append("sectorID", this.governmentInfo.sector);
 	formData.append("accountability", this.governmentInfo.accountability);
	formData.append("shift_hours", this.companyInfo.shift_hours);
	formData.append("period_rate", this.companyInfo.period_rate);

 	axios.create({
 		baseURL : this.apiUrl,
 		headers : {'Authorization' : `Bearer ${this.token}`}
 	}).post('/employee/update',formData)
 	.then((res) => {

 		setTimeout(() => {
 			location.reload();
 			this.dialog = false
 		},500)
 	});

 },
 changeStatus:function(){
 	if(this.companyInfo.is_active == 'HOLD'){
 		this.companyInfo.isHold = true
 	}else{
 		this.companyInfo.isHold = false
 	}
 },

 deleteEmployee:function(){
	 this.inact_promt = true
 },

 proceedInactive(){
	let formData = new FormData();
	formData.append("id", this.personalInfo.id);
	formData.append("inact_date",this.inact_effectivity_date);
	formData.append("inact_period",this.inact_payroll_period);
	formData.append("inact_reason",this.inact_reason);
	axios.create({
	  baseURL : this.apiUrl,
	  headers : {'Authorization' : `Bearer ${this.token}`}
	}).post('/employee/inactive',formData).then((res) => {
  		setTimeout(() => {
				location.reload();
				this.inact_promt = false
			},500)
	})
 },

 getDropdownProperties:function(){
 	axios.create({
 		baseURL : this.apiUrl,
 		headers : {'Authorization' : `Bearer ${this.token}`}
 	}).get('/employee/dropdown/properties')
 	.then((res) => {
 		this.gender = res.data.data.gender
 		this.paymentmode = res.data.data.paymentMode
 		this.payingbank = res.data.data.bank
 		this.payclass = res.data.data.payClass
 		this.holdPeriodList = res.data.data.holdPeriodList
 		this.departmentslist = res.data.data.department
 		this.statuses = res.data.data.status
 		this.rank = res.data.data.rank
 		this.positions = res.data.data.positions
 		this.accounts = res.data.data.account
 		this.costCenter = res.data.data.costCenter
 		this.location = res.data.data.location
 		this.civilStatus  = res.data.data.civilStatus
 		this.relationship = res.data.data.relationship
 		this.taxStatus = res.data.data.taxStatus
 		this.empStatus = res.data.data.empStatus
 		this.companyOptions = res.data.data.companies
 		this.immediateHeadOptions = res.data.data.immediatehead
 		this.divisionHeadOptions = res.data.data.divisionhead
 		this.coursesOptions = res.data.data.courses

 		this.accountabilities = res.data.data.accountability
 		this.educationalOptions = res.data.data.educAttaintment

 		this.reasons = res.data.data.reasons
		this.periodRates = res.data.data.periodRate
 	});
 },

 checkEduc:function(){
 	var educ = this.educationBackground.educationAttainment
 	if(educ == 'HIGH SCHOOL'){
 		this.isHSGrad = true;
 		this.isCGrad = false;
 	}else{
 		this.isHSGrad = false;
 		this.isCGrad = true;
 	}
 },

 calendarChange:function(){
  this.$refs.heDateProxy.hide() //close calendar
  var date = this.companyInfo.holdEffectivity.substring(8, 10)
  if(date > 10 && date < 26){
  	this.companyInfo.hold_period = '11 to 25'
  }else{
  	this.companyInfo.hold_period = '26 to 10'
  }
},

calculateTotalSalary:function(){
	var total =  parseInt(this.salaryInfo.basicSalary) + parseInt(this.salaryInfo.allowance) + parseInt(this.salaryInfo.deminimis);
	if(isNaN(total)){
		total = 0;
	}
	this.salaryInfo.totalSalary = parseInt(total);
},

checkPayclass:function(){
	var type = this.governmentInfo.payClass

	if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
		this.isMWE = true
		this.displayRegions()
		this.disabled = true
	}else{
		this.disabled = false
		this.isMWE = false
		this.salaryInfo.basicSalary = 0
		this.salaryInfo.allowance = 0
		this.salaryInfo.deminimis = 0
		this.salaryInfo.totalSalary = 0
	}
},

displayRegions: function() {
	axios.create({
		baseURL: this.apiUrl,
		headers: {
			'Authorization': `Bearer ${this.token}`
		}})
	.get(`/admin/regions`)
	.then((res) => {

		this.regionOptions = res.data.data;
		setTimeout(() => {
			this.spinner = false
		},500)
	})
},

getSector:function(){
	axios.create({
		baseURL: this.apiUrl,
		headers: {
			'Authorization': `Bearer ${this.token}`
		}})
	.get(`/admin/region/sectors/${this.governmentInfo.region}`)
	.then((res) => {
		this.governmentInfo.sector = ''
		this.salaryInfo.basicSalary = ''
		this.salaryInfo.allowance = ''
		this.salaryInfo.deminimis = ''
		this.salaryInfo.totalSalary = ''
		this.sectorOptions = res.data.data;
		setTimeout(() => {
			this.spinner = false
		},500)
	})
},

getMWEInfo:function(){
	axios.create({
		baseURL: this.apiUrl,
		headers: {
			'Authorization': `Bearer ${this.token}`
		}})
	.get(`/admin/check/mwe/${this.governmentInfo.region}/${this.governmentInfo.sector}/${this.governmentInfo.payClass}`)
	.then((res) => {
		if(res.data.data != null){
			this.salaryInfo.basicSalary = res.data.data.basic
			this.salaryInfo.allowance = res.data.data.allowance
			this.salaryInfo.deminimis = res.data.data.deminimis
			this.salaryInfo.totalSalary = res.data.data.total
		}else{
			this.salaryInfo.basicSalary = 0
			this.salaryInfo.allowance = 0
			this.salaryInfo.deminimis = 0
			this.salaryInfo.totalSalary = 0
		}

	})
},

employmentStatusOption:function(){
	var ranks = this.companyInfo.rank.toLowerCase();
	if(ranks === 'trainee' || ranks === 'consultant'){
		this.companyInfo.employmentStatus = this.companyInfo.rank
	}

	if(ranks === 'trainee'){
		this.isTrainee = false;
		var traineeID = this.personalInfo.employee_no.replace('SGCC','TRAI')
		this.personalInfo.employee_no = traineeID
	}else{
		this.isTrainee = true;
		var empID = this.personalInfo.employee_no.replace('TRAI','SGCC')
		this.personalInfo.employee_no = empID
	}

	if(ranks === 'consultant'){
		this.ifConsultant = false;
		this.isTrainee = false;
	}else{
		this.ifConsultant = true;
	}
},


  deactToogle(){
      if(this.isActive == false){
        this.inact_promt = true
      }
  },

  cancelInactive(){
    this.isActive = true
  },


deactivateEmployee:function(){
	if(!this.isActive){
		this.isActiveMsg = 'This employee will no longer show on the masterlist';
	}else{
		this.isActiveMsg = '';
	}

	let formData = new FormData();
	formData.append("id", this.personalInfo.id);
	formData.append("status",this.isActive);
	axios.create({
		baseURL : this.apiUrl,
		headers : {'Authorization' : `Bearer ${this.token}`}
	}).post('/employee/status',formData)
	.then((res) => {
		console.log(res.data)
    this.inact_promt = false
	});
}

}
}
</script>
