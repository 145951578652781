<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-10 q-pa-md">
                    <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                       <q-card-section class="text-center">
                         <q-img :src="require('../../assets/header_pic.png')" basic width="400px" class="items-center" />
                         <p class="text-overline">15th Floor Raffles Corporate Center, Emerald Ave. Ortigas Center, Pasig</p>
                       </q-card-section>
                       <q-separator/>
                        <q-card-section>
                           <p class="text-h6">Reason/s for Resignation.</p>
                           <div class="row">
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                 <q-chip>
                                   <q-avatar color="blue" text-color="white">{{ reasons.betterCompensationPackage }}</q-avatar>
                                   Better compensation package
                                 </q-chip>
                                 <q-chip>
                                   <q-avatar color="blue" text-color="white">{{ reasons.careerChange }}</q-avatar>
                                   Career change
                                 </q-chip>
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-chip>
                                  <q-avatar color="blue" text-color="white">{{ reasons.localEmployment }}</q-avatar>
                                  Local Employment
                                </q-chip>
                                <q-chip>
                                  <q-avatar color="blue" text-color="white">{{ reasons.distanceProximity }}</q-avatar>
                                  Distance or Proximity
                                </q-chip>
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-chip>
                                  <q-avatar color="blue" text-color="white">{{ reasons.overseasEmployment }}</q-avatar>
                                  Overseas Employment
                                </q-chip>
                                <q-chip>
                                  <q-avatar color="blue" text-color="white">{{ reasons.personalReason }}</q-avatar>
                                  Family and/or personal reasons
                                </q-chip>
                             </div>
                             <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                                <q-chip>
                                  <q-avatar color="blue" text-color="white">{{ reasons.healthIssues }}</q-avatar>
                                  Health Issues
                                </q-chip>
                             </div>
                          </div>
                          <q-btn flat color="blue" label="Others" @click="otherAnswer(reasons.otherReason,'Reason for Resignation Other Answer')"/>
                       </q-card-section>
                       <q-separator/>
                       <q-card-section>
                          <p class="text-h6">Tasks, Roles and Responsibilities</p>
                          <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                             <q-markup-table separator="none" flat>
                                <thead>
                                <tr>
                                  <th class="text-center"></th>
                                  <th class="text-center">Strongly Disagree</th>
                                  <th class="text-center">Disagree</th>
                                  <th class="text-center">Agree</th>
                                  <th class="text-center">Strongly Agree</th>
                                </tr>
                              </thead>
                                <tbody>
                                  <tr>
                                    <td class="text-left">Job was challenging</td>
                                    <td class="text-center">{{ tasks.jobWasChallenging.hasOwnProperty("1") ? tasks.jobWasChallenging['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.jobWasChallenging.hasOwnProperty("2") ? tasks.jobWasChallenging['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.jobWasChallenging.hasOwnProperty("3") ? tasks.jobWasChallenging['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.jobWasChallenging.hasOwnProperty("4") ? tasks.jobWasChallenging['4'] : 0}}%</td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Sufficient opportunities for advancement </td>
                                    <td class="text-center">{{ tasks.sufficientOpportunity.hasOwnProperty("1") ? tasks.sufficientOpportunity['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientOpportunity.hasOwnProperty("2") ? tasks.sufficientOpportunity['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientOpportunity.hasOwnProperty("3") ? tasks.sufficientOpportunity['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientOpportunity.hasOwnProperty("4") ? tasks.sufficientOpportunity['4'] : 0}}%</td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Workload was manageable</td>
                                    <td class="text-center">{{ tasks.workloadManageable.hasOwnProperty("1") ? tasks.workloadManageable['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.workloadManageable.hasOwnProperty("2") ? tasks.workloadManageable['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.workloadManageable.hasOwnProperty("3") ? tasks.workloadManageable['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.workloadManageable.hasOwnProperty("4") ? tasks.workloadManageable['4'] : 0}}%</td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Sufficient resources and staff were available</td>
                                    <td class="text-center">{{ tasks.sufficientResources.hasOwnProperty("1") ? tasks.sufficientResources['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientResources.hasOwnProperty("2") ? tasks.sufficientResources['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientResources.hasOwnProperty("3") ? tasks.sufficientResources['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.sufficientResources.hasOwnProperty("4") ? tasks.sufficientResources['4'] : 0}}%</td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Skills were effectively used</td>
                                    <td class="text-center">{{ tasks.skillWereEffective.hasOwnProperty("1") ? tasks.skillWereEffective['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.skillWereEffective.hasOwnProperty("2") ? tasks.skillWereEffective['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.skillWereEffective.hasOwnProperty("3") ? tasks.skillWereEffective['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.skillWereEffective.hasOwnProperty("4") ? tasks.skillWereEffective['4'] : 0}}%</td>
                                  </tr>
                                  <tr>
                                    <td class="text-left">Access to adequate training and development programs </td>
                                    <td class="text-center">{{ tasks.accessToAdequate.hasOwnProperty("1") ? tasks.accessToAdequate['1'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.accessToAdequate.hasOwnProperty("2") ? tasks.accessToAdequate['2'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.accessToAdequate.hasOwnProperty("3") ? tasks.accessToAdequate['3'] : 0}}%</td>
                                    <td class="text-center">{{ tasks.accessToAdequate.hasOwnProperty("4") ? tasks.accessToAdequate['4'] : 0}}%</td>
                                  </tr>
                                </tbody>
                              </q-markup-table>
                              <q-btn flat color="blue" label="Others" @click="otherAnswer(tasks.whatCanBeImproved,'Tasks Roles and Responsibilities Other Answer')"/>
                          </div>
                      </q-card-section>
                      <q-separator/>
                      <q-card-section>
                        <p class="text-h6">Wages & Benefits</p>
                        <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                           <q-markup-table separator="none" flat>
                              <thead>
                              <tr>
                                 <th class="text-center"></th>
                                 <th class="text-center">Strongly Disagree</th>
                                 <th class="text-center">Disagree</th>
                                 <th class="text-center">Agree</th>
                                 <th class="text-center">Strongly Agree</th>
                              </tr>
                             </thead>
                              <tbody>
                                 <tr>
                                   <td class="text-left">Adequate salary in relation to responsibilities</td>
                                   <td class="text-center">{{ wages.adequateSalary.hasOwnProperty("1") ? wages.adequateSalary['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.adequateSalary.hasOwnProperty("2") ? wages.adequateSalary['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.adequateSalary.hasOwnProperty("3") ? wages.adequateSalary['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.adequateSalary.hasOwnProperty("4") ? wages.adequateSalary['4'] : 0}}%</td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Wages were paid on time</td>
                                   <td class="text-center">{{ wages.wagesPaid.hasOwnProperty("1") ? wages.wagesPaid['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.wagesPaid.hasOwnProperty("2") ? wages.wagesPaid['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.wagesPaid.hasOwnProperty("3") ? wages.wagesPaid['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.wagesPaid.hasOwnProperty("4") ? wages.wagesPaid['4'] : 0}}%</td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Company benefits were good </td>
                                   <td class="text-center">{{ wages.companyBenefits.hasOwnProperty("1") ? wages.companyBenefits['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.companyBenefits.hasOwnProperty("2") ? wages.companyBenefits['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.companyBenefits.hasOwnProperty("3") ? wages.companyBenefits['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.companyBenefits.hasOwnProperty("4") ? wages.companyBenefits['4'] : 0}}%</td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Work-life balance promoted and practiced</td>
                                   <td class="text-center">{{ wages.workLikeBalance.hasOwnProperty("1") ? wages.workLikeBalance['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.workLikeBalance.hasOwnProperty("2") ? wages.workLikeBalance['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.workLikeBalance.hasOwnProperty("3") ? wages.workLikeBalance['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.workLikeBalance.hasOwnProperty("4") ? wages.workLikeBalance['4'] : 0}}%</td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Merit increase reflects performance and contributions to the company</td>
                                   <td class="text-center">{{ wages.meritIncrease.hasOwnProperty("1") ? wages.meritIncrease['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.meritIncrease.hasOwnProperty("2") ? wages.meritIncrease['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.meritIncrease.hasOwnProperty("3") ? wages.meritIncrease['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.meritIncrease.hasOwnProperty("4") ? wages.meritIncrease['4'] : 0}}%</td>
                                 </tr>
                                 <tr>
                                   <td class="text-left">Highly satisfied with employee services (Pantry, Clinic & Gym)</td>
                                   <td class="text-center">{{ wages.highlySatisfied.hasOwnProperty("1") ? wages.highlySatisfied['1'] : 0}}%</td>
                                   <td class="text-center">{{ wages.highlySatisfied.hasOwnProperty("2") ? wages.highlySatisfied['2'] : 0}}%</td>
                                   <td class="text-center">{{ wages.highlySatisfied.hasOwnProperty("3") ? wages.highlySatisfied['3'] : 0}}%</td>
                                   <td class="text-center">{{ wages.highlySatisfied.hasOwnProperty("4") ? wages.highlySatisfied['4'] : 0}}%</td>
                                 </tr>
                              </tbody>
                             </q-markup-table>
                             <q-btn flat color="blue" label="Others" @click="otherAnswer(wages.whatImprovement,'Wages & Benefits Other Answer')"/>
                        </div>
                    </q-card-section>
                    <q-separator/>
                    <q-card-section>
                      <p class="text-h6">The Organization</p>
                      <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                         <q-markup-table separator="none" flat>
                            <thead>
                            <tr>
                               <th class="text-center"></th>
                               <th class="text-center">Strongly Disagree</th>
                               <th class="text-center">Disagree</th>
                               <th class="text-center">Agree</th>
                               <th class="text-center">Strongly Agree</th>
                            </tr>
                           </thead>
                            <tbody>
                               <tr>
                                 <td class="text-left">Favorable and encouraging workplace</td>
                                 <td class="text-center">{{ organization.favorable.hasOwnProperty("1") ? organization.favorable['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.favorable.hasOwnProperty("2") ? organization.favorable['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.favorable.hasOwnProperty("3") ? organization.favorable['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.favorable.hasOwnProperty("4") ? organization.favorable['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Appropriate tools and equipment for the tasks</td>
                                 <td class="text-center">{{ organization.appropriateTools.hasOwnProperty("1") ? organization.appropriateTools['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.appropriateTools.hasOwnProperty("2") ? organization.appropriateTools['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.appropriateTools.hasOwnProperty("3") ? organization.appropriateTools['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.appropriateTools.hasOwnProperty("4") ? organization.appropriateTools['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Fosters positive relationships</td>
                                 <td class="text-center">{{ organization.fosterPositive.hasOwnProperty("1") ? organization.fosterPositive['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fosterPositive.hasOwnProperty("2") ? organization.fosterPositive['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fosterPositive.hasOwnProperty("3") ? organization.fosterPositive['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fosterPositive.hasOwnProperty("4") ? organization.fosterPositive['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Sufficient staff to cover workload</td>
                                 <td class="text-center">{{ organization.sufficientStaff.hasOwnProperty("1") ? organization.sufficientStaff['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.sufficientStaff.hasOwnProperty("2") ? organization.sufficientStaff['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.sufficientStaff.hasOwnProperty("3") ? organization.sufficientStaff['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.sufficientStaff.hasOwnProperty("4") ? organization.sufficientStaff['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Effective internal communication</td>
                                 <td class="text-center">{{ organization.effectiveEternal.hasOwnProperty("1") ? organization.effectiveEternal['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.effectiveEternal.hasOwnProperty("2") ? organization.effectiveEternal['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.effectiveEternal.hasOwnProperty("3") ? organization.effectiveEternal['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.effectiveEternal.hasOwnProperty("4") ? organization.effectiveEternal['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Respectful and harassment-free</td>
                                 <td class="text-center">{{ organization.respectfulandHarrassment.hasOwnProperty("1") ? organization.respectfulandHarrassment['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.respectfulandHarrassment.hasOwnProperty("2") ? organization.respectfulandHarrassment['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.respectfulandHarrassment.hasOwnProperty("3") ? organization.respectfulandHarrassment['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.respectfulandHarrassment.hasOwnProperty("4") ? organization.respectfulandHarrassment['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Fair treatment without discrimination</td>
                                 <td class="text-center">{{ organization.fairTreatment.hasOwnProperty("1") ? organization.fairTreatment['1'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fairTreatment.hasOwnProperty("2") ? organization.fairTreatment['2'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fairTreatment.hasOwnProperty("3") ? organization.fairTreatment['3'] : 0}}%</td>
                                 <td class="text-center">{{ organization.fairTreatment.hasOwnProperty("4") ? organization.fairTreatment['4'] : 0}}%</td>
                               </tr>
                            </tbody>
                           </q-markup-table>
                           <q-btn flat color="blue" label="Others" @click="otherAnswer(organization.whatDoYouThink,'The Organization Other Answer')"/>
                      </div>
                     </q-card-section>
                     <q-separator/>
                     <q-card-section>
                      <p class="text-h6">Management (Manager / Supervisor / TL)</p>
                      <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
                         <q-markup-table separator="none" flat>
                            <thead>
                            <tr>
                               <th class="text-center"></th>
                               <th class="text-center">Strongly Disagree</th>
                               <th class="text-center">Disagree</th>
                               <th class="text-center">Agree</th>
                               <th class="text-center">Strongly Agree</th>
                            </tr>
                           </thead>
                            <tbody>
                               <tr>
                                 <td class="text-left">Sufficient knowledge of the job</td>
                                 <td class="text-center">{{ management.sufficientKnowledge.hasOwnProperty("1") ? management.sufficientKnowledge['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.sufficientKnowledge.hasOwnProperty("2") ? management.sufficientKnowledge['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.sufficientKnowledge.hasOwnProperty("3") ? management.sufficientKnowledge['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.sufficientKnowledge.hasOwnProperty("4") ? management.sufficientKnowledge['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Gives fair and equal treatment</td>
                                 <td class="text-center">{{ management.givesFair.hasOwnProperty("1") ? management.givesFair['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.givesFair.hasOwnProperty("2") ? management.givesFair['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.givesFair.hasOwnProperty("3") ? management.givesFair['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.givesFair.hasOwnProperty("4") ? management.givesFair['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Offers and promotes ways to develop</td>
                                 <td class="text-center">{{ management.offerAndPromote.hasOwnProperty("1") ? management.offerAndPromote['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.offerAndPromote.hasOwnProperty("2") ? management.offerAndPromote['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.offerAndPromote.hasOwnProperty("3") ? management.offerAndPromote['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.offerAndPromote.hasOwnProperty("4") ? management.offerAndPromote['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Provides constructive feedback</td>
                                 <td class="text-center">{{ management.provideConstructive.hasOwnProperty("1") ? management.provideConstructive['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.provideConstructive.hasOwnProperty("2") ? management.provideConstructive['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.provideConstructive.hasOwnProperty("3") ? management.provideConstructive['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.provideConstructive.hasOwnProperty("4") ? management.provideConstructive['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Receptive to open communication</td>
                                 <td class="text-center">{{ management.receptive.hasOwnProperty("1") ? management.receptive['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.receptive.hasOwnProperty("2") ? management.receptive['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.receptive.hasOwnProperty("3") ? management.receptive['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.receptive.hasOwnProperty("4") ? management.receptive['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Maintains a professional relationship</td>
                                 <td class="text-center">{{ management.maintain.hasOwnProperty("1") ? management.maintain['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.maintain.hasOwnProperty("2") ? management.maintain['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.maintain.hasOwnProperty("3") ? management.maintain['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.maintain.hasOwnProperty("4") ? management.maintain['4'] : 0}}%</td>
                               </tr>
                               <tr>
                                 <td class="text-left">Recognizes and acknowledges achievements</td>
                                 <td class="text-center">{{ management.recognizes.hasOwnProperty("1") ? management.recognizes['1'] : 0}}%</td>
                                 <td class="text-center">{{ management.recognizes.hasOwnProperty("2") ? management.recognizes['2'] : 0}}%</td>
                                 <td class="text-center">{{ management.recognizes.hasOwnProperty("3") ? management.recognizes['3'] : 0}}%</td>
                                 <td class="text-center">{{ management.recognizes.hasOwnProperty("4") ? management.recognizes['4'] : 0}}%</td>
                               </tr>
                            </tbody>
                           </q-markup-table>
                           <q-btn flat color="blue" label="Others" @click="otherAnswer(management.yourSuggestions,'Management Other Answer')"/>
                      </div>
                     </q-card-section>
                     <q-card-section class="text-center">
                     <q-btn color="primary"  @click="otherAnswer(remarks,'Remarks')" label="Remarks Answer"/>
                     </q-card-section>
                    </q-card-section>
                </q-card>
            </div>
        </div>
        <ExitInterviewDialog></ExitInterviewDialog>
    </div>
</template>

<script>

import axios from 'axios'
import moment from "moment";
import ExitInterviewDialog from './ExitInterviewDialog';
export default{
   components: {
     "ExitInterviewDialog": ExitInterviewDialog,
   },
    data(){
        return{
            reasons:{
               betterCompensationPackage: 0,
               localEmployment: 0,
               overseasEmployment: 0,
               healthIssues: 0,
               careerChange: 0,
               distanceProximity: 0,
               personalReason: 0,
               otherReason: '',
            },

            //Tasks, Roles and Responsibilities
            tasks:{
               jobWasChallenging: '',
               sufficientOpportunity: '',
               workloadManageable: '',
               sufficientResources: '',
               skillWereEffective: '',
               accessToAdequate: '',
               whatCanBeImproved: '',
            },

            //Wages & Benefits
            wages:{
               adequateSalary: '',
               wagesPaid: '',
               companyBenefits: '',
               workLikeBalance: '',
               meritIncrease: '',
               highlySatisfied: '',
               whatImprovement: '',
            },

            //The Organization
            organization:{
               favorable: '',
               appropriateTools: '',
               fosterPositive: '',
               sufficientStaff: '',
               effectiveEternal: '',
               respectfulandHarrassment: '',
               fairTreatment: '',
               whatDoYouThink: '',
            },

            //Management (Manager / Supervisor / TL)
            management:{
               sufficientKnowledge: '',
               givesFair: '',
               offerAndPromote: '',
               provideConstructive: '',
               receptive: '',
               maintain: '',
               recognizes: '',
               yourSuggestions: '',
            },

            remarks:[]

        }
    },


    methods: {
        getReport:function(){
           axios.create({
           baseURL : this.apiUrl,
           headers : {'Authorization' : `Bearer ${this.token}`}
          }).get('/employee/interview/report')
          .then((res) => {
             this.reasons = res.data.data.reason
             this.tasks = res.data.data.task
             this.wages = res.data.data.wages
             this.organization = res.data.data.organization
             this.management = res.data.data.management
             this.remarks = res.data.data.remarks
         });
        },

        otherAnswer:function(otherReason,title){
           this.eventHub.$emit('showOtherAnswer', { stat: true,reasons: otherReason,title:title});
        }
    },

    mounted(){
      this.getReport()
    }
}
</script>
