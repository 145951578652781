<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Pool List
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
        <div class="row q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <q-btn outline label="Final Interview" @click="multipleForFinalInterview" color="teal"/>
          <q-btn outline label="For Exam" @click="multipleForExam" color="indigo"/>
          <q-btn outline label="Dump" @click="multipleForDump" color="red"/>
					<!-- <q-btn outline color="green" label="Template" @click="getTemplate()"/>
					<q-btn outline color="orange" label="Upload Pool" @click="importPool()"/> -->
				</div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <!-- :visible-columns="visibleColumns" 01182023 -->
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter"
                row-key="id" dense :visible-columns="visibleColumns"
                selection="multiple" :selected.sync="forPoolSelected" virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                  <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>

                      <q-btn
                        class="q-mb-lg q-mr-sm"
                        color="primary"
                        icon-right="fas fa-download"
                        label="Export to csv"
                        no-caps
                        @click="exportTable"
                      />

                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search" @change="searchData">
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>

                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                    <q-td>
                      <q-checkbox dense v-model="props.selected"/>
                      <!-- <q-checkbox dense v-model="props.row.id" v-bind:disabled="account_telco == true"/> -->
                    </q-td>
                    <q-td key="action" :props="props">
                      <!-- <q-btn key="status" color="primary" @click="forExam(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="For Exam" />
                      <q-btn color="red" @click="dump(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Dump" /> -->
                      <q-btn key="status" color="brown" rounded size="sm" @click="viewDetails('Pool',props.row.id)" text-color="white" label="Edit" />
                      <q-btn key="id" color="teal" rounded size="sm" text-color="white" @click="singleForFinalInterview(props.row.id, props.row.applicant_id)" v-if="props.row.account_telco == true" label="Final Interview" />
                    </q-td>
                    <!-- <q-td key="trainee_id" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.trainee_id }}</q-td> -->
                    <!-- <q-td key="applicant_id" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.account }}</q-td>
                    <q-td key="batch" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.result }}</q-td>

                    <q-td key="date_of_application" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" :props="props" @click="viewDetails('Pool',props.row.id)">{{ props.row.with_call_center_experience }}</q-td> -->
                    <q-td key="a_id" :props="props">{{ props.row.id }}</q-td>
                    <q-td key="applicant_id" :props="props" >{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" >{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" >{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" >{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" >{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" >{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" >{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" >{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" >{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" >{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" >{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" >{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" >{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" >{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" >{{ props.row.account }}</q-td>
                    <!-- <q-td key="batch" :props="props" >{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" :props="props" >{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" :props="props" >{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" :props="props " >{{ props.row.result }}</q-td>-->

                    <q-td key="date_of_application" :props="props" >{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" :props="props" >{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" :props="props" >{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="reprofile_count" :props="props " >{{ props.row.reprofile_count }}</q-td> <!-- 01182023 -->

                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="forExamAction" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Exam</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Date</div>
            <div class="col-6 q-pl-sm">Exam Source</div>
          </div>
          <div class="row q-mb-md">
            <div class="col-6 q-pr-sm">
              <q-input type="date" v-model="form_exam.examDate" outlined color="indigo-6"></q-input>
            </div>
            <div class="col-6 q-pl-sm">
              <q-select v-model="form_exam.examSource" :options="examSource" outlined color="indigo-6"/>
            </div>
          </div>
          <div class="row modal-header-for-exam" v-if="this.multiple == false">
            <span class="modal-header">Application ID</span>
            <div class="modal-header-for-exam-inside">
              <span class="text-bold text-indigo-7 " >
                {{ this.form_exam.applicantID[0] }}
              </span>
            </div>
          </div>
          <div class="row modal-header-for-exam" v-if="this.multiple == true">
            <span class="modal-header">Application ID</span>
            <div class="modal-header-for-exam-inside row justify-center ">
              <span  v-for="trackid in this.form_exam.applicantID" :key="trackid" class="text-bold text-indigo-7 col-6 col-sm-4 border-multi" >
                {{ trackid }}
              </span>
            </div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" @click="closeFormExamAction" v-close-popup dense/>
          <q-btn flat label="For Exam" class="bg-indigo-6 text-white" @click="updateApplicationExam" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forFinalInterviewDialog" persistent>
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">Schedule this applicant/s for Final Interview?</span>
        </q-card-section>
        <q-card-section>
          <div class="row modal-header-for-exam" v-if="this.multipleFI == false">
              <span class="modal-header">Application ID</span>
              <div class="modal-header-for-exam-inside">
                <span class="text-bold text-indigo-7 " >
                  {{ this.form_fi.applicantID[0] }}
                </span>
              </div>
          </div>
          <div class="row modal-header-for-exam" v-if="this.multipleFI == true">
            <span class="modal-header">Application ID</span>
            <div class="modal-header-for-exam-inside row justify-center ">
              <span  v-for="trackid in this.form_fi.applicantID" :key="trackid" class="text-bold text-indigo-7 col-6 col-sm-4 border-multi" >
                {{ trackid }}
              </span>
            </div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <q-btn flat label="Yes" color="indigo-7" @click="scheduleForFinalInterview" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forDumpDialog" persistent>
      <q-card class="my-card" style="width: 500px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-red-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">Dump this applicant/s?</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <div class="row modal-header-for-dump" v-if="this.dumpMultiple == false">
            <span class="modal-header-dump">Application ID</span>
            <div class="modal-header-for-dump-inside">
              <span class="text-bold text-red-7 " >
                {{ this.fordump.trackingID[0] }}
              </span>
            </div>
          </div>
          <div class="row modal-header-for-dump" v-if="this.dumpMultiple == true">
            <span class="modal-header-dump">Application ID</span>
            <div class="modal-header-for-dump-inside row justify-center">
              <span  v-for="trackid in this.fordump.trackingID" :key="trackid" class="text-bold text-red-7 col-6 col-sm-4 border-multi" >
                {{ trackid }}
              </span>
            </div>
          </div>

              <!-- <span v-for="trackid in this.fordump.trackingID" :key="trackid" class="text-bold text-indigo-7" >
                  {{ trackid }}
              </span> ? -->
          <!-- </span> -->
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" @click="closeDumpDialog" v-close-popup />
          <q-btn flat label="Yes" color="indigo-7" @click="forDumping" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
        <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
          <div class="text-h5" style="margin: auto;">EDIT APPLICATION</div>
          <!-- <div class="text-h5">{{ vAD.name }}</div>
          <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div> -->
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-form ref="vForm" class="col-gutter-y-sm">
              <q-card-section class="q-mt-sm q-pa-md"> <!-- Personal Information -->
                <div class="text-bold text-head q-mb-sm">Personal Information</div>
                <div class="row q-col-gutter-md">
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.first_name"
                      label="First Name"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.middle_name"
                      label="Middle Name"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.last_name"
                      label="Last Name"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                </div>
                <div class="row q-col-gutter-md">
                  <div class="col-8">
                    <q-input
                      outlined
                      v-model="editApplicant.present_address"
                      label="Present Address"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.present_city"
                      label="City"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                </div>
                <div class="row q-col-gutter-md">
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.email"
                      label="Email"
                      type="email"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.mobile_number"
                      label="Mobile Number"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      v-model="editApplicant.telephone_number"
                      label="Telephone Number"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                </div>
                <div class="row q-col-gutter-md">
                  <div class="col-3">
                    <q-select outlined v-model="editApplicant.gender" :options="gender" label="Gender" lazy-rules/>
                  </div>
                  <div class="col-3">
                    <q-input
                      outlined
                      v-model="editApplicant.birthday"
                      label="Birthdate"
                      type="date"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                  <div class="col-3">
                    <q-select outlined v-model="editApplicant.civil_status" :options="civilstatus" label="Gender" lazy-rules/>
                  </div>
                  <div class="col-3">
                    <q-input
                      outlined
                      v-model="editApplicant.religion"
                      label="Religion"
                      lazy-rules
                      :rules="[ val => val && val.length > 0 || 'Please type something']"
                    />
                  </div>
                </div>
              </q-card-section>
              <q-card-section class="q-mt-xs q-pa-md"><!-- Educational Background -->
                <div class="text-bold text-head q-mb-sm">Educational Background</div>
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <q-select outlined v-model="editApplicant.high_edattain" :options="edAttain" label="Gender" lazy-rules/>
                  </div>
                  <div class="col-6">
                    <q-input
                        outlined
                        v-model="editApplicant.course"
                        label="Course"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Please type something']"
                      />
                  </div>
                </div>
              </q-card-section>
              <q-card-section class="q-mt-xs q-pa-md"><!-- Source of Application -->
                <div class="text-bold text-head q-mb-sm">Source/Information of Application</div>
                <div class="row q-col-gutter-md">
                  <div class="col-4">
                    <q-select outlined v-model="editApplicant.through" :options="through" label="Through" lazy-rules/>
                  </div>
                  <div class="col-4">
                    <q-select outlined v-model="editApplicant.position" :options="position" label="Position" lazy-rules/>
                  </div>
                  <div class="col-4">
                    <q-input
                        outlined
                        v-model="editApplicant.account"
                        label="Account"
                      />
                  </div>
                </div>
              </q-card-section>
              <q-card-section class="q-mt-xs q-pa-md"><!-- Vaccination Information -->
                <div class="text-bold text-head q-mb-sm">Vaccination Information</div>
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <q-select outlined v-model="editApplicant.vaccinated" :options="vaccinated" label="Vaccinated?" lazy-rules/>
                  </div>
                  <div class="col-6">
                    <q-input
                        outlined
                        v-model="editApplicant.vaccine"
                        label="Vaccine Status"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Please type something']"
                      />
                  </div>
                </div>
              </q-card-section>
              <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
                <div class="col-3 q-pl-sm justify-end">
                  <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
                </div>
                <div class="row col-6 q-pl-sm q-col-gutter-sm"></div>
                <div class="col-3 q-pl-sm justify-end">
                  <q-btn label="UPDATE" class="full-width bg-green-6 text-white btn-height" @click="updateApplication" v-close-popup />
                </div>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <div class="bg-white" style="height: auto; width: 500px; max-width: 500px;">
        <q-card>
          <q-card-section class="row q-pa-sm bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-exclamation-circle" />
            <span class="q-pl-sm">Application Assessment : [ {{ form.assessorAction }} ]</span>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm" v-if="form.assessorAction == 'Failed'">
            <div class="q-gutter-sm">
              <q-radio v-model="form.failedAssessment" val="Failed" label="Failed" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Reconsideration" label="For Reconsideration" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Pooling" label="For Pooling" color="amber-8" />
            </div>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm">
            <span class="small-text">Assessment:</span>
            <q-input v-model="form.assessorRemarks" dense color="amber-8">
              <template v-slot:append>
                <q-icon name="fa fa-times" @click="form.assessorRemarks = null" class="cursor-pointer" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn flat label="Cancel" @click="form.assessorRemarks = null,form.failedAssessment = null" color="amber-8" v-close-popup />
            <q-btn flat label="Confirm" @click="processAssessment" class="bg-amber-8 text-white" />
          </q-card-actions>
        </q-card>
      </div>
    </q-dialog>
    <q-dialog v-model="notSelectedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="fas fa-exclamation" color="red" text-color="white" />
          <span class="q-ml-sm">{{ error_not_selected }}
          </span>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Okay" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}
export default {
  name: 'ForPool',
  data() {
    return {
      error_not_selected: "There is no selected applicants!",
      filter: '',
      forExamAction: false,
      multiple: false,
      dumpMultiple: false,
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewDetailsDialog: false,
      actionDialog: false,
      importfile:false,
      spinner:false,
			file:'',
      applications: '',
      applicationsData: [],
      Pagination: {
        sortBy: 'a_id',
        descending: 'ascending',
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'a_id',
          field: 'a_id',
          label: 'ID',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'reprofile_count',
          field: 'reprofile_count',
          label: 'Reprofile Count',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
      ],
      applicationCounts: 0,
      vAD: {
        id: null,
        trackingID: null,
        dateApplied: null,
        name: null,
        presentAddress: null,
        permanentAddress: null,
        email: null,
        mobileNumber: null,
        telNumber: null,
        gender: null,
        birthday: null,
        civilStatus: null,
        religion: null,
        knowCompanyThru: null,
        positionApplyingFor: null,
        callCenterExp: null,
        ccExpDuration: null,
        ccExpCompany: null,
        ccExpPosition: null,
        ccExpAccount: null,
        ccExpReasonLeave: null,
        otherWorkExperience: null,
        owExpDuration: null,
        owExpCompany: null,
        owExpPosition: null,
        owExpBusNature: null,
        owExpReasonLeave: null,
        educAttainment: null,
        yearAttended: null,
        suc: null,
        course: null,
        yearLevel: null,
        natureOfIllness1: null,
        illnessDuration1: null,
        natureOfIllness2: null,
        illnessDuration2: null,
        natureOfIllness3: null,
        illnessDuration3: null,
        contactPerson: null,
        contactRelation: null,
        contactNumber: null,
        assessment: null,
        assessedBy: null,
        assessmentDate: null,
        lastProcess: null
      },
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      approveNotifType: '',
      forExamDialog: false,
      formultiexam: {
        applicantID: [],
        trackingID: []
      },
      exportModal: false,
      exporting: {
        account: '',
        batch: ''
      },
      exporting_account: [],
      exporting_batch: [],
      noData: false,
      forPoolSelected: [],
      notSelectedDialog: false,
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Pool'
      },
      forDumpDialog: false,
      fordump: {
        applicantID: [],
        trackingID: []
      },
      timer: "",
      form_exam: {
        examID: [],
        applicantID: [],
        examDate: null,
        examSource: null,
      },
      form_fi: {
        fiID: [],
        applicantID: [],
      },
      examSource: ['BUPLAS', 'HRIS', 'N/A'],
      editApplicant: {
        id: "",
        applicant_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        present_address: "",
        present_city: "",
        email: "",
        mobile_number: "",
        telephone_number: "",
        gender: "",
        birthday: "",
        civil_status: "",
        religion: "",
        high_edattain: "",
        course: "",
        vaccinated: "",
        vaccine: "",
        through: "",
        position: "",
        account: "",  // 01-26-2023
      },
      city: ['Caloocan','Las Piñas','Makati','Malabon','Mandaluyong','Manila','Marikina','Muntinlupa','Navotas','Parañaque','Pasay','Pasig','Pateros','Quezon City','San Juan','Valenzuela','Taguig City','Rizal','Bulacan','Cavite','Batangas','Laguna','Quezon','Bicol Region','Visayas Region','Mindanao Region','Others…'],
      gender: ['Female', 'Male'],
      civilstatus: ['Single', 'Single with Child', 'Single with Children', 'Married', 'Separated', 'Widowed'],
      edAttain: ['High School Graduate', 'College Undergraduate', 'Degree Holder', 'Vocational Graduate'],
      vaccinated: ['Y', 'N'],
      through: ['Job Posting', 'Referral', 'Walk-In', 'Re-apply', 'Jobfair', 'Others'],
      position: ['Call Center Agent', 'Team Leader', 'Assistant Team Leader', 'Others'],
      forFinalInterviewDialog: false,
      forFinalInterviewAction: false,
      multipleFI: false,
      visibleColumns: ['action', 'applicant_id', 'firstname', 'middlename' , 'lastname', 'mobile','email',
      'presaddress', 'prescity','civil_status','gender','date_of_birth','edattain','course','estrainee','account',
      'date_of_application','position_applying_for','with_call_center_experience','reprofile_count'],
    }
  },
  methods: {
    searchData: function(){
            vueCookies.set(this.searchKey, {
                'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },
    loadApplications: function() {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Applications';
      this.applications = [];
      this.applicationsData = [];

      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }

      this.filter = this.searchKeyword;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-pool-list', {
          params: {
            pool: 'Y',
          }
        })
        .then((res) => {
          this.applications = res.data.data;
          // console.log(res.data.data);
          this.applicationCounts = Object.keys(this.applications).length;

          for (let eachApplication of this.applications) {
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            let tempBirthdate = eachApplication.date_of_birth;

            let date_birth = "";
            if (tempBirthdate == "0000-00-00"){
              date_birth = "N/A";
            }else{
              date_birth = date.formatDate(tempBirthdate, 'MMMM DD, YYYY');
            }


            // console.log(tempBirthdate)
            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }

            // 01182023
            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }

            // 01182023
            var call_exp = '';
            if(eachApplication.ccexperience == "N"){
              call_exp = "NO";
            }else if(eachApplication.ccexperience == "Y"){
              call_exp = "YES";
            }else{
              call_exp = eachApplication.ccexperience;
            }

            // console.log(document.getElementById("checkbox"+eachApplication.id))

            var account_telco = false;
            if(eachApplication.account != null){
              var account = eachApplication.account.toLowerCase();
              if(account.includes("telco")){
                account_telco = true;
              }else{
                account_telco = false;
              }
            }else{
              account_telco = false;
            }

            // console.log(eachApplication);
            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn, // 01182023
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: call_exp, // 01182023
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: status,
              date_of_birth: date_birth,
              account_telco: account_telco,
              reprofile_count: eachApplication.reprofile_count // 01182023
            });
          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
          this.forPoolSelected = [];
        })
    },

    searchDate(){
      this.loading = true;
      let formData = new FormData();
      this.searchDateToFrom.dateFrom = this.dateFrom;
      this.searchDateToFrom.dateTo = this.dateTo;
      //  this.searchDateToFrom.status = this.status; // 01182023

      formData.append('form_data', JSON.stringify(this.searchDateToFrom));
      axios.create({
          baseURL: this.apiUrl,
          headers: {
              'Authorization': `Bearer ${this.token}`
          }
          }).post('/recruitments/application/search-date', formData).then((res) => {
          // console.log(res)
          this.loading = false;
          this.applicationsData = [];
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;

          for (let eachApplication of this.applications) {
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }


            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: eachApplication.edattain,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: status,
              date_of_birth: eachApplication.date_of_birth,
              reprofile_count: eachApplication.reprofile_count // 01182023
            });
          }
      });
    },

    finalInterview(id, applicantid){
      // console.log(id);
      this.form_fi.fiID = id;
      this.form_fi.applicantID = applicantid;
      this.forFinalInterviewDialog = true;
    },

    scheduleForFinalInterview(){
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_fi));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/telco-for-final-interview', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.forPoolSelected = [];
          this.loadApplications();
        }, 1000);
      });
    },

    singleForFinalInterview: function(rowID, applicantID){

      this.form_fi.fiID = [];
      this.form_fi.applicantID = [];

      this.form_fi.fiID.push(rowID);
      this.form_fi.applicantID.push(applicantID);

      this.forFinalInterviewDialog = true;

      // console.log("singleForFinalInterview: ", this.form_fi);
    },

    multipleForFinalInterview(){

      var error = false;
      if(this.forPoolSelected.length > 0){
        if(this.forPoolSelected.length == 1){
          this.multipleFI = false;
        }else {
          this.multipleFI = true;
        }

        this.form_fi.fiID = [];
        this.form_fi.applicantID = [];

        var showError = false;
        for(var i = 0; i < this.forPoolSelected.length;i++){
          if(this.forPoolSelected[i].account_telco == false){
            this.error_not_selected = "You selected an Applicant that is not from Telco account! "
            this.notSelectedDialog = true;
            showError = true;
            break;
          }else{
            this.form_fi.fiID.push(this.forPoolSelected[i].id);
            this.form_fi.applicantID.push(this.forPoolSelected[i].tracking_number);
          }
        }

        if(showError == false){
          this.forFinalInterviewDialog = true;
        }

      }else{
        this.error_not_selected = "There is no selected applicants!";
        this.notSelectedDialog = true;
      }
    },

    dump:function(applicantID, applicationID){
      this.fordump.applicantID = [];
      this.fordump.trackingID = [];

      this.fordump.applicantID.push(applicantID);
      this.fordump.trackingID.push(applicationID);
      this.forDumpDialog = true;
      // console.log(this.fordump);
    },

    scheduleForExam: function(){
      // console.log("Schedule for exam");
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.forexam));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-from-pool', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.forPoolSelected = [];
          this.loadApplications();
        }, 1000);
      });
    },

    forDumping: function(){
      // console.log("dump");
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.fordump));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-from-pool-dump', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.forPoolSelected = [];
          this.loadApplications();
          this.multiple = false;
        }, 1000);
      });
    },

    closeFormExamAction(){
      this.forExamAction = false;
      this.multiple = false;
    },

    closeDumpDialog(){
      this.forDumpDialog = false;
      this.dumpMultiple = false;
    },


    multipleForDump(){
      if(this.forPoolSelected.length > 0){
        if(this.forPoolSelected.length == 1){
          this.dumpMultiple = false;
        }else {
          this.dumpMultiple = true;
        }
        this.fordump.applicantID = [];
        this.fordump.trackingID = [];
        for(var i = 0; i < this.forPoolSelected.length;i++){
          this.fordump.applicantID.push(this.forPoolSelected[i].id);
          this.fordump.trackingID.push(this.forPoolSelected[i].tracking_number);
        }
        this.forDumpDialog = true;
      }else{
        this.notSelectedDialog = true;
      }

    },

    multipleForExam(){
      var error = false;
      if(this.forPoolSelected.length > 0){
        if(this.forPoolSelected.length == 1){
          this.multiple = false;
        }else {
          this.multiple = true;
        }

        this.form_exam.examID = [];
        this.form_exam.applicantID = [];

        var showError = false;

        for(var i = 0; i < this.forPoolSelected.length;i++){
          if(this.forPoolSelected[i].account_telco == false){
            this.form_exam.examID.push(this.forPoolSelected[i].id);
            this.form_exam.applicantID.push(this.forPoolSelected[i].tracking_number);
          }else{
            this.error_not_selected = "You selected an Applicant that cannot take an exam!"
            this.notSelectedDialog = true;
            showError = true;
            break;
          }
        }

        if(showError == false){
          this.forExamAction = true;
        }
      }else{
        this.error_not_selected = "There is no selected applicants!";
        this.notSelectedDialog = true;
      }
    },



    updateApplicationExam: function(){
      this.loadingAction = true;
      this.loadingText = 'Updating Application';
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_exam));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-for-exam', formData).then((res) => {
        setTimeout(() => {
          this.forExamSelected = [];
          this.loadApplications();
          this.loadingAction = false;
        }, 1000);
      });
    },


    importPool:function(){
      this.importfile = true;
    },

    closeModal:function(){
      this.importfile = false;
    },

    onSubmit:function(){
      this.spinner = true
      let formData  = new FormData();
      formData.append("files", this.file);

      axios({
        onUploadProgress: uploadEvent => {
          console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
        },
        headers : {'Authorization' : `Bearer ${this.token}`},
        url:this.apiUrl+'/recruitments/pool-list/upload',
        data: formData,
        method: 'POST'
      }).then((res) => {
        // console.log("response: ",res)
        //this.onRequest({pagination:this.pagination,filter:''})
        this.spinner = false;
        this.importfile = false;
        this.loadApplications();
      }).catch((error) => {
        console.log("error: ",error)
      })
    },

    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },

    viewDetails: function(applicationStatus, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';
      // console.log(rowID)
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-details-list', {
          params: {
            id: rowID,
            status: applicationStatus,
          }
        })
        .then((res) => {
          // console.log(res)
          this.loadingModel = false;
          var viewApplicantDetails = res.data.data[0];

          var edattn = '';
          if(viewApplicantDetails.edattain == "ed0"){
            edattn = "High School Graduate";
          } else if(viewApplicantDetails.edattain == "ed1"){
            edattn = "College Level";
          } else if(viewApplicantDetails.edattain == "ed2"){
            edattn = "Degree Holder";
          } else if(viewApplicantDetails.edattain == "ed3"){
            edattn = "Vocational Graduate";
          }else {
            edattn = viewApplicantDetails.edattain;
          }

          // FOR EDIT
          this.editApplicant.id = viewApplicantDetails.id;
          this.editApplicant.applicant_id = viewApplicantDetails.tracking_number;

          // personal information
          this.editApplicant.first_name = viewApplicantDetails.firstname;
          this.editApplicant.middle_name = viewApplicantDetails.middlename;
          this.editApplicant.last_name = viewApplicantDetails.lastname;
          this.editApplicant.present_address = viewApplicantDetails.presaddress;
          this.editApplicant.present_city = viewApplicantDetails.prescity;
          this.editApplicant.email = viewApplicantDetails.email;
          this.editApplicant.mobile_number = viewApplicantDetails.mobile;
          this.editApplicant.telephone_number = viewApplicantDetails.telephone;
          this.editApplicant.gender = viewApplicantDetails.gender;
          this.editApplicant.birthday = viewApplicantDetails.date_of_birth;
          this.editApplicant.civil_status = viewApplicantDetails.civil_status;
          this.editApplicant.religion = viewApplicantDetails.religion;

          //educational background
          this.editApplicant.high_edattain = edattn;
          this.editApplicant.course = viewApplicantDetails.school_year;

          //Source of Application
          this.editApplicant.account = viewApplicantDetails.account;   // 01-26-2023
          this.editApplicant.through = viewApplicantDetails.through;
          this.editApplicant.position = viewApplicantDetails.position_sought;

          //Vaccination Information
          this.editApplicant.vaccinated = viewApplicantDetails.vaccinated;
          this.editApplicant.vaccine = viewApplicantDetails.vaccination_status;

          // END:: FOR EDIT


          this.viewDetailsDialog = true;
        })
    },

    updateApplication(){
      this.loadingAction = true;
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.editApplicant));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-application', formData).then((res) => {
        setTimeout(() => {
          this.forExamSelected = [];
          this.loadApplications();
          this.loadingAction = false;
        }, 1000);
      });
    },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/assessment', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.viewDetailsDialog = false;
            this.applicationsData = [];
            this.loadApplications();
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Assessment Done'
            });
            this.form.assessorAction = null;
            this.form.assessorRemarks = null;
            this.form.failedAssessment = null;
            this.form.appID = null;
          }, 1000);
        });
      }
    },

    exportTable () {
      // naive encoding to csv format
      const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
        this.applicationsData.map(row => this.ResultColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'Pool.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  mounted() {
    this.loadApplications();
    this.timer = setInterval(this.loadApplications, 300000);
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}

.modal-header{
  border-bottom: solid 2px #3949ab;
  text-align: center;
  width: 100%;
  font-size: 20px;
  background: #3949ab;
  color: white;
}

.modal-header-for-exam {
  border: solid 2px #3949ab;
  margin-bottom: 10px;
  text-align: center;
}

.modal-header-for-exam-inside {
  width: 100%;
  padding: 10px;
  font-size: 17px;
}

.border-multi {
  border: solid 1px;
}

.modal-header-dump{
  border-bottom: solid 2px #f44336;
  text-align: center;
  width: 100%;
  font-size: 20px;
  background: #f44336;
  color: white;
}

.modal-header-for-dump {
  width: 100%;
  border: solid 2px #f44336 ;
  margin-bottom: 10px;
  text-align: center;
}

.modal-header-for-dump-inside {
  width: 100%;
  padding: 10px;
  font-size: 17px;
}
</style>
