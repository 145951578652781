<template>
  <div class="q-pa-md">
    <div class="q-pa-md">
      <div class="row">
		<div class="row justify q-banner--top-padding">
			<q-btn outline color="green" label="Add Adj Code" @click="addAdjCode"/>
			<q-btn outline color="green" label="Adjustment Template" @click="getTemplate"/>
			<q-btn outline color="orange" label="Manual Adjustment" @click="addManualAdj"/>
			<q-btn outline color="red" label="Upload Adjustment" @click="uploadAdj"/>

		</div>
        <div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
            <q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
          	<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
          	<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
			<q-select v-model="typefilter" :options="options" label="Types"  style="width: 200px"/>
			<q-btn color="primary" label="Filter Data" @click="getAdjustmentList"/>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
      <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Detailed TK" :data="adjustmentData" :columns="columns" row-key="id" separator="cell" :pagination.sync="pagination">

		<template v-slot:no-data="">
          <div class="full-width row flex-center text-red q-gutter-sm">
            <i class="fas fa-exclamation-triangle"></i>
            <span>
              No Result Found!
            </span>
          </div>
        </template>
        <template v-slot:top>
          <q-input dense v-model="searchKey" placeholder="Search Employee">
            <i class="fas fa-search" slot="append"></i>
          </q-input>
          <q-space />
          <q-btn outline color="primary" label="Download" no-caps @click="exportTable" />
        </template>
      </q-table>
    </div>
    <q-dialog v-model="spinner" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section>
          <q-spinner-gears color="cyan" size="5.5em" />
        </q-card-section>
      </q-card>
    </q-dialog>

	<q-dialog v-model="manual" persistent>
      	<q-card style="min-width: 350px">
        	<q-card-section>
          		<div class="text-h6">Add Manual Adjustment</div>
        	</q-card-section>
        	<q-card-section class="q-pt-none">
				<q-form @submit="onSubmit" class="q-gutter-md">
	 			<q-input filled v-model="employee_id" label="Employee ID" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
				<q-select filled v-model="adjusment_code" :options="adjCodeDataOptions" label="Adjustment Code" />
				<q-toggle v-model="taxable" size="xl" val="YES"  color="green" label="Taxable?" />
				<q-select filled v-model="adj_year" :options="yearOptions" label="Year" />
				<q-select filled v-model="adj_month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name"/>
	          	<q-select filled v-model="adj_cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name"/>
				<q-input filled v-model="amount" label="Amount" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
				<q-select filled v-model="types" :options="options" label="Types" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
				<div align="right">
				   <q-btn label="Close" v-close-popup color="orange"/>
				   <q-btn label="Submit" type="submit" color="primary"   class="q-ml-sm" />
				</div>
   				</q-form>
        	</q-card-section>

      	</q-card>
    </q-dialog>

	<!-- manual code -->
	<q-dialog v-model="manualcode" persistent>
      	<q-card class="q-bottom-sheet--list"  style="max-width:2000px;width:2000px;height:850px">
        	<q-card-section>
          		<div class="text-h6">Add Adjustment Code</div>
        	</q-card-section>
        	<q-card-section class="q-pt-none">
				<div class="row">
					<div class="col-3 q-mb-md" >
						<q-form @submit="onSubmitCode" class="q-gutter-md">
			 			<q-input filled v-model="adjCode" label="Adjustment Code" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
						<q-input filled v-model="adjDescription" label="Adjustment Description" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
						<div align="right">
						   <q-btn label="Close" v-close-popup color="orange"/>
						   <q-btn label="Submit" type="submit" color="primary"   class="q-ml-sm" />
						</div>
		   				</q-form>
					</div>
					<div class="col-9 q-mb-md" >
						<div class="q-pa-md">
							<q-table :data="adjCodeData" :columns="codeColumns" row-key="id" :pagination.sync="pagination" >
								<template v-slot:body="props">
							  		<q-tr slot="body" :props="props" @click="selectedRow(props)">
										<q-td key="adjCode" :props="props">{{ props.row.adjCode }}</q-td>
										<q-td key="adjDesc" :props="props">{{ props.row.adjDesc }}</q-td>
							  		</q-tr>
								</template>
								<template v-slot:no-data="">
								  	<div class="full-width row flex-center text-red q-gutter-sm">
									<i class="fas fa-exclamation-triangle"></i>
									<span>
									  No Result Found!
									</span>
								  	</div>
							</template>
				  			</q-table>
						</div>
					</div>
				</div>
        	</q-card-section>
      	</q-card>
    </q-dialog>
	<!-- import adjustment -->
	<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
		<q-card class="q-bottom-sheet--list">
			<q-form ref="form" autocomplete="off" @submit="uploadFile">
				<q-card-section>
					<div class="text-h6">Upload Adjustment</div>
				</q-card-section>
				<q-card-section>
					<small>(.csv Only)</small><br/>
					<q-file color="teal" filled v-model="files" label="Select your file"  accept=".csv" :rules="[formRules.required]">
						<template v-slot:prepend>
						  <i class="fa fa-upload"> </i>
						</template>
					 </q-file>
				</q-card-section>
				<q-card-actions align="right">
					<div>
						<q-btn flat label="Submit" type="submit" color="primary"/>
						<q-btn flat label="Close" color="primary" v-close-popup/>
					</div>
				</q-card-actions>
			</q-form>
		</q-card>
	</q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'


function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}

export default {
  data() {
    return {
	manual:false,
	employee_id:'',
	adjusment_code:'',
	taxable: false,
	adj_year:new Date().getFullYear(),
	adj_month:new Date().getMonth() + 1,
	adj_cutoff:1,
	amount:'',
	types:'',
	options: ['ADDITION', 'DEDUCTION'],

	manualcode:false,
	tempCodeID:0,
	adjCode:'',
	adjDescription:'',
	adjCodeData:[], //for table
	//stringCodeOptions:[],
	adjCodeDataOptions:[], //for dropdown

	importfile:false,
	files:[],

	typefilter:'ALL',
      yearfilter:new Date().getFullYear(),
      yearOptions: [],
      separator: 'cell',

      spinner: false,

      pagination: {
        rowsPerPage: 30
      },

      month: new Date().getMonth() + 1,
      cutoff: 1,
      headers: [],
      schdules: [],
      adjustmentData: [],
      initData: [],
      cutoffOptions: [{ id: 1, name: 'First Cut-off' }, { id: 2, name: 'Second Cut-off' }],

      monthOptions: [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ],
      searchKey: '',


      columns: [
        { name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true, desc: 'Employee ID' },
        { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true, desc: 'Name' },
		{ name: 'adjcode', align: 'center', label: 'Adjustment Code', field: 'adjcode', sortable: true, desc: 'Adjustment Code' },
		{ name: 'taxable', align: 'Taxable', label: 'Taxable', field: 'taxable', sortable: true, desc: 'Taxable' },
        { name: 'year', align: 'center', label: 'Year', field: 'year', sortable: true, desc: 'Year' },
		{ name: 'month', align: 'center', label: 'Month', field: 'month', sortable: true, desc: 'Month' },
		{ name: 'cutoff', align: 'center', label: 'Cut-Off', field: 'cutoff', sortable: true, desc: 'Cut-Off' },
		{ name: 'amount', align: 'center', label: 'Amount', field: 'amount', sortable: true, desc: 'Amount' },
		{ name: 'types', align: 'center', label: 'Types', field: 'types', sortable: true, desc: 'Types' },
      ],

	  codeColumns: [
  		{ name: 'adjCode', align: 'center', label: 'Adjustment Code', field: 'adjCode', sortable: true },
	  	{ name: 'adjDesc', align: 'center', label: 'Adjustment Description', field: 'adjDesc', sortable: true },
	  ],
    }
  },

  created() {
    this.yearsList()
	this.getAdjustmentCode()
	this.getAdjustmentList()
  },

  methods: {
	uploadAdj:function(){
		this.importfile = true
	},

	addManualAdj:function(){
		this.manual = true;
	},

	addAdjCode:function(){
		this.manualcode = true;
	},

	selectedRow:function(obj){
		this.tempCodeID = obj.row.adjCodeID
		this.adjCode = obj.row.adjCode
		this.adjDescription = obj.row.adjDesc
	},

	onSubmitCode:function(){
		let formData = new FormData();

		formData.append("tempCodeID", this.tempCodeID);
		formData.append("adjCode", this.adjCode);
		formData.append("adjDesc", this.adjDescription);

		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
          }).post(`/payroll/adjustment/code`, formData)
          .then((res) => {
			console.log(res.data)
			this.$q.notify({
				message: 'Successfully added!',
				color: 'green',
				position:'center',
				actions: [
					{ label: 'Dismiss', color: 'white', handler: () => { } }
				]
			})
			this.getAdjustmentCode()
			this.tempCodeID = 0
			this.adjCode = ''
			this.adjDescription = ''
          });
	},

	onSubmit:function(){
		let formData = new FormData();
		formData.append("empID", this.employee_id);
		formData.append("adjcode", this.adjusment_code);
		formData.append("taxable", this.taxable);
        formData.append("year", this.adj_year);
        formData.append("month", this.adj_month);
        formData.append("cutoff", this.adj_cutoff);
		formData.append("amount", this.amount);
		formData.append("types", this.types);
		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
          }).post(`/payroll/adjustment`, formData)
          .then((res) => {
			  console.log(res.data)
            //this.getDetailedTK()
            //this.generatedData = res.data.data
            //this.initData = res.data.dtr
            //this.spinner = false
            //this.dataFlag = true
			this.getAdjustmentList()
			this.clearFields();
          });
	},

	clearFields(){
		this.employee_id = ''
		this.adjusment_code = ''
		this.taxable = false
        this.adj_year = ''
        this.adj_month = ''
        this.adj_cutoff = ''
		this.amount = ''
		this.manual = false;
	},

    getAdjustmentCode: function() {
		this.spinner = true
		axios.create({
			baseURL: this.apiUrl,
			headers: {
				'Authorization': `Bearer ${this.token}`
			}})
		.get(`/payroll/adjustment/code`)
		.then((res) => {
			this.adjCodeDataOptions = res.data.codelist
			this.adjCodeData = res.data.data
			setTimeout(() => {
				this.spinner = false
			},500)
		})
    },

	getAdjustmentList: function() {
		this.spinner = true
		axios.create({
			baseURL: this.apiUrl,
			headers: {
				'Authorization': `Bearer ${this.token}`
			}})
		.get(`/payroll/adjustment/${this.yearfilter}/${this.month}/${this.cutoff}/${this.typefilter}`)
		.then((res) => {
			setTimeout(() => {
				this.adjustmentData = res.data.data;
				this.spinner = false
			},500)
		})
    },

    yearsList : function(){
        axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
        }).post(`/timekeeping/years/list`)
        .then((res) => {
            this.yearOptions = res.data.data
        });
    },


	getTemplate: function() {
		axios.create({
			baseURL: this.apiUrl,
			headers: {
				'Authorization': `Bearer ${this.token}`
			}})
		.get('/employee/template/download')
		.then((res) => {
			//console.log(res.data.data.adjustment_template)
			this.downloadTemplate(res.data.data.adjustment_template)
		})
	},

	downloadTemplate:function(url){
		axios({
			url: this.apiUrl+'/'+url,
			method: 'GET',
			responseType: 'arraybuffer',
		}).then((response) => {
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement('a');
			fileLink.href = fileURL;
			fileLink.setAttribute('download', 'Adjustment_Template.csv');
			document.body.appendChild(fileLink);

			fileLink.click();
		});
	},

	uploadFile:function(){
		this.spinner = true
		let formData  = new FormData();
		formData.append("files", this.files);
		axios({
			onUploadProgress: uploadEvent => {
				console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
			},
			headers : {'Authorization' : `Bearer ${this.token}`},
			url:this.apiUrl+'/payroll/adjustment/upload',
			data: formData,
			method: 'POST'
			}).then(response => {
				this.getAdjustmentList()
				setTimeout(() => {
					this.spinner = false
					this.importfile = false
					this.files = []
					this.$q.notify({
						message: 'Adjustment Uploaded Successfully!',
						color: 'green',
						position:'center',
						actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
					})
				},500)
			}).catch((error) => {
				console.log(error)
		})
	},

    exportTable: function() {

      const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
        this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function' ?
          col.field(row) :
          row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'DetailedTK.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

  }
}
</script>
<style>
tr:hover {
  cursor: pointer;
  background-color: #f8f5f8;
}
</style>
