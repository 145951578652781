<template>

  <div class="q-pa-md">
      <q-form @submit="onSubmit">
        <div class="row items-start">
          <div class="row inline q-pa-md">
              <q-input filled outlined v-model="fromDate" mask="date" :rules="['date']" label="Date From">
                  <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                          <q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="fromDate" @input="() => $refs.dhDateProxy.hide()" />
                          </q-popup-proxy>
                      </i>
                  </template>
              </q-input>
          </div>
          <div class="row inline q-pa-md">
              <q-input filled outlined v-model="toDate" mask="date" :rules="['date']" label="Date To">
                  <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                          <q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="toDate" @input="() => $refs.dhDateProxy.hide()" />
                          </q-popup-proxy>
                      </i>
                  </template>
              </q-input>
          </div>
          <div class="row inline q-pa-md">
            <q-select filled v-model="status" :options="statusOptions" label="Status" style="width: 200px" class="q-mr-sm col" :rules="[formRules.required]"/>
          </div>
          <div class="row inline q-pa-md">
            <q-select filled v-model="leaves" :options="leavesOptions" label="Leaves" emit-value map-options option-value="id" option-label="name" style="width: 200px"  class="q-mr-sm col" :rules="[formRules.required]"/>
          </div>
          <div class="row inline q-pa-md">
            <q-btn outline style="height: 54px; color: green;" label="Generate Report" type="submit" />
          </div>
        </div>
      </q-form>
      <q-table :data="data" :columns="columns" row-key="id">
        <q-tr slot="body" slot-scope="props" :props="props">
          <q-td key="leaveType" :props="props">{{ props.row.leaveType }}</q-td>
          <q-td key="fromDate" :props="props">{{ props.row.fromDate }}</q-td>
          <q-td key="toDate" :props="props">{{ props.row.toDate }}</q-td>
          <q-td key="total" :props="props">{{ props.row.total }}</q-td>
          <q-td key="status" :props="props">{{ props.row.status }}</q-td>
          <q-td key="name" :props="props">{{ props.row.name }}</q-td>
          <q-td key="dept" :props="props">{{ props.row.dept }}</q-td>
          <q-td key="position" :props="props">{{ props.row.position }}</q-td>
        </q-tr>
        <template v-slot:no-data>
          <div class="full-width row flex-center text-amber-10 q-gutter-sm">
            <span>No Result Found!</span><q-icon size="1.5em" name="fa fa-exclamation-triangle" />
          </div>
        </template>
        <template v-slot:top-right>
          <q-btn
            color="primary"
            icon-right="fas fa-download"
            label="Export to Excel"
            no-caps
            @click="exportToExcel"
          />
        </template>
      </q-table>

      <q-dialog v-model="spinner" persistent >
          <q-card class="my-card transparent no-shadow">
              <q-card-section >
                  <q-spinner-gears color="cyan"  size="5.5em"/>
              </q-card-section>
          </q-card>
      </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { exportFile } from 'quasar'

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  data () {
    return {
        fromDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        toDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        status:'',
        statusOptions: ['Pending','Approved','In Process','Declined','Done'],//['Pending','Approved','Declined','Processing','Processed','Done'],
        leaves:{'id':0,'name':'ALL'},
        leavesOptions: [],
        columns: [
        { name: 'leaveType', align: 'center', label: 'Leave Type', field: 'leaveType'},
        { name: 'fromDate', align: 'center', label: 'From Date', field: 'fromDate'},
        { name: 'toDate', align: 'center', label: 'To Date', field: 'toDate' },
        { name: 'total', align: 'center', label: 'Total Days', field: 'total' },
        { name: 'status', align: 'center', label: 'Status', field: 'status' },
        { name: 'name', align: 'center', label: 'Name', field: 'name' },
        { name: 'dept', align: 'center', label: 'Department', field: 'dept' },
        { name: 'position', align: 'center', label: 'Position', field: 'position' },
      ],
      data: [],
      spinner:false,
    }
},

created(){
    this.leaveList()
},

  methods: {
      onSubmit () {
          this.spinner = true;
          let formData = new FormData();
          formData.append("fromDate", this.fromDate);
          formData.append("toDate", this.toDate);
          formData.append("status", this.status);
          formData.append("leaves", this.leaves);
          axios.create({
              baseURL : this.apiUrl,
              headers : {'Authorization' : `Bearer ${this.token}`}
          }).post('/leave/reports',formData)
          .then((res) => {
              this.data = res.data.data
              this.spinner = false
          });

      },
    leaveList : function(){
      axios.create({
          baseURL : this.apiUrl,
          headers : {'Authorization' : `Bearer ${this.token}`}
      }).get(`/leave/list`)
      .then((res) => {
          this.leavesOptions = res.data.data
      });
    },

    exportToExcel: function() {
      axios.create({
          baseURL: this.apiUrl,
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).get(`/leave/export`, {
          params: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            status: this.status,
            leaves: this.leaves
          }
        }).then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Leave Reports.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    // exportToExcel(){
    //     axios.create({
    //         baseURL: this.apiUrl,
    //         headers: {
    //           'Authorization': `Bearer ${this.token}`
    //         }
    //       }).get('/leave/export', {
    //         params: {
    //           fromDate: this.fromDate,
    //           toDate: this.toDate,
    //           status: this.status,
    //           leaves: this.leaves
    //         }
    //       }).then((res) => { })
    // },

    exportTable () {
      // naive encoding to csv format
      const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
        this.data.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'LeavesReport.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    }
  },

}
</script>
