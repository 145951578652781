<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding">
					<h4 class="q-ma-none">Lock Settings</h4>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px" @input="fetchAllData"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="fetchAllData"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="fetchAllData"/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-lg-6 col-xs-12  q-mb-md q-pr-xl">
				<q-card flat bordered class="my-card bg-grey-1">
					<q-card-section>
						<div class="row items-center no-wrap">
							<div class="text-h6">Lock Schedule</div>
						</div>
					</q-card-section>

					<q-card-actions align="center">
						<div class="q-gutter-md row">
							<div class="q-gutter-sm">
								<q-radio  size="80px" color="green" v-model="lockSchedule" val="unlock" label="Unlock Schedule" @input="lockTable('unlock','SC')"/>
								<q-radio  size="80px" color="red" v-model="lockSchedule" val="lock" label="Lock Schedule" @input="lockTable('lock','SC')"/>
							</div>
						</div>
					</q-card-actions>
					<q-separator />
					<q-card-actions align="center">
						<q-chip outline :color="(lockSchedule == 'lock') ? 'red' : 'green'" text-color="white">
							{{ (lockSchedule == 'lock') ? 'This cut-off was locked and close for sending schedule.' : 'This cut-off is open and available to edit by the employee.'}}
						</q-chip>
					</q-card-actions>
				</q-card>
			</div>
			<div class="col-6 col-lg-6 col-xs-12  q-mb-md q-pr-xl">
				<q-card flat bordered class="my-card bg-grey-1">
					<q-card-section>
						<div class="row items-center no-wrap">
							<div class="text-h6">Lock Timekeeping</div>
						</div>
					</q-card-section>
					<q-card-actions align="center">
						<div class="q-gutter-md row">
							<div class="q-gutter-sm">
								<q-radio  size="80px" color="green" v-model="lockTimekeeping" val="unlock" label="Unlock Timekeeping" @input="lockTable('unlock','TK')"/>
								<q-radio  size="80px" color="red" v-model="lockTimekeeping" val="lock" label="Lock Timekeeping" @input="lockTable('lock','TK')"/>
							</div>
						</div>
					</q-card-actions>
					<q-separator />
					<q-card-actions align="center">
						<q-chip outline :color="(lockTimekeeping == 'lock') ? 'red' : 'green'" text-color="white">
							{{ (lockTimekeeping == 'lock') ? 'This cut-off was locked in filing leaves. Filed leaves beyond this date are not included for this cut-off.' : 'This cut-off was open for filing leaves or any kind of form filling.'}}
						</q-chip>
					</q-card-actions>
				</q-card>
			</div>
			<div class="col-6 col-lg-6 col-xs-12  q-mb-md q-pr-xl">
				<q-card flat bordered class="my-card bg-grey-1">
					<q-card-section>
						<div class="row items-center no-wrap">
							<div class="text-h6">Salary Basis Computation</div>
						</div>
					</q-card-section>
					<q-card-actions align="center">
						<div class="q-gutter-md row">
							<div class="q-gutter-sm">
								<q-radio  size="80px" color="green" v-model="basisComputation" val="1" label="Use Basic Salary" @input="updateBasisComputation"/>
								<q-radio  size="80px" color="red" v-model="basisComputation" val="2" label="Use Total Salary" @input="updateBasisComputation"/>
							</div>
						</div>
					</q-card-actions>
					<q-separator />
					<q-card-actions align="center">
						<q-chip outline :color="(basisComputation == '1') ? 'green' : 'red'" text-color="white" >
							This cut-off is use {{ (basisComputation == '1') ? 'Basic' : 'Total'}} Salary for computation.
						</q-chip>
					</q-card-actions>
				</q-card>
			</div>
			<div class="col-6 col-lg-6 col-xs-12  q-mb-md q-pr-xl">
				<q-card flat bordered class="my-card bg-grey-1">
					<q-card-section>
						<div class="row items-center no-wrap">
							<div class="text-h6">Reprocess Timekeeping Computation</div>
						</div>
					</q-card-section>
					<q-card-actions align="center">
						<div class="q-gutter-md row">
							<div class="q-gutter-sm">
								<q-btn size="25px" :loading="modEffectLoading" class="q-px-xl q-py-xs" color="green" label="Re-process Timekeeping" @click="reprocessDialog">
									<template v-slot:loading>
									  <q-spinner-gears class="on-left" />
									  RESETTING...
									</template>
								</q-btn>
							</div>
						</div>
					</q-card-actions>
					<q-separator />
				</q-card>
			</div>
			<div class="col-6 col-lg-6 col-xs-12  q-mb-md q-pr-xl">
				<q-card flat bordered class="my-card bg-grey-1">
					<q-card-section>
						<div class="row items-center no-wrap">
							<div class="text-h6">SCHEDULE TYPE</div>
						</div>
					</q-card-section>
					<q-card-actions align="center" >
						<div class="q-gutter-md row">
							<div class="q-gutter-sm">
								<q-radio  size="80px" color="green" v-model="scheduleType" val="LAW" label="BY LAW" @input="selectedType"/>
								<q-radio  size="80px" color="blue" v-model="scheduleType" val="CCW" label="COMPRESSED WORK" @input="selectedType"/>
							</div>
						</div>
					</q-card-actions>
					<q-separator />
					<q-card-actions align="center" v-show="(scheduleType == 'CCW')">
						<div class="q-pa-md">
						    <q-date v-model="ccwdays" multiple landscape/>
						</div>
						<q-btn size="25px" :loading="modEffectLoading" class="q-px-xl q-py-xs" color="green" label="SAVE COMPRESSED WORK DATE" @click="saveCompressedWorkDate">
							<template v-slot:loading>
							  <q-spinner-gears class="on-left" />
							  RESETTING...
							</template>
						</q-btn>
					</q-card-actions>
				</q-card>
			</div>
		</div>
		<q-dialog v-model="confirm" persistent>
      		<q-card>
        		<q-card-section class="row items-center">
					<span class="text-red"><i class="fa fa-exclamation-triangle fa-2x"></i></span>
          			<span class="q-ml-sm">Do you want to re-process this cutoff!</span>
        		</q-card-section>
				<q-card-section>
				   <span class="q-ml-sm">{{ cutoffLabel }} of {{ monthLabel }},  {{ yearfilter }}    </span>
				 </q-card-section>
        		<q-card-actions align="right">
          			<q-btn flat label="Cancel" color="primary" v-close-popup />
          			<q-btn flat label="Confirm" color="primary" v-close-popup  @click="reprocessPayroll"/>
        		</q-card-actions>
      		</q-card>
    	</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {

	data () {
		return {
			yearfilter:new Date().getFullYear(),
	        yearOptions: [],
			modEffectLoading: false,
			confirm:false,

			isLock:false,
			isLockTk:false,

			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,

			scheduleType:'',
			basisComputation:'',
			lockSchedule:'',
			lockTimekeeping:'',

			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],
			type:'',
			monthLabel:'',
			cutoffLabel:'',
			//new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			ccwdays: [],
			isByLaw: false
		}
	},

	created(){
		this.fetchAllData()
		this.yearsList()
	},

	methods: {

		fetchAllData:function(){
		  	axios.all([
		  		this.getSalaryBasic(),
		  		this.checkIfTableIsLock(),
				this.getScheduleType(),
				this.getCCWDates()
			]).then(axios.spread((basic,islock,schedType,ccw) => {
		  			//for total data

				this.basisComputation = basic.data.basis
				this.lockSchedule = islock.data.scStatus
				this.lockTimekeeping = islock.data.tkStatus

				this.scheduleType = schedType.data.sched_type
				this.ccwdays = ccw.data.data

				//this.ccwdays = ccw.data.start_date
				//console.log(ccw.data.start_date)

			  }));
		},

		getSalaryBasic:function() {
	  		return axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get(`/schedule/basis/${this.yearfilter}/${this.month}/${this.cutoff}`)
		},

		checkIfTableIsLock: function(){
			return axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get(`/schedule/islock/${this.yearfilter}/${this.month}/${this.cutoff}`)
		},

		getScheduleType:function() {
	  		return axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get(`/schedule/type/${this.yearfilter}/${this.month}/${this.cutoff}`)
		},

		getCCWDates:function() {
	  		return axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get(`/schedule/ccwdate/${this.yearfilter}/${this.month}/${this.cutoff}`)
		},


		updateBasisComputation:function(val){
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			formData.append("basis", val);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/basis',formData)
			.then((res) => {
				this.$q.notify({
					message: res.data.message,
					color: 'green',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
			});
		},

		lockTable:function(islock,type){
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			formData.append("islock", islock);
			formData.append("type", type);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/lock',formData)
			.then((res) => {
				//console.log(res.data)
				this.checkIfTableIsLock()
				this.$q.notify({
					message: res.data.message,
					color: 'green',
					position:'center',
					actions: [
						{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				})

			});
		},

		reprocessDialog:function(){
			this.confirm = true
			this.monthLabel = this.monthOptions.find(x => x.id === this.month).name;
			this.cutoffLabel = this.cutoffOptions.find(x => x.id === this.cutoff).name;
			console.log(this.test)
		},

		reprocessPayroll:function(){
			this.modEffectLoading = true;
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/payroll/reprocess',formData)
			.then((res) => {
				console.log(res.data)

				setTimeout(() => {
					this.$q.notify({
    					message: 'Payroll Reset!',
    					color: 'green',
    					position:'center',
    					actions: [
    						{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
    					]
    				})

    				this.modEffectLoading = false;
					this.confirm = false
		        }, 1500);

			});
		},

		yearsList : function(){
	        axios.create({
	            baseURL : this.apiUrl,
	            headers : {'Authorization' : `Bearer ${this.token}`}
	        }).post(`/timekeeping/years/list`)
	        .then((res) => {
	            this.yearOptions = res.data.data
	        });
	    },

		selectedType:function(){
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			formData.append("scheduleType", this.scheduleType);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/type',formData).then((res) => {
				this.$q.notify({
					message: "Schdule type changed!",
					color: 'green',
					position:'center',
					actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
				})
			});
		},

		// calendarChange:function(){
		// 	let formData = new FormData();
		// 	formData.append("year", this.yearfilter);
		// 	formData.append("month", this.month);
		// 	formData.append("cutoff", this.cutoff);
		// 	axios.create({
		// 		baseURL : this.apiUrl,
		// 		headers : {'Authorization' : `Bearer ${this.token}`}
		// 	}).post('/schedule/type',formData).then((res) => {
		// 		this.$q.notify({
		// 			message: "Schdule type changed!",
		// 			color: 'green',
		// 			position:'center',
		// 			actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
		// 		})
		// 	});
		//
		// },

		saveCompressedWorkDate:function(){
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			formData.append("ccwdays", this.ccwdays);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/compressed/create',formData)
			.then((res) => {
				console.log(res.data)
			});
		}
	}
}
</script>
<style>
.caps {
	text-transform: uppercase
}
</style>
