<template>
    <div class="q-gutter-sm q-pa-lg">
      <div class="row">
        <div class="col-12 q-mb-md right">
          <h4 class="q-ma-none">Search or Sync Employees </h4><br />
          <q-btn color="negative" @click="syncData" glossy label="sync" />
        </div>
        <div class="col-12 q-mb-sm">
            <q-input label="Search Employee ID" dense outlined v-model="employee_id" @keyup.enter="searchEmp">
              <template v-slot:append>
                <q-icon name="fas fa-search" />
              </template>
            </q-input>
        </div>
        <div class="col-12 q-mb-md">
          <q-table
                v-if="searchEmployee != ''"
                title="Employees"
                dense
                :data="searchEmployee"
                :columns="columns"
                row-key="id"
                :pagination.sync="pagination"
                :loading="loading"
                :filter="searchKey"
            >
              <template v-slot:body="props">
                <q-tr slot="body" :props="props">
                  <q-td key="photo" :props="props">
                    <q-avatar>
                      <img src="../../assets/boy-avatar.png">
                    </q-avatar>
                  </q-td>

                  <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
                  <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                  <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                  <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                  <q-td key="reason" :props="props">{{ props.row.reason }}</q-td>
                  <!-- <q-td key="is_active" :props="props">
                    <q-btn  size="sm" :color="props.row.is_active == 'active' ? 'green': props.row.is_active == 'hold' ? 'orange' : 'red' " rounded :label="props.row.is_active"/>
                  </q-td> -->

                  <q-td key="action" :props="props" >
                    <div>
                      <q-btn v-if="props.row.hold == 'N'" size="sm" round outline color="blue" @click="selectedRow(props.row)" >
                        <i class="fas fa-lock"></i>
                        <q-tooltip anchor="top middle" content-class="bg-blue" self="bottom middle" :offset="[10, 10]" >
                          <strong>Hold Employee</strong>
                        </q-tooltip>
                      </q-btn>
                      <q-btn v-if="props.row.hold == 'Y'" size="sm" round outline color="red" @click="unHold(props.row)" >
                        <i class="fas fa-lock-open"></i>
                        <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]" >
                          <strong>Unhold Employee</strong>
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:no-data="">
                <div class="full-width row flex-center text-red q-gutter-sm">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span>
                    No Result Found!
                  </span>
                </div>
              </template>
              <template v-slot:top-right>
                <q-input dense v-model="searchKey" placeholder="Search Employee">
                  <i class="fas fa-search" slot="append"></i>
                </q-input>
              </template>
            </q-table>
        </div>
          <div class="col-12 q-mb-md">
            <q-table
                title="Hold Employees"
                dense
                :data="holdEmployeeData"
                :columns="columns"
                row-key="id"
                :pagination.sync="pagination"
                :loading="loading"
                :filter="searchKey"
            >
              <template v-slot:body="props">
                <q-tr slot="body" :props="props">
                  <q-td key="photo" :props="props">
                    <q-avatar>
                      <img src="../../assets/boy-avatar.png">
                    </q-avatar>
                  </q-td>

                  <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
                  <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                  <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                  <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                  <q-td key="reason" :props="props">{{ props.row.reason }}</q-td>
                  <!-- <q-td key="is_active" :props="props">
                    <q-btn  size="sm" :color="props.row.is_active == 'active' ? 'green': props.row.is_active == 'hold' ? 'orange' : 'red' " rounded :label="props.row.is_active"/>
                  </q-td> -->

                  <q-td key="action" :props="props" >
                    <div>
                      <q-btn v-if="props.row.hold == 'N'" size="sm" round outline color="blue" @click="selectedRow(props.row)" >
                        <i class="fas fa-lock"></i>
                        <q-tooltip anchor="top middle" content-class="bg-blue" self="bottom middle" :offset="[10, 10]" >
                          <strong>Hold Employee</strong>
                        </q-tooltip>
                      </q-btn>
                      <q-btn v-if="props.row.hold == 'Y'" size="sm" round outline color="red" @click="unHold(props.row)" >
                        <i class="fas fa-lock-open"></i>
                        <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]" >
                          <strong>Unhold Employee</strong>
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:no-data="">
                <div class="full-width row flex-center text-red q-gutter-sm">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span>
                    No Result Found!
                  </span>
                </div>
              </template>
              <template v-slot:top-right>
                <q-input dense v-model="searchKey" placeholder="Search Employee">
                  <i class="fas fa-search" slot="append"></i>
                </q-input>
              </template>
            </q-table>

            <!-- ============================================================= -->
            <q-dialog v-model="prompt" persistent>
                <q-card style="min-width: 350px">
                    <q-card-section>
                    <div class="text-h6">Reason</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        <q-input dense v-model="reason" autofocus />
                    </q-card-section>

                    <q-card-actions align="right" class="text-primary">
                        <q-btn flat label="Cancel" v-close-popup />
                        <q-btn flat label="Submit" v-close-popup  @click="holdEmployee"/>
                    </q-card-actions>
                </q-card>
            </q-dialog>
              <!-- ============================================================= -->
          </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    name: 'Pantry',
    data() {
      return {
        prompt:false,
        loading:false,
        spinner: false,
        searchKey: null,
        searchEmployee: [],
        holdEmployeeData: [],
        employee_id: '',
        pagination: {
          rowsPerPage: 10
        },
        columns: [
        { name: 'photo', align: 'left', label: 'Photo', field: '', sortable: true },
        { name: 'employee_id', align: 'center', label: 'Employee Id', field: 'employee_id', sortable: true },
        { name: 'firstname', align: 'center', label: 'First Name', field: 'firstname', sortable: true },
        { name: 'lastname', align: 'center', label: 'Last Name', field: 'lastname', sortable: true },
        { name: 'reason', align: 'center', label: 'Reason', field: 'reason', sortable: true },
        { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
        ],
        reason:'',
        id: ''
      }

    },

    created() {
        this.getEmployeeList(this.userData.department,'Y')
    },

    methods: {
        getEmployeeList(id,hold){
            var form = new FormData()
            form.append('id',id);
            form.append('hold',hold)
            axios.post(
              this.apiUrl+'/pantry/employee',form,
              { headers : {'Authorization' : `Bearer ${this.token}`}}
            ).then((res)=>{
                // console.log(res);
                this.holdEmployeeData = res.data;
                // this.searchEmployee = res.data;
            })
        },

        selectedRow(data){
            this.prompt = true
            this.id = data.id
        },

        holdEmployee(){
            var form = new FormData();
            form.append('id',this.id)
            form.append('hold','Y')
            form.append('reason',this.reason)
            form.append('user',this.userData.username)
            axios.post(
                this.apiUrl+"/pantry/hold",form,
                {headers : {'Authorization' : `Bearer ${this.token}`}}
            ).then((res) => {
                // console.log('hold',res)
                this.getEmployeeList(this.userData.department,'Y');
                this.searchEmp()
            })
        },

        unHold(data){
            var form = new FormData();
            form.append('id',data.id)
            form.append('hold','N')
            form.append('reason','')
            form.append('user',this.userData.username)
            axios.post(
              this.apiUrl+"/pantry/hold",form,
              {headers : {'Authorization' : `Bearer ${this.token}`}}
            ).then((res) => {
                // console.log('result',res)
                this.getEmployeeList(this.userData.department);
                this.searchEmp()
            })
        },

        syncData(){
          // console.log('test 123',this.userData.department)
          var form = new FormData()
          form.append('dept_id',this.userData.department)
          axios.post(this.apiUrl+'/pantry/sync',form,{
            headers : {'Authorization' : `Bearer ${this.token}`}
          }).then((res) => {
            // console.log('sync data:',res.data)
            this.$q.notify({
              icon: 'fas fa-database',
              message: 'Database sync successfully',
              color: 'positive',
              position:'center',
              timeout: 1000
            })
          })
        },

        searchEmp() {
            // console.log('search',this.employee_id,'Y')
            if(this.employee_id != ''){
              var form = new FormData()
              form.append('id',this.userData.department)
              form.append('empid',this.employee_id)
              axios.post(this.apiUrl+'/pantry/search',form,{
                headers: { 'Authorization' : 'Bearer '+this.token}
              }).then((res) => {
                // console.log('search result',res)
                if(res.data == ''){
                  this.$q.notify({
                      icon: 'fas fa-database',
                      message: 'Employee ID not found',
                      color: 'negative',
                      position:'center',
                      timeout: 1000
                  })
                }
                this.searchEmployee = res.data
              })
            }
        }

    }
  }
  </script>
