<template>
  <div class="q-pa-md">
    <div class="q-pa-md">
      <div class="row">
        <div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
          <q-btn color="primary" label="Filter" @click="fetchFromServer(0,0,0)" />
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
      <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Payroll Summary"
               :data="generatedData"
               :columns="columns"
               row-key="id" separator="cell"
               no-data-label="I didn't find anything for you!"
               :pagination.sync="pagination"
               :loading="loading"
               @request="onRequest"
               binary-state-sort>

        <template v-slot:body="props">
          <q-tr slot="body" :props="props">
            <q-td key="year" :props="props">{{ props.row.year }}</q-td>
            <q-td key="month" :props="props">{{ monthOptions[props.row.month -1].name }}</q-td>
            <q-td key="cut_off" :props="props">{{ (props.row.cut_off == 1) ? 'First Cut-Off' : 'Second Cut-Off'  }}</q-td>
            <q-td key="action" :props="props">
              <q-btn @click="viewDetails(props.row)" color="positive">View Details</q-btn>
            </q-td>
          </q-tr>
        </template>

        <template v-slot:no-data="">
          <div class="full-width row flex-center text-red q-gutter-sm">
            <i class="fas fa-exclamation-triangle"></i>
            <span>The filter didn't uncover any results!</span>
          </div>
        </template>
        <template v-slot:loading>
          <q-inner-loading showing>
            <q-spinner-hourglass  size="50px" color="cyan" />
          </q-inner-loading>
        </template>

        <template v-slot:top="props">
          <q-input dense v-model="filter" placeholder="Search Employee" class="caps">
            <i class="fas fa-search" slot="append"></i>
          </q-input>
          <q-space />
        </template>
      </q-table>
    </div>
      <q-dialog v-model="spinner" persistent>
        <q-card class="my-card transparent no-shadow">
          <q-card-section>
            <q-spinner-gears color="cyan" size="5.5em" />
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog
          v-model="detialsDialog"
          full-height
          full-width
          bg-grey
      >

        <q-card class="my-card no-shadow">
          <q-btn outline color="primary" class="q-ma-sm" label="Download" no-caps @click="exportTable"/>
          <q-card-section>
            <q-table
                title="Payroll History"
                :data="rows"
                :columns="columnDetails"
                row-key="name"
                :pagination="pagination2"
            />

          </q-card-section>
        </q-card>
      </q-dialog>

    </div>

  </template>
  <script>

  import axios from 'axios'
  import {exportFile} from "quasar";

  function wrapCsvValue(val, formatFn) {
    let formatted = formatFn !== void 0 ? formatFn(val) : val

    formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

    formatted = formatted.split('"').join('""')
    return `"${formatted}"`
  }


    export default {
      data() {
        return {
          filter: '',
          loading: false,
          hasPendingInTK: false,
          pagination: {
            // page: 1,
            rowsPerPage: 15,
            // rowsNumber: 10
          },
          pagination2: {
            rowsPerPage: 15,
          },
          detialsDialog: false,
          yearfilter: new Date().getFullYear(),
          yearOptions: [
              2024,2023,2022,2021,2020
          ],
          separator: 'cell',
          spinner: false,
          month: new Date().getMonth() + 1,
          cutoff: 1,
          headers: [],
          schdules: [],
          searchKey: '',
          cutoffOptions: [{id: 1, name: 'First Cut-off'}, {id: 2, name: 'Second Cut-off'}],
          monthOptions: [
            {id: 1, name: 'January'},
            {id: 2, name: 'February'},
            {id: 3, name: 'March'},
            {id: 4, name: 'April'},
            {id: 5, name: 'May'},
            {id: 6, name: 'June'},
            {id: 7, name: 'July'},
            {id: 8, name: 'August'},
            {id: 9, name: 'September'},
            {id: 10, name: 'October'},
            {id: 11, name: 'November'},
            {id: 12, name: 'December'},
          ],

          columns: [
            {name: 'year', align: 'center', label: 'YEAR', field: 'year', sortable: true},
            {name: 'month', align: 'center', label: 'MONTH', field: 'month', sortable: true},
            {name: 'cut_off', align: 'center', label: 'CUTT OFF', field: 'cut_off', sortable: true},
            {name: 'action', align: 'center', label: 'ACTION', field: 'action', sortable: true},
          ],
          generatedData: [],

          columnDetails: [
            { name: 'empID', label: 'EMPLOYEE ID', field: 'empID', sortable: true },
            { name: 'name', label: 'NAME', field: 'name', sortable: true },
            { name: 'UNDERTIME', label: 'UNDERTIME', field: 'UNDERTIME', sortable: true },
            { name: 'ABSENT', required: true, label: 'ABSENT', align: 'left', field: 'ABSENT', sortable: true },
            { name: 'ADJUSTMENT', align: 'center', label: 'ADJUSTMENT', field: 'ADJUSTMENT', sortable: true },
            { name: 'ALLOWANCE', label: 'ALLOWANCE', field: 'ALLOWANCE', sortable: true },
            { name: 'BASIC_PAY', label: 'BASIC_PAY)', field: 'BASIC_PAY' },
            { name: 'CUTOFF_PAY', label: 'CUTOFF_PAY', field: 'CUTOFF_PAY' },
            { name: 'DAILY_RATE', label: 'DAILY_RATE', field: 'DAILY_RATE' },
            { name: 'DEDUCTION', label: 'DEDUCTION', field: 'DEDUCTION', sortable: true},
            { name: 'DEMINIMIS', label: 'DEMINIMIS', field: 'DEMINIMIS', sortable: true },
            { name: 'GROSS_PAY', label: 'GROSS_PAY', field: 'GROSS_PAY', sortable: true },
            { name: 'HOURLY_RATE', label: 'HOURLY_RATE', field: 'HOURLY_RATE', sortable: true },
            { name: 'NET_PAY', label: 'NET_PAY', field: 'NET_PAY', sortable: true },
            { name: 'NIGHT_DIFF', label: 'NIGHT_DIFF', field: 'NIGHT_DIFF', sortable: true },
            { name: 'OVERTIME', label: 'OVERTIME', field: 'OVERTIME', sortable: true },
            { name: 'PAG_IBIG', label: 'PAG_IBIG', field: 'PAG_IBIG', sortable: true },
            { name: 'PHILHEALTH', label: 'PHILHEALTH', field: 'PHILHEALTH', sortable: true },
            { name: 'REG_HOLIDAY', label: 'REG_HOLIDAY', field: 'REG_HOLIDAY', sortable: true },
            { name: 'REG_HOUR', label: 'REG_HOUR', field: 'REG_HOUR', sortable: true },
            { name: 'SPH_HOLIDAY', label: 'SPH_HOLIDAY', field: 'SPH_HOLIDAY', sortable: true },
            { name: 'SSS', label: 'SSS', field: 'SSS', sortable: true },
            { name: 'TAX', label: 'TAX', field: 'TAX', sortable: true },
            { name: 'TOTAL_DEDUCTION', label: 'TOTAL_DEDUCTION', field: 'TOTAL_DEDUCTION', sortable: true },


          ],
          rows: [],
        }
      },
      mounted(){
        this.onRequest({
          pagination: this.pagination,
          filter: ''
        })
      },
      methods: {

        //=================================================
        // fetch all data  on request
        //=================================================

        onRequest (props) {
          const { page, rowsPerPage } = props.pagination

          const filter = props.filter
          this.loading = true
          setTimeout(() => {
            this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
            })
            const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
            const startRow = (page - 1) * rowsPerPage
            this.fetchFromServer(startRow, fetchCount, filter)
            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
          }, 1500)
        },

        //=================================================
        // get total count
        //=================================================

        async getRowsNumberCount (filter) {
          let response = await axios.get(this.apiUrl + '/payroll-history/count', {
            headers: { 'Authorization': `Bearer ${this.token}` },
            params: {
              year: this.yearfilter,
              // month: this.month,
              // cutoff: this.cutoff,
              // filter: filter,
            }
          })
          return response.data
        },

        //=================================================
        // fetch all data in table server side
        //=================================================

        fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/payroll-all-history', {
            headers: { 'Authorization': `Bearer ${this.token}` },
            params: {
              year: this.yearfilter,
              month: this.month,
              cutoff: this.cutoff,
              start: startRow,
              countPerPage: count,
              filter: filter,
            }
          }).then((response) => {
            this.generatedData = [];
            response.data.forEach((data,ind) => {
                this.generatedData.push(
                    {
                      id: data.id,
                      month: data.month,
                      year: data.year,
                      cut_off: data.cut_off,
                      created_by: data.created_by,
                      data: data.data,
                    }
                )
            });
            this.loading = false
          })
        },

        //=================================================
        // fetch all data in table
        //=================================================

        viewDetails(data){
          this.rows = [];
          this.detialsDialog = true
          let formData = new FormData();
          formData.append('month',data.month)
          formData.append('year',data.year)
          formData.append('cutoff',data.cut_off)
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`,
              'Content-Type': 'application/json'
            }
          }).post('/payroll-history',formData).then((res) => {
              res.data.data.forEach((rows, ind) => {
                  this.rows.push(JSON.parse(rows.data))
              })
          })
        },


        exportTable: function() {

          const content = [this.columnDetails.map(col => wrapCsvValue(col.label))].concat(
              this.rows.map(row => this.columnDetails.map(col => wrapCsvValue(
                  typeof col.field === 'function' ?
                      col.field(row) :
                      row[col.field === void 0 ? col.name : col.field],
                  col.format
              )).join(','))
          ).join('\r\n')

          const status = exportFile(
              'Payroll.csv',
              content,
              'text/csv'
          )

          if (status !== true) {
            this.$q.notify({
              message: 'Browser denied file download...',
              color: 'negative',
              icon: 'warning'
            })
          }
        },

      },
      created(){
        // this.getAllHistory()
      }
    }

  </script>
