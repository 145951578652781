<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Final Exam List
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" row-key="id" dense
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('For Final Exam',props.row.id)">
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="date_of_application" :props="props">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" :props="props">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" :props="props">{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="applicant_id" :props="props">{{ props.row.applicant_id }}</q-td>
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
        <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
          <div class="text-h5">{{ vAD.name }}</div>
          <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md">
              <div class="text-bold text-head">Personal Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Present Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.presentAddress}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Permanent Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.permanentAddress}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Email Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.email}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Mobile Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.mobileNumber}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Telephone Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.telNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Gender" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.gender}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Birthday" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.birthday}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Civil Status" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.civilStatus}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Religion" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.religion}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Application Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Know Company Through" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.knowCompanyThru}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Position Applying For" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.positionApplyingFor}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Work Eperiences</div>
              <div class="row q-col-gutter-md">
                <div class="col">
                  <q-field label="With Call Center Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.callCenterExp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Account Handled" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpAccount}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-md">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="With Other Work Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.otherWorkExperience}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Nature of Work" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpBusNature}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Educational Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-2">
                  <q-field label="Highest Educational Attainment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.educAttainment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1">
                  <q-field label="Year Attended" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearAttended}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-5">
                  <q-field label="School / University / College" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.suc}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3" v-if="vAD.educAttainment != 'High School Graduate'">
                  <q-field label="Course" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.course}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1" v-if="vAD.educAttainment == 'College Level'">
                  <q-field label="Year Level" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearLevel}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md" v-if="vAD.natureOfIllness1 != 'N/A'">
              <div class="text-bold text-head">Medical / Health Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness1}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration1}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness2 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness2}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration2}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness3 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness3}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration3}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Emergency Contact</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Contact Person" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactPerson}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Relationship" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactRelation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Assessment</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Assessed By" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessedBy}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Date of Assessment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessmentDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-2">
                  <q-field label="Last Process" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.lastProcess}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="For Final Interview" @click="actionForm('For Final Interview')" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Failed" @click="actionForm('Failed')" class="full-width bg-red-6 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <div class="bg-white" style="height: auto; width: 500px; max-width: 500px;">
        <q-card>
          <q-card-section class="row q-pa-sm bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-exclamation-circle" />
            <span class="q-pl-sm">Application Assessment : [ {{ form.assessorAction }} ]</span>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm" v-if="form.assessorAction == 'Failed'">
            <div class="q-gutter-sm">
              <q-radio v-model="form.failedAssessment" val="Failed" label="Failed" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Reconsideration" label="For Reconsideration" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Pooling" label="For Pooling" color="amber-8" />
            </div>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm">
            <span class="small-text">Assessment:</span>
            <q-input v-model="form.assessorRemarks" dense color="amber-8">
              <template v-slot:append>
                <q-icon name="fa fa-times" @click="form.assessorRemarks = null" class="cursor-pointer" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn flat label="Cancel" @click="form.assessorRemarks = null,form.failedAssessment = null" color="amber-8" v-close-popup />
            <q-btn flat label="Confirm" @click="processAssessment" class="bg-amber-8 text-white" />
          </q-card-actions>
        </q-card>
      </div>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

export default {
  name: 'ForFinalExamNA',
  data() {
    return {
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewDetailsDialog: false,
      actionDialog: false,
      applications: '',
      applicationsData: [],
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [{
          name: 'name',
          field: 'name',
          label: 'Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        }
      ],
      applicationCounts: null,
      vAD: {
        id: null,
        trackingID: null,
        dateApplied: null,
        name: null,
        presentAddress: null,
        permanentAddress: null,
        email: null,
        mobileNumber: null,
        telNumber: null,
        gender: null,
        birthday: null,
        civilStatus: null,
        religion: null,
        knowCompanyThru: null,
        positionApplyingFor: null,
        callCenterExp: null,
        ccExpDuration: null,
        ccExpCompany: null,
        ccExpPosition: null,
        ccExpAccount: null,
        ccExpReasonLeave: null,
        otherWorkExperience: null,
        owExpDuration: null,
        owExpCompany: null,
        owExpPosition: null,
        owExpBusNature: null,
        owExpReasonLeave: null,
        educAttainment: null,
        yearAttended: null,
        suc: null,
        course: null,
        yearLevel: null,
        natureOfIllness1: null,
        illnessDuration1: null,
        natureOfIllness2: null,
        illnessDuration2: null,
        natureOfIllness3: null,
        illnessDuration3: null,
        contactPerson: null,
        contactRelation: null,
        contactNumber: null,
        assessment: null,
        assessedBy: null,
        assessmentDate: null,
        lastProcess: null
      },
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      approveNotifType: ''
    }
  },
  methods: {
    loadApplications: function() {
      this.loadingText = 'Retrieving Applications';

      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }

      this.filter = this.searchKeyword;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/retrieve-applications', {
          params: {
            status: 'For Final Exam'
          }
        })
        .then((res) => {
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          for (let eachApplication of this.applications) {
            let tempDate = date.extractDate(eachApplication.date_of_application, 'YYYY-MM-DD HH:mm:ss');
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            this.applicationsData.push({
              id: eachApplication.id,
              name: eachApplication.name,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_applying_for,
              with_call_center_experience: eachApplication.with_call_center_experience,
              applicant_id: eachApplication.applicant_id
            });
          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    viewDetails: function(applicationStatus, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/application-details', {
          params: {
            id: rowID,
            status: applicationStatus
          }
        })
        .then((res) => {
          this.loadingModel = false;
          var viewApplicantDetails = res.data.data;

          var temp_date_applied, temp_birthdate;

          this.vAD.id = viewApplicantDetails.id;

          this.vAD.trackingID = viewApplicantDetails.tracking_id;

          temp_date_applied = date.extractDate(viewApplicantDetails.date_of_application, 'YYYY-MM-DD HH:mm:ss');
          this.vAD.dateApplied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY');

          this.vAD.name = this.toTitleCase(viewApplicantDetails.firstname) + ' ' + this.toTitleCase(viewApplicantDetails.middlename) + ' ' +
            this.toTitleCase(viewApplicantDetails.lastname);

          this.vAD.presentAddress = this.toTitleCase(viewApplicantDetails.present_address) + ', ' + this.toTitleCase(viewApplicantDetails.present_city_municipality) + ', ' + this.toTitleCase(viewApplicantDetails.present_province);

          this.vAD.permanentAddress = this.toTitleCase(viewApplicantDetails.permanent_address) + ', ' + this.toTitleCase(viewApplicantDetails.permanent_city_municipality) + ', ' + this.toTitleCase(viewApplicantDetails.permanent_province);

          this.vAD.email = viewApplicantDetails.email.toLowerCase();

          this.vAD.mobileNumber = viewApplicantDetails.mobile_number;

          this.vAD.telNumber = viewApplicantDetails.landline_number;

          this.vAD.gender = this.toTitleCase(viewApplicantDetails.gender);

          temp_birthdate = date.extractDate(viewApplicantDetails.date_of_birth, 'YYYY-MM-DD');
          this.vAD.birthday = date.formatDate(temp_birthdate, 'MMMM DD, YYYY');

          this.vAD.civilStatus = this.toTitleCase(viewApplicantDetails.civil_status);

          this.vAD.religion = this.toTitleCase(viewApplicantDetails.religion);
          if (viewApplicantDetails.know_company_through == 'POSTING' || viewApplicantDetails.know_company_through == 'REFERRAL' || viewApplicantDetails.know_company_through == 'OTHERS') {
            this.vAD.knowCompanyThru = this.toTitleCase(viewApplicantDetails.know_company_through) + ': ' + this.toTitleCase(viewApplicantDetails.know_through_suboption);
          } else {
            this.vAD.knowCompanyThru = this.toTitleCase(viewApplicantDetails.know_company_through);
          }

          if (viewApplicantDetails.position_applying_for == 'OTHERS') {
            this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_applying_for) + ': ' + this.toTitleCase(viewApplicantDetails.other_position);
          } else {
            this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_applying_for);
          }

          if (viewApplicantDetails.call_center_experience == 'YES') {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.call_center_experience);
            this.vAD.ccExpDuration = this.toTitleCase(viewApplicantDetails.call_center_exp_years) + ' & ' + this.toTitleCase(viewApplicantDetails.call_center_exp_months);
            this.vAD.ccExpCompany = this.toTitleCase(viewApplicantDetails.cc_exp_company_name);
            this.vAD.ccExpPosition = this.toTitleCase(viewApplicantDetails.call_center_exp_position);
            this.vAD.ccExpAccount = this.toTitleCase(viewApplicantDetails.cc_exp_account_handled);
            this.vAD.ccExpReasonLeave = this.toTitleCase(viewApplicantDetails.cc_exp_reason_leaving);
          } else {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.call_center_experience);
          }

          if (viewApplicantDetails.other_work_experience == 'YES') {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.other_work_experience);
            this.vAD.owExpDuration = this.toTitleCase(viewApplicantDetails.other_exp_years) + ' & ' + this.toTitleCase(viewApplicantDetails.other_exp_months);
            this.vAD.owExpCompany = this.toTitleCase(viewApplicantDetails.other_exp_company_name);
            this.vAD.owExpPosition = this.toTitleCase(viewApplicantDetails.other_exp_position);
            this.vAD.owExpBusNature = this.toTitleCase(viewApplicantDetails.other_exp_nature_business);
            this.vAD.owExpReasonLeave = this.toTitleCase(viewApplicantDetails.other_exp_reason_leaving);
          } else {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.other_work_experience);
          }

          this.vAD.educAttainment = this.toTitleCase(viewApplicantDetails.educational_background);

          this.vAD.yearAttended = viewApplicantDetails.year_attended;

          this.vAD.suc = this.toTitleCase(viewApplicantDetails.suc);

          if (viewApplicantDetails.educational_background == 'DEGREE HOLDER' || viewApplicantDetails.educational_background == 'VOCATIONAL COURSE') {
            this.vAD.course = this.toTitleCase(viewApplicantDetails.course);
          }

          if (viewApplicantDetails.educational_background == 'COLLEGE LEVEL') {
            this.vAD.course = this.toTitleCase(viewApplicantDetails.course);
            this.vAD.yearLevel = this.toTitleCase(viewApplicantDetails.year_level);
          }

          this.vAD.natureOfIllness1 = viewApplicantDetails.nature_of_illness1;

          if (viewApplicantDetails.nature_of_illness1 == 'N/A') {
            this.vAD.illnessDuration1 = 'N/A';
          } else {
            this.vAD.illnessDuration1 = this.toTitleCase(viewApplicantDetails.approx_from_month1) + ' ' + viewApplicantDetails.approx_from_year1 +
              ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month1) + ' ' + viewApplicantDetails.approx_to_year1;
          }

          this.vAD.natureOfIllness2 = viewApplicantDetails.nature_of_illness2;

          if (viewApplicantDetails.nature_of_illness2 == 'N/A') {
            this.vAD.illnessDuration2 = 'N/A';
          } else {
            this.vAD.illnessDuration2 = this.toTitleCase(viewApplicantDetails.approx_from_month2) + ' ' + viewApplicantDetails.approx_from_year2 +
              ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month2) + ' ' + viewApplicantDetails.approx_to_year2;
          }

          this.vAD.natureOfIllness3 = viewApplicantDetails.nature_of_illness3;

          if (viewApplicantDetails.nature_of_illness3 == 'N/A') {
            this.vAD.illnessDuration3 = 'N/A';
          } else {
            this.vAD.illnessDuration3 = this.toTitleCase(viewApplicantDetails.approx_from_month3) + ' ' + viewApplicantDetails.approx_from_year3 +
              ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month3) + ' ' + viewApplicantDetails.approx_to_year3;
          }

          this.vAD.contactPerson = this.toTitleCase(viewApplicantDetails.emergency_contact_name);

          this.vAD.contactRelation = this.toTitleCase(viewApplicantDetails.emergency_contact_relation);

          this.vAD.contactNumber = viewApplicantDetails.emergency_contact_number;

          this.vAD.assessment = viewApplicantDetails.ongoing_assessment;

          this.vAD.assessedBy = viewApplicantDetails.assessor_firstname + ' ' + viewApplicantDetails.assessor_lastname;

          this.vAD.assessmentDate = viewApplicantDetails.date_assessed;

          this.vAD.lastProcess = viewApplicantDetails.last_process;

          this.viewDetailsDialog = true;
        })
    },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/assessment', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.viewDetailsDialog = false;
            this.applicationsData = [];
            this.loadApplications();
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Assessment Done'
            });
            this.form.assessorAction = null;
            this.form.assessorRemarks = null;
            this.form.failedAssessment = null;
            this.form.appID = null;
          }, 1000);
        });
      }
    }
  },

  mounted() {
    this.loadApplications();
  }
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
